import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import {useNavigate} from 'react-router-dom';

import { checkValidity } from '../../../shared/utility';
import { saveSubType, load_user_details } from '../../../store/actions/auth';
import routes from '../../../config/routes';
import Input from '../../../components/UI/Input/Input';
import Footer from '../../Footer/Footer';


function SubType({user, saveSubType, load_user_details, isAuthenticated}) {

    //SELECT FIELDS
    const number = [
        {value: '', displayValue: 'Sélectionner votre réponse'},
        {value: 'A', displayValue: 'A'},
        {value: 'B', displayValue: 'B'},
        {value: 'C', displayValue: 'C'},
    ]

    //STATE
    const [loader, setLoader] = useState(true);

    const [inputs, setInputs] = useState({
        ST1: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "Lors d'une grande fête, j'ai tendance à :",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis",
            proposal_A: "Me promener et dire bonjour à plein de monde",
            proposal_B: "Me tenir près du buffet et à voir qui vient",
            proposal_C: "Me centrer sur une ou deux personnes qui attirent mon attention en ignorant les autres"
        },
        ST2: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "Une des raisons majeures pour laquelle je travaille, c'est que :						",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis",
            proposal_A: "J'aime la sensation de contribuer à quelque chose",
            proposal_B: "Comme ça je peux payer les factures et même faire quelques économies",
            proposal_C: "Cela me donne un endroit où dépenser mon énergie",
        },
        ST3: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "J'aime la sensation :",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis",
            proposal_A: "D'être en contact avec ceux pour qui je me donne du mal",
            proposal_B: "D'avoir mes affaires qui roulent",
            proposal_C: "De voir l'impact que j'ai sur les gens",
        },
        ST4: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "Ce que j'aime le plus dans l'intimité, c'est :",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis",
            proposal_A: "La communication émotionnelle et l'affection",
            proposal_B: "La sensation de sécurité et d'agrément",
            proposal_C: "La sensation de fusionner avec l'autre",
        },
        ST5: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "Quand je pense à mon futur je voudrais :",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis",
            proposal_A: "Avoir influencé les autres par ma vie et mon oeuvre",
            proposal_B: "Avoir créé mon vrai chez moi",
            proposal_C: "Avoir finalement trouvé ma vraie moitié",
        },
        ST6: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "Quand je déménage, un élément important est l'accessibilité que j'aurai :",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis",
            proposal_A: "A de nouvelles personnes intéressantes",
            proposal_B: "A de nouveaux endroits beaux et agréables",
            proposal_C: "A de nouvelles activités stimulantes",
        },
        ST7: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "Quand je pense à l'argent, je considère qu'on devrait :",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis",
            proposal_A:"Le dépenser pour faire des choses avec et pour les gens",
            proposal_B:"Tenir ses comptes et l'investir de manière avisée",
            proposal_C:"L'uitliser et le dépenser quand il est là",
        },
        ST8: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "Quand je voyage, même quand c'est court et rapide, je :",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis",
            proposal_A:"Fais attention à ce que je prends, en fonction des évènements auxquel j'assisterai",
            proposal_B:"Prends trop de tout: nourriture, vêtements, vitamines, ...",
            proposal_C:"Prends de quoi m'occuper et quelques vêtements de base",
        },
        ST9: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "Je suis souvent agacé(e) par :",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis",
            proposal_A:"L'absence de prise en compte des sentiments chez les autres",
            proposal_B:"Ceux qui me volent mon temps et mon énergie",
            proposal_C:"L'absence de profondeur ou de curiosité chez les autres",
        }
    });

    const [valid, setValid] = useState(false);
    const [quizCompleted, setQuizCompleted] = useState(false);

    //Redirect
    const navigate = useNavigate();

    //ComponentDidMount
    useEffect(() => {
        load_user_details();
        if (!user){
            setTimeout(() => {
            setLoader(false);
            }, 1000);
        }
        else {
            if(user.ST){
                navigate(routes.HOME)
            }
            else{
                setLoader(false)
            }
        }
    }, [])

    //ComponentDidUpdate
    useEffect(() => {
        if (!user){
            setTimeout(() => {
            setLoader(false);
            }, 1000);
        }
        else {
            if(user.ST){
                navigate(routes.HOME)
            }
            else{
                setLoader(false)
            }
        }
        const auth = localStorage.getItem('authenticated')
        if (! auth){
            alert('vous devez vous connecter pour répondre au questionnaire.')
            navigate(routes.HOME)
        }
    });

    //Functions

    const InputChangedHandler = (event, id) => {

        //Change Value
        const newInputs = {...inputs};
        newInputs[id].value = event.target.value;
        newInputs[id].touched = true;

        //Check value
        newInputs[id].valid = checkValidity(event.target.value, newInputs[id].validation)

        setInputs(newInputs);

        //Form checking
        let formIsValid = true;
        for (let input in newInputs) {
            formIsValid = newInputs[input].valid && formIsValid;
        }
        setValid(formIsValid);
    };

    const formHandler = event => {
        event.preventDefault();
    }


    //Save Confirmation
    const saveClickedHandler = () => {
        console.log(localStorage);
        const ST = {
            user: user.email, user_email: user.email,
            ST1: inputs.ST1.value, ST2: inputs.ST2.value, ST3: inputs.ST3.value, ST4: inputs.ST4.value, ST5: inputs.ST5.value, ST6: inputs.ST6.value, ST7: inputs.ST7.value,
            ST8: inputs.ST8.value, ST9: inputs.ST9.value
        };
        try{
            saveSubType(
                ST.user, ST.user_email,
                ST.ST1, ST.ST2, ST.ST3, ST.ST4, ST.ST5, ST.ST6, ST.ST7, ST.ST8, ST.ST9
            );
            setQuizCompleted(true);
            navigate(routes.QUIZ)
        }
        catch(err){
            alert("Vérifiez que l'ensemble des champs soient renseignés")
        }
    }

    //Variables

    const formElementArray = [];
    for(let key in inputs) {
        formElementArray.push({
            id: key,
            config: inputs[key]
        });
    };

    let form = (
        <form onSubmit={(e) => formHandler(e)}>
            {formElementArray.map(formElement => (
            <div className="row d-flex justify-content-center mt-5" key={formElement.id}>
                <h3 className="RobotoLight orange mt-5">{formElement.config.label}</h3>
                <h4 className="RobotoLight text-white text-start mt-3"><span className="orange fs-2">A </span>{formElement.config.proposal_A}</h4>
                <h4 className="RobotoLight text-white text-start mt-3"><span className="orange fs-2">B </span>{formElement.config.proposal_B}</h4>
                <h4 className="RobotoLight text-white text-start mt-3"><span className="orange fs-2">C </span>{formElement.config.proposal_C}</h4>
                <Input
                    id={formElement.id}
                    value={formElement.config.value}

                    type={formElement.config.elementType}
                    config={formElement.config.elementConfig}
                    valid={formElement.config.valid}
                    touched={formElement.config.touched}
                    errorMessage={formElement.config.errorMessage}
                    changed={(e) => InputChangedHandler(e, formElement.id)}
                />
            </div>
            ))}
        </form>
    );


    return loader ? (
        <>
            <div className="container-fluid">
                <div className='row d-flex justify-content-center mt-5'>

                </div>
            </div>
        </>
    ) : (
        <div className='container-fluid p-3'>
            <div className='row d-flex justify-content-center mt-5'>
                <h1 className='PoppinsLight or'>Enneagramme: Sous Types</h1>
            </div>
            <div className='row d-flex justify-content-center mt-5'>
                <div className='col-12 col-md-8 cardPurple p-4 mt-3'>
                    <h4 className='RobotoThin text-white'>
                        Faites le choix qui vous semble le plus naturel
                    </h4>
                </div>
            </div>
            <div className='row d-flex justify-content-center mt-5'>
                <div className='col-12 col-md-6 mb-5 mt-4'>
                    {form}
                </div>
            </div>
            <div className='row d-flex justify-content-center gy-3 mt-5 mb-5'>
                <button type='submit' className='signupBtn' onClick={saveClickedHandler} disabled={!valid} >
                    <span className='signupBtnSpan roboto'>Enregistrer</span>
                </button>
            </div>
            <Footer/>
        </div>
    )
};

const mapStateToProps = state => ({
    user: state.auth.user,
    isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps, {saveSubType, load_user_details}) (SubType);