import React from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { Provider } from 'react-redux';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import AOS from 'aos';
import '../../../node_modules/aos/dist/aos.css';

import { AnimatePresence } from "framer-motion";

import storeAccount from '../../store/storeAccount';
import './App.css';
import routes from '../../config/routes';

import Layout from '../../hoc/Layout/Layout';
import Home from '../../components/Home/Home';
import Profile from '../../components/Profile/Profile';
import Admin from '../../components/Admin/Admin';
import UserResults from '../../components/Admin/UserResults/UserResults';
import Validated from '../../components/Admin/Validated/Validated';
import Comment from '../../components/Admin/Validated/Comment';
import UserActivate from '../../components/Admin/Validated/UserActivate';
import Trainings from '../../components/Admin/Trainings/Trainings';
import CreateTraining from '../../components/Admin/Trainings/CreateTraining';
import UpdateTraining from '../../components/Admin/Trainings/UpdateTraining';
import Quiz from '../../components/Quiz/Quiz';
import Login from '../Security/Login/Login';
import UserDetails from '../Security/UserDetails/UserDetails';
import Signup from '../Security/Signup/Signup';
import Activate from '../Security/Activate/Activate';
import Choice from '../Security/Choice/Choice';
import CompanyRegister from '../Security/CompanyRegister/CompanyRegister';
import ResetPassword from '../Security/ResetPassword/ResetPassword';
import ResetPasswordConfirm from '../Security/ResetPasswordConfirm/ResetPasswordConfirm';

import Preferences from '../../components/Quiz/Preferences/Preferences';
import Avoidances from '../../components/Quiz/Avoidances/Avoidances';
import Descriptions from '../../components/Quiz/Descriptions/Descriptions';
import TransactionalAnalysis from '../../components/Quiz/TransactionalAnalysis/TransactionalAnalysis';
import LifePosition from '../../components/Quiz/LifePosition/LifePosition';
import Assertiveness from '../../components/Quiz/Assertiveness/Assertiveness';
import SubType from '../../components/Quiz/SubType/SubType';
import EmotionalRepertoire from '../../components/Quiz/EmotionalRepertoire/EmotionalRepertoire';
import MyersBriggs from '../../components/Quiz/MyersBriggs/MyersBriggs';
import Temperament from '../../components/Quiz/Temperament/Temperament';
import Values from '../../components/Quiz/Values/Values';
import EnergyModel from '../../components/Quiz/EnergyModel/EnergyModel';
import EnergyKeyWord from '../../components/Quiz/EnergyKeyWord/EnergyKeyWord';
import EnergyLaw from '../../components/Quiz/EnergyLaw/EnergyLaw';
import Leadership from '../../components/Quiz/Leadership/Leadership';

import RGPD from '../../components/RGPD/RGPD';
import LegalNotice from '../../components/RGPD/LegalNotice';


function App() {

  AOS.init();
  const location = useLocation();

  return (
    <div className="App">
        <Provider store={storeAccount}>
            <Layout>
                <ToastContainer />
                <Routes location={location} key={location.pathname} >
                    <Route exact path={routes.HOME} element={<Home/>} />
                    <Route exact path={routes.PROFILE} element={<Profile/>} />
                    <Route exact path={routes.ADMIN} element={<Admin/>} />
                    <Route exact path={routes.USER_RESULTS} element={<UserResults/>} />
                    <Route exact path={routes.USER_VALIDATED} element={<Validated/>} />
                    <Route exact path={routes.USER_ACTIVATE} element={<UserActivate/>} />
                    <Route exact path={routes.USER_COMMENT} element={<Comment/>} />
                    <Route exact path={routes.TRAININGS} element={<Trainings/>} />
                    <Route exact path={routes.CREATE_TRAINING} element={<CreateTraining/>} />
                    <Route exact path={routes.UPDATE_TRAINING} element={<UpdateTraining/>} />
                    <Route exact path={routes.USER_DETAILS} element={<UserDetails/>} />
                    <Route exact path={routes.QUIZ} element={<Quiz/>} />
                    <Route exact path={routes.LOGIN} element={<Login/>}/>
                    <Route exact path={routes.SIGNUP} element={<Signup/>}/>
                    <Route exact path={routes.ACTIVATE} element={<Activate/>}/>
                    <Route exact path={routes.CHOICE} element={<Choice/>}/>
                    <Route exact path={routes.COMPANY} element={<CompanyRegister/>}/>
                    <Route exact path={routes.RESET_PASSWORD} element={<ResetPassword/>}/>
                    <Route exact path={routes.RESET_PASSWORD_CONFIRM} element={<ResetPasswordConfirm/>}/>
                    <Route exact path={routes.PREFERENCES} element={<Preferences/>}/>
                    <Route exact path={routes.AVOIDANCES} element={<Avoidances/>}/>
                    <Route exact path={routes.DESCRIPTIONS} element={<Descriptions/>}/>
                    <Route exact path={routes.TRANSACTIONALANALYSIS} element={<TransactionalAnalysis/>}/>
                    <Route exact path={routes.LIFEPOSITION} element={<LifePosition/>}/>
                    <Route exact path={routes.ASSERTIVENESS} element={<Assertiveness/>}/>
                    <Route exact path={routes.SUBTYPE} element={<SubType/>}/>
                    <Route exact path={routes.EMOTIONALREPERTOIRE} element={<EmotionalRepertoire/>}/>
                    <Route exact path={routes.MYERSBRIGGS} element={<MyersBriggs/>}/>
                    <Route exact path={routes.TEMPERAMENT} element={<Temperament/>}/>
                    <Route exact path={routes.VALUES} element={<Values/>}/>
                    <Route exact path={routes.ENERGYMODEL} element={<EnergyModel/>}/>
                    <Route exact path={routes.ENERGYKEYWORD} element={<EnergyKeyWord/>}/>
                    <Route exact path={routes.ENERGYLAW} element={<EnergyLaw/>}/>
                    <Route exact path={routes.LEADERSHIP} element={<Leadership/>}/>
                    <Route exact path={routes.RGPD} element={<RGPD/>}/>
                    <Route exact path={routes.LEGALNOTICE} element={<LegalNotice/>}/>
                    <Route render={() => <h1>404</h1>} />
                </Routes>
            </Layout>
        </Provider>
    </div>
  );
}

export default App;
