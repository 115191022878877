import React, {useState, useEffect} from 'react';
import NavItems from './NavigationItems/NavigationItems';
import { connect } from 'react-redux';
import { logout } from '../../../store/actions/auth';
import {useNavigate} from 'react-router-dom';

import classes from './Navigation.module.css'
import brand from './nav_picts/logo-d3s.png';
import signOut from './nav_picts/onOff.png';
import routes from '../../../config/routes';


function Navbar({logout, isAuthenticated}){

    const disconnect = () => {
        navigate(routes.HOME);
        logout();
    }

    const [mins, setMinutes] = useState();
    const [secs, setSeconds] = useState();

    useEffect(() => {
        const auth = localStorage.getItem('authenticated')
        if (auth){
            const deadLine = Math.floor (240 * 60 * 1000);
            const loginDate = localStorage.getItem("loginDate")
            const date = Date.parse(loginDate)
            const endtime =  date + deadLine;

            const startingMinutes = Math.floor (((endtime - new Date()) / 1000/60));

            const startingSeconds = Math.floor (((endtime - new Date() - startingMinutes) / 1000) % 60);
            setMinutes(startingMinutes);
            setSeconds(startingSeconds)

           let sampleInterval = setInterval(() => {
                if (secs > 0) {
                    setSeconds(secs - 1);
                  }
                if (secs === 0 || secs <= 0) {
                    if (mins === 0 || mins <= 0) {
                      disconnect();
                    } else {
                      setMinutes(mins - 1);
                      setSeconds(59);
                    }
                }
           }, 1000);
            return () => {
               clearInterval(sampleInterval);
            };
        }
    });


    //REDIRECT
    const navigate = useNavigate();

    //FUNCTIONS

    const guestLinks = () => (
        <>
            <NavItems exact to={routes.LOGIN}>Connexion</NavItems>
        </>
    );

    const authLinks = () => (
        <>
            <NavItems exact to={routes.PROFILE}>Profil</NavItems>
            <a type="button" className="nav-link purple ms-2" onClick={disconnect}><img src={signOut} className="img squareSize20" alt="logout"/></a>
            <p className="nav-link purple ms-2">
              {mins}:{secs < 10 ? `0${secs}` : secs}
            </p>

        </>
    );

    return (
        <nav className={[classes.navi, "navbar navbar-expand-sm"].join(" ")}>
          <div className="container-fluid">
            <div className="navbar-brand">
               <button className={classes.togBtn} type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                   <img src={ brand } className={[classes.imgBrand, "navBrand"].join(" ")} alt='brand'/>
               </button>
            </div>

            <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
              <div className="col-12 col-sm-6 col-md-6 col-lg-3 navbar-nav smartCard">
                <NavItems exact to={routes.HOME}>Accueil</NavItems>
                {isAuthenticated ? authLinks() : guestLinks()}
              </div>
            </div>
          </div>
        </nav>
    );
};

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps, {logout}) (Navbar);

