//Library
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { checkValidity } from '../../../shared/utility';
import { load_user_details } from '../../../store/actions/auth';
import { create_training } from '../../../store/actions/train';
import routes from '../../../config/routes';

//Components
import Input from '../../UI/Input/Input';

function CreateTraining({load_user_details, create_training, user, isAuthenticated}) {

    //STATE
    const [loader, setLoader] = useState(true);
    const [selectedFile, setSelectedFile] = useState()

    const active = [
        {value: '', displayValue: ''},
        {value: true, displayValue: "ACTIVE"},
        {value: false, displayValue: "INACTIVE"},
    ]

    //State
    const [inputs, setInputs] = useState({
        name: {
            elementType: 'input',
            elementConfig: {
                type: 'name',
                placeholder: 'Titre'
            },
            value: '',
            label: 'Titre',
            valid: false,
            validation: {
                required: true,
                maxLength: 150,
            },
            touched: false,
            errorMessage: "Champ requis - 150 caractères au maximum"
        },
        description: {
            elementType: 'textarea',
            elementConfig: {
                type: 'name',
                placeholder: 'Description'
            },
            value: '',
            label: 'Description',
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: ""
        },
        start: {
            elementType: 'input',
            elementConfig: {
                type: 'date',
                placeholder: '__-__-____'
            },
            value: '',
            label: 'Date de début',
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis"
        },
        end: {
            elementType: 'input',
            elementConfig: {
                type: 'date',
                placeholder: '__-__-____'
            },
            value: '',
            label: 'Date de fin',
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis"
        },
        is_active: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: active
            },
            value: '',
            label: "Statut de la formation",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis",
        },
        thumbnail: {
            elementType: 'input',
            elementConfig: {
                type: 'file',
                placeholder: ''
            },
            value: '',
            label: "Image ou photo",
            valid: true,
            validation: {
                noRules: true,
            },
            touched: false,
            errorMessage: "Champs requis"
        }
    });

    const [valid, setValid] = useState(false);

    //Functions

    const InputChangedHandler = (event, id) => {

        //Change Value
        const newInputs = {...inputs};
        newInputs[id].value = event.target.value;
        newInputs[id].touched = true;

        if(newInputs[id].elementConfig.type === 'file'){
            setSelectedFile(event.target.files[0]);
        }

        //Check value
        newInputs[id].valid = checkValidity(event.target.value, newInputs[id].validation)

        setInputs(newInputs);

        //Form checking
        let formIsValid = true;
        for (let input in newInputs) {
            formIsValid = newInputs[input].valid && formIsValid;
        }
        setValid(formIsValid);
    };

    const formHandler = event => {
        event.preventDefault();
    }

    //ComponentDidMount
    useEffect(() => {
        load_user_details();
        const auth = localStorage.getItem('authenticated');
        if (! auth){
            navigate(routes.HOME)
            alert('vous devez vous connecter pour accéder à votre profil')
        }
    }, [])

    //ComponentDidUpdate
     useEffect(() => {
        const auth = localStorage.getItem('authenticated');
        if (! auth){
            navigate(routes.HOME)
            alert('vous devez vous connecter pour accéder à votre profil')
        }
        if (!user){
            navigate(routes.LOGIN)
        }
        else {
            if (user.is_front_admin === false){
                navigate(routes.HOME)
            }
        }
    });



    //Redirect
    const navigate = useNavigate();

    //Confirm Register
    const registerClickedHandler = () => {

        const training = {
            name: inputs.name.value,
            description: inputs.description.value,
            start: inputs.start.value,
            end: inputs.end.value,
            is_active: inputs.is_active.value,
            thumbnail: selectedFile,
        };

        try{
            create_training(training.name, training.description, training.start, training.end, training.is_active, training.thumbnail);
            navigate(routes.TRAININGS);
        }
        catch(err){
            alert("Vérifiez que l'ensemble des champs soient renseignés")
        }

    }

    //Variables

    const formElementArray = [];
    for(let key in inputs) {
        formElementArray.push({
            id: key,
            config: inputs[key]
        });
    };

    let form = (
        <form onSubmit={(e) => formHandler(e)}>
            {formElementArray.map(formElement => (
                <div className="row d-flex justify-content-center mt-5" key={formElement.id}>
                    <Input
                        key={formElement.id}
                        id={formElement.id}
                        value={formElement.config.value}
                        label={formElement.config.label}
                        type={formElement.config.elementType}
                        config={formElement.config.elementConfig}
                        valid={formElement.config.valid}
                        touched={formElement.config.touched}
                        errorMessage={formElement.config.errorMessage}
                        changed={(e) => InputChangedHandler(e, formElement.id)}
                        proposal_A={formElement.config.proposal_A}
                        proposal_B={formElement.config.proposal_B}
                    />
                </div>
            ))}
            <div className='row d-flex justify-content-center gy-3 mt-5 mb-5'>
                <button type='button' className='signupBtn' onClick={registerClickedHandler} disabled={!valid}><span className='signupBtnSpan RobotoBold'>Enregistrer</span></button>
            </div>
        </form>
    );


    return (
        <div className='container-fluid'>
            <div className='row d-flex justify-content-center my-5'>
            <h1 className='text-center mt-5 PoppinsLight or'>Création de formation</h1>
                <div className='col-12 col-md-6'>
                    {form}
                </div>
            </div>
        </div>
    )
};

const mapStateToProps = state => ({
    user: state.auth.user,
    isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps, { load_user_details, create_training }) (CreateTraining);