import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import {useNavigate} from 'react-router-dom';

import { checkValidity } from '../../../shared/utility';
import { saveMyersBriggs, load_user_details } from '../../../store/actions/auth';
import routes from '../../../config/routes';
import Input from '../../../components/UI/Input/Input';
import Footer from '../../Footer/Footer';


function MyersBriggs({user, saveMyersBriggs, load_user_details, isAuthenticated}) {

    //SELECT FIELDS
    const number = [
        {value: '', displayValue: 'Sélectionner votre réponse'},
        {value: 'A', displayValue: 'A'},
        {value: 'B', displayValue: 'B'},
    ]

    //STATE
    const [loader, setLoader] = useState(true);

    const [inputs, setInputs] = useState({
        MB1: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis",
            proposal_A: "A. Je fais preuve d'une calme énergie",
            proposal_B: "B. Je suis très dynamique",
        },
        MB2: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis",
            proposal_A: "A. J' écoute plus que je parle",
            proposal_B: "B. Je parle plus que j' écoute",
        },
        MB3: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis",
            proposal_A: "A. Je réfléchis posément",
            proposal_B: "B. Je pense tout haut",
        },
        MB4: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis",
            proposal_A: "A. Je pense, puis j'agis",
            proposal_B: "B. J' agis, puis je pense",
        },
        MB5: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis",
            proposal_A: "A. Je me sens bien quand je suis seul",
            proposal_B: "B. Je me sens bien quand je suis entouré",
        },
        MB6: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis",
            proposal_A: "A. Je me considère comme plutôt secret(e) et réservé(e)",
            proposal_B: "B. Je me considère comme facile à comprendre",
        },
        MB7: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis",
            proposal_A: "A. Je possède une bonne capacité de concentration",
            proposal_B: "B. Je peux  facilement être distrait",
        },
        MB8: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis",
            proposal_A: "A. Je me concentre sur une seule chose à la fois",
            proposal_B: "B. Je préfère faire plusieurs choses à la fois",
        },
        MB9: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis",
            proposal_A: "A. Vous êtes indépendant(e), peu communicatif(ve)",
            proposal_B: "B. Vous êtes  expansif(ve) et enthousiaste",
        },
        MB10: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis",
            proposal_A: "A. Dans la queue, j' attends sans rien dire",
            proposal_B: "B. Dans la queue je bavarde facilement avec les autres",
        },
        MB11: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis",
            proposal_A: "A. Si je  dois rencontrer des inconnus, ça me fatigue",
            proposal_B: "B. Si je dois rencontrer des inconnus ça me met en forme",
        },
        MB12: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis",
            proposal_A: "A. Le téléphone sonne, j' espère que quelqu'un d'autre répondra",
            proposal_B: "B. Le téléphone sonne je me précipite pour répondre",
        },
        MB13: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis",
            proposal_A: "A. J'ai  tendance à écouter d'abord ce qui va se dire",
            proposal_B: "B. J'ai tendance à dire rapidement ce que je pense",
        },
        MB14: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis",
            proposal_A: "A. J'élabore mes idées en y réfléchissant",
            proposal_B: "B. J'élabore mes idées en parlant",
        },
        MB15: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis",
            proposal_A: "A. Je suis plus doué(e) pour écouter autrui",
            proposal_B: "B. Je suis plus doué(e) pour tenir une conversation",
        },
        MB16: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis",
            proposal_A: "A. Je me considère comme quelqu'un de réservé",
            proposal_B: "B. Je me considère comme quelqu'un d'ouvert",
        },
        MB17: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis",
            proposal_A: "A. Je préfère être seul pour y réfléchir",
            proposal_B: "B. Je préfère être en groupe pour en discuter",
        },
        MB18: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis",
            proposal_A: "A. Je préfère m'exprimer par l'écriture",
            proposal_B: "B. Je préfère m'exprimer par la parole",
        },
        MB19: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis",
            proposal_A: "A. J'ai  des relations sélectives",
            proposal_B: "B. J'ai  de nombreuses relations",
        },
        MB20: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis",
            proposal_A: "A. Je me concentre sur les faits et les détails précis",
            proposal_B: "B. Je m'intéresse aux idées et aux grandes synthèses",
        },
        MB21: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis",
            proposal_A: "A. J'apprécie les solutions pratiques",
            proposal_B: "B. Je remarque tout ce qui est nouveau et différent",
        },
        MB22: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis",
            proposal_A: "A. Je note les détails et j'ai la mémoire des faits",
            proposal_B: "B. Je pense aux implications futures",
        },
        MB23: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis",
            proposal_A: "A. Je me fie à l'expérience",
            proposal_B: "B. Je me fie à l'inspiration, et mon intuition",
        },
        MB24: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis",
            proposal_A: "A. J'aime me remémorer les expériences passées",
            proposal_B: "B. J'aime me projeter dans l'avenir",
        },
        MB25: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis",
            proposal_A: "A. Je suis orienté sur les réalités du moment",
            proposal_B: "B. Je suis orienté vers les possibilités futures",
        },
        MB26: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis",
            proposal_A: "A. Je préfère me fier aux compétences reconnues",
            proposal_B: "B. J'aime apprendre de nouvelles compétences",
        },
        MB27: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis",
            proposal_A: "A. Je préfère les instructions étapes par étapes",
            proposal_B: "B. Je suis imaginatif : je vois ce qui pourrait se faire",
        },
        MB28: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis",
            proposal_A: "A. Je travaille à un rythme régulier",
            proposal_B: "B. Je déborde d'énergie",
        },
        MB29: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis",
            proposal_A: "A. Je suis réaliste, concret",
            proposal_B: "B. Je suis attiré par les idées originales",
        },
        MB30: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis",
            proposal_A: "A. Je suis   plus porté sur l'observation",
            proposal_B: "B. Je suis  plus porté sur l'introspection",
        },
        MB31: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis",
            proposal_A: "A. J'ai tendance à m' attacher plus aux faits",
            proposal_B: "B. j'ai tendance à m' attacher plus aux conjonctures",
        },
        MB32: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis",
            proposal_A: "A. Je  préfère d'un écrivain qu'il écrive les choses noir sur blanc",
            proposal_B: "B. Je préfère d'un écrivain qu'il utilise des métaphores et symboles",
        },
        MB33: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis",
            proposal_A: "A. Je pense que : le bon sens est  généralement fiable",
            proposal_B: "B. Je pense que : le bon sens est  fréquemment trompeur",
        },
        MB34: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis",
            proposal_A: "A. Mon bon sens me suggère des solutions pratiques",
            proposal_B: "B. Mon imagination me suggère des solutions nouvelles",
        },
        MB35: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis",
            proposal_A: "A. Je pense que  les enfants ne se rendent pas assez utiles",
            proposal_B: "B. Je pense que  les enfants n'utilisent pas assez leur imagination",
        },
        MB36: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis",
            proposal_A: "A. J' utilise plus souvent mon sens pratique",
            proposal_B: "B. J' utilise plus souvent mon imagination",
        },
        MB37: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis",
            proposal_A: "A. J'appréhende les choses dans leurs détails",
            proposal_B: "B. J'appréhende les choses dans leur globalité",
        },
        MB38: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis",
            proposal_A: "A. Je m' intéresse plus aux faits",
            proposal_B: "B. Je m' intéresse plus aux possibilités",
        },
        MB39: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis",
            proposal_A: "A. Je suis naturellement objectif dans mes décisions",
            proposal_B: "B. Je fonde mes décisions sur mes valeurs et mes sentiments",
        },
        MB40: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis",
            proposal_A: "A. Je peux paraitre calme et froid au premier abord",
            proposal_B: "B. Je peux paraitre chaleureux et amical spontanément",
        },
        MB41: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis",
            proposal_A: "A. J' apprécie la loyauté et la justice",
            proposal_B: "B. J' apprécie l'harmonie et la compassion",
        },
        MB42: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis",
            proposal_A: "A. Je m' implique émotionnellement  dans peu de choses",
            proposal_B: "B. Je prends les choses à cœur",
        },
        MB43: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis",
            proposal_A: "A. Je remarque vite les failles et les défauts",
            proposal_B: "B. Je suis  prompt à faire des compliments",
        },
        MB44: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis",
            proposal_A: "A. J'aime argumenter pour le plaisir",
            proposal_B: "B. J'évite la discussion et le conflit",
        },
        MB45: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis",
            proposal_A: "A. Je suis motivé par la réalisation de ce que j'entreprends",
            proposal_B: "B. je suis motivé par les félicitations et la reconnaissance",
        },
        MB46: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis",
            proposal_A: "A. Je suis  sensible à la logique",
            proposal_B: "B. Je fais  confiance à mes impressions",
        },
        MB47: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis",
            proposal_A: "A. Je suis honnête et direct",
            proposal_B: "B. Je suis diplomate et fais  preuve de tact",
        },
        MB48: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis",
            proposal_A: "A. Je préfère avoir de la suite dans les idées",
            proposal_B: "B. Je préfère avoir des relations harmonieuses",
        },
        MB49: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis",
            proposal_A: "A. Si je dois décevoir quelqu'un : en étant franc et direct",
            proposal_B: "B. Si je dois décevoir quelqu'un : en étant  amical et empathique",
        },
        MB50: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis",
            proposal_A: "A. Dans une discussion animée, je campe plutôt sur mes positions",
            proposal_B: "B. Dans une discussion animée, je cherche avant tout un terrain d'entente",
        },
        MB51: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis",
            proposal_A: "A. Quel est le meilleur compliment : que je suis logique",
            proposal_B: "B. Quel est le meilleur compliment : que je suis sensible",
        },
        MB52: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis",
            proposal_A: "A. Qu'est ce qui me fera fléchir : des preuves incontestables",
            proposal_B: "B. Qu'est ce qui me fera fléchir : un plaidoyer touchant",
        },
        MB53: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis",
            proposal_A: "A. Je souhaiterais avoir : plus de volonté",
            proposal_B: "B. Je souhaiterais avoir : plus d'émotions",
        },
        MB54: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis",
            proposal_A: "A. Je pense que : Le monde ne peut pas vivre sans raison",
            proposal_B: "B. Je pense que : Le monde ne peut pas vivre sans amour",
        },
        MB55: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis",
            proposal_A: "A. Je me demande surtout si c'est bien vrai ",
            proposal_B: "B. Je me demande surtout si c'est bien important",
        },
        MB56: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis",
            proposal_A: "A. Pour moi,  la bonne décision doit avant tout être factuelle et logique ",
            proposal_B: "B. Pour moi, La bonne décision doit avant tout faire consensus",
        },
        MB57: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis",
            proposal_A: "A. En général :je garde la tête froide ",
            proposal_B: "B. En général : je suis chaleureux",
        },
        MB58: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis",
            proposal_A: "A. J' aime prendre des décisions",
            proposal_B: "B. Je remets  les décisions à plus tard chaque fois que je le  peux",
        },
        MB59: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis",
            proposal_A: "A. Je suis  sérieux et conventionnel",
            proposal_B: "B. Je suis ludique et non-conventionnel",
        },
        MB60: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis",
            proposal_A: "A. Je suis mon calendrier et suis parfaitement ponctuel",
            proposal_B: "B. Je n'ai ni heure ni délai",
        },
        MB61: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis",
            proposal_A: "A. Je range mon bureau parce qu'il le faut",
            proposal_B: "B. Je range mon bureau quand j'en ai envie",
        },
        MB62: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis",
            proposal_A: "A. L'exactitude est la politesse des rois",
            proposal_B: "B. A l'impossible nul n'est tenu",
        },
        MB63: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis",
            proposal_A: "A. Ma vie est plutôt organisée, méthodique et planifiée",
            proposal_B: "B. Ma vie est plutôt spontanée, flexible et décontractée",
        },
        MB64: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis",
            proposal_A: "A. Je vais  au bout de mes projets",
            proposal_B: "B. Je préfère démarrer des projets plutôt que les mener au bout",
        },
        MB65: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis",
            proposal_A: "A. Je travaille d'abord, et je m'amuserai après",
            proposal_B: "B. Je m' amuse d'abord et je travaillerai ensuite",
        },
        MB66: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis",
            proposal_A: "A. Je  veux  des décisions fermes",
            proposal_B: "B. J'ai du mal à m' engager",
        },
        MB67: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis",
            proposal_A: "A. Je ne  discute pas les règles",
            proposal_B: "B. Je  discute parfois  les règles",
        },
        MB68: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis",
            proposal_A: "A. J'aime  faire des plans et les suivre",
            proposal_B: "B. Je préfère  conserver ma liberté d'action",
        },
        MB69: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis",
            proposal_A: "A. Je suis à l'aise au sein de plans bien définis",
            proposal_B: "B. Je n'aime pas les programmes rigides",
        },
        MB70: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis",
            proposal_A: "A. Je préfère  avoir pris une décision",
            proposal_B: "B. Je préfère devoir prendre une décision",
        },
        MB71: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis",
            proposal_A: "A. A la fin d'un travail je préfère tout laisser bien en ordre",
            proposal_B: "B. A la fin d'un travail je passe facilement à autre chose",
        },
        MB72: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis",
            proposal_A: "A. Qu'est ce qui me satisfait le plus : le produit fini",
            proposal_B: "B. Qu'est ce qui me satisfait le plus : le travail en cours",
        },
        MB73: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis",
            proposal_A: "A. Je suis du genre à : tout fignoler",
            proposal_B: "B. Je suis du genre à : explorer les possibilités",
        },
        MB74: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis",
            proposal_A: "A. Je suis plus à l'aise pour : arriver à une décision",
            proposal_B: "B. Je suis plus à l'aise pour : garder plusieurs possibilités",
        },
        MB75: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis",
            proposal_A: "A. Je préfère travailler : selon un plan d'action",
            proposal_B: "B. Je préfère travailler : en improvisation",
        },
        MB76: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis",
            proposal_A: "A. Je préfère organiser des soirées, rdv à l'avance",
            proposal_B: "B. Je préfère garder la liberté de faire ce qui me plait le moment venu",
        },
        MB77: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis",
            proposal_A: "A. Lorsque j'ai un travail à accomplir, je l'organise avec soin avant",
            proposal_B: "B. Lorsque j'ai un travail à accomplir, j'aime découvrir au fur et à mesure",
        },
        MB78: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis",
            proposal_A: "A. Faire les choses à la dernière minute est une source de stress",
            proposal_B: "B. Faire les choses à la dernière minute est une stimulation",
        },
        MB79: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis",
            proposal_A: "A. Etablir un programme pour les activités quotidiennes m'est agréable",
            proposal_B: "B. Etablir un programme pour les activités quotidiennes m'est désagréable et pas naturel",
        },
        MB80: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis",
            proposal_A: "A. Je planifie à court et long terme",
            proposal_B: "B. Je m'adapte au fur et à mesure et change d'orientation",
        },
    });

    const [valid, setValid] = useState(false);
    const [quizCompleted, setQuizCompleted] = useState(false);

    //Redirect
    const navigate = useNavigate();

    //ComponentDidMount
    useEffect(() => {
        load_user_details();
        if (!user){
            setTimeout(() => {
            setLoader(false);
            }, 1000);
        }
        else {
            if(user.MB){
                navigate(routes.HOME)
            }
            else{
                setLoader(false)
            }
        }
    }, [])

    //ComponentDidUpdate
    useEffect(() => {
        if (!user){
            setTimeout(() => {
            setLoader(false);
            }, 1000);
        }
        else {
            if(user.MB){
                navigate(routes.HOME)
            }
            else{
                setLoader(false)
            }
        }
        const auth = localStorage.getItem('authenticated')
        if (! auth){
            alert('vous devez vous connecter pour répondre au questionnaire.')
            navigate(routes.HOME)
        }
    });

    //Functions

    const InputChangedHandler = (event, id) => {

        //Change Value
        const newInputs = {...inputs};
        newInputs[id].value = event.target.value;
        newInputs[id].touched = true;

        //Check value
        newInputs[id].valid = checkValidity(event.target.value, newInputs[id].validation)

        setInputs(newInputs);

        //Form checking
        let formIsValid = true;
        for (let input in newInputs) {
            formIsValid = newInputs[input].valid && formIsValid;
        }
        setValid(formIsValid);
    };

    const formHandler = event => {
        event.preventDefault();
    }


    //Save Confirmation
    const saveClickedHandler = () => {
        console.log(localStorage);
        const MB = {
            user: user.email, user_email: user.email,
            MB1: inputs.MB1.value, MB2: inputs.MB2.value, MB3: inputs.MB3.value, MB4: inputs.MB4.value, MB5: inputs.MB5.value,
            MB6: inputs.MB6.value, MB7: inputs.MB7.value, MB8: inputs.MB8.value, MB9: inputs.MB9.value, MB10: inputs.MB10.value,
            MB11: inputs.MB11.value, MB12: inputs.MB12.value, MB13: inputs.MB13.value, MB14: inputs.MB14.value, MB15: inputs.MB15.value,
            MB16: inputs.MB16.value, MB17: inputs.MB17.value, MB18: inputs.MB18.value, MB19: inputs.MB19.value, MB20: inputs.MB20.value,
            MB21: inputs.MB21.value, MB22: inputs.MB22.value, MB23: inputs.MB23.value, MB24: inputs.MB24.value, MB25: inputs.MB25.value,
            MB26: inputs.MB26.value, MB27: inputs.MB27.value, MB28: inputs.MB28.value, MB29: inputs.MB29.value, MB30: inputs.MB30.value,
            MB31: inputs.MB31.value, MB32: inputs.MB32.value, MB33: inputs.MB33.value, MB34: inputs.MB34.value, MB35: inputs.MB35.value,
            MB36: inputs.MB36.value, MB37: inputs.MB37.value, MB38: inputs.MB38.value, MB39: inputs.MB39.value, MB40: inputs.MB40.value,
            MB41: inputs.MB41.value, MB42: inputs.MB42.value, MB43: inputs.MB43.value, MB44: inputs.MB44.value, MB45: inputs.MB45.value,
            MB46: inputs.MB46.value, MB47: inputs.MB47.value, MB48: inputs.MB48.value, MB49: inputs.MB49.value, MB50: inputs.MB50.value,
            MB51: inputs.MB51.value, MB52: inputs.MB52.value, MB53: inputs.MB53.value, MB54: inputs.MB54.value, MB55: inputs.MB55.value,
            MB56: inputs.MB56.value, MB57: inputs.MB57.value, MB58: inputs.MB58.value, MB59: inputs.MB59.value, MB60: inputs.MB60.value,
            MB61: inputs.MB61.value, MB62: inputs.MB62.value, MB63: inputs.MB63.value, MB64: inputs.MB64.value, MB65: inputs.MB65.value,
            MB66: inputs.MB66.value, MB67: inputs.MB67.value, MB68: inputs.MB68.value, MB69: inputs.MB69.value, MB70: inputs.MB70.value,
            MB71: inputs.MB71.value, MB72: inputs.MB72.value, MB73: inputs.MB73.value, MB74: inputs.MB74.value, MB75: inputs.MB75.value,
            MB76: inputs.MB76.value, MB77: inputs.MB77.value, MB78: inputs.MB78.value, MB79: inputs.MB79.value, MB80: inputs.MB80.value,
        };
        try{
            saveMyersBriggs(
                MB.user, MB.user_email,
                MB.MB1, MB.MB2, MB.MB3, MB.MB4, MB.MB5, MB.MB6, MB.MB7, MB.MB8, MB.MB9, MB.MB10,
                MB.MB11, MB.MB12, MB.MB13, MB.MB14, MB.MB15, MB.MB16, MB.MB17, MB.MB18, MB.MB19, MB.MB20,
                MB.MB21, MB.MB22, MB.MB23, MB.MB24, MB.MB25, MB.MB26, MB.MB27, MB.MB28, MB.MB29, MB.MB30,
                MB.MB31, MB.MB32, MB.MB33, MB.MB34, MB.MB35, MB.MB36, MB.MB37, MB.MB38, MB.MB39, MB.MB40,
                MB.MB41, MB.MB42, MB.MB43, MB.MB44, MB.MB45, MB.MB46, MB.MB47, MB.MB48, MB.MB49, MB.MB50,
                MB.MB51, MB.MB52, MB.MB53, MB.MB54, MB.MB55, MB.MB56, MB.MB57, MB.MB58, MB.MB59, MB.MB60,
                MB.MB61, MB.MB62, MB.MB63, MB.MB64, MB.MB65, MB.MB66, MB.MB67, MB.MB68, MB.MB69, MB.MB70,
                MB.MB71, MB.MB72, MB.MB73, MB.MB74, MB.MB75, MB.MB76, MB.MB77, MB.MB78, MB.MB79, MB.MB80
            );
            setQuizCompleted(true);
            navigate(routes.QUIZ)
        }
        catch(err){
            alert("Vérifiez que l'ensemble des champs soient renseignés")
        }
    }

    //Variables

    const formElementArray = [];
    for(let key in inputs) {
        formElementArray.push({
            id: key,
            config: inputs[key]
        });
    };

    let form = (
        <form onSubmit={(e) => formHandler(e)}>
            {formElementArray.map(formElement => (
            <div className="row d-flex justify-content-center mt-5 mb-5 text-white" key={formElement.id}>
                <h4 className="RobotoLight text-start">{formElement.config.proposal_A}</h4>
                <h4 className="RobotoLight text-start">{formElement.config.proposal_B}</h4>
                <Input
                    id={formElement.id}
                    value={formElement.config.value}
                    label={formElement.config.label}
                    type={formElement.config.elementType}
                    config={formElement.config.elementConfig}
                    valid={formElement.config.valid}
                    touched={formElement.config.touched}
                    errorMessage={formElement.config.errorMessage}
                    changed={(e) => InputChangedHandler(e, formElement.id)}

                />
            </div>
            ))}
        </form>
    );


    return loader ? (
        <>
            <div className="container-fluid">
                <div className='row d-flex justify-content-center mt-5'>

                </div>
            </div>
        </>
    ) : (
        <div className='container-fluid p-3'>
            <div className='row d-flex justify-content-center mt-5'>
                <h1 className='PoppinsLight or'>Myers & Briggs Type Indicator</h1>
            </div>
            <div className='row d-flex justify-content-center mt-5 RobotoLight'>
                <div className='col-12 col-md-8 cardPurple p-5 text-white text-start mt-5'>
                    <h5 className='RobotoThin text-center mb-5'>
                        MBTI®
                    </h5>
                    <p>
                        Les résultats du MBTI identifient les différences qui existent entre les personnes. Différences qui
                        peuvent être la cause de nombreux malentendus.
                    </p>
                    <p>
                        Vos réponses au MBTI vous permettront, au cours de l’entretien qui suivra, d’identifier vos talents
                        personnels. Ces informations vous aideront à mieux vous connaître, à découvrir ce qui vous motive,
                        vos atouts naturels ainsi que les domaines dans lesquels vous pouvez vous développer.
                    </p>
                    <p>
                        Elles vous aideront également à apprécier les personnes qui sont différentes de vous.
                    </p>
                    <p>
                        Une bonne compréhension de votre type MBTI vous permettra de vous affirmer et de développer la
                        coopération avec les autres. Les auteurs du MBTI, Katharine Cook Briggs (1875–1968) et sa fille,
                        Isabel Briggs Myers (1897–1980), ont observé avec rigueur et enthousiasme les différences de
                        personnalités.
                    </p>
                    <p>
                        Elles étudièrent et développèrent les idées du psychiatre suisse Carl G. Jung (1875–1961) puis les
                        utilisèrent pour mieux comprendre les personnes de leur entourage
                    </p>
                    <p>
                        Le MBTI® de nos jours : après plus de 50 ans de recherches et de développement, le MBTI est
                        devenu l’outil le plus utilisé dans la compréhension des différences de personnalité.
                    </p>
                    <p>
                        Etant donné qu’il explique les bases du fonctionnement humain, le MBTI est utilisé à des fins aussi
                        nombreuses que diverses, comme par exemple :
                    </p>
                    <p>
                        Meilleure compréhension de soi et meilleur développement personnel<br/>
                        Evolution et gestion de carrière<br/>
                        Développement organisationnel<br/>
                        Cohésion d’équipes<br/>
                        Formation au management et au leadership<br/>
                        Résolution de problèmes<br/>
                        Conseil en communication<br/>
                        Apprentissage et développement<br/>
                        Conseil pédagogique<br/>
                    </p>
                </div>
            </div>
            <div className='row d-flex justify-content-center mt-5'>
                <h4 className="RobotoLight orange mt-5">Sélectionnez les réponses qui vous correspondent</h4>
                <div className='col-12 col-md-6 mb-5 mt-4'>
                    {form}
                </div>
            </div>
            <div className='row d-flex justify-content-center gy-3 mt-5 mb-5'>
                <button type='submit' className='signupBtn' onClick={saveClickedHandler} disabled={!valid} >
                    <span className='signupBtnSpan roboto'>Enregistrer</span>
                </button>
            </div>
            <Footer/>
        </div>
    )
};

const mapStateToProps = state => ({
    user: state.auth.user,
    isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps, {saveMyersBriggs, load_user_details}) (MyersBriggs);