import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import {useNavigate} from 'react-router-dom';

import { checkValidity } from '../../../shared/utility';
import { saveAvoidances, load_user_details } from '../../../store/actions/auth';
import routes from '../../../config/routes';
import Input from '../../../components/UI/Input/Input';
import Footer from '../../Footer/Footer';

import Graph from './avoid_picts/avoidGraph.jpg'

function Avoidances({user, saveAvoidances, load_user_details, isAuthenticated}) {

    //SELECT FIELDS
    const number = [
        {value: '', displayValue: 'Quel est votre premier évitement'},
        {value: '1', displayValue: '1'},
        {value: '2', displayValue: '2'},
        {value: '3', displayValue: '3'},
        {value: '4', displayValue: '4'},
        {value: '5', displayValue: '5'},
        {value: '6', displayValue: '6'},
        {value: '7', displayValue: '7'},
        {value: '8', displayValue: '8'},
        {value: '9', displayValue: '9'},
    ]
    const number_2 = [
        {value: '', displayValue: 'Quel est votre second évitement'},
        {value: '1', displayValue: '1'},
        {value: '2', displayValue: '2'},
        {value: '3', displayValue: '3'},
        {value: '4', displayValue: '4'},
        {value: '5', displayValue: '5'},
        {value: '6', displayValue: '6'},
        {value: '7', displayValue: '7'},
        {value: '8', displayValue: '8'},
        {value: '9', displayValue: '9'},
    ]

    //STATE
    const [loader, setLoader] = useState(true);

    const [inputs, setInputs] = useState({
        avoidance_1: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "Evitement N°1",
                options: number
            },
            value: '',
            label: "",
            valid: false,
            validation: {
                required: true,
                different: "",
            },
            touched: false,
            errorMessage: "La réponse doit différer de la suivante. Champ requis"
        },
        avoidance_2: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "Evitement N°2",
                options: number_2
            },
            value: '',
            label: "",
            valid: false,
            validation: {
                required: true,
                different: "",
            },
            touched: false,
            errorMessage: "La réponse doit différer de la précédente. Champ requis"
        }
    });

    const [choices, setChoices] = useState({
        avoidance_3: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "",
            valid: false,
            validation: {
                required: true,
                different: "",
            },
            touched: false,
            errorMessage: "La réponse doit différer de la suivante. Champ requis"
        },
        avoidance_4: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "Choix N°2",
                options: number_2
            },
            value: '',
            label: "",
            valid: false,
            validation: {
                required: true,
                different: "",
            },
            touched: false,
            errorMessage: "La réponse doit différer de la précédente. Champ requis"
        },
    });

    const [valid, setValid] = useState(false);
    const [quizCompleted, setQuizCompleted] = useState(false);

    //Redirect
    const navigate = useNavigate();

    //ComponentDidMount
    useEffect(() => {
        load_user_details();
        if (!user){
            setTimeout(() => {
            setLoader(false);
            }, 1000);
        }
        else {
            if(user.AVOID){
                navigate(routes.HOME)
            }
            else{
                setLoader(false)
            }
        }
    }, [])

    //ComponentDidUpdate
    useEffect(() => {
        if (!user){
            setTimeout(() => {
            setLoader(false);
            }, 1000);
        }
        else {
            if(user.AVOID){
                navigate(routes.HOME)
            }
            else{
                setLoader(false)
            }
        }
        const auth = localStorage.getItem('authenticated')
        if (! auth){
            alert('vous devez vous connecter pour répondre au questionnaire.')
            navigate(routes.HOME)
        }
    });

    //Functions

    const InputChangedHandler = (event, id) => {

        //Change Value
        const newInputs = {...inputs};
        newInputs[id].value = event.target.value;
        newInputs[id].touched = true;

        //Check value
        inputs.avoidance_1.validation.different = [];
        inputs.avoidance_1.validation.different.push(inputs.avoidance_2.value);
        inputs.avoidance_2.validation.different = [];
        inputs.avoidance_2.validation.different.push(inputs.avoidance_1.value);

        newInputs[id].valid = checkValidity(event.target.value, newInputs[id].validation)

        for (let input in newInputs){
            newInputs[input].valid = checkValidity(newInputs[input].value, newInputs[input].validation)
        }

        setInputs(newInputs);

        //Form checking
        let formIsValid = true;
        for (let input in newInputs) {
            formIsValid = newInputs[input].valid && formIsValid;
        }
        setValid(formIsValid);
    };

    const choicesChangedHandler = (event, id) => {

        //Change Value
        const newChoices = {...choices};
        newChoices[id].value = event.target.value;
        newChoices[id].touched = true;

        //Check value
        choices.avoidance_3.validation.different = [];
        choices.avoidance_3.validation.different.push(choices.avoidance_4.value);
        choices.avoidance_4.validation.different = [];
        choices.avoidance_4.validation.different.push(choices.avoidance_3.value);

        newChoices[id].valid = checkValidity(event.target.value, newChoices[id].validation)

        for (let choice in newChoices){
            newChoices[choice].valid = checkValidity(newChoices[choice].value, newChoices[choice].validation)
        }

        setChoices(newChoices);

        //Form checking
        let formIsValid = true;
        for (let choice in newChoices) {
            formIsValid = newChoices[choice].valid && formIsValid;
        }
        setValid(formIsValid);
    };

    const formHandler = event => {
        event.preventDefault();
    }


    //Save Confirmation
    const saveClickedHandler = () => {
        console.log(localStorage);
        const avoidances = {
            user: user.email,
            user_email: user.email,
            question_1: inputs.avoidance_1.value,
            question_2: inputs.avoidance_2.value,
            question_3: choices.avoidance_3.value,
            question_4: choices.avoidance_4.value
        };
        try{
            saveAvoidances(avoidances.user, avoidances.user_email, avoidances.question_1, avoidances.question_2, avoidances.question_3, avoidances.question_4);
            setQuizCompleted(true);
            navigate(routes.QUIZ)
        }
        catch(err){
            alert("Vérifiez que l'ensemble des champs soient renseignés")
        }
    }

    //Variables

    const formElementArray = [];
    for(let key in inputs) {
        formElementArray.push({
            id: key,
            config: inputs[key]
        });
    };

    const formElementArray2 = [];
    for(let key in choices) {
        formElementArray2.push({
            id: key,
            config: choices[key]
        });
    };

    let form = (
        <form onSubmit={(e) => formHandler(e)}>
            {formElementArray.map(formElement => (
                <Input
                    key={formElement.id}
                    id={formElement.id}
                    value={formElement.config.value}
                    label={formElement.config.label}
                    type={formElement.config.elementType}
                    config={formElement.config.elementConfig}
                    valid={formElement.config.valid}
                    touched={formElement.config.touched}
                    errorMessage={formElement.config.errorMessage}
                    changed={(e) => InputChangedHandler(e, formElement.id)}
                />
            ))}
        </form>
    );

    let form2 = (
        <form onSubmit={(e) => formHandler(e)}>
            {formElementArray2.map(formElement => (
                <Input
                    key={formElement.id}
                    id={formElement.id}
                    value={formElement.config.value}
                    label={formElement.config.label}
                    type={formElement.config.elementType}
                    config={formElement.config.elementConfig}
                    valid={formElement.config.valid}
                    touched={formElement.config.touched}
                    errorMessage={formElement.config.errorMessage}
                    changed={(e) => choicesChangedHandler(e, formElement.id)}
                />
            ))}
        </form>
    );


    return loader ? (
        <>
            <div className="container-fluid">
                <div className='row d-flex justify-content-center mt-5'>

                </div>
            </div>
        </>
    ) : (
        <div className='container-fluid p-3'>
            <div className='row d-flex justify-content-center mt-5'>
                <h1 className='PoppinsLIght or'>Enneagramme : Evitements</h1>
            </div>
            <div className='row d-flex justify-content-center mt-5'>
                <div className='col-12 col-md-8 cardPurple p-4 mb-5'>
                    <h3 className='RobotoThin text-white'>
                        Que cherchez vous profondément à éviter?
                    </h3>
                </div>
            </div>
            <div className='row d-flex justify-content-center mt-5'>
                <div className="col-10">
                    <h4 className='PoppinsLight orange mb-4'><b>2/ Choisissez un n° de phrase spontanément, puis un deuxième s'il vous semble aussi proche de vous</b></h4>
                </div>
            </div>
            <div className='row d-flex justify-content-center mt-3'>
                <div className='col-12 col-md-6 RobotoLight text-start text-white mx-4'>
                    <h4 className="mb-4"><b><span className="orange fs-2">1 </span></b> La colère - l'imperfection, peur de mal faire (éviter d'être surpris en colère; éviter l'erreur, le désordre, les critiques) </h4>
                    <h4 className="mb-4"><b><span className="orange fs-2">2 </span></b> La reconnaissance de ses propres besoins - la solitude (éviter de ne servir à rien, de se sentir inutile)</h4>
                    <h4 className="mb-4"><b><span className="orange fs-2">3 </span></b> L'échec (éviter de ne pas être apprécié, admiré, aimé s'il ne fait rien, évite l'échec et la reconnaissance)</h4>
                    <h4 className="mb-4"><b><span className="orange fs-2">4 </span></b> La banalité (ne pas être reconnu comme différent des autres, peur d'être abandonné des autres, fuit le quotidien, et les règles.)</h4>
                    <h4 className="mb-4"><b><span className="orange fs-2">5 </span></b> Le vide intérieur - l'envahissement et l'intrusion (évite d'être envahi par les autres et les démonstrations émotionnelles)</h4>
                    <h4 className="mb-4"><b><span className="orange fs-2">6 </span></b> La déviance - la trahison, la peur (tendance à prévoir le pire)</h4>
                    <h4 className="mb-4"><b><span className="orange fs-2">7 </span></b> La soufrance - l'immobilisme, l'enfermemnt, (peur de manquer de quelque chose et de la mort)</h4>
                    <h4 className="mb-4"><b><span className="orange fs-2">8 </span></b> La faiblesse (éviter d'être perçu comme n'étant pas à la hauteur, de ne pas pouvoir contrôler ce qu'il se passe, peur de paraître faible, combatif, refuse d'être dominé et de paraître faible</h4>
                    <h4 className="mb-4"><b><span className="orange fs-2">9 </span></b> Le conflit (évite de s'exprimer si cela doit entrainer un conflit, éviter les mésententes, les tensions)</h4>
                </div>
            </div>
            <div className='row d-flex justify-content-center'>
                <div className='col-12 col-md-6 mb-5 mt-4'>
                    {form}
                </div>
            </div>
            <div className='row d-flex justify-content-center mt-5'>
                <div className="col-10">
                    <h4 className='PoppinsLight orange'><b>2/ Dans le graphique ci-dessous, choisissez un n° spontanément, puis un deuxième s’il vous semble aussi proche de vous</b></h4>
                </div>
            </div>
            <div className='row d-flex justify-content-center mt-5'>
                <div className='col-12 col_md-10'>
                    <img src={Graph} className='img img-fluid'/>
                </div>
            </div>
            <div className='row d-flex justify-content-center'>
                <div className='col-12 col-md-6 mt-4'>
                    {form2}
                </div>
            </div>
            <div className='row d-flex justify-content-center gy-3 mt-5 mb-5'>
                <button type='button' className='signupBtn' onClick={saveClickedHandler} disabled={!valid}>
                    <span className='signupBtnSpan roboto'>Enregistrer</span>
                </button>
            </div>
            <Footer/>
        </div>
    )
};

const mapStateToProps = state => ({
    user: state.auth.user,
    isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps, {saveAvoidances, load_user_details}) (Avoidances);