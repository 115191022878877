import React from "react";
import Footer from '../Footer/Footer';



function RGPD () {


    return(
        <div className="container-fluid">
            <div className="row d-flex justify-content-center">
                <h1 className="Poppins or">Politique de confidentialité</h1>
            </div>
            <div className="row d-flex justify-content-center">
                <div className="col-6 mt-5 purple">
                    <p>
                        La présente constitue la politique de confidentialité de la SARL D3S,
                        société immatriculée en Nouvelle-Calédonie (N° 1 351 295.001), dont le siège social est situé
                        au 73 route du Port Despointes, Faubourg Blanchot, 98800 Nouméa.
                    </p>
                </div>
                <div className="col-12 col-sm-8 cardGold mt-5 Roboto purple p-4">
                    <h3 className="PoppinsLight orange mb-4">Introduction</h3>
                    <h5>
                        La SARL D3S s'engage à protéger et respecter votre confidentialité, ainsi qu'à agir conformément
                         à la législation actuelle sur la protection des données telle qu'elle s'applique à nos activités
                         en Nouvelle-Calédonie et en Europe, notamment au RGPD de l'UE (le règlement général sur
                         la protection des données (UE) 2016/ 679), ainsi que toute législation applicable en vigueur,
                         qui lui succède, qui le complète ou qui l’amende.
                    </h5>
                    <h3 className="PoppinsLight orange mb-4 mt-4">Données personnelles que nous collectons</h3>
                    <h5>
                        Que vous soyez client, participant ou membre du personnel,
                        nous collectons et utilisons vos données personnelles conformément aux méthodes et aux fins décrites
                        dans la présente Politique de confidentialité.  Les données personnelles que nous recueillons
                        proviennent directement des informations que vous nous communiquez.
                    </h5>
                    <h3 className="RobotoBold purple mb-4 mt-4">Clients</h3>
                    <h5 className="text-start">
                        <span className="RobotoBold orange">Données d'identité : </span>
                        Incluant nom et prénom, nom d'utilisateur ou identification similaire, titre, fonction, rôle,
                        date de naissance.
                    </h5>
                    <h5 className="text-start">
                        <span className="RobotoBold orange">Coordonnées : </span>
                        Incluant adresse de facturation, adresse de livraison, adresse e-mail, numéros de téléphone.
                    </h5>
                    <h5 className="text-start">
                        <span className="RobotoBold orange">Données techniques : </span>
                        Incluant adresse IP, données de connexion, type et version de navigateur, et autres technologies
                        propres à l'appareil utilisé pour accéder à notre plateforme.
                    </h5>
                    <h5 className="text-start">
                        <span className="RobotoBold orange">Données sur votre profil : </span>
                        Incluant nom d'utilisateur, mot de passe, et vos commentaires
                    </h5>
                    <h5 className="mt-5">
                        Les données seront collectées pour que les clients
                        puissent recevoir des produits et services, le cas échéant, afin de gérer les relations
                        contractuelles de façon régulière et de permettre l'administration des comptes.
                    </h5>
                    <h3 className="RobotoBold purple mb-4 mt-4">Participants</h3>
                    <h5 className="text-start">
                        <span className="RobotoBold orange">Données d'identité : </span>
                        Incluant nom et prénom, nom d'utilisateur ou identification similaire, fonction, qualification,
                        date de naissance.
                    </h5>
                    <h5 className="text-start">
                        <span className="RobotoBold orange">Coordonnées : </span>
                        Incluant l'adresse e-mail
                    </h5>
                    <h5 className="mt-5">
                        Les données seront collectées auprès des participants qui répondent à nos questionnaires
                        d'évaluation, et lors de la génération des résultats et des rapports.
                        De plus, lorsque vous nous envoyez des commentaires et/ou lorsque vous participez à
                        une formation, ou à une autre prestation de conseil animée par nous,
                        vous êtes susceptible de fournir des données supplémentaires
                        non mentionnées ci-dessus. Ces données seront collectées par un praticien
                        et utilisées à des fins
                        de prestation de services.
                    </h5>
                    <h3 className="PoppinsLight orange mb-5 mt-5">Comment et quand nous collectons vos données personnelles</h3>
                    <h3 className="RobotoBold purple mb-4 mt-4">Clients</h3>
                    <h5 className="mt-3 mb-3 text-start">
                        Les données seront collectées :
                    </h5>
                    <h5 className="text-start">
                        <span className="RobotoBold orange">_ </span>
                        préalablement à, lors de la prise d'effet, et pendant la durée de la relation contractuelle
                        établie lorsque vous demandez à bénéficier de nos produits et services
                    </h5>
                    <h5 className="text-start">
                        <span className="RobotoBold orange">_ </span>
                        lorsque vous remplissez des formulaires sur notre site ou concernant nos produits et services,
                        y compris l'enregistrement pour utiliser notre site, et l'inscription à nos services.
                    </h5>
                    <h5 className="text-start">
                        <span className="RobotoBold orange">_ </span>
                        lorsque vous remplissez des formulaires sur notre site ou concernant nos produits et services,
                        y compris l'enregistrement pour utiliser notre site, et l'inscription à nos services.
                    </h5>
                    <h5 className="text-start">
                        <span className="RobotoBold orange">_ </span>
                        lorsque vous nous contactez ou nous signalez un problème, ou que vous nous envoyez des commentaires.
                    </h5>
                    <h5 className="text-start">
                        <span className="RobotoBold orange">_ </span>
                        lors de vos transactions avec nous par l'intermédiaire de notre site Web ou lorsque vous nous
                        passez une commande au téléphone ou par e-mail.
                    </h5>
                    <h3 className="RobotoBold purple mb-4 mt-4">Participants</h3>
                    <h5 className="">
                        Les données sont collectées lorsque vous répondez à un questionnaire d'évaluation.
                        Les données peuvent également être collectées par votre organisation associée,
                        une entreprise cliente de nos services vous employant,
                        une entreprise cliente de nos services pour laquelle vous candidatez.
                    </h5>
                    <h5 className="">
                        Lorsque vous nous envoyez des commentaires et/ou lorsque vous participez à une formation,
                        ou à une autre prestation de conseil animée par nous, vous êtes susceptible de fournir des données
                        supplémentaires non mentionnées ci-dessus.
                    </h5>
                    <h3 className="PoppinsLight orange mb-5 mt-5">Comment et pourquoi nous utilisons vos données personnelles</h3>
                    <h3 className="RobotoBold purple mb-4 mt-4">Clients</h3>
                    <h5 className="mt-3 mb-3 text-start">
                        Les données personnelles des clients (notamment l'identité, les coordonnées et les données techniques,
                        d'utilisation et de profil) seront utilisées aux fins suivantes :
                    </h5>
                    <h5 className="text-start">
                        <span className="RobotoBold orange">_ </span>
                        vous fournir les produits et services que vous nous avez demandés
                    </h5>
                    <h5 className="text-start">
                        <span className="RobotoBold orange">_ </span>
                        gérer notre relation contractuelle de façon continue
                    </h5>
                    <h5 className="text-start">
                        <span className="RobotoBold orange">_ </span>
                        assurer l'administration des clients, notamment remplir les obligations contractuelles qui nous
                        lient mutuellement et conserver la correspondance si vous nous contactez
                    </h5>
                    <h5 className="text-start">
                        <span className="RobotoBold orange">_ </span>
                        vous permettre d'utiliser des fonctionnalités interactives de notre service
                    </h5>
                    <h5 className="text-start">
                        <span className="RobotoBold orange">_ </span>
                        assurer l'administration technique de notre site, notamment vous avertir des modifications
                        apportées à notre service
                    </h5>
                    <h3 className="RobotoBold purple mb-4 mt-4">Participants</h3>
                    <h5 className="text-start">
                        Les données personnelles collectées auprès des participants qui répondent à nos questionnaires
                        seront utilisées, par nous-même, en tant que responsable du traitement des données :
                    </h5>
                    <h5 className="text-start">
                        <span className="RobotoBold orange">_ </span>
                        pour fournir, à la demande des praticiens/ou de votre organisation associée, des rapports informatisés
                        et personnalisés, générés à partir des réponses des participants à nos questionnaires d'évaluation,
                        y compris par l'intermédiaire de notre plateforme d'analyse basée sur le Web, ou autre.
                        Nous sommes parfois amenés à combiner les données d'un participant avec celles d'autres participants,
                        par exemple pour créer des rapports d'équipe.
                    </h5>
                    <h5 className="text-start">
                        <span className="RobotoBold orange">_ </span>
                        lorsque des commentaires sont communiqués, avec pour finalité la session de retours entre
                        le participant et le praticien
                    </h5>
                    <h5 className="text-start">
                        <span className="RobotoBold orange">_ </span>
                        lorsque vous participez à une formation, ou à une autre prestation de conseil animée par notre équipe,
                        les données supplémentaires que vous fournissez peuvent être utilisées pour assurer nos prestations
                        de services
                    </h5>
                    <h3 className="RobotoBold purple mb-4 mt-4">Fondements juridiques du traitement des données</h3>
                    <h5 className="text-start">
                        Les finalités pour lesquelles nous utilisons vos données sont décrites ci-dessous.
                        Communément appelées « fondements juridiques », elles constituent les bases légales du
                        traitement de vos données.  Selon la finalité spécifique de notre utilisation de chaque élément
                        de donnée, nous pouvons traiter vos données selon plusieurs fondements juridiques.
                        Des informations complémentaires sont disponibles sur demande. Nous indiquons ci-dessous les
                        circonstances dans lesquelles nos intérêts légitimes constituent des fondements juridiques.
                    </h5>

                    <div className="row mt-5">
                        <div className="col-6">
                            <h4 className="PoppinsLight or mb-3">Finalité</h4>
                            <div className="col-12 cardCream p-2">
                                <h5 className="purple mb-3">Clients</h5>
                                <h5 className="text-start">
                                    <span className="RobotoBold orange">_ </span>
                                    enregistrement du client et gestion de la relation
                                </h5>
                                <h5 className="text-start">
                                    <span className="RobotoBold orange">_ </span>
                                    communication des mises à jour de nos conditions
                                </h5>
                                <h5 className="text-start">
                                    <span className="RobotoBold orange">_ </span>
                                    communication d'informations sur nos produits et services
                                </h5>
                                <h5 className="text-start">
                                    <span className="RobotoBold orange">_ </span>
                                    demande de commentaires sur nos produits et services
                                </h5>
                            </div>
                        </div>

                        <div className="col-6">
                            <h4 className="PoppinsLight or mb-3">Intérêts légitimes</h4>
                            <div className="col-12 cardCream p-2">
                                <h5 className="purple mb-3">Clients</h5>
                                <h5 className="text-start">
                                    <span className="RobotoBold orange">_ </span>
                                    fournir des produits et services à nos clients
                                </h5>
                                <h5 className="text-start">
                                    <span className="RobotoBold orange">_ </span>
                                    fournir nos évaluations au personnel de nos clients
                                </h5>
                                <h5 className="text-start">
                                    <span className="RobotoBold orange">_ </span>
                                    tenir nos fichiers à jour
                                </h5>
                                <h5 className="text-start">
                                    <span className="RobotoBold orange">_ </span>
                                    garantir une gouvernance, une comptabilité, une gestion et un audit appropriés de
                                    nos opérations commerciales
                                </h5>
                            </div>
                        </div>
                    </div>

                    <div className="row mt-5">
                        <div className="col-6">
                            <h4 className="PoppinsLight or mb-3">Finalité</h4>
                            <div className="col-12 cardOr text-white p-2">
                                <h5 className="purple mb-3">Clients</h5>
                                <h5 className="text-start">
                                    <span className="RobotoBold orange">_ </span>
                                    facturation, gestion des paiements, des tarifs et des frais
                                </h5>
                                <h5 className="text-start">
                                    <span className="RobotoBold orange">_ </span>
                                    collecte et recouvrement des sommes qui nous sont dues
                                </h5>
                            </div>
                        </div>

                        <div className="col-6">
                            <h4 className="PoppinsLight or mb-3">Intérêts légitimes</h4>
                            <div className="col-12 cardOr text-white p-2">
                                <h5 className="purple mb-3">Clients</h5>
                                <h5 className="text-start">
                                    <span className="RobotoBold orange">_ </span>
                                    Exécution d'un contrat avec les clients
                                </h5>
                                <h5 className="text-start">
                                    <span className="RobotoBold orange">_ </span>
                                    Exécution d'un contrat avec les clients
                                </h5>
                            </div>
                        </div>
                    </div>

                    <div className="row mt-5">
                        <div className="col-6">
                            <h4 className="PoppinsLight or mb-3">Finalité</h4>
                            <div className="col-12 cardCream p-2">
                                <h5 className="purple mb-3">Participants</h5>
                                <h5 className="text-start">
                                    <span className="RobotoBold orange">_ </span>
                                    enregistrement des participants
                                </h5>
                                <h5 className="text-start">
                                    <span className="RobotoBold orange">_ </span>
                                    réalisation de nos évaluations
                                </h5>
                            </div>
                        </div>

                        <div className="col-6">
                            <h4 className="PoppinsLight or mb-3">Intérêts légitimes</h4>
                            <div className="col-12 cardCream p-2">
                                <h5 className="purple mb-3">Participants</h5>
                                <h5 className="text-start">
                                    <span className="RobotoBold orange">_ </span>
                                    fournir nos évaluations au personnel de nos clients
                                </h5>
                                <h5 className="text-start">
                                    <span className="RobotoBold orange">_ </span>
                                    fournir des formations
                                </h5>
                                <h5 className="text-start">
                                    <span className="RobotoBold orange">_ </span>
                                    tenir nos fichiers à jour
                                </h5>
                                <h5 className="text-start">
                                    <span className="RobotoBold orange">_ </span>
                                    garantir une gouvernance, une comptabilité, une gestion et un audit appropriés de
                                    nos opérations commerciales
                                </h5>
                            </div>
                        </div>
                    </div>

                    <div className="row mt-5">
                        <div className="col-6">
                            <h4 className="PoppinsLight or mb-3">Finalité</h4>
                            <div className="col-12 cardOr text-white p-2">
                                <h5 className="purple mb-3">Dans l'ensemble</h5>
                                <h5 className="text-start">
                                    <span className="RobotoBold orange">_ </span>
                                    administrer et protéger notre activité et le présent site Web (y compris le dépannage,
                                    l'analyse des données, les tests, la maintenance des systèmes, l'assistance, la création
                                    de rapports et l'hébergement de données)
                                </h5>
                                <h5 className="text-start">
                                    <span className="RobotoBold orange">_ </span>
                                    utiliser l'analyse des données pour améliorer notre site Web, nos produits/services,
                                    nos relations clients et l'expérience utilisateur
                                </h5>
                            </div>
                        </div>

                        <div className="col-6">
                            <h4 className="PoppinsLight or mb-3">Intérêts légitimes</h4>
                            <div className="col-12 cardOr text-white p-2">
                                <h5 className="purple mb-3">Dans l'ensemble</h5>
                                <h5 className="text-start">
                                    <span className="RobotoBold orange">_ </span>
                                    actualiser notre site Web et assurer sa pertinence, développer nos activités
                                </h5>
                            </div>
                        </div>
                    </div>

                    <h3 className="PoppinsLight orange mb-5 mt-5">Comment et pourquoi nous utilisons vos données personnelles</h3>
                    <h4>
                        Nous pouvons être amenés à partager vos données personnelles avec des tierces parties à des fins
                        de traitement ou de sous-traitance.  Nous appliquons un processus de sélection de nos partenaires,
                        et les soumettons à un examen périodique. Nous pouvons aussi être amenés à partager vos données
                        personnelles avec des entreprises clientes vous employant ou des entreprises clientes pour
                        lesquelles vous candidatez.  En général, nos clients jouent le rôle de contrôleurs
                        des données personnelles de leur personnel et des données personnelles des participants en recrutement.
                        Nos clients peuvent être des particuliers pour leur propre compte ou des organisations.
                    </h4>

                    <h3 className="PoppinsLight orange mb-5 mt-5">Comment nous conservons vos données en toute sécurité</h3>
                    <h4>
                        Nous nous engageons à assurer la sécurité du traitement, ainsi que la confidentialité, l'intégrité,
                        la disponibilité et la résilience permanentes des systèmes et services relatifs aux données personnelles
                        que nous détenons, afin d'empêcher toute destruction, perte ou altération accidentelles ou illégales,
                        ainsi que toute divulgation ou tout accès non autorisés.
                    </h4>
                    <h4>
                        En notre qualité à la fois de contrôleur et de processeur, nous mettons en œuvre des mesures
                        organisationnelles et techniques appropriées pour assurer un niveau de sécurité des informations
                        proportionnel aux risques. Notre hébergement et nos applications sont conçues pour permettre un
                        déploiement sécurisé des services, et un fonctionnement sécurisé pour les clients.
                    </h4>
                    <h4>
                        Les données des participants sont exclusivement accessibles par certains membres du personnel de
                        la SARL D3S et un membre de notre partenaire informatique qui assurent le support et l'administration
                        des systèmes de génération des rapports,
                        notamment notre plateforme d'analyse basée sur le Web.
                        L'accès aux données des participants est exclusivement réservé aux seules personnes qui en ont besoin.
                    </h4>
                    <h4>
                        Notez que lorsque vous choisissez un nom d'utilisateur et/ou un mot de passe pour accéder à certaines
                        sections de nos sites Web, ou utiliser nos produits et services, il vous incombe la responsabilité
                        de préserver la confidentialité de ce nom d'utilisateur et de ce mot de passe.
                        Vous ne devez divulguer ces informations à personne
                    </h4>
                    <h4>
                        La présente Politique de confidentialité s'applique exclusivement aux informations collectées par
                        la SARL D3S. Les liens au sein de notre site Web vers des sites, modules complémentaires et
                        applications de tierces parties ne sont pas couverts par cette Politique de confidentialité.
                        Si vous suivez un lien vers d'autres sites Web, nous vous conseillons de lire leurs propres
                        politiques de confidentialité. Nous déclinons toute responsabilité quant à ces politiques.
                    </h4>

                    <h3 className="PoppinsLight orange mb-5 mt-5">Conservation et anonymat des données</h3>
                    <h4>
                        Nous conserverons vos données personnelles uniquement aussi longtemps que nécessaire pour
                        satisfaire la finalité de la collecte de ces données, y compris dans le but de respecter la
                        conformité avec toute obligation légale, comptable ou déclarative.
                    </h4>
                    <h4>
                        Pour déterminer la durée adéquate de conservation des données personnelles, nous prenons en compte
                        la quantité, la nature et le caractère sensible des données, le risque potentiel associé à leur
                        utilisation non autorisée ou à leur divulgation, les finalités du traitement des données personnelles
                        et si nous pouvons arriver à ces fins par d'autres moyens, ainsi que les exigences légales en vigueur,
                        y compris les délais légaux de conservation.  Par exemple, en vertu de la loi, nous sommes dans
                        l'obligation de conserver : (i) certaines informations concernant les clients et les fournisseurs
                        pendant dix ans pour satisfaire aux réglementations fiscales et aux exigences en matière d'audit.
                    </h4>
                    <h4>
                        Concernant les données personnelles collectées suite à la réalisation de nos évaluations par les
                        participants (comprenant les données personnelles collectées par l'intermédiaire de notre
                        plateforme basée sur le Web), ces données sont modifiables en tout temps par le participant.
                    </h4>

                    <h3 className="PoppinsLight orange mb-5 mt-5">Vos droits</h3>
                    <h4>
                        Demander l'accès à vos données personnelles (communément appelé « demande d'accès aux données
                        personnelles »). Cette demande vous permet de recevoir une copie des données personnelles que
                        nous détenons à votre sujet et de vous assurer ainsi que nous les traitons conformément à la loi.
                    </h4>
                    <h4>
                        Demander la rectification des données personnelles que nous détenons à votre sujet.
                        Cette demande vous permet de faire corriger toutes les données incomplètes ou inexactes que nous
                        détenons à votre sujet. Néanmoins, nous sommes parfois dans l'obligation de vérifier l'exactitude
                        des nouvelles données que vous nous communiquez.
                    </h4>
                    <h4>
                        Demander l'effacement de vos données personnelles. Cette demande vous permet de nous demander
                        de supprimer ou retirer des données personnelles lorsque nous n'avons aucune raison valable de
                        continuer à les traiter. Vous avez également le droit de nous demander de supprimer ou retirer
                        vos données personnelles lorsque nous avons procédé illégalement au traitement de vos informations
                        ou lorsque nous sommes dans l'obligation d'effacer vos données personnelles conformément à la
                        législation locale.
                    </h4>
                    <h4>
                        Refuser le traitement de vos données. Cette demande vous permet de refuser le traitement lorsque
                        nous nous basons sur un intérêt légitime (ou celui d'une tierce partie) et qu'une situation
                        particulière vous concernant vous incite à refuser le traitement sur ce motif car vous estimez
                        qu'il porte atteinte à vos libertés et droits fondamentaux.
                    </h4>
                    <h4>
                        Demander la limitation du traitement de vos données personnelles. Cette demande vous permet de
                        nous demander de suspendre le traitement de vos données personnelles dans les cas suivants :
                        (a) si vous souhaitez que nous établissions l'exactitude des données ;
                        (b) lorsque notre utilisation des données est illégale mais que vous ne souhaitez pas que nous
                        les effacions (c) lorsque vous avez besoin que nous détenions les données même si nous n'en
                        n'avons plus l'usage car elles vous sont nécessaires pour établir, exercer ou défendre des droits ;
                        ou (d) vous avez signifié votre refus à un usage de vos données, mais nous devons vérifier si
                        nous avons des motifs légitimes de les traiter.
                    </h4>
                    <h4>
                        Demander le transfert de vos données personnelles à vous même ou à une tierce partie.
                        Nous fournirons, à vous ou à la tierce partie de votre choix, vos données personnelles sous un
                        format électronique structuré et communément utilisé. Veuillez noter que ce droit s'applique
                        uniquement aux informations automatisées pour lesquelles vous nous avez initialement donné votre
                        consentement, ou lorsque nous avons utilisé les informations dans le cadre d'un contrat établi
                        avec vous.
                    </h4>
                    <h4>
                        Retirer votre consentement. Vous pouvez retirer votre consentement à tout moment lorsque le
                        consentement constitue le fondement légal sur lequel nous traitons vos données personnelles.
                        Cependant, ce retrait ne porte en aucun cas atteinte à la légalité de tout traitement réalisé
                        avant le retrait de votre consentement. Si vous retirez votre consentement, nous risquons de ne
                        pas pouvoir vous fournir certains produits ou services. Dans un tel cas, nous vous le ferons
                        savoir au moment où vous retirerez votre consentement.
                    </h4>
                    <h4>
                        Vous n'aurez à payer aucuns frais pour accéder à vos données personnelles (ni pour exercer vos droits).
                        Cependant, lorsqu'une demande s'avère manifestement dénuée de fondement, répétitive ou abusive,
                        nous pouvons vous facturer une somme modique. Par ailleurs, dans certaines circonstances,
                        nous pouvons refuser de répondre à votre demande.
                    </h4>
                    <h4>
                        Nous sommes susceptibles de vous demander des informations spécifiques pour nous aider à confirmer
                        votre identité ou vérifier que vous avez le droit d'accéder à vos données personnelles
                        (ou d'exercer tout autre droit). Cette mesure de sécurité a pour finalité de s'assurer que des
                        données personnelles ne sont pas divulguées à des personnes non autorisées à les recevoir.
                    </h4>

                    <h3 className="PoppinsLight orange mb-5 mt-5">Modifications de vos données personnelles</h3>
                    <h4>
                        Il est important que les données personnelles en notre possession soient exactes et actualisées.
                        Vous avez donc la possibilité de les modifier directement depuis notre plate-forme.
                    </h4>

                    <h3 className="PoppinsLight orange mb-5 mt-5">Comment obtenir les données personnelles que nous détenons à votre sujet</h3>
                    <h4>
                        Pour obtenir l'accès aux données personnelles que nous détenons à votre sujet, envoyez votre demande
                        écrite par courrier postal à l'adresse de notre siège ou par e-mail à sandra.parent@myd3s.com
                    </h4>

                    <h3 className="PoppinsLight orange mb-5 mt-5">Comment nous contacter et votre droit à déposer une plainte</h3>
                    <h4>
                        Si vous avez des questions concernant la présente Politique de confidentialité, la protection
                        des données ou, plus généralement, la confidentialité, veuillez contacter la gérante de la SARL D3S
                        par e-mail à sandra.parent@myd3s.com
                        Vous avez également le droit de formuler une plainte auprès de l'autorité de surveillance
                        compétente chargée de la protection des données.
                    </h4>

                    <h3 className="PoppinsLight orange mb-5 mt-5">Droit applicable</h3>
                    <h4>
                        La présente Politique de confidentialité est régie par le droit de Nouvelle-Calédonie et le RGPD de l'UE
                    </h4>

                    <h3 className="PoppinsLight orange mb-5 mt-5">État de la présente Politique de confidentialité et modifications</h3>
                    <h4>
                        La présente Politique de confidentialité est entrée en vigueur le 26 mai 2018 et a été mise à jour le 01 novembre 2022.
                        Comme nous pouvons être amenés à en modifier les termes de temps à autre, consultez-la régulièrement pour être tenu
                        informé de son actualisation.
                    </h4>
                </div>
            </div>
            <Footer/>
        </div>

    );
};

export default RGPD;