import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import {useNavigate} from 'react-router-dom';

import { checkValidity } from '../../../shared/utility';
import { saveValues, load_user_details } from '../../../store/actions/auth';
import routes from '../../../config/routes';
import Input from '../../../components/UI/Input/Input';
import Footer from '../../Footer/Footer';


function Values({user, saveValues, load_user_details, isAuthenticated}) {

    //SELECT FIELDS
    const number = [
        {value: '', displayValue: 'Premier choix'},
        {value: 'Bois', displayValue: 'A'},
        {value: 'Feu', displayValue: 'B'},
        {value: 'Terre', displayValue: 'C'},
        {value: 'Metal', displayValue: 'D'},
        {value: 'Eau', displayValue: 'E'},
    ]
    const number_2 = [
        {value: '', displayValue: 'Second choix'},
        {value: 'Bois', displayValue: 'A'},
        {value: 'Feu', displayValue: 'B'},
        {value: 'Terre', displayValue: 'C'},
        {value: 'Metal', displayValue: 'D'},
        {value: 'Eau', displayValue: 'E'},
    ]
    const number_3 = [
        {value: '', displayValue: 'Troisième choix'},
        {value: 'Bois', displayValue: 'A'},
        {value: 'Feu', displayValue: 'B'},
        {value: 'Terre', displayValue: 'C'},
        {value: 'Metal', displayValue: 'D'},
        {value: 'Eau', displayValue: 'E'},
    ]
    const number_4 = [
        {value: '', displayValue: 'Quatrième choix'},
        {value: 'Bois', displayValue: 'A'},
        {value: 'Feu', displayValue: 'B'},
        {value: 'Terre', displayValue: 'C'},
        {value: 'Metal', displayValue: 'D'},
        {value: 'Eau', displayValue: 'E'},
    ]
    const number_5 = [
        {value: '', displayValue: 'Cinquième choix'},
        {value: 'Bois', displayValue: 'A'},
        {value: 'Feu', displayValue: 'B'},
        {value: 'Terre', displayValue: 'C'},
        {value: 'Metal', displayValue: 'D'},
        {value: 'Eau', displayValue: 'E'},
    ]

    //STATE
    const [loader, setLoader] = useState(true);

    const [inputs, setInputs] = useState({
        GRP1: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "Premier choix",
            valid: false,
            validation: {
                required: true,
                different: "",
            },
            touched: false,
            errorMessage: "La réponse doit différer des autres. Champ requis",
            proposal_A: "Indépendance de la pensée et de l'action",
            proposal_B: "Choisir",
            proposal_C: "Créer",
            proposal_D: "Explorer",
            proposal_E: "L'autonomie",
            proposal_F: "La créativité",
            proposal_G: "La liberté",
            proposal_H: "Choisir ses propres buts",
            proposal_I: "La curiosité",
            proposal_J: "Le droit à une vie privée",
            proposal_K: "La stimulation",
            proposal_L: "Les nouveautés",
            proposal_M: "Les défis à relever",
            proposal_N: "Les valeurs modernes d'innovation et de croissance",
            proposal_O: "Les valeurs d'ouverture au changement",
            proposal_P: "Les valeurs d'amélioration et de dépassement de soi",
            proposal_Q: "La beauté",
            proposal_R: "L'imagination",
            proposal_S: "Le courage",
            proposal_T: "L'audace",
        },
        GRP2: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number_2
            },
            value: '',
            label: "Groupe de valeurs B",
            valid: false,
            validation: {
                required: true,
                different: "",
            },
            touched: false,
            errorMessage: "La réponse doit différer des autres. Champ requis",
            proposal_A: "Valeurs d'expression de soi",
            proposal_B: "Créativité",
            proposal_C: "Participation aux décisions politiques",
            proposal_D: "Participation aux décisions économiques",
            proposal_E: "Participation aux décisions éthiques",
            proposal_F: "La diversité culturelle",
            proposal_G: "Vie passionante",
            proposal_H: "Vie active",
            proposal_I: "Vie stimulante",
            proposal_J: "L'indulgence",
            proposal_K: "La serviabilité intellectuelle (pour le bien des autres)",
            proposal_L: "L'intelligence",
            proposal_M: "Hauteur de vue",
            proposal_N: "Lucidité et discernement",
            proposal_O: "L'ouverture d'esprit",
            proposal_P: "L'amusement",
            proposal_Q: "La joie de vivre",
            proposal_R: "La bonne humeur",
            proposal_S: "Le succès personnel",
            proposal_T: "L'approbation sociale",
            proposal_U: "Le statut social reconnu (respect et admiration)",
        },
        GRP3: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number_3
            },
            value: '',
            label: "Groupe de valeurs C",
            valid: false,
            validation: {
                required: true,
                different: "",
            },
            touched: false,
            errorMessage: "La réponse doit différer des autres. Champ requis",
            proposal_A: "Valeurs de conversation",
            proposal_B: "Valeurs d'acquisition matérielle",
            proposal_C: "Valeurs d'éducation",
            proposal_D: "Valeurs de plaisir (Vie agréable menée sans hâte)",
            proposal_E: "Le confort (Vie aisée, prospère)",
            proposal_F: "La gratification sensuelle personnelle",
            proposal_G: "Les liens sincères (amitié)",
            proposal_H: "Authenticité",
            proposal_I: "Sécurité familiale (prendre soin de ceux qu'on aime)",
            proposal_J: "La logique",
            proposal_K: "La bonté",
            proposal_L: "La tendresse",
            proposal_M: "La bienveillance",
            proposal_N: "Le lien à la nature",
            proposal_O: "L'égalité (égalité des chances)",
        },
        GRP4: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number_4
            },
            value: '',
            label: "Groupe de valeurs D",
            valid: false,
            validation: {
                required: true,
                different: "",
            },
            touched: false,
            errorMessage: "La réponse doit différer des autres. Champ requis",
            proposal_A: "Valeurs traditionnelles (autorité, famille, religion)",
            proposal_B: "Valeurs de survie (sécurité, appartenance)",
            proposal_C: "Sécurité familiale",
            proposal_D: "Sécurité nationale",
            proposal_E: "Le sentiement d'accomplissement (contribution durable)",
            proposal_F: "Respect de soi",
            proposal_G: "La sagesse",
            proposal_H: "La politesse",
            proposal_I: "La réciprocité",
            proposal_J: "La justice",
            proposal_K: "La propreté (rangé, ordonné)",
            proposal_L: "La maitrise de soi",
            proposal_M: "Les compétences",
            proposal_N: "La cohérence",
            proposal_O: "L'obéissance (respect)",
            proposal_P: "La responsabilité (digne de confiance, sérieux)",
            proposal_Q: "La conformité (modération des actions, des goûts, des préférences et des impulsions susceptibles de destabiliser ou de blesser les autres ou encore de transgresser les attentes ou les normes sociales))",
        },
        GRP5: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number_5
            },
            value: '',
            label: "Groupe de valeurs E",
            valid: false,
            validation: {
                required: true,
                different: "",
            },
            touched: false,
            errorMessage: "La réponse doit différer des autres. Champ requis",
            proposal_A: "La spiritualité",
            proposal_B: "Le sens de l'écoute",
            proposal_C: "L'adaptation",
            proposal_D: "Le lâcher prise",
            proposal_E: "La transcendance de la réalité quotidienne",
            proposal_F: "L'harmonie intérieure'",
            proposal_G: "Le détachement",
            proposal_H: "L'unité avec la nature",
            proposal_I: "L'unité avec le vivant",
            proposal_J: "L'unité avec les autres",
            proposal_K: "L'endurance",
            proposal_L: "La guidance (se sentir guider et guider les autres)",
            proposal_M: "L'autorité",
            proposal_N: "L'influence",
            proposal_O: "Le pouvoir personnel",
            proposal_P: "Le pouvoir social",
            proposal_Q: "La force",
            proposal_R: "La volonté profonde de réaliser son destin",
        },

    });

    const [valid, setValid] = useState(false);


    //Redirect
    const navigate = useNavigate();

    //ComponentDidMount
    useEffect(() => {
        load_user_details();
        if (!user){
            setTimeout(() => {
            setLoader(false);
            }, 1000);
        }
        else {
            if(user.VAL){
                navigate(routes.HOME)
            }
            else{
                setLoader(false)
            }
        }
    }, [])

    //ComponentDidUpdate
    useEffect(() => {
        if (!user){
            setTimeout(() => {
            setLoader(false);
            }, 1000);
        }
        else {
            if(user.VAL){
                navigate(routes.HOME)
            }
            else{
                setLoader(false)
            }
        }
        const auth = localStorage.getItem('authenticated')
        if (! auth){
            alert('vous devez vous connecter pour répondre au questionnaire.')
            navigate(routes.HOME)
        }
    });

    //Functions

    const InputChangedHandler = (event, id) => {

        //Change Value
        const newInputs = {...inputs};
        newInputs[id].value = event.target.value;
        newInputs[id].touched = true;

        //Check value
        inputs.GRP1.validation.different = [];
        inputs.GRP1.validation.different.push(inputs.GRP2.value, inputs.GRP3.value, inputs.GRP4.value, inputs.GRP5.value);
        inputs.GRP2.validation.different = [];
        inputs.GRP2.validation.different.push(inputs.GRP1.value, inputs.GRP3.value, inputs.GRP4.value, inputs.GRP5.value);
        inputs.GRP3.validation.different = [];
        inputs.GRP3.validation.different.push(inputs.GRP1.value, inputs.GRP2.value, inputs.GRP4.value, inputs.GRP5.value);
        inputs.GRP4.validation.different = [];
        inputs.GRP4.validation.different.push(inputs.GRP2.value, inputs.GRP3.value, inputs.GRP1.value, inputs.GRP5.value);
        inputs.GRP5.validation.different = [];
        inputs.GRP5.validation.different.push(inputs.GRP2.value, inputs.GRP3.value, inputs.GRP4.value, inputs.GRP1.value);

        newInputs[id].valid = checkValidity(event.target.value, newInputs[id].validation)

        for (let input in newInputs){
            newInputs[input].valid = checkValidity(newInputs[input].value, newInputs[input].validation)
        }

        setInputs(newInputs);

        //Form checking
        let formIsValid = true;
        for (let input in newInputs) {
            formIsValid = newInputs[input].valid && formIsValid;
        }
        setValid(formIsValid);
    };

    const formHandler = event => {
        event.preventDefault();
    }


    //Save Confirmation
    const saveClickedHandler = () => {
        console.log(localStorage);
        const GRP = {
            user: user.email, user_email: user.email,
            GRP1: inputs.GRP1.value, GRP2: inputs.GRP2.value, GRP3: inputs.GRP3.value,
            GRP4: inputs.GRP4.value, GRP5: inputs.GRP5.value
        };
        try{
            saveValues(
                GRP.user, GRP.user_email,
                GRP.GRP1, GRP.GRP2, GRP.GRP3,
                GRP.GRP4, GRP.GRP5
            );

            navigate(routes.QUIZ)
        }
        catch(err){
            alert("Vérifiez que l'ensemble des champs soient renseignés")
        }
    }

    //Variables

    const formElementArray = [];
    for(let key in inputs) {
        formElementArray.push({
            id: key,
            config: inputs[key]
        });
    };

    let form = (
        <form onSubmit={(e) => formHandler(e)}>
            {formElementArray.map(formElement => (
            <div className="row d-flex justify-content-center mt-5" key={formElement.id}>
                <Input
                    id={formElement.id}
                    value={formElement.config.value}

                    type={formElement.config.elementType}
                    config={formElement.config.elementConfig}
                    valid={formElement.config.valid}
                    touched={formElement.config.touched}
                    errorMessage={formElement.config.errorMessage}
                    changed={(e) => InputChangedHandler(e, formElement.id)}
                />
            </div>
            ))}
        </form>
    );


    return loader ? (
        <>
            <div className="container-fluid">
                <div className='row d-flex justify-content-center mt-5'>

                </div>
            </div>
        </>
    ) : (
        <div className='container-fluid'>
            <div className='row d-flex justify-content-center mt-5'>
                <h1 className='PoppinsLight or'>Profil Energétique: Les 5 groupes de valeurs</h1>
            </div>
            <div className='row d-flex justify-content-center mt-3'>
                <div className='col-12 col-md-8 cardPurple p-4 mt-5 mb-5'>
                    <h4 className='RobotoThin text-white'>
                        Votre personnalité correspond plus ou moins à l’un ou l’autre de ces sous groupes.
                        Vous avez un peu de chaque valeur en fonction de vos qualités énergétiques,
                        dans une hiérarchie différente pour chacun.
                    </h4>
                    <h5 className="RobotoThin text-white">(la hiérarchie aide à décider de votre type de personnalité)</h5>
                </div>
            </div>
            <div className="row d-flex justify-content-center mt-5 mb-5">
                <h4 className="PoppinsLight orange">Dans quel sous groupe A B C D E vous reconnaissez vous le plus, merci de hiérarchiser:</h4>
            </div>
            <div className="row d-flex justify-content-center p-0 p-md-5 mt-5 mt-md-0">
                <h4 className="PoppinsLight orange text-start mb-3"><b>Groupe de valeurs <span className="purple fs-2">A</span></b></h4>
                <div className="row RobotoLight text-start text-white cardGold p-4">
                    <div className="col-12 col-md-3">
                        <h4 className="mb-md-3">Indépendance de la pensée et de l'action</h4>
                        <h4 className="mb-md-3">Choisir</h4>
                        <h4 className="mb-md-3">Créer</h4>
                        <h4 className="mb-md-3">Explorer</h4>
                        <h4 className="mb-md-3">L'autonomie</h4>
                        <h4 className="mb-md-3">La créativité</h4>
                    </div>
                    <div className="col-12 col-md-3">
                        <h4 className="mb-md-3">La liberté</h4>
                        <h4 className="mb-md-3">Choisir ses propres buts</h4>
                        <h4 className="mb-md-3">La curiosité</h4>
                        <h4 className="mb-md-3">Le droit à une vie privée</h4>
                        <h4 className="mb-md-3">La stimulation</h4>
                        <h4 className="mb-md-3">Les nouveautés</h4>
                    </div>
                    <div className="col-12 col-md-3">
                        <h4 className="mb-md-3">Les défis à relever</h4>
                        <h4 className="mb-md-3">Les valeurs modernes d'innovation et de croissance</h4>
                        <h4 className="mb-md-3">Les valeurs d'ouverture au changement</h4>
                    </div>
                    <div className="col-12 col-md-3">
                        <h4 className="mb-md-3">Les valeurs d'amélioration et de dépassement de soi</h4>
                        <h4 className="mb-md-3">La beauté</h4>
                        <h4 className="mb-md-3">L'imagination</h4>
                        <h4 className="mb-md-3">Le courage</h4>
                        <h4 className="mb-md-3">L'audace</h4>
                    </div>
                </div>
            </div>
            <div className="row d-flex justify-content-center p-0 p-md-5 mt-5 mt-md-0">
                <h4 className="PoppinsLight orange text-start mb-3"><b>Groupe de valeurs <span className="purple fs-2">B</span></b></h4>
                <div className="row RobotoLight text-start text-white cardGold p-4">
                    <div className="col-12 col-md-3">
                        <h4 className="mb-md-3">Valeurs d'expression de soi</h4>
                        <h4 className="mb-md-3">Créativité</h4>
                        <h4 className="mb-md-3">Participation aux décisions politiques</h4>
                        <h4 className="mb-md-3">Participation aux décisions économiques</h4>
                        <h4 className="mb-md-3">Participation aux décisions éthiques</h4>
                        <h4 className="mb-md-3">La diversité culturelle</h4>
                    </div>
                    <div className="col-12 col-md-3">
                        <h4 className="mb-md-3">Vie passionante</h4>
                        <h4 className="mb-md-3">Vie active</h4>
                        <h4 className="mb-md-3">Vie stimulante</h4>
                        <h4 className="mb-md-3">L'indulgence</h4>
                        <h4 className="mb-md-3">La serviabilité intellectuelle (pour le bien des autres)</h4>
                        <h4 className="mb-md-3">L'intelligence</h4>
                    </div>
                    <div className="col-12 col-md-3">
                        <h4 className="mb-md-3">Hauteur de vue</h4>
                        <h4 className="mb-md-3">Lucidité et discernement</h4>
                        <h4 className="mb-md-3">L'ouverture d'esprit</h4>
                        <h4 className="mb-md-3">Le statut social reconnu (respect et admiration)</h4>
                    </div>
                    <div className="col-12 col-md-3">
                        <h4 className="mb-md-3">L'amusement</h4>
                        <h4 className="mb-md-3">La joie de vivre</h4>
                        <h4 className="mb-md-3">La bonne humeur</h4>
                        <h4 className="mb-md-3">Le succès personnel</h4>
                        <h4 className="mb-md-3">L'approbation sociale</h4>
                    </div>
                </div>
            </div>
            <div className="row d-flex justify-content-center p-0 p-md-5 mt-5 mt-md-0">
                <h4 className="PoppinsLight orange text-start mb-3"><b>Groupe de valeurs <span className="purple fs-2">C</span></b></h4>
                <div className="row RobotoLight text-start text-white cardGold p-4">
                    <div className="col-12 col-md-3">
                        <h4 className="mb-md-3">Valeurs de conversation</h4>
                        <h4 className="mb-md-3">Valeurs d'acquisition matérielle</h4>
                        <h4 className="mb-md-3">Valeurs d'éducation</h4>
                        <h4 className="mb-md-3">Valeurs de plaisir (Vie agréable menée sans hâte)</h4>
                        <h4 className="mb-md-3">La gratification sensuelle personnelle</h4>
                        <h4 className="mb-md-3">Les liens sincères (amitié)</h4>
                    </div>
                    <div className="col-12 col-md-3">
                        <h4 className="mb-md-3">Authenticité</h4>
                        <h4 className="mb-md-3">Sécurité familiale (prendre soin de ceux qu'on aime)</h4>
                        <h4 className="mb-md-3">La logique</h4>
                        <h4 className="mb-md-3">La bonté</h4>
                        <h4 className="mb-md-3">La tendresse</h4>
                        <h4 className="mb-md-3">La bienveillance</h4>
                    </div>
                    <div className="col-12 col-md-3">
                        <h4 className="mb-md-3">Hauteur de vue</h4>
                        <h4 className="mb-md-3">Lucidité et discernement</h4>
                        <h4 className="mb-md-3">L'ouverture d'esprit</h4>
                        <h4 className="mb-md-3">Le statut social reconnu (respect et admiration)</h4>
                    </div>
                    <div className="col-12 col-md-3">
                        <h4 className="mb-md-3">Le lien à la nature</h4>
                        <h4 className="mb-md-3">L'égalité (égalité des chances)</h4>
                    </div>
                </div>
            </div>
            <div className="row d-flex justify-content-center p-0 p-md-5 mt-5 mt-md-0">
                <h4 className="PoppinsLight orange text-start mb-3"><b>Groupe de valeurs <span className="purple fs-2">D</span></b></h4>
                <div className="row RobotoLight text-start text-white cardGold p-4">
                    <div className="col-12 col-md-3">
                        <h4 className="mb-md-3">Valeurs traditionnelles (autorité, famille, religion)</h4>
                        <h4 className="mb-md-3">Valeurs de survie (sécurité, appartenance)</h4>
                        <h4 className="mb-md-3">Sécurité familiale</h4>
                        <h4 className="mb-md-3">Sécurité nationale</h4>
                        <h4 className="mb-md-3">Le sentiement d'accomplissement (contribution durable)</h4>
                        <h4 className="mb-md-3">Respect de soi</h4>
                    </div>
                    <div className="col-12 col-md-3">
                        <h4 className="mb-md-3">La sagesse</h4>
                        <h4 className="mb-md-3">La politesse</h4>
                        <h4 className="mb-md-3">La réciprocité</h4>
                        <h4 className="mb-md-3">La justice</h4>
                        <h4 className="mb-md-3">La propreté (rangé, ordonné)</h4>
                        <h4 className="mb-md-3">La maitrise de soi</h4>
                    </div>
                    <div className="col-12 col-md-3">
                        <h4 className="mb-md-3">Les compétences</h4>
                        <h4 className="mb-md-3">La cohérence</h4>
                        <h4 className="mb-md-3">L'obéissance (respect)</h4>
                        <h4 className="mb-md-3">La responsabilité (digne de confiance, sérieux)</h4>
                    </div>
                    <div className="col-12 col-md-3">
                        <h4 className="mb-md-3">
                            La conformité (modération des actions, des goûts, des préférences et des impulsions susceptibles
                            de destabiliser ou de blesser les autres ou encore de transgresser les attentes ou les normes sociales))
                        </h4>
                    </div>
                </div>
            </div>
            <div className="row d-flex justify-content-center p-0 p-md-5 mt-5 mt-md-0">
                <h4 className="PoppinsLight orange text-start mb-3"><b>Groupe de valeurs <span className="purple fs-2">E</span></b></h4>
                <div className="row RobotoLight text-start text-white cardGold p-4">
                    <div className="col-12 col-md-3">
                        <h4 className="mb-md-3">La spiritualité</h4>
                        <h4 className="mb-md-3">Le sens de l'écoute</h4>
                        <h4 className="mb-md-3">L'adaptation</h4>
                        <h4 className="mb-md-3">Le lâcher prise</h4>
                        <h4 className="mb-md-3">La transcendance de la réalité quotidienne</h4>
                        <h4 className="mb-md-3">L'harmonie intérieure</h4>
                    </div>
                    <div className="col-12 col-md-3">
                        <h4 className="mb-md-3">Le détachement</h4>
                        <h4 className="mb-md-3">L'unité avec la nature</h4>
                        <h4 className="mb-md-3">L'unité avec le vivant</h4>
                        <h4 className="mb-md-3">L'unité avec les autres</h4>
                        <h4 className="mb-md-3">L'endurance</h4>
                        <h4 className="mb-md-3">La guidance (se sentir guider et guider les autres)</h4>
                    </div>
                    <div className="col-12 col-md-3">
                        <h4 className="mb-md-3">L'autorité</h4>
                        <h4 className="mb-md-3">L'influence</h4>
                        <h4 className="mb-md-3">Le pouvoir personnel</h4>
                        <h4 className="mb-md-3">Le pouvoir social</h4>
                    </div>
                    <div className="col-12 col-md-3">
                        <h4 className="mb-md-3">La force</h4>
                        <h4 className="mb-md-3">La volonté profonde de réaliser son destin</h4>
                    </div>
                </div>
            </div>
            <div className="row d-flex justify-content-center">
                <div className='col-12 col-xl-6 mb-5 mt-4'>
                    {form}
                </div>
            </div>
            <div className='row d-flex justify-content-center gy-3 mt-5 mb-5'>
                <button type='submit' className='signupBtn' onClick={saveClickedHandler} disabled={!valid} >
                    <span className='signupBtnSpan roboto'>Enregistrer</span>
                </button>
            </div>
            <Footer/>
        </div>
    )
};

const mapStateToProps = state => ({
    user: state.auth.user,
    isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps, {saveValues, load_user_details}) (Values);