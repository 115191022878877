import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import {Link, useNavigate} from 'react-router-dom';
import axiosAccounts from '../../config/axios_accounts';
import {USER_DETAILS_LOADED_SUCCESS, USER_DETAILS_LOADED_FAIL} from '../../store/actions/types';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { logout } from '../../store/actions/auth';
import routes from '../../config/routes';

import Footer from '../Footer/Footer';
import Check from './picts/Pouce.png';
import Logout from './picts/onOff.png';
import Logo from './picts/logo-d3s.png';


function Quiz ({logout, user, isAuthenticated}){

    //STATE
    const [loader, setLoader] = useState(true);

    //REDIRECT
    const navigate = useNavigate();

    //FUNCTIONS

    const showToastError = () => {
        toast('vous devez vous connecter pour accéder à votre profil !', {
            position: toast.POSITION.TOP_RIGHT
        });
    };

    const prefValidation = () => {
        if (user.BS === false){
            if (user.PREF === true){
                return(
                    <>
                       <div className='row d-flex justify-content-center p-4'>
                            <div className='col-12 col-md-6 card cardOr p-4 align-items-center' data-aos="fade-right" data-aos-duration="1000" data-aos-offset="200" data-aos-easing="ease-in-out">
                                <h3 className=' PoppinsLight text-white'>Préférences</h3>
                                <img src={Check} />
                            </div>
                        </div>
                    </>
                )
            }
            else{
                return(
                    <>
                        <div className='row d-flex justify-content-center p-4'>
                            <div className='col-12 col-md-6 card cardOr p-4 align-items-center' data-aos="fade-right" data-aos-duration="1000" data-aos-offset="200" data-aos-easing="ease-in-out">
                                <h3 className=' PoppinsLight text-white'>Préférences</h3>
                                <Link to={routes.PREFERENCES} className='signupBtn'>
                                    <span className='signupBtnSpan'>Commencer</span>
                                </Link>
                            </div>
                        </div>
                    </>
                )
            }
        }
    };

    const avoidValidation = () => {
        if (user.BS === false){
            if (user.AVOID === true){
                return(
                    <>
                       <div className='row d-flex justify-content-center p-4 mt-5'>
                            <div className='col-12 col-md-6 card cardOr p-4 align-items-center' data-aos="fade-left" data-aos-duration="1000" data-aos-offset="200" data-aos-easing="ease-in-out">
                                <h3 className=' PoppinsLight text-white'>Evitements</h3>
                                <img src={Check} />
                            </div>
                        </div>
                    </>
                )
            }
            else{
                return(
                    <>
                        <div className='row d-flex justify-content-center p-4 mt-5' data-aos="fade-left" data-aos-duration="1000" data-aos-offset="200" data-aos-easing="ease-in-out">
                            <div className='col-12 col-md-6 card cardOr p-4 align-items-center'>
                                <h3 className=' PoppinsLight text-white'>Evitements</h3>
                                <Link to={routes.AVOIDANCES} className='signupBtn'>
                                    <span className='signupBtnSpan'>Commencer</span>
                                </Link>
                            </div>
                        </div>
                    </>
                )
            }
        }
    };

    const descriptionsValidation = () => {
        if (user.BS === false){
            if (user.DESCRIPT === true){
                return(
                    <>
                       <div className='row d-flex justify-content-center p-4 mt-5'>
                            <div className='col-12 col-md-6 card cardOr p-4 align-items-center' data-aos="fade-right" data-aos-duration="1000" data-aos-offset="200" data-aos-easing="ease-in-out">
                                <h3 className=' PoppinsLight text-white'>Descriptions</h3>
                                <img src={Check} />
                            </div>
                        </div>
                    </>
                )
            }
            else{
                return(
                    <>
                        <div className='row d-flex justify-content-center p-4 mt-5'>
                            <div className='col-12 col-md-6 card cardOr p-4 align-items-center' data-aos="fade-right" data-aos-duration="1000" data-aos-offset="200" data-aos-easing="ease-in-out">
                                <h3 className=' PoppinsLight text-white'>Descriptions</h3>
                                <Link to={routes.DESCRIPTIONS} className='signupBtn'>
                                    <span className='signupBtnSpan'>Commencer</span>
                                </Link>
                            </div>
                        </div>
                    </>
                )
            }
        }
    };

    const keywordsValidation = () => {
        if (user.BS === false){
            if (user.EKW === true){
                return(
                    <>
                       <div className='row d-flex justify-content-center p-4 mt-5'>
                            <div className='col-12 col-md-6 card cardOr p-4 align-items-center' data-aos="fade-left" data-aos-duration="1000" data-aos-offset="200" data-aos-easing="ease-in-out">
                                <h3 className=' PoppinsLight text-white'>Mots clés</h3>
                                <img src={Check} />
                            </div>
                        </div>
                    </>
                )
            }
            else{
                return(
                    <>
                        <div className='row d-flex justify-content-center p-4 mt-5'>
                            <div className='col-12 col-md-6 card cardOr p-4 align-items-center' data-aos="fade-left" data-aos-duration="1000" data-aos-offset="200" data-aos-easing="ease-in-out">
                                <h3 className=' PoppinsLight text-white'>Mots Clés</h3>
                                <Link to={routes.ENERGYKEYWORD} className='signupBtn'>
                                    <span className='signupBtnSpan RobotoBoldItalic'>Commencer</span>
                                </Link>
                            </div>
                        </div>
                    </>
                )
            }
        }
    };

    const ATValidation = () => {
        if (user.AT === true){
            return(
                <>
                   <div className='row d-flex justify-content-center p-4 mt-5'>
                        <div className='col-12 col-md-6 card cardOr p-4 align-items-center' data-aos="fade-right" data-aos-duration="1000" data-aos-offset="200" data-aos-easing="ease-in-out">
                            <h3 className=' PoppinsLight text-white'>Analyse Transactionnelle</h3>
                            <img src={Check} />
                        </div>
                    </div>
                </>
            )
        }
        else{
            return(
                <>
                    <div className='row d-flex justify-content-center p-4 mt-5'>
                        <div className='col-12 col-md-6 card cardOr p-4 align-items-center' data-aos="fade-right" data-aos-duration="1000" data-aos-offset="200" data-aos-easing="ease-in-out">
                            <h3 className=' PoppinsLight text-white'>Analyse Transactionnelle</h3>
                            <Link to={routes.TRANSACTIONALANALYSIS} className='signupBtn'>
                                <span className='signupBtnSpan RobotoBoldItalic'>Commencer</span>
                            </Link>
                        </div>
                    </div>
                </>
            )
        }
    };

    const LPValidation = () => {
        if (user.BS === false){
            if (user.LP === true){
                return(
                    <>
                       <div className='row d-flex justify-content-center p-4 mt-5'>
                            <div className='col-12 col-md-6 card cardOr p-4 align-items-center' data-aos="fade-left" data-aos-duration="1000" data-aos-offset="200" data-aos-easing="ease-in-out">
                                <h3 className=' PoppinsLight text-white'>Position de Vie</h3>
                                <img src={Check} />
                            </div>
                        </div>
                    </>
                )
            }
            else{
                return(
                    <>
                        <div className='row d-flex justify-content-center p-4 mt-5'>
                            <div className='col-12 col-md-6 card cardOr p-4 align-items-center' data-aos="fade-left" data-aos-duration="1000" data-aos-offset="200" data-aos-easing="ease-in-out">
                                <h3 className=' PoppinsLight text-white'>Position de Vie</h3>
                                <Link to={routes.LIFEPOSITION} className='signupBtn'>
                                    <span className='signupBtnSpan'>Commencer</span>
                                </Link>
                            </div>
                        </div>
                    </>
                )
            }
        }
    };

    const ASValidation = () => {
        if (user.BS === false){
            if (user.AS === true){
                return(
                    <>
                       <div className='row d-flex justify-content-center p-4 mt-5'>
                            <div className='col-12 col-md-6 card cardOr p-4 align-items-center' data-aos="fade-right" data-aos-duration="1000" data-aos-offset="300" data-aos-easing="ease-in-out">
                                <h3 className=' PoppinsLight text-white'>Assertivité</h3>
                                <img src={Check} />
                            </div>
                        </div>
                    </>
                )
            }
            else{
                return(
                    <>
                        <div className='row d-flex justify-content-center p-4 mt-5'>
                            <div className='col-12 col-md-6 card cardOr p-4 align-items-center' data-aos="fade-right" data-aos-duration="1000" data-aos-offset="300" data-aos-easing="ease-in-out">
                                <h3 className=' PoppinsLight text-white'>Assertivité</h3>
                                <Link to={routes.ASSERTIVENESS} className='signupBtn'>
                                    <span className='signupBtnSpan'>Commencer</span>
                                </Link>
                            </div>
                        </div>
                    </>
                )
            }
        }
    };

    const STValidation = () => {
        if (user.BS === false){
            if (user.ST === true){
                return(
                    <>
                       <div className='row d-flex justify-content-center p-4 mt-5'>
                            <div className='col-12 col-md-6 card cardOr p-4 align-items-center' data-aos="fade-left" data-aos-duration="1000" data-aos-offset="300" data-aos-easing="ease-in-out">
                                <h3 className=' PoppinsLight text-white'>Sous Types</h3>
                                <img src={Check} />
                            </div>
                        </div>
                    </>
                )
            }
            else{
                return(
                    <>
                        <div className='row d-flex justify-content-center p-4 mt-5'>
                            <div className='col-12 col-md-6 card cardOr p-4 align-items-center' data-aos="fade-left" data-aos-duration="1000" data-aos-offset="300" data-aos-easing="ease-in-out">
                                <h3 className=' PoppinsLight text-white'>Sous Types</h3>
                                <Link to={routes.SUBTYPE} className='signupBtn'>
                                    <span className='signupBtnSpan'>Commencer</span>
                                </Link>
                            </div>
                        </div>
                    </>
                )
            }
        }
    };

    const ERValidation = () => {
        if (user.BS === false){
            if (user.ER === true){
                return(
                    <>
                       <div className='row d-flex justify-content-center p-4 mt-5'>
                            <div className='col-12 col-md-6 card cardOr p-4 align-items-center' data-aos="fade-right" data-aos-duration="1000" data-aos-offset="300" data-aos-easing="ease-in-out">
                                <h3 className=' PoppinsLight text-white'>Répertoire Emotionnel</h3>
                                <img src={Check} />
                            </div>
                        </div>
                    </>
                )
            }
            else{
                return(
                    <>
                        <div className='row d-flex justify-content-center p-4 mt-5'>
                            <div className='col-12 col-md-6 card cardOr p-4 align-items-center' data-aos="fade-right" data-aos-duration="1000" data-aos-offset="300" data-aos-easing="ease-in-out">
                                <h3 className=' PoppinsLight text-white'>Répertoire Emotionnel</h3>
                                <Link to={routes.EMOTIONALREPERTOIRE} className='signupBtn'>
                                    <span className='signupBtnSpan'>Commencer</span>
                                </Link>
                            </div>
                        </div>
                    </>
                )
            }
        }
    };

    const MBTIValidation = () => {
        if (user.MB === true){
            return(
                <>
                   <div className='row d-flex justify-content-center p-4 mt-5'>
                        <div className='col-12 col-md-6 card cardOr p-4 align-items-center' data-aos="fade-left" data-aos-duration="1000" data-aos-offset="100" data-aos-easing="ease-in-out">
                            <h3 className=' PoppinsLight text-white'>Myers & Briggs Type Indicator</h3>
                            <img src={Check} />
                        </div>
                    </div>
                </>
            )
        }
        else{
            return(
                <>
                    <div className='row d-flex justify-content-center p-4 mt-5'>
                        <div className='col-12 col-md-6 card cardOr p-4 align-items-center' data-aos="fade-left" data-aos-duration="1000" data-aos-offset="100" data-aos-easing="ease-in-out">
                            <h3 className=' PoppinsLight text-white'>Myers & Briggs Type Indicator</h3>
                            <Link to={routes.MYERSBRIGGS} className='signupBtn'>
                                <span className='signupBtnSpan'>Commencer</span>
                            </Link>
                        </div>
                    </div>
                </>
            )
        }
    };

    const TempValidation = () => {
        if (user.BS === false){
            if (user.TP === true){
                return(
                    <>
                       <div className='row d-flex justify-content-center p-4 mt-5'>
                            <div className='col-12 col-md-6 card cardOr p-4 align-items-center' data-aos="fade-right" data-aos-duration="1000" data-aos-offset="100" data-aos-easing="ease-in-out">
                                <h3 className=' PoppinsLight text-white'>Tempéraments</h3>
                                <img src={Check} />
                            </div>
                       </div>
                    </>
                )
            }
            else{
                return(
                    <>
                        <div className='row d-flex justify-content-center p-4 mt-5'>
                            <div className='col-12 col-md-6 card cardOr p-4 align-items-center' data-aos="fade-right" data-aos-duration="1000" data-aos-offset="100" data-aos-easing="ease-in-out">
                                <h3 className=' PoppinsLight text-white'>Tempéraments</h3>
                                <Link to={routes.TEMPERAMENT} className='signupBtn'>
                                    <span className='signupBtnSpan'>Commencer</span>
                                </Link>
                            </div>
                        </div>
                    </>
                )
            }
        }
    };

    const ValuesValidation = () => {
        if (user.BR === true || user.BE === true){
            if (user.VAL === true){
                return(
                    <>
                       <div className='row d-flex justify-content-center p-4 mt-5'>
                            <div className='col-12 col-md-6 card cardOr p-4 align-items-center' data-aos="fade-left" data-aos-duration="1000" data-aos-offset="400" data-aos-easing="ease-in-out">
                                <h3 className=' PoppinsLight text-white'>Profil Energétique: Les 5 groupes de valeurs</h3>
                                <img src={Check} />
                            </div>
                       </div>
                    </>
                )
            }
            else{
                return(
                    <>
                        <div className='row d-flex justify-content-center p-4 mt-5'>
                            <div className='col-12 col-md-6 card cardOr p-4 align-items-center' data-aos="fade-left" data-aos-duration="1000" data-aos-offset="400" data-aos-easing="ease-in-out">
                                <h3 className=' PoppinsLight text-white'>Profil Energétique: Les 5 groupes de valeurs</h3>
                                <Link to={routes.VALUES} className='signupBtn'>
                                    <span className='signupBtnSpan'>Commencer</span>
                                </Link>
                            </div>
                        </div>
                    </>
                )
            }
        };
    };

    const EMValidation = () => {
        if (user.BR === true || user.BE === true){
            if (user.EM === true){
                return(
                    <>
                       <div className='row d-flex justify-content-center p-4 mt-5'>
                            <div className='col-12 col-md-6 card cardOr p-4 align-items-center' data-aos="fade-right" data-aos-duration="1000" data-aos-offset="" data-aos-easing="ease-in-out">
                                <h3 className=' PoppinsLight text-white'>Profil Energétique: Préférences</h3>
                                <img src={Check} />
                            </div>
                       </div>
                    </>
                )
            }
            else{
                return(
                    <>
                        <div className='row d-flex justify-content-center p-4 mt-5'>
                            <div className='col-12 col-md-6 card cardOr p-4 align-items-center' data-aos="fade-right" data-aos-duration="1000" data-aos-offset="" data-aos-easing="ease-in-out">
                                <h3 className=' PoppinsLight text-white'>Profil Energétique: Préférences</h3>
                                <Link to={routes.ENERGYMODEL} className='signupBtn'>
                                    <span className='signupBtnSpan'>Commencer</span>
                                </Link>
                            </div>
                        </div>
                    </>
                )
            }
        }
    };

//    const ELValidation = () => {
//        if (user.EL === true){
//            return(
//                <>
//                   <div className='row d-flex justify-content-center p-4 mt-5'>
//                        <div className='col-12 col-md-6 card cardOr p-4 align-items-center' data-aos="fade-left" data-aos-duration="1000" data-aos-offset="400" data-aos-easing="ease-in-out">
//                            <h3 className=' PoppinsLight text-white'>Profil Energétique: 10 Lois</h3>
//                            <img src={Check} />
//                        </div>
//                   </div>
//                </>
//            )
//        }
//        else{
//            return(
//                <>
//                    <div className='row d-flex justify-content-center p-4 mt-5'>
//                        <div className='col-12 col-md-6 card cardOr p-4 align-items-center' data-aos="fade-left" data-aos-duration="1000" data-aos-offset="300" data-aos-easing="ease-in-out">
//                            <h3 className=' PoppinsLight text-white'>Profil Energétique: 10 Lois</h3>
//                            <Link to={routes.ENERGYLAW} className='signupBtn'>
//                                <span className='signupBtnSpan'>Commencer</span>
//                            </Link>
//                        </div>
//                    </div>
//                </>
//            )
//        }
//    };

    const LeadValidation = () => {
        if (user.BR === true || user.BE === true){
            if (user.LEAD === true){
                return(
                    <>
                       <div className='row d-flex justify-content-center p-4 mt-5'>
                            <div className='col-12 col-md-6 card cardOr p-4 align-items-center' data-aos="fade-right" data-aos-duration="1000" data-aos-offset="" data-aos-easing="ease-in-out">
                                <h3 className=' PoppinsLight text-white'>Leadership</h3>
                                <img src={Check} />
                            </div>
                       </div>
                    </>
                )
            }
            else{
                return(
                    <>
                        <div className='row d-flex justify-content-center p-4 mt-5'>
                            <div className='col-12 col-md-6 card cardOr p-4 align-items-center' data-aos="fade-right" data-aos-duration="1000" data-aos-offset="" data-aos-easing="ease-in-out">
                                <h3 className=' PoppinsLight text-white'>Leadership</h3>
                                <Link to={routes.LEADERSHIP} className='signupBtn'>
                                    <span className='signupBtnSpan'>Commencer</span>
                                </Link>
                            </div>
                        </div>
                    </>
                )
            }
        }
    };

    const clickLogout = () => {
        navigate(routes.HOME);
        logout();
    };

    //ComponentDidMount
    useEffect(() => {

        //USER INFORMATIONS
        const load_user_details = () => async dispatch => {

            let accessToken = localStorage.getItem('access');

            if ( accessToken ) {
                const config = {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `JWT ${accessToken}`,
                        'Accept': 'application/json'
                    }
                };

                try{
                    const res = await axiosAccounts.get('details/', config);
                    console.log('user details loaded')
                    localStorage.setItem('userLoaded', false)
                    dispatch({
                        type: USER_DETAILS_LOADED_SUCCESS,
                        payload: res.data
                    });
                    if (!user){
                       navigate(routes.LOGIN)
                    }
                    else {
                        if (user.is_front_admin === true){
                            navigate(routes.ADMIN)
                        }
                        setLoader(false)
                    }
                }
                catch (err) {
                    showToastError();
                    dispatch({
                        type: USER_DETAILS_LOADED_FAIL
                    });
                }
            }
            else {
                showToastError()
            }
        };
        load_user_details()
    }, [])

    //ComponentDidUpdate
    useEffect(() => {
        console.log('##### UPDATE ######')
        const auth = localStorage.getItem('authenticated')
        const loading = localStorage.getItem('userLoaded')
        console.log(loading)
        if (! auth){
            navigate(routes.HOME)
            showToastError()
        }
        if (!user) {
            navigate(routes.LOGIN)
        }
        else {
            if (user.is_front_admin === true){
                navigate(routes.ADMIN)
            }
            if (loading) {
                setLoader(false)
            }
        }
    });

    return loader ? (
        <>
            <div className="container screenHeight100">
                <div className='row screenHeight100'>

                </div>
            </div>
        </>
    ) : (
        <div className='container-fluid '>
            <div className='row d-flex justify-content-end pe-5 pt-5 screenheight20'>
                <div className="col-6">
                    <img src={Logout} type='button' onClick={clickLogout} className='logout' />
                </div>
            </div>
            <div className='row d-flex justify-content-center mt-5 mb-5'>
                {user ? <h1 className='PoppinsLight or mb-3'>Questionnaires de <span className='PoppinsLight purple'>{user.first_name} {user.last_name}</span></h1> : ''}
                <h5 className="PoppinsLight or">(Après avoir validé l'ensemble de vos questionnaires revenez sur votre profil pour envoyer vos réponses)</h5>
            </div>
            { prefValidation() }
            { avoidValidation() }
            { descriptionsValidation() }
            { keywordsValidation() }
            { ATValidation() }
            { LPValidation() }
            { ASValidation() }
            { STValidation() }
            { ERValidation() }
            { MBTIValidation() }
            { TempValidation() }
            { ValuesValidation() }
            { EMValidation() }
            { LeadValidation() }
            <Footer/>
        </div>
    )
};

const mapStateToProps = state => ({
    user: state.auth.user,
    isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps, { logout }) (Quiz);