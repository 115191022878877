import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import {useNavigate} from 'react-router-dom';

import { checkValidity } from '../../../shared/utility';
import { saveEmotionalRepertoire, load_user_details } from '../../../store/actions/auth';
import routes from '../../../config/routes';
import Input from '../../../components/UI/Input/Input';
import Footer from '../../Footer/Footer';


function EmotionalRepertoire({user, saveEmotionalRepertoire, load_user_details, isAuthenticated}) {

    //SELECT FIELDS
    const number = [
        {value: '', displayValue: 'Sélectionner votre réponse'},
        {value: '0', displayValue: '0'},
        {value: '1', displayValue: '1'},
        {value: '2', displayValue: '2'},
        {value: '3', displayValue: '3'},
        {value: '4', displayValue: '4'},
        {value: '5', displayValue: '5'},
        {value: '6', displayValue: '6'},
        {value: '7', displayValue: '7'},
        {value: '8', displayValue: '8'},
        {value: '9', displayValue: '9'},
    ]

    //STATE
    const [loader, setLoader] = useState(true);

    const [inputs, setInputs] = useState({
        REJFOR: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "Combien de ces sentiments éprouvez vous SOUVENT ?",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis",
            proposal_A: "Ardent",
            proposal_B: "Béat",
            proposal_C: "Emerveillé",
            proposal_D: "Enthousiaste",
            proposal_E: "Euphorique",
            proposal_F: "Extatique",
            proposal_G: "Exalté",
            proposal_H: "Passionné",
            proposal_I: "Radieux",
        },
        REJMOY: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "Combien de ces sentiments éprouvez vous SOUVENT ?",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis",
            proposal_A: "Heureux",
            proposal_B: "Jovial",
            proposal_C: "Joyeux",
            proposal_D: "Optimiste",
            proposal_E: "Ravi",
            proposal_F: "Epanoui",
            proposal_G: "Enjoue",
            proposal_H: "Enchanté",
            proposal_I: "Reconnaissant",
        },
        REJFAI: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "Combien de ces sentiments éprouvez vous SOUVENT ?",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis",
            proposal_A: "Calme",
            proposal_B: "Content",
            proposal_C: "Detendu",
            proposal_D: "Enjoue",
            proposal_E: "Gai",
            proposal_F: "Insouciant",
            proposal_G: "Paisible",
            proposal_H: "Plein d'entrain",
            proposal_I: "Satisfait",
        },
        RECFOR: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "Combien de ces sentiments éprouvez vous SOUVENT ?",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis",
            proposal_A: "Acerbe",
            proposal_B: "Courroucé",
            proposal_C: "Enragé",
            proposal_D: "Exaspéré",
            proposal_E: "Furieux",
            proposal_F: "Haineux",
            proposal_G: "Révolté",
            proposal_H: "Véhément",
            proposal_I: "Vengeur",
        },
        RECMOY: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "Combien de ces sentiments éprouvez vous SOUVENT ?",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis",
            proposal_A: "Agité",
            proposal_B: "Agressif",
            proposal_C: "Bélliqueux",
            proposal_D: "Dégouté",
            proposal_E: "Frustré",
            proposal_F: "Faché",
            proposal_G: "Indigné",
            proposal_H: "Rancunier",
            proposal_I: "Véxé",
        },
        RECFAI: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "Combien de ces sentiments éprouvez vous SOUVENT ?",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis",
            proposal_A: "Agacé",
            proposal_B: "Contrarié",
            proposal_C: "Embété",
            proposal_D: "Enervé",
            proposal_E: "Froissé",
            proposal_F: "Grognon",
            proposal_G: "Insatisfait",
            proposal_H: "Irrité",
            proposal_I: "Mécontent",
        },
        RETFOR: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "Combien de ces sentiments éprouvez vous SOUVENT ?",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis",
            proposal_A: "Anéanti",
            proposal_B: "Brisé",
            proposal_C: "Bouleversé",
            proposal_D: "Déprimé",
            proposal_E: "Désespéré",
            proposal_F: "Détruit",
            proposal_G: "Dévasté",
            proposal_H: "Effondré",
            proposal_I: "Ravagé",
        },
        RETMOY: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "Combien de ces sentiments éprouvez vous SOUVENT ?",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis",
            proposal_A: "Abandonné",
            proposal_B: "Apathique",
            proposal_C: "Découragé",
            proposal_D: "Mélancolique",
            proposal_E: "Nostalgique",
            proposal_F: "Pessimiste",
            proposal_G: "Rejeté",
            proposal_H: "Malheureux",
            proposal_I: "Triste",
        },
        RETFAI: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "Combien de ces sentiments éprouvez vous SOUVENT ?",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis",
            proposal_A: "Affecté",
            proposal_B: "Déçu",
            proposal_C: "Désenchanté",
            proposal_D: "Impuissant",
            proposal_E: "Ombrageux",
            proposal_F: "Peiné",
            proposal_G: "Sensible",
            proposal_H: "Seul",
            proposal_I: "Susceptible",
        },
        REPFOR: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "Combien de ces sentiments éprouvez vous SOUVENT ?",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis",
            proposal_A: "Effrayé",
            proposal_B: "Epouvanté",
            proposal_C: "Horrifié",
            proposal_D: "Paniqué",
            proposal_E: "Paralysé",
            proposal_F: "Paranoïaque",
            proposal_G: "Sidéré",
            proposal_H: "Tourmenté",
            proposal_I: "Traumatisé",
        },
        REPMOY: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "Combien de ces sentiments éprouvez vous SOUVENT ?",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis",
            proposal_A: "Anxieux",
            proposal_B: "Inquiet",
            proposal_C: "Préoccupé",
            proposal_D: "Perturbé",
            proposal_E: "Soucieux",
            proposal_F: "Soupçonneux",
            proposal_G: "Stressé",
            proposal_H: "Tendu",
            proposal_I: "Troublé",
        },
        REPFAI: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "Combien de ces sentiments éprouvez vous SOUVENT ?",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis",
            proposal_A: "Hésitant",
            proposal_B: "Incertain",
            proposal_C: "Indécis",
            proposal_D: "Méfiant",
            proposal_E: "Perplexe",
            proposal_F: "Prudent",
            proposal_G: "Réticent",
            proposal_H: "Surpris",
            proposal_I: "Vigilant",
        },
    });

    const [valid, setValid] = useState(false);
    const [quizCompleted, setQuizCompleted] = useState(false);

    //Redirect
    const navigate = useNavigate();

    //ComponentDidMount
    useEffect(() => {
        load_user_details();
        if (!user){
            setTimeout(() => {
            setLoader(false);
            }, 1000);
        }
        else {
            if(user.ER){
                navigate(routes.HOME)
            }
            else{
                setLoader(false)
            }
        }
    }, [])

    //ComponentDidUpdate
    useEffect(() => {
        if (!user){
            setTimeout(() => {
            setLoader(false);
            }, 1000);
        }
        else {
            if(user.ER){
                navigate(routes.HOME)
            }
            else{
                setLoader(false)
            }
        }
        const auth = localStorage.getItem('authenticated')
        if (! auth){
            alert('vous devez vous connecter pour répondre au questionnaire.')
            navigate(routes.HOME)
        }
    });

    //Functions

    const InputChangedHandler = (event, id) => {

        //Change Value
        const newInputs = {...inputs};
        newInputs[id].value = event.target.value;
        newInputs[id].touched = true;

        //Check value
        newInputs[id].valid = checkValidity(event.target.value, newInputs[id].validation)

        setInputs(newInputs);

        //Form checking
        let formIsValid = true;
        for (let input in newInputs) {
            formIsValid = newInputs[input].valid && formIsValid;
        }
        setValid(formIsValid);
    };

    const formHandler = event => {
        event.preventDefault();
    }


    //Save Confirmation
    const saveClickedHandler = () => {
        console.log(localStorage);
        const RE = {
            user: user.email, user_email: user.email,
            REJFOR: inputs.REJFOR.value, REJMOY: inputs.REJMOY.value, REJFAI: inputs.REJFAI.value,
            RECFOR: inputs.RECFOR.value, RECMOY: inputs.RECMOY.value, RECFAI: inputs.RECFAI.value,
            RETFOR: inputs.RETFOR.value, RETMOY: inputs.RETMOY.value, RETFAI: inputs.RETFAI.value,
            REPFOR: inputs.REPFOR.value, REPMOY: inputs.REPMOY.value, REPFAI: inputs.REPFAI.value,
        };
        try{
            saveEmotionalRepertoire(
                RE.user, RE.user_email,
                RE.REJFOR, RE.REJMOY, RE.REJFAI,
                RE.RECFOR, RE.RECMOY, RE.RECFAI,
                RE.RETFOR, RE.RETMOY, RE.RETFAI,
                RE.REPFOR, RE.REPMOY, RE.REPFAI,
            );
            setQuizCompleted(true);
            navigate(routes.QUIZ)
        }
        catch(err){
            alert("Vérifiez que l'ensemble des champs soient renseignés")
        }
    }

    //Variables

    const formElementArray = [];
    for(let key in inputs) {
        formElementArray.push({
            id: key,
            config: inputs[key]
        });
    };

    let form = (
        <form onSubmit={(e) => formHandler(e)}>
            {formElementArray.map(formElement => (
            <div className="row d-flex justify-content-center mt-5" key={formElement.id}>

                <h4 className="RobotoLight orange mt-5 mb-3 text-start"><b>{formElement.config.label}</b></h4>
                <div className="cardGold">
                <div className="row mt-5 mb-5 text-start text-white">
                    <div className="col-6 col-sm-4">
                        <h4 className="RobotoLight">{formElement.config.proposal_A}</h4>
                        <h4 className="RobotoLight">{formElement.config.proposal_B}</h4>
                        <h4 className="RobotoLight">{formElement.config.proposal_C}</h4>
                    </div>
                    <div className="col-6 col-sm-4">
                        <h4 className="RobotoLight">{formElement.config.proposal_D}</h4>
                        <h4 className="RobotoLight">{formElement.config.proposal_E}</h4>
                        <h4 className="RobotoLight">{formElement.config.proposal_F}</h4>
                    </div>
                    <div className="col-6 col-sm-4">
                        <h4 className="RobotoLight">{formElement.config.proposal_G}</h4>
                        <h4 className="RobotoLight">{formElement.config.proposal_H}</h4>
                        <h4 className="RobotoLight">{formElement.config.proposal_I}</h4>
                    </div>
                </div>
                </div>
                <Input
                    id={formElement.id}
                    value={formElement.config.value}
                    type={formElement.config.elementType}
                    config={formElement.config.elementConfig}
                    valid={formElement.config.valid}
                    touched={formElement.config.touched}
                    errorMessage={formElement.config.errorMessage}
                    changed={(e) => InputChangedHandler(e, formElement.id)}
                />

            </div>
            ))}
        </form>
    );


    return loader ? (
        <>
            <div className="container-fluid">
                <div className='row d-flex justify-content-center mt-5'>

                </div>
            </div>
        </>
    ) : (
        <div className='container-fluid p-3'>
            <div className='row d-flex justify-content-center mt-5'>
                <h1 className='PoppinsLight or'>Répertoire Emotionnel</h1>
            </div>
            <div className='row d-flex justify-content-center'>
                <div className='col-12 col-md-6 mb-5 mt-4'>
                    {form}
                </div>
            </div>
            <div className='row d-flex justify-content-center gy-3 mt-5 mb-5'>
                <button type='submit' className='signupBtn' onClick={saveClickedHandler} disabled={!valid} >
                    <span className='signupBtnSpan roboto'>Enregistrer</span>
                </button>
            </div>
            <Footer/>
        </div>
    )
};

const mapStateToProps = state => ({
    user: state.auth.user,
    isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps, {saveEmotionalRepertoire, load_user_details}) (EmotionalRepertoire);