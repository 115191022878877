//Library
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { checkValidity } from '../../../shared/utility';
import { edit_user_details, load_user_details } from '../../../store/actions/auth';
import routes from '../../../config/routes';

//Components
import Input from '../../../components/UI/Input/Input';

function UserDetails({edit_user_details, load_user_details, user, isAuthenticated}) {

    //STATE
    const [loader, setLoader] = useState(true);
    const [selectedFile, setSelectedFile] = useState()

    //SELECT FIELDS
    const bs_type = [
        {value: '', displayValue: ''},
        {value: 'BR', displayValue: 'Recrutement'},
        {value: 'BE', displayValue: 'Employé'},
        {value: 'BP', displayValue: 'Personnel'},
        {value: 'BS', displayValue: 'Simplifié'},
    ];
    const commit = [
        {value: '', displayValue: ''},
        {value: 'commitment', displayValue: "J'ai compris et je m'engage"},
    ]


    //State
    const [inputs, setInputs] = useState({
        first_name: {
            elementType: 'input',
            elementConfig: {
                type: 'name',
                placeholder: ''
            },
            value: '',
            label: 'Prénom',
            valid: false,
            validation: {
                required: true,
                maxLength: 150,
            },
            touched: false,
            errorMessage: "Champ requis - 150 caractères au maximum"
        },
        last_name: {
            elementType: 'input',
            elementConfig: {
                type: 'name',
                placeholder: 'Votre nom'
            },
            value: '',
            label: 'Nom',
            valid: false,
            validation: {
                required: true,
                maxLength: 150,
            },
            touched: false,
            errorMessage: "Champ requis - 150 caractères au maximum"
        },
        birth_date: {
            elementType: 'input',
            elementConfig: {
                type: 'date',
                placeholder: '__-__-____'
            },
            value: '',
            label: 'Votre date de naissance',
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis"
        },
        balance_sheet: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: bs_type
            },
            value: '',
            label: "Dans quel cadre effectuez-vous ce bilan ?",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis",
        },
        diploma: {
            elementType: 'input',
            elementConfig: {
                type: 'name',
                placeholder: 'Vos diplômes'
            },
            value: '',
            label: 'Diplômes',
            valid: false,
            validation: {
                required: true,
                maxLength: 150,
            },
            touched: false,
            errorMessage: "Champ requis - 150 caractères au maximum"
        },
        occupation: {
            elementType: 'input',
            elementConfig: {
                type: 'name',
                placeholder: 'Votre profession'
            },
            value: '',
            label: 'Profession',
            valid: false,
            validation: {
                required: true,
                maxLength: 150,
            },
            touched: false,
            errorMessage: "Champ requis - 150 caractères au maximum"
        },
        company: {
            elementType: 'input',
            elementConfig: {
                type: 'name',
                placeholder: 'Votre entreprise'
            },
            value: '',
            label: 'Dans quelle entreprise exercez-vous ?',
            valid: false,
            validation: {
                required: true,
                maxLength: 150,
            },
            touched: false,
            errorMessage: "Champ requis - 150 caractères au maximum"
        },
        recruiter: {
            elementType: 'input',
            elementConfig: {
                type: 'name',
                placeholder: 'Recruteur'
            },
            value: '',
            label: 'Pour quelle entreprise postulez-vous ?',
            valid: true,
            validation: {
                maxLength: 150,
            },
            touched: false,
            errorMessage: "Champ requis - 150 caractères au maximum"
        },
        curriculum: {
            elementType: 'input',
            elementConfig: {
                type: 'file',
                placeholder: ''
            },
            value: '',
            label: "Dans le cadre d'un recrutement, veuillez téléchargez votre CV au format PDF",
            valid: true,
            validation: {
                noRules: true,
            },
            touched: false,
            errorMessage: "Champs requis"
        },
        commitment: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: commit,
            },
            value: '',
            label: "Cadre de Confidentialité",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis",
            proposal_A: "« Aucune partie de ces questionnaires en ligne ne peut être reproduite, stockée ou transmise sous quelque format, sur quelque support ou de quelque manière que ce soit sans autorisation écrite et préalable de Sandra Parent – D3S »",
            proposal_B: "Pour accèder aux différents questionnaires vous devez vous engager à respecter cet accord de non divulgation et de non reproduction.",
        },
    });

    const [valid, setValid] = useState(false);

    //Functions

    const InputChangedHandler = (event, id) => {

        //Change Value
        const newInputs = {...inputs};
        newInputs[id].value = event.target.value;
        newInputs[id].touched = true;

        if(newInputs[id].elementConfig.type === 'file'){
            setSelectedFile(event.target.files[0]);
        }

        //Check value
        newInputs[id].valid = checkValidity(event.target.value, newInputs[id].validation)

        setInputs(newInputs);

        //Form checking
        let formIsValid = true;
        for (let input in newInputs) {
            formIsValid = newInputs[input].valid && formIsValid;
        }
        setValid(formIsValid);
    };

    const formHandler = event => {
        event.preventDefault();
    }

    //ComponentDidMount
    useEffect(() => {
        load_user_details();
        if (!user){
            setTimeout(() => {
            setLoader(false);
            }, 1000);
        }
        else {
            setLoader(false)
        }
        const auth = localStorage.getItem('authenticated');
        if (! auth){
            navigate(routes.HOME)
            alert('vous devez vous connecter pour accéder à votre profil')
        }
    }, [])

    //ComponentDidUpdate
     useEffect(() => {
        const auth = localStorage.getItem('authenticated');
        if (! auth){
            navigate(routes.HOME)
            alert('vous devez vous connecter pour accéder à votre profil')
        }
    });



    //Redirect
    const navigate = useNavigate();

    //Confirm Register
    const registerClickedHandler = () => {

        let BR = false
        let BP = false
        let BE = false
        let BS = false
        let balance = inputs.balance_sheet.value;
        let BDate;

        if(balance === 'BR'){
            BR = true;
        }
        if(balance === 'BP'){
            BP = true;
        }
        if(balance === 'BE'){
            BE = true;
        }
        if(balance === 'BS'){
            BS = true;
        }

        let commitment = false;
        let CMT = inputs.commitment.value
        if(CMT === 'commitment'){
            commitment = true;
        }

        const user = {
            first_name: inputs.first_name.value,
            last_name: inputs.last_name.value,
            birth_date: inputs.birth_date.value,
            diploma: inputs.diploma.value,
            occupation: inputs.occupation.value,
            company: inputs.company.value,
            recruiter: inputs.recruiter.value,
            curriculum: selectedFile,
            BR: BR,
            BP: BP,
            BE: BE,
            BS: BS,
            commitment: commitment,
        };

        try{
            console.log('user BS')
            console.log(user.BS)
            edit_user_details(
                user.first_name,
                user.last_name,
                user.birth_date,
                user.diploma,
                user.occupation,
                user.company,
                user.recruiter,
                user.curriculum,
                user.BR,
                user.BP,
                user.BE,
                user.BS,
                user.commitment
            );
            navigate(routes.PROFILE);
        }
        catch(err){
            alert("Vérifiez que l'ensemble des champs soient renseignés")
        }

    }

    //Variables

    const formElementArray = [];
    for(let key in inputs) {
        formElementArray.push({
            id: key,
            config: inputs[key]
        });
    };

    let form = (
        <form onSubmit={(e) => formHandler(e)}>
            {formElementArray.map(formElement => (
                <div className="row d-flex justify-content-center mt-5" key={formElement.id}>
                    <Input
                        key={formElement.id}
                        id={formElement.id}
                        value={formElement.config.value}
                        label={formElement.config.label}
                        type={formElement.config.elementType}
                        config={formElement.config.elementConfig}
                        valid={formElement.config.valid}
                        touched={formElement.config.touched}
                        errorMessage={formElement.config.errorMessage}
                        changed={(e) => InputChangedHandler(e, formElement.id)}
                        proposal_A={formElement.config.proposal_A}
                        proposal_B={formElement.config.proposal_B}
                    />
                </div>
            ))}
            <div className='row d-flex justify-content-center gy-3 mt-5 mb-5'>
                <button type='button' className='signupBtn' onClick={registerClickedHandler} disabled={!valid}><span className='signupBtnSpan RobotoBold'>Enregistrer</span></button>
            </div>
        </form>
    );


    return loader ? (
        <>
            <div className="container-fluid">
                <div className='row d-flex justify-content-center mt-5'>

                </div>
            </div>
        </>
    ) : (
        <div className='container-fluid'>
            <div className='row d-flex justify-content-center my-5'>
            <h1 className='text-center mt-5 PoppinsLight or'>Mes Informations Personnelles</h1>
                <div className='col-12 col-md-6'>
                    {form}
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = state => ({
    user: state.auth.user,
    isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps, { edit_user_details, load_user_details }) (UserDetails);