// Librairies
import React from 'react';
import classes from './Input.module.css';

function Input(props) {

    let inputElement;
    const inputClasses = [];

    if(!props.valid && props.touched) {
        inputClasses.push(classes.invalid);
    }

    switch(props.type) {
        case('input'):
            inputElement = (
                <input
                    {...props.config}
                    value={props.value}
                    className={inputClasses}
                    id={props.id}
                    onChange={props.changed} />
            );
            break;
        case('textarea'):
            inputElement = (
                <textarea
                    value={props.value}
                    onChange={props.changed}
                    className={inputClasses}
                    id={props.id}></textarea>
            );
            break;
        case('select'):
            inputElement = (
                <select
                    value={props.value}
                    id={props.id}
                    onChange={props.changed}>
                        className={inputClasses}
                    {props.config.options.map(option => (
                        <option key={option.value} value={option.value}>
                            {option.displayValue}
                        </option>
                    ))}
                </select>
            );
            break;
        default:
            inputElement = (
                <input
                    {...props.config}
                    value={props.value}
                    className={inputClasses}
                    id={props.id}
                    onChange={props.changed} />
            );
            break;
    }

    return (
        <div className={[classes.Input, 'RobotoBold'].join(' ')}>
            <label htmlFor={props.id} className="mb-3 RobotoThin">{props.label}</label>
            <p className='RobotoItalic text-start' htmlFor={props.id}>{props.proposal_A}</p>
            <p className='RobotoItalic text-start' htmlFor={props.id}>{props.proposal_B}</p>
            <p className='RobotoItalic text-start' htmlFor={props.id}>{props.proposal_C}</p>
            <p className='RobotoItalic text-start' htmlFor={props.id}>{props.proposal_D}</p>
            <p className='RobotoItalic text-start' htmlFor={props.id}>{props.proposal_E}</p>
            <p className='RobotoItalic text-start' htmlFor={props.id}>{props.proposal_F}</p>
            <p className='RobotoItalic text-start' htmlFor={props.id}>{props.proposal_G}</p>
            <p className='RobotoItalic text-start' htmlFor={props.id}>{props.proposal_H}</p>
            <p className='RobotoItalic text-start' htmlFor={props.id}>{props.proposal_I}</p>
            <p className='RobotoItalic text-start' htmlFor={props.id}>{props.proposal_J}</p>
            <p className='RobotoItalic text-start' htmlFor={props.id}>{props.proposal_K}</p>
            <p className='RobotoItalic text-start' htmlFor={props.id}>{props.proposal_L}</p>
            <p className='RobotoItalic text-start' htmlFor={props.id}>{props.proposal_M}</p>
            <p className='RobotoItalic text-start' htmlFor={props.id}>{props.proposal_N}</p>
            <p className='RobotoItalic text-start' htmlFor={props.id}>{props.proposal_O}</p>
            <p className='RobotoItalic text-start' htmlFor={props.id}>{props.proposal_P}</p>
            <p className='RobotoItalic text-start' htmlFor={props.id}>{props.proposal_Q}</p>
            <p className='RobotoItalic text-start' htmlFor={props.id}>{props.proposal_R}</p>
            <p className='RobotoItalic text-start' htmlFor={props.id}>{props.proposal_S}</p>
            <p className='RobotoItalic text-start' htmlFor={props.id}>{props.proposal_T}</p>
            <p className='RobotoItalic text-start' htmlFor={props.id}>{props.proposal_U}</p>
            <p className='RobotoItalic text-start' htmlFor={props.id}>{props.proposal_V}</p>
            {inputElement}
            {!props.valid && props.touched ? <span className="RobotoItalic">{props.errorMessage}</span> : null}
        </div>
    );
}

export default Input;