import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import {Link, useNavigate} from 'react-router-dom';
import axiosQuiz from'../../../config/axios_quiz';
import axiosAccounts from'../../../config/axios_accounts';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { logout, load_user_details } from '../../../store/actions/auth';
import routes from '../../../config/routes';

import Logout from '../picts/onOff.png';


function UserResults ({logout, user, load_user_details, isAuthenticated}){

    //STATE
    const [loader, setLoader] = useState(true);
    const [results, setResults] = useState ({});
    const [info, setInfo] = useState ({});

    //REDIRECT
    const navigate = useNavigate();

    //VARIABLES


    //ComponentDidMount

    useEffect(() =>{

        let accessToken = localStorage.getItem('access');
        let user = localStorage.getItem('candidate');

        if( accessToken ) {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `JWT ${accessToken}`,
                    'Accept': 'application/json'
                }
            };

            const axiosFetch = async () => {
                try {
                    const res = await axiosQuiz.get(`results/${user}`, config);
                    console.log('results')
                    setResults(res.data)
                    console.log(results)

                }
                catch(err){
                    console.log('Axios error')
                }
            };
            axiosFetch();
        }
        if( accessToken ) {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `JWT ${accessToken}`,
                    'Accept': 'application/json'
                }
            };

            const axiosFetch = async () => {
                try {
                    const res = await axiosAccounts.get(`details/${user}`, config);
                    console.log('Infos')
                    setInfo(res.data)
                    console.log(info)

                }
                catch(err){
                    console.log('Axios error')
                }
            };
            axiosFetch();
        }
    }, []);

    const logoutHandler = () => {
        logout();
        navigate(routes.HOME);
    }

    const showToastError = () => {
        toast('vous devez vous connecter pour accéder à votre profil !', {
            position: toast.POSITION.TOP_CENTER
        });
    };


    useEffect(() => {
        load_user_details();
        if (!user){
            setTimeout(() => {
            setLoader(false);
            }, 1000);
        }
        else {
            if (user.is_front_admin === false){
                navigate(routes.HOME)
            }
            setLoader(false)
        }
    }, [])

    //ComponentDidUpdate
    useEffect(() => {
        const auth = localStorage.getItem('authenticated')
        if (! auth){
            navigate(routes.HOME)
            showToastError()
        }
        if (!user){
            setTimeout(() => {
            setLoader(false);
            }, 1000);
        }
        else {
            if (user.is_front_admin === false){
                navigate(routes.HOME)
            }
            setLoader(false)
        }
    });

    return loader ? (
        <>
            <div className="container-fluid">
                <div className='row d-flex justify-content-center mt-5 screenHeight100'>

                </div>
            </div>
        </>
    ) : (
        <div className='container-fluid p-4'>
                <div className='row d-flex justify-content-end screenHeight20'>
                    <div className="col-6">
                        <img src={Logout} type='button' onClick={logoutHandler} className='logout' />
                    </div>
                </div>
            <div className='row d-flex justify-content-center mt-5'>
                <h1 className="PoppinsLight or">Informations & Résultats</h1>
            </div>
            <div className='row d-flex justify-content-center p-4'>
                <div className='col-12 col-md-6 card cardOr p-4 align-items-center'>
                    <h3 className='PoppinsLight text-white mb-3'>Informations Personnelles</h3>
                    <h4 className='RobotoThin text-white'>Prénom: <span className='RobotoLight purple'>{info.first_name}</span></h4>
                    <h4 className='RobotoThin text-white'>Nom: <span className='RobotoLight purple'>{info.last_name}</span></h4>
                    <h4 className='RobotoThin text-white'>Date de naissance: <span className='RobotoLight purple'>{info.birth_date}</span></h4>
                    <h4 className='RobotoThin text-white'>Profession: <span className='RobotoLight purple'>{info.occupation}</span></h4>
                    <h4 className='RobotoThin text-white'>Entreprise actuelle: <span className='RobotoLight purple'>{info.company}</span></h4>
                    <h4 className='RobotoThin text-white'>Recruteur: <span className='RobotoLight purple'>{info.recruiter}</span></h4>
                    {info.BR ? <h3 className='RobotoThin text-white'>Bilan <span className='RobotoLight purple'>en Recrutement</span></h3> : ''}
                    {info.BP ? <h3 className='RobotoThin text-white'>Bilan <span className='RobotoLight purple'>personnel</span></h3> : ''}
                    {info.BE ? <h3 className='RobotoThin text-white'>Bilan <span className='RobotoLight purple'>en Entreprise</span></h3> : ''}
                </div>
            </div>
            <div className='row d-flex justify-content-center p-4'>
                <div className='col-12 col-md-6 cardGold p-4 align-items-center'>
                    <h3 className='PoppinsLight orange mb-3'>Contact</h3>
                    <a href="mailto:{info.email}" className="text-decoration-none">
                        <h3 type="button" className="RobotoLight or text-decoration-none text-break">{info.email}</h3>
                    </a>
                    {(info.is_active === true) ? (
                        <h3 className='PoppinsLight purple mt-3 mb-3'>Email vérifié</h3>
                        ) : (
                        <div className="row d-flex justify-content-center">
                            <h3 className='PoppinsLight orange mb-3'>Compte non vérifié</h3>
                            <Link to={routes.USER_ACTIVATE} className='signupBtn'>
                                <span className='signupBtnSpan'>Activer</span>
                            </Link>
                        </div>
                        )}
                </div>
            </div>
            {info.enneagramme ? (
                <div className="row d-flex justify-content-center p-4">
                    <div className='col-12 col-md-6 card cardPurple p-4 align-items-center'>
                        <h1 className="PoppinsLight text-white">Profil {info.enneagramme} {info.MBTI}</h1>
                        <Link to={routes.USER_VALIDATED} className='signupBtn'>
                            <span className='signupBtnSpan'>Modifier</span>
                        </Link>
                    </div>

                </div>
            ) : (
                <div className="row d-flex justify-content-center p-4">
                    <div className='col-12 col-md-6 card cardPurple p-4 align-items-center'>
                        <h1 className="PoppinsLight text-white">Valider le profile</h1>
                        <Link to={routes.USER_VALIDATED} className='signupBtn'>
                            <span className='signupBtnSpan'>Modifier</span>
                        </Link>
                    </div>
                </div>
                )
            }
            {info.comment ? (
                <div className="row d-flex justify-content-center p-4">
                    <div className='col-12 col-md-6 cardGold p-4 align-items-center'>
                        <h3 className="PoppinsLight orange">Commentaire</h3>
                        <p className='Roboto or fs-3'>{info.comment}</p>
                        <div className="row d-flex justify-content-center">
                            <Link to={routes.USER_COMMENT} className='signupBtn'>
                                <span className='signupBtnSpan'>Modifier</span>
                            </Link>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="row d-flex justify-content-center p-4">
                    <div className='col-12 col-md-6 card cardPurple p-4 align-items-center'>
                        <h1 className="PoppinsLight text-white">Ecrire un commentaire</h1>
                        <Link to={routes.USER_COMMENT} className='signupBtn'>
                            <span className='signupBtnSpan'>Modifier</span>
                        </Link>
                    </div>
                </div>
                )
            }

            <div className="row mt-5">
                <div className="row RobotoLight p-2">
                    <div className="col-12 col-md-4 mt-5">
                        <h3 className="PoppinsLight or">Enneagramme : Préférences</h3>
                        <div className="cardGold p-2">
                            <h4 className="purple">Préférence n°1 : <span className="orange">Profil {results.preferences_1}</span></h4>
                            <h4 className="purple">Préférence n°2 : <span className="orange">Profil {results.preferences_2}</span></h4>
                            <h4 className="purple">Préférence n°3 : <span className="orange">Profil {results.preferences_3}</span></h4>
                            <h4 className="purple">Préférence à 20 ans : <span className="orange">Profil {results.preferences_4}</span></h4>
                        </div>
                    </div>
                    <div className="col-12 col-md-4 mt-5">
                        <h3 className="PoppinsLight or">Enneagramme : Evitements</h3>
                        <div className="cardGold p-2">
                            <h4 className="purple">Evitement n°1 : <span className="orange">Profil {results.avoidances_1}</span></h4>
                            <h4 className="purple">Evitement n°2 : <span className="orange">Profil {results.avoidances_2}</span></h4>
                            <h4 className="purple">Evitement n°3 : <span className="orange">Profil {results.avoidances_3}</span></h4>
                            <h4 className="purple">Evitement à 20 ans : <span className="orange">Profil {results.avoidances_4}</span></h4>
                        </div>
                    </div>
                    <div className="col-12 col-md-4 mt-5">
                        <h3 className="PoppinsLight or">Enneagramme : Descriptions</h3>
                        <div className="cardGold p-2">
                            <h4 className="purple">Description n°1 : <span className="orange">Profil {results.descriptions_1}</span></h4>
                            <h4 className="purple">Description n°2 : <span className="orange">Profil {results.descriptions_2}</span></h4>
                            <h4 className="purple">Description n°3 : <span className="orange">Profil {results.descriptions_3}</span></h4>
                            <h4 className="purple">Description à 20 ans : <span className="orange">Profil {results.descriptions_4}</span></h4>
                        </div>
                    </div>
                </div>
                <div className="row d-flex justify-content-center mt-5">
                    <h3 className="PoppinsLight or">Enneagramme : Mots Clés</h3>
                    <div className="col-12 col-md-6 RobotoLight cardGold orange p-2">
                        <h4 className="fs-2">
                        {results.EKW1}<span className="purple"><b>  >  </b></span>{results.EKW2}<span className="purple"><b>  >  </b></span>
                        {results.EKW3}<span className="purple"><b>  >  </b></span>
                        {results.EKW4}<span className="purple"><b>  >  </b></span>{results.EKW5}<span className="purple"><b>  >  </b></span>
                        {results.EKW6}<span className="purple"><b>  >  </b></span>{results.EKW7}<span className="purple"><b>  >  </b></span>
                        {results.EKW8}<span className="purple"><b>  >  </b></span>{results.EKW9}
                        </h4>
                    </div>
                </div>
                <div className="row d-flex justify-content-center mt-5">
                    <h2 className="PoppinsLight or mt-5 mb-5">Sous Types</h2>
                    <div className="col-6 col-md-2 RobotoLight orange p-2">
                        <h4 className="PoppinsLight purple">Conservation</h4>
                        <div className="cardGold p-2">
                            <h4 className="RobotoLight fs-2">{results.CONSERVATION}</h4>
                        </div>
                    </div>
                    <div className="col-6 col-md-2 RobotoLight orange p-2">
                        <h4 className="PoppinsLight purple">Sociale</h4>
                        <div className="cardGold p-2">
                            <h4 className="RobotoLight fs-2">{results.SOCIAL}</h4>
                        </div>
                    </div>
                    <div className="col-6 col-md-2 RobotoLight orange p-2">
                        <h4 className="PoppinsLight purple">Intime</h4>
                        <div className="cardGold p-2">
                            <h4 className="RobotoLight fs-2">{results.INTIMATE}</h4>
                        </div>
                    </div>
                </div>
                <div className="row d-flex justify-content-center mt-5">
                    <h2 className="PoppinsLight or mt-5 mb-5">Analyse Transactionnelle</h2>
                    <div className="col-6 col-md-2 RobotoLight orange p-2">
                        <h4 className="PoppinsLight purple">Parent Normatif</h4>
                        <div className="cardGold p-2">
                            <h4 className="RobotoLight fs-2">{results.PNORM}</h4>
                        </div>
                    </div>
                    <div className="col-6 col-md-2 RobotoLight orange p-2">
                        <h4 className="PoppinsLight purple">Parent Nourricier</h4>
                        <div className="cardGold p-2">
                            <h4 className="RobotoLight fs-2">{results.PNOUR}</h4>
                        </div>
                    </div>
                    <div className="col-6 col-md-2 RobotoLight orange p-2">
                        <h4 className="PoppinsLight purple">Adulte</h4>
                        <div className="cardGold p-2">
                            <h4 className="RobotoLight fs-2">{results.ADULT}</h4>
                        </div>
                    </div>
                    <div className="col-6 col-md-2 RobotoLight orange p-2">
                        <h4 className="PoppinsLight purple">EAS</h4>
                        <div className="cardGold p-2">
                            <h4 className="RobotoLight fs-2">{results.EAS}</h4>
                        </div>
                    </div>
                    <div className="col-6 col-md-2 RobotoLight orange p-2">
                        <h4 className="PoppinsLight purple">EAR</h4>
                        <div className="cardGold p-2">
                            <h4 className="RobotoLight fs-2">{results.EAR}</h4>
                        </div>
                    </div>
                    <div className="col-6 col-md-2 RobotoLight orange p-2">
                        <h4 className="PoppinsLight purple">ESP</h4>
                        <div className="cardGold p-2">
                            <h4 className="RobotoLight fs-2">{results.ESP}</h4>
                        </div>
                    </div>
                </div>
                <div className="row d-flex justify-content-center mt-5">
                    <h2 className="PoppinsLight or mt-5 mb-5">Position de Vie</h2>
                    <div className="col-6 col-md-2 RobotoLight orange p-2">
                        <h4 className="PoppinsLight purple">Segment A</h4>
                        <div className="cardGold p-2">
                            <h4 className="RobotoLight fs-2">{results.SEGA}</h4>
                        </div>
                    </div>
                    <div className="col-6 col-md-2 RobotoLight orange p-2">
                        <h4 className="PoppinsLight purple">Segment B</h4>
                        <div className="cardGold p-2">
                            <h4 className="RobotoLight fs-2">{results.SEGB}</h4>
                        </div>
                    </div>
                    <div className="col-6 col-md-2 RobotoLight orange p-2">
                        <h4 className="PoppinsLight purple">Segment C</h4>
                        <div className="cardGold p-2">
                            <h4 className="RobotoLight fs-2">{results.SEGC}</h4>
                        </div>
                    </div>
                    <div className="col-6 col-md-2 RobotoLight orange p-2">
                        <h4 className="PoppinsLight purple">Segment D</h4>
                        <div className="cardGold p-2">
                            <h4 className="RobotoLight fs-2">{results.SEGD}</h4>
                        </div>
                    </div>
                </div>
                <div className="row d-flex justify-content-center mt-5">
                    <h2 className="PoppinsLight or mt-5 mb-5">Assertivité</h2>
                    <div className="col-6 col-md-2 RobotoLight orange p-2">
                        <h4 className="PoppinsLight purple">Courage, fuyons !</h4>
                        <div className="cardGold p-2">
                            <h4 className="RobotoLight fs-2">{results.ESCAPE}</h4>
                        </div>
                    </div>
                    <div className="col-6 col-md-2 RobotoLight orange p-2">
                        <h4 className="PoppinsLight purple">A l'attaque !</h4>
                        <div className="cardGold p-2">
                            <h4 className="RobotoLight fs-2">{results.OFFENSIVE}</h4>
                        </div>
                    </div>
                    <div className="col-6 col-md-2 RobotoLight orange p-2">
                        <h4 className="PoppinsLight purple">Ruse & Artifices</h4>
                        <div className="cardGold p-2">
                            <h4 className="RobotoLight fs-2">{results.CUNNING}</h4>
                        </div>
                    </div>
                    <div className="col-6 col-md-2 RobotoLight orange p-2">
                        <h4 className="PoppinsLight purple">Cartes sur table</h4>
                        <div className="cardGold p-2">
                            <h4 className="RobotoLight fs-2">{results.ASSERTIVE}</h4>
                        </div>
                    </div>
                </div>
                <div className="row d-flex justify-content-center mt-5">
                    <h2 className="PoppinsLight or mt-5 mb-5">Répertoire Emotionnel</h2>
                    <div className="row d-flex justify-content-center mt-4">
                        <div className="col-4 col-md-2 RobotoLight orange p-2">
                            <h4 className="PoppinsLight purple">Joie Forte</h4>
                            <div className="cardGold p-2">
                                <h4 className="RobotoLight fs-2">{results.REJFOR}</h4>
                            </div>
                        </div>
                        <div className="col-4 col-md-2 RobotoLight orange p-2">
                            <h4 className="PoppinsLight purple d-none d-md-block">Joie Moyenne</h4>
                            <h4 className="PoppinsLight purple d-md-none">Joie Moy.</h4>
                            <div className="cardGold p-2">
                                <h4 className="RobotoLight fs-2">{results.REJMOY}</h4>
                            </div>
                        </div>
                        <div className="col-4 col-md-2 RobotoLight orange p-2">
                            <h4 className="PoppinsLight purple purple d-none d-md-block">Joie Faible</h4>
                            <h4 className="PoppinsLight purple purple d-md-none">Joie Fai.</h4>
                            <div className="cardGold p-2">
                                <h4 className="RobotoLight fs-2">{results.REJFAI}</h4>
                            </div>
                        </div>
                    </div>
                    <div className="row d-flex justify-content-center mt-4">
                        <div className="col-4 col-md-2 RobotoLight orange p-2">
                            <h4 className="PoppinsLight purple">Colère Forte</h4>
                            <div className="cardGold p-2">
                                <h4 className="RobotoLight fs-2">{results.RECFOR}</h4>
                            </div>
                        </div>
                        <div className="col-4 col-md-2 RobotoLight orange p-2">
                            <h4 className="PoppinsLight purple">Colère Moyenne</h4>
                            <div className="cardGold p-2">
                                <h4 className="RobotoLight fs-2">{results.RECMOY}</h4>
                            </div>
                        </div>
                        <div className="col-4 col-md-2 RobotoLight orange p-2">
                            <h4 className="PoppinsLight purple">Colère Faible</h4>
                            <div className="cardGold p-2">
                                <h4 className="RobotoLight fs-2">{results.RECFAI}</h4>
                            </div>
                        </div>
                    </div>
                    <div className="row d-flex justify-content-center mt-4">
                        <div className="col-4 col-md-2 RobotoLight orange p-2">
                            <h4 className="PoppinsLight purple">Tristesse Forte</h4>
                            <div className="cardGold p-2">
                                <h4 className="RobotoLight fs-2">{results.RETFOR}</h4>
                            </div>
                        </div>
                        <div className="col-4 col-md-2 RobotoLight orange p-2">
                            <h4 className="PoppinsLight purple">Tristesse Moyenne</h4>
                            <div className="cardGold p-2">
                                <h4 className="RobotoLight fs-2">{results.RETMOY}</h4>
                            </div>
                        </div>
                        <div className="col-4 col-md-2 RobotoLight orange p-2">
                            <h4 className="PoppinsLight purple">Tristesse Faible</h4>
                            <div className="cardGold p-2">
                                <h4 className="RobotoLight fs-2">{results.RETFAI}</h4>
                            </div>
                        </div>
                    </div>
                    <div className="row d-flex justify-content-center mt-4">
                        <div className="col-4 col-md-2 RobotoLight orange p-2">
                            <h4 className="PoppinsLight purple">Peur Forte</h4>
                            <div className="cardGold p-2">
                                <h4 className="RobotoLight fs-2">{results.REPFOR}</h4>
                            </div>
                        </div>
                        <div className="col-4 col-md-2 RobotoLight orange p-2">
                            <h4 className="PoppinsLight purple">Peur Moyenne</h4>
                            <div className="cardGold p-2">
                                <h4 className="RobotoLight fs-2">{results.REPMOY}</h4>
                            </div>
                        </div>
                        <div className="col-4 col-md-2 RobotoLight orange p-2">
                            <h4 className="PoppinsLight purple">Peur Faible</h4>
                            <div className="cardGold p-2">
                                <h4 className="RobotoLight fs-2">{results.REPFAI}</h4>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row d-flex justify-content-center mt-5">
                    <h2 className="PoppinsLight or mt-5 mb-5">MYERS & BRIGGS Type Indicator</h2>
                    <div className="row d-flex justify-content-center mt-4">
                        <div className="col-6 col-md-2 RobotoLight orange p-2">
                            <h4 className="PoppinsLight purple">Intro</h4>
                            <div className="cardGold p-2">
                                <h4 className="RobotoLight fs-2">{results.INTRO}</h4>
                            </div>
                        </div>
                        <div className="col-6 col-md-2 RobotoLight orange p-2">
                            <h4 className="PoppinsLight purple">Extra</h4>
                            <div className="cardGold p-2">
                                <h4 className="RobotoLight fs-2">{results.EXTRO}</h4>
                            </div>
                        </div>
                    </div>
                    <div className="row d-flex justify-content-center mt-4">
                        <div className="col-6 col-md-2 RobotoLight orange p-2">
                            <h4 className="PoppinsLight purple">Sensation</h4>
                            <div className="cardGold p-2">
                                <h4 className="RobotoLight fs-2">{results.SENSITIVE}</h4>
                            </div>
                        </div>
                        <div className="col-6 col-md-2 RobotoLight orange p-2">
                            <h4 className="PoppinsLight purple">Intuition</h4>
                            <div className="cardGold p-2">
                                <h4 className="RobotoLight fs-2">{results.INTUITIVE}</h4>
                            </div>
                        </div>
                    </div>
                    <div className="row d-flex justify-content-center mt-4">
                        <div className="col-6 col-md-2 RobotoLight orange p-2">
                            <h4 className="PoppinsLight purple">Pensée</h4>
                            <div className="cardGold p-2">
                                <h4 className="RobotoLight fs-2">{results.THINKING}</h4>
                            </div>
                        </div>
                        <div className="col-6 col-md-2 RobotoLight orange p-2">
                            <h4 className="PoppinsLight purple">Sentiments</h4>
                            <div className="cardGold p-2">
                                <h4 className="RobotoLight fs-2">{results.FEELING}</h4>
                            </div>
                        </div>
                    </div>
                    <div className="row d-flex justify-content-center mt-4">
                        <div className="col-6 col-md-2 RobotoLight orange p-2">
                            <h4 className="PoppinsLight purple">Jugement</h4>
                            <div className="cardGold p-2">
                                <h4 className="RobotoLight fs-2">{results.JUDGEMENT}</h4>
                            </div>
                        </div>
                        <div className="col-6 col-md-2 RobotoLight orange p-2">
                            <h4 className="PoppinsLight purple">Perception</h4>
                            <div className="cardGold p-2">
                                <h4 className="RobotoLight fs-2">{results.PERCEPTION}</h4>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row d-flex justify-content-center mt-5">
                    <h2 className="PoppinsLight or mt-5 mb-5">MBTI : Familles de Tempéraments</h2>
                    <div className="col-6 col-md-2 RobotoLight orange p-2">
                        <h4 className="PoppinsLight purple">Tempérament Actuel</h4>
                        <div className="cardGold p-2">
                            <h4 className="RobotoLight fs-2">{results.TP1}</h4>
                        </div>
                    </div>
                    <div className="col-6 col-md-2 RobotoLight orange p-2">
                        <h4 className="PoppinsLight purple">Tempérament à 20 ans</h4>
                        <div className="cardGold p-2">
                            <h4 className="RobotoLight fs-2">{results.TP2}</h4>
                        </div>
                    </div>
                </div>
                <div className="row d-flex justify-content-center mt-5">
                    <h2 className="PoppinsLight or mt-5 mb-5">Profil Energétique : Groupes de valeurs</h2>
                    <div className="col-6 col-md-2 RobotoLight orange p-2">
                        <h4 className="PoppinsLight purple">1</h4>
                        <div className="cardGold p-2">
                            <h4 className="RobotoLight fs-2">{results.GRP1}</h4>
                        </div>
                    </div>
                    <div className="col-6 col-md-2 RobotoLight orange p-2">
                        <h4 className="PoppinsLight purple">2</h4>
                        <div className="cardGold p-2">
                            <h4 className="RobotoLight fs-2">{results.GRP2}</h4>
                        </div>
                    </div>
                    <div className="col-6 col-md-2 RobotoLight orange p-2">
                        <h4 className="PoppinsLight purple">3</h4>
                        <div className="cardGold p-2">
                            <h4 className="RobotoLight fs-2">{results.GRP3}</h4>
                        </div>
                    </div>
                    <div className="col-6 col-md-2 RobotoLight orange p-2">
                        <h4 className="PoppinsLight purple">4</h4>
                        <div className="cardGold p-2">
                            <h4 className="RobotoLight fs-2">{results.GRP4}</h4>
                        </div>
                    </div>
                    <div className="col-6 col-md-2 RobotoLight orange p-2">
                        <h4 className="PoppinsLight purple">5</h4>
                        <div className="cardGold p-2">
                            <h4 className="RobotoLight fs-2">{results.GRP5}</h4>
                        </div>
                    </div>
                </div>
                <div className="row d-flex justify-content-center mt-5">
                    <h2 className="PoppinsLight or mt-5 mb-5">Profil Energétique : Préférences</h2>
                    <div className="col-6 col-md-2 RobotoLight orange p-2">
                        <h4 className="PoppinsLight purple">1</h4>
                        <div className="cardGold p-2">
                            <h4 className="RobotoLight fs-2">{results.EM1}</h4>
                        </div>
                    </div>
                    <div className="col-6 col-md-2 RobotoLight orange p-2">
                        <h4 className="PoppinsLight purple">2</h4>
                        <div className="cardGold p-2">
                            <h4 className="RobotoLight fs-2">{results.EM2}</h4>
                        </div>
                    </div>
                    <div className="col-6 col-md-2 RobotoLight orange p-2">
                        <h4 className="PoppinsLight purple">3</h4>
                        <div className="cardGold p-2">
                            <h4 className="RobotoLight fs-2">{results.EM3}</h4>
                        </div>
                    </div>
                    <div className="col-6 col-md-2 RobotoLight orange p-2">
                        <h4 className="PoppinsLight purple">4</h4>
                        <div className="cardGold p-2">
                            <h4 className="RobotoLight fs-2">{results.EM4}</h4>
                        </div>
                    </div>
                    <div className="col-6 col-md-2 RobotoLight orange p-2">
                        <h4 className="PoppinsLight purple">5</h4>
                        <div className="cardGold p-2">
                            <h4 className="RobotoLight fs-2">{results.EM5}</h4>
                        </div>
                    </div>
                </div>
                <div className="row d-flex justify-content-center mt-5">
                    <h2 className="PoppinsLight or mt-5 mb-5">Leadership</h2>
                    <div className="col-6 col-md-2 RobotoLight orange p-2">
                        <h4 className="PoppinsLight purple">Production</h4>
                        <div className="cardGold p-2">
                            <h4 className="RobotoLight fs-2">{results.PRODUCTION}</h4>
                        </div>
                    </div>
                    <div className="col-6 col-md-2 RobotoLight orange p-2">
                        <h4 className="PoppinsLight purple">Humain</h4>
                        <div className="cardGold p-2">
                            <h4 className="RobotoLight fs-2">{results.HUMAN}</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

const mapStateToProps = state => ({
    user: state.auth.user,
    isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps, { logout, load_user_details }) (UserResults);