import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import {useNavigate} from 'react-router-dom';

import { checkValidity } from '../../../shared/utility';
import { saveLifePosition, load_user_details } from '../../../store/actions/auth';
import routes from '../../../config/routes';
import Input from '../../../components/UI/Input/Input';
import Footer from '../../Footer/Footer';


function LifePosition({user, saveLifePosition, load_user_details, isAuthenticated}) {

    //SELECT FIELDS
    const number = [
        {value: '', displayValue: 'Sélectionner votre réponse'},
        {value: '0', displayValue: 'Ne me ressemble pas du tout'},
        {value: '1', displayValue: 'Me ressemble un peu'},
        {value: '2', displayValue: 'Me ressemble assez'},
        {value: '3', displayValue: 'Tout à fait moi'},
    ]

    //STATE
    const [loader, setLoader] = useState(true);

    const [inputs, setInputs] = useState({
        PV1: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "Je suis perçu comme quelqu'un de fataliste",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis"
        },
        PV2: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "J'ai tendance à me sous estimer",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis"
        },
        PV3: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "J'apprécie la compagnie des autres",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis"
        },
        PV4: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "On ne peut compter sur personne à part soi",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis"
        },
        PV5: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "On ne peut compter sur personne même pas sur soi même",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis"
        },
        PV6: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "J'ai confiance dans l'avenir de l'humanité",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis"
        },
        PV7: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "J'ai parfois tendance à rabaisser les autres",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis"
        },
        PV8: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "J'aime qu'on m'obéisse",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis"
        },
        PV9: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "Je suis moins chanceux que les autres",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis"
        },
        PV10: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "Nous n'y arriverons jamais, nous sommes fichus",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis"
        },
        PV11: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "Je suis plutôt mal dans mes baskets ",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis"
        },
        PV12: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "La vie n'est vraiment drôle pour personne",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis"
        },
        PV13: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "J'aime consulter les gens autour de moi avant de prendre une décision",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis"
        },
        PV14: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "Je me sens bien dans ma peau",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis"
        },
        PV15: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "Les gens devraient suivre un peu plus souvent mes conseils",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis"
        },
        PV16: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "Tu es plus doué que moi pour ce genre de problème, je te laisse te débrouiller ",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis"
        },
        PV17: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "Un pour tous, tous pourris !",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis"
        },
        PV18: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "Je m'adapte en toute circonstance",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis"
        },
        PV19: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "Ce que les autres pensent de moi ne peut que m'enrichir",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis"
        },
        PV20: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "Je n'ai rien à faire de ce qu'on peut penser de moi",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis"
        },
        PV21: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "On dit de moi que je suis une personne épanouie",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis"
        },
        PV22: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "Je suis plutôt défaitiste de nature",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis"
        },
        PV23: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "Je doute souvent de mes capacités",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis"
        },
        PV24: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "Je n'ai pas une très haute idée de moi-même",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis"
        },
        PV25: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "Je n'ai pas confiance dans les autres",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis"
        },
        PV26: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "J'ai la critique facile",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis"
        },
        PV27: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "Je n'aime pas prendre des responsabilités",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis"
        },
        PV28: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "J'ai de nombreux amis",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis"
        },
        PV29: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "Je perds souvent pied alors j'appelle à l'aide",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis"
        },
        PV30: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "Je suis désabusé",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis"
        },
        PV31: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "J'aurai le dernier mot",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis"
        },
        PV32: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "Tous dans la même galère",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis"
        }
    });

    const [valid, setValid] = useState(false);
    const [quizCompleted, setQuizCompleted] = useState(false);

    //Redirect
    const navigate = useNavigate();

    //ComponentDidMount
    useEffect(() => {
        load_user_details();
        if (!user){
            setTimeout(() => {
            setLoader(false);
            }, 1000);
        }
        else {
            if(user.LP){
                navigate(routes.HOME)
            }
            else{
                setLoader(false)
            }
        }
    }, [])

    //ComponentDidUpdate
    useEffect(() => {
        if (!user){
            setTimeout(() => {
            setLoader(false);
            }, 1000);
        }
        else {
            if(user.LP){
                navigate(routes.HOME)
            }
            else{
                setLoader(false)
            }
        }
        const auth = localStorage.getItem('authenticated')
        if (! auth){
            alert('vous devez vous connecter pour répondre au questionnaire.')
            navigate(routes.HOME)
        }
    });

    //Functions

    const InputChangedHandler = (event, id) => {

        //Change Value
        const newInputs = {...inputs};
        newInputs[id].value = event.target.value;
        newInputs[id].touched = true;

        //Check value
        newInputs[id].valid = checkValidity(event.target.value, newInputs[id].validation)

        setInputs(newInputs);

        //Form checking
        let formIsValid = true;
        for (let input in newInputs) {
            formIsValid = newInputs[input].valid && formIsValid;
        }
        setValid(formIsValid);
    };

    const formHandler = event => {
        event.preventDefault();
    }


    //Save Confirmation
    const saveClickedHandler = () => {
        console.log(localStorage);
        const PV = {
            user: user.email, user_email: user.email,
            PV1: inputs.PV1.value, PV2: inputs.PV2.value, PV3: inputs.PV3.value, PV4: inputs.PV4.value, PV5: inputs.PV5.value, PV6: inputs.PV6.value, PV7: inputs.PV7.value,
            PV8: inputs.PV8.value, PV9: inputs.PV9.value, PV10: inputs.PV10.value, PV11: inputs.PV11.value, PV12: inputs.PV12.value, PV13: inputs.PV13.value, PV14: inputs.PV14.value,
            PV15: inputs.PV15.value, PV16: inputs.PV16.value, PV17: inputs.PV17.value, PV18: inputs.PV18.value, PV19: inputs.PV19.value, PV20: inputs.PV20.value, PV21: inputs.PV21.value,
            PV22: inputs.PV22.value, PV23: inputs.PV23.value, PV24: inputs.PV24.value, PV25: inputs.PV25.value, PV26: inputs.PV26.value, PV27: inputs.PV27.value, PV28: inputs.PV28.value,
            PV29: inputs.PV29.value, PV30: inputs.PV30.value, PV31: inputs.PV31.value, PV32: inputs.PV32.value
        };
        try{
            saveLifePosition(
                PV.user, PV.user_email,
                PV.PV1, PV.PV2, PV.PV3, PV.PV4, PV.PV5, PV.PV6, PV.PV7, PV.PV8, PV.PV9, PV.PV10, PV.PV11, PV.PV12, PV.PV13, PV.PV14, PV.PV15, PV.PV16, PV.PV17, PV.PV18, PV.PV19, PV.PV20,
                PV.PV21, PV.PV22, PV.PV23, PV.PV24, PV.PV25, PV.PV26, PV.PV27, PV.PV28, PV.PV29, PV.PV30, PV.PV31, PV.PV32
            );
            setQuizCompleted(true);
            navigate(routes.QUIZ)
        }
        catch(err){
            alert("Vérifiez que l'ensemble des champs soient renseignés")
        }
    }

    //Variables

    const formElementArray = [];
    for(let key in inputs) {
        formElementArray.push({
            id: key,
            config: inputs[key]
        });
    };

    let form = (
        <form onSubmit={(e) => formHandler(e)}>
            {formElementArray.map(formElement => (
            <div className="row d-flex justify-content-center mt-5" key={formElement.id}>
                <h4 className="RobotoLight orange">{formElement.config.label}</h4>
                <Input
                    id={formElement.id}
                    value={formElement.config.value}

                    type={formElement.config.elementType}
                    config={formElement.config.elementConfig}
                    valid={formElement.config.valid}
                    touched={formElement.config.touched}
                    errorMessage={formElement.config.errorMessage}
                    changed={(e) => InputChangedHandler(e, formElement.id)}
                />
            </div>
            ))}
        </form>
    );


    return loader ? (
        <>
            <div className="container-fluid">
                <div className='row d-flex justify-content-center mt-5'>

                </div>
            </div>
        </>
    ) : (
        <div className='container-fluid p-3'>
            <div className='row d-flex justify-content-center mt-5'>
                <h1 className='PoppinsLight or'>Position de Vie</h1>
            </div>
            <div className='row d-flex justify-content-center mt-3'>
                <div className='col-12 col-md-8 p-4 mt-5 cardPurple'>
                    <h4 className='RobotoThin text-white'>
                        Examinez chaque affirmation et affectez lui le nombre de points correspondant :
                    </h4>
                </div>
            </div>
            <div className='row d-flex justify-content-center RobotoLight text-start mt-5'>
                <div className="col-12 col-md-8 cardGold p-3 text-white">
                    <h4><span className="orange"> 0 Pts</span> Cela ne me ressemble pas du tout</h4>
                    <h4><span className="orange"> 1 Pts</span> Cela me ressemble un peu</h4>
                    <h4><span className="orange"> 2 Pts</span> Cela me ressemble assez</h4>
                    <h4><span className="orange"> 3 Pts</span> C'est tout à fait moi</h4>
                </div>
            </div>

            <div className='row d-flex justify-content-center'>
                <div className='col-12 col-md-6 mb-5 mt-4'>
                    {form}
                </div>
            </div>
            <div className='row d-flex justify-content-center gy-3 mt-5 mb-5'>
                <button type='button' className='signupBtn' onClick={saveClickedHandler} disabled={!valid} >
                    <span className='signupBtnSpan roboto'>Enregistrer</span>
                </button>
            </div>
            <Footer/>
        </div>
    )
};

const mapStateToProps = state => ({
    user: state.auth.user,
    isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps, {saveLifePosition, load_user_details}) (LifePosition);