import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import {useNavigate} from 'react-router-dom';

import { checkValidity } from '../../../shared/utility';
import { saveTransactionalAnalysis, load_user_details } from '../../../store/actions/auth';
import routes from '../../../config/routes';
import Input from '../../../components/UI/Input/Input';
import Footer from '../../Footer/Footer';


function TransactionalAnalysis({user, saveTransactionalAnalysis, load_user_details, isAuthenticated}) {

    //SELECT FIELDS
    const number = [
        {value: '', displayValue: 'Sélectionner votre réponse'},
        {value: '0', displayValue: 'Jamais ou presque'},
        {value: '1', displayValue: 'Parfois'},
        {value: '2', displayValue: 'Souvent'},
        {value: '3', displayValue: 'Toujours ou presque'},
    ]

    //STATE
    const [loader, setLoader] = useState(true);

    const [inputs, setInputs] = useState({
        AT1: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "J'aime que les autres se confient à moi ",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis"
        },
        AT2: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "Je suis poli",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis"
        },
        AT3: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "Je me fis à mon intuition",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis"
        },
        AT4: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "Je suis exigeant envers les autres",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis"
        },
        AT5: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "Je fais respecter les règlements",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis"
        },
        AT6: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "j'ai un tempérament cordial et bon enfant",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis"
        },
        AT7: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "Je pratique la négociation",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis"
        },
        AT8: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "Je me conforme aux règlements",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis"
        },
        AT9: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "Je pense que pour exister il faut se battre",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis"
        },
        AT10: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "J'aime la nouveauté",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis"
        },
        AT11: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "Je suis sérieux",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis"
        },
        AT12: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "J'aime bien provoquer autrui",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis"
        },
        AT13: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "Je fais ce qui me plait",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis"
        },
        AT14: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "Je lutte contre la désorganisation et la pagaille",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis"
        },
        AT15: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "Pour résoudre un problème je fais confiance aux recettes éprouvées",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis"
        },
        AT16: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "Je m'inquiète pour les autres",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis"
        },
        AT17: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "Dans un dialogue j'écoute et je reformule le point de vue d'autrui",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis"
        },
        AT18: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "Je me sens responsable de ce qui m'arrive",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis"
        },
        AT19: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "Je perds mes moyens quand je ne suis pas dans mon univers habituel",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis"
        },
        AT20: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "J'ai une attitude conciliante",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis"
        },
        AT21: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "Je porte des jugements sur le comportement d'autrui",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis"
        },
        AT22: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "Je suis ponctuel",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis"
        },
        AT23: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "Il suffit de peu de chose pour me mettre en colère",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis"
        },
        AT24: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "J'aime les jeux",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis"
        },
        AT25: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "J'obtiens ce que je désire",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis"
        },
        AT26: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "Je me méfie des idées reçues",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis"
        },
        AT27: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "Dans des situations mouvementées je garde mon calme",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis"
        },
        AT28: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "Certaines remarques des autres me blessent",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis"
        },
        AT29: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "J'aime la remise en cause",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis"
        },
        AT30: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "J'exprime ce que je ressens",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis"
        },
        AT31: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "J'agis en fonction d'un certains nombre de principes auxquels je crois",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis"
        },
        AT32: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "J'analyse mon propre comportement",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis"
        },
        AT33: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "Quand on me donne un ordre, j'obéis",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis"
        },
        AT34: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "J'aide les autres à résoudre leurs problèmes",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis"
        },
        AT35: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "J'ai tendance à réagir impulsivement",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis"
        },
        AT36: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "J'ai un esprit de contradiction",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis"
        },
        AT37: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "Dans une situation de conflit je cherche avant tout à dédramatiser",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis"
        },
        AT38: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "Je prends mes décisions en toute autonomie",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis"
        },
        AT39: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "Je fais confiance à la hiérarchie",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis"
        },
        AT40: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "J'ai tendance à vouloir dominer les autres",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis"
        },
        AT41: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "Mon langage est direct et spontané",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis"
        },
        AT42: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "Je suis les conseils de ceux qui ont de l'expérience",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis"
        },
        AT43: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "Je discute pour le plaisir de la joute verbale",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis"
        },
        AT44: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "J'ai un tempérant contestataire",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis"
        },
        AT45: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "Lorsque je rencontre un problème, j'examine tous les éléments de la situation avant de prendre une décision",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis"
        },
        AT46: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "Je m'exprime avec réserve et discrétion",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis"
        },
        AT47: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "Je pense qu'aujourd'hui les gens ont tendance à oublier leur devoir",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis"
        },
        AT48: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "Le fait qu'on me donne des ordres m'indispose",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis"
        },
        AT49: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "J'apprécie la compagnie des enfants",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis"
        },
        AT50: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "Je fais des reproches à autrui",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis"
        },
        AT51: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "Les gens viennent me demander conseil et assistance",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis"
        },
        AT52: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "Je me révolte contre les contraintes",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis"
        },
        AT53: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "Je prends du recul par rapport aux situations que je vis",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis"
        },
        AT54: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "J'ai tendance à être bienveillant vis-à-vis d'autrui",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis"
        },
        AT55: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "Je recherche les situations de conflit",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis"
        },
        AT56: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "Je suis créatif",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis"
        },
        AT57: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "je donne sans hésiter un coup de main à des collègues ou à des amis qui font appel à moi",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis"
        },
        AT58: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "Avant d'agir je me fixe des objectifs",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis"
        },
        AT59: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "Je pense que le travail a une valeur morale",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis"
        },
        AT60: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "Je pense que les gens aiment qu'on les sécurise",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis"
        }
    });

    const [valid, setValid] = useState(false);
    const [quizCompleted, setQuizCompleted] = useState(false);

    //Redirect
    const navigate = useNavigate();

    //ComponentDidMount
    useEffect(() => {
        load_user_details();
        if (!user){
            setTimeout(() => {
            setLoader(false);
            }, 1000);
        }
        else {
            if(user.AT){
                navigate(routes.HOME)
            }
            else{
                setLoader(false)
            }
        }
    }, [])

    //ComponentDidUpdate
    useEffect(() => {
        if (!user){
            setTimeout(() => {
            setLoader(false);
            }, 1000);
        }
        else {
            if(user.AT){
                navigate(routes.HOME)
            }
            else{
                setLoader(false)
            }
        }
        const auth = localStorage.getItem('authenticated')
        if (! auth){
            alert('vous devez vous connecter pour répondre au questionnaire.')
            navigate(routes.HOME)
        }
    });

    //Functions

    const InputChangedHandler = (event, id) => {

        //Change Value
        const newInputs = {...inputs};
        newInputs[id].value = event.target.value;
        newInputs[id].touched = true;

        //Check value
        newInputs[id].valid = checkValidity(event.target.value, newInputs[id].validation)

        setInputs(newInputs);

        //Form checking
        let formIsValid = true;
        for (let input in newInputs) {
            formIsValid = newInputs[input].valid && formIsValid;
        }
        setValid(formIsValid);
    };

    const formHandler = event => {
        event.preventDefault();
    }


    //Save Confirmation
    const saveClickedHandler = () => {
        console.log(localStorage);
        const AT = {
            user: user.email, user_email: user.email,
            AT1: inputs.AT1.value, AT2: inputs.AT2.value, AT3: inputs.AT3.value, AT4: inputs.AT4.value, AT5: inputs.AT5.value, AT6: inputs.AT6.value, AT7: inputs.AT7.value,
            AT8: inputs.AT8.value, AT9: inputs.AT9.value, AT10: inputs.AT10.value, AT11: inputs.AT11.value, AT12: inputs.AT12.value, AT13: inputs.AT13.value, AT14: inputs.AT14.value,
            AT15: inputs.AT15.value, AT16: inputs.AT16.value, AT17: inputs.AT17.value, AT18: inputs.AT18.value, AT19: inputs.AT19.value, AT20: inputs.AT20.value, AT21: inputs.AT21.value,
            AT22: inputs.AT22.value, AT23: inputs.AT23.value, AT24: inputs.AT24.value, AT25: inputs.AT25.value, AT26: inputs.AT26.value, AT27: inputs.AT27.value, AT28: inputs.AT28.value,
            AT29: inputs.AT29.value, AT30: inputs.AT30.value, AT31: inputs.AT31.value, AT32: inputs.AT32.value, AT33: inputs.AT33.value, AT34: inputs.AT34.value, AT35: inputs.AT35.value,
            AT36: inputs.AT36.value, AT37: inputs.AT37.value, AT38: inputs.AT38.value, AT39: inputs.AT39.value, AT40: inputs.AT40.value, AT41: inputs.AT41.value, AT42: inputs.AT42.value,
            AT43: inputs.AT43.value, AT44: inputs.AT44.value, AT45: inputs.AT45.value, AT46: inputs.AT46.value, AT47: inputs.AT47.value, AT48: inputs.AT48.value, AT49: inputs.AT49.value,
            AT50: inputs.AT50.value, AT51: inputs.AT51.value, AT52: inputs.AT52.value, AT53: inputs.AT53.value, AT54: inputs.AT54.value, AT55: inputs.AT55.value, AT56: inputs.AT56.value,
            AT57: inputs.AT57.value, AT58: inputs.AT58.value, AT59: inputs.AT59.value, AT60: inputs.AT60.value
        };
        try{
            saveTransactionalAnalysis(
                AT.user, AT.user_email,
                AT.AT1, AT.AT2, AT.AT3, AT.AT4, AT.AT5, AT.AT6, AT.AT7, AT.AT8, AT.AT9, AT.AT10, AT.AT11, AT.AT12, AT.AT13, AT.AT14, AT.AT15, AT.AT16, AT.AT17, AT.AT18, AT.AT19, AT.AT20,
                AT.AT21, AT.AT22, AT.AT23, AT.AT24, AT.AT25, AT.AT26, AT.AT27, AT.AT28, AT.AT29, AT.AT30, AT.AT31, AT.AT32, AT.AT33, AT.AT34, AT.AT35, AT.AT36, AT.AT37, AT.AT38, AT.AT39,
                AT.AT40, AT.AT41, AT.AT42, AT.AT43, AT.AT44, AT.AT45, AT.AT46, AT.AT47, AT.AT48, AT.AT49, AT.AT50, AT.AT51, AT.AT52, AT.AT53, AT.AT54, AT.AT55, AT.AT56, AT.AT57, AT.AT58,
                AT.AT59, AT.AT60
            );
            setQuizCompleted(true);
            navigate(routes.QUIZ)
        }
        catch(err){
            alert("Vérifiez que l'ensemble des champs soient renseignés")
        }
    }

    //Variables

    const formElementArray = [];
    for(let key in inputs) {
        formElementArray.push({
            id: key,
            config: inputs[key]
        });
    };

    let form = (
        <form onSubmit={(e) => formHandler(e)}>
            {formElementArray.map(formElement => (
            <div className="row d-flex justify-content-center mt-5" key={formElement.id}>
                <h4 className="RobotoLight orange">{formElement.config.label}</h4>
                <Input
                    id={formElement.id}
                    value={formElement.config.value}

                    type={formElement.config.elementType}
                    config={formElement.config.elementConfig}
                    valid={formElement.config.valid}
                    touched={formElement.config.touched}
                    errorMessage={formElement.config.errorMessage}
                    changed={(e) => InputChangedHandler(e, formElement.id)}
                />
            </div>
            ))}
        </form>
    );


    return loader ? (
        <>
            <div className="container-fluid">
                <div className='row d-flex justify-content-center mt-5'>

                </div>
            </div>
        </>
    ) : (
        <div className='container-fluid p-3'>
            <div className='row d-flex justify-content-center mt-5'>
                <h1 className='PoppinsLight or'>Analyse Transactionnelle</h1>
            </div>
            <div className='row d-flex justify-content-center mt-5'>
                <div className='col-12 col-md-8 p-4 cardPurple'>
                    <h4 className='RobotoThin text-white'>
                        Examinez chaque affirmation et affectez lui le nombre de points correspondant :
                    </h4>
                </div>
            </div>
            <div className='row d-flex justify-content-center RobotoLight text-start mt-3'>
                <div className="col-12 col-md-8 cardGold p-3 mt-5 text-white">
                    <h4><span className="orange fs-2">0 Pts</span> C'est quelque chose que vous ne faites ou ne pensez <b>JAMAIS</b> ou <b>PRESQUE JAMAIS</b> </h4>
                    <h4><span className="orange fs-2">1 Pts</span> C'est quelque chose que vous faites ou pensez <b>PARFOIS</b></h4>
                    <h4><span className="orange fs-2">2 Pts</span> C'est quelque chose que vous faites ou pensez <b>SOUVENT</b></h4>
                    <h4><span className="orange fs-2">3 Pts</span> C'est quelque chose que vous faites ou pensez <b>TOUJOURS</b> ou <b>PRESQUE TOUJOURS</b></h4>
                </div>
            </div>

            <div className='row d-flex justify-content-center'>
                <div className='col-12 col-md-6 mb-5 mt-4'>
                    {form}
                </div>
            </div>
            <div className='row d-flex justify-content-center gy-3 mt-5 mb-5'>
                <button type='button' className='signupBtn' onClick={saveClickedHandler} disabled={!valid} >
                    <span className='signupBtnSpan roboto'>Enregistrer</span>
                </button>
            </div>
            <Footer/>
        </div>
    )
};

const mapStateToProps = state => ({
    user: state.auth.user,
    isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps, {saveTransactionalAnalysis, load_user_details}) (TransactionalAnalysis);