import React from "react";
import Footer from '../Footer/Footer';



function LegalNotice () {
    return(
        <div className="container-fluid">
            <h1 className="Poppins or">Mentions Légales</h1>
            <div className="row p-4 d-flex justify-content-center Roboto purple">
                <h4 className="">
                    Conformément aux dispositions des articles 6-III et 19 de la Loi n° 2004-575 du 21 juin 2004 pour la
                    Confiance dans l'économie numérique, dite L.C.E.N., nous portons à la connaissance des utilisateurs
                    et visiteurs du site : <a href="http://www.myd3s.com" target="_blank">www.myd3s.com</a> les informations suivantes :
                </h4>
            </div>
            <div className="row p-4 d-flex justify-content-center Roboto purple">
                <div className="cardGold col-12 col-sm-10 p-4">
                    <h2 className="Poppins or mb-4">Editeur</h2>
                    <h4>
                        Le site www.myd3s.com est la propriété exclusive de la <span className="RobotoBold or">SARL D3S</span>, qui l'édite.
                    </h4>
                    <h4 className="mt-4">
                        <span className="RobotoBold or">SARL D3S</span>
                    </h4>
                    <h4 className="">
                        Société Anonyme à Responsabilité Limitée
                    </h4>
                    <h4 className="">
                        73 route du Port Despointes
                    </h4>
                    <h4 className="">
                        Faubourg Blanchot
                    </h4>
                    <h4 className="">
                        98800 Nouméa
                    </h4>
                    <h4 className="">
                        Nouvelle-Calédonie
                    </h4>
                    <h4 className="">
                        Immatriculée au Ridet sous le numéro 1 351 295.001
                    </h4>
                    <h4 className="">
                        sandra.parent@myd3s.com
                    </h4>
                    <h2 className="Poppins or mt-4 mb-4">Directeur de la publication</h2>
                    <h4 className="">
                        Intuitive Conception
                    </h4>
                    <h4 className="">
                        710 RT1, Lotissement les Jammeloniers
                    </h4>
                    <h4 className="">
                        98835 Dumbéa
                    </h4>
                    <h4 className="">
                        Nouvelle-Calédonie
                    </h4>
                    <h4 className="">
                        sebastien.genty@intuitive-conception.com
                    </h4>
                    <h2 className="Poppins or mt-4 mb-4">Hébergement</h2>
                    <h4 className="">
                        Ce site est hébergé sur Amazon Web Service
                    </h4>
                    <h2 className="Poppins or mt-4 mb-4">Descriptions des services fournis</h2>
                    <h4 className="">
                        Le site www.myd3s.com a pour objet de fournir une information concernant l’ensemble des activités
                        de la société. Le proprietaire du site s’efforce de fournir sur le site des informations aussi
                        précises que possible. Toutefois, il ne pourra être tenue responsable des omissions,
                        des inexactitudes et des carences dans la mise à jour, qu’elles soient de son fait ou du fait
                        des tiers partenaires qui lui fournissent ces informations. Toutes les informations proposées
                        sont données à titre indicatif, sont non exhaustives, et sont susceptibles d’évoluer.
                        Elles sont données sous réserve de modifications ayant été apportées depuis leur mise en ligne.
                    </h4>
                    <h2 className="Poppins or mt-4 mb-4">Propriéte Intellectuelle et contrefaçons</h2>
                    <h4 className="">
                        Le proprietaire du site est propriétaire des droits de propriété intellectuelle ou détient
                        les droits d’usage sur tous les éléments accessibles sur le site, notamment les textes, images,
                        graphismes, logo, icônes, sons, logiciels… Toute reproduction, représentation, modification,
                        publication, adaptation totale ou partielle des éléments du site, quel que soit le moyen ou le
                        procédé utilisé, est interdite, sauf autorisation écrite préalable à l'email :
                        sandra.parent@myd3s.com.
                    </h4>
                    <h4>
                        Toute exploitation non autorisée du site ou de l’un quelconque de ces éléments qu’il contient
                        sera considérée comme constitutive d’une contrefaçon et poursuivie conformément aux dispositions
                        des articles L.335-2 et suivants du Code de Propriété Intellectuelle.
                    </h4>
                    <h2 className="Poppins or mt-4 mb-4">Liens hypertexte et cookies</h2>
                    <h4>
                       le site www.myd3s.com contient un certain nombre de liens hypertextes vers d’autres sites
                       (partenaires, informations …) mis en place avec l’autorisation du proprietaire du site .
                       Cependant, le proprietaire du site n’a pas la possibilité de vérifier le contenu des sites ainsi visités
                       et décline donc toute responsabilité de ce fait quand aux risques éventuels de contenus illicites.
                       L’utilisateur est informé que lors de ses visites sur le site www.myd3s.com, un ou des cookies sont
                       susceptible de s’installer automatiquement sur son ordinateur. Un cookie est un fichier de petite taille,
                       qui ne permet pas l’identification de l’utilisateur, mais qui enregistre des informations relatives
                       à la navigation d’un ordinateur sur un site. Les données ainsi obtenues visent à faciliter la navigation
                       ultérieure sur le site, et ont également vocation à permettre diverses mesures de fréquentation.
                       Le paramétrage du logiciel de navigation permet d’informer de la présence de cookie et éventuellement,
                       de refuser de la manière décrite à l’adresse suivante : www.cnil.fr.
                       Le refus d’installation d’un cookie peut entraîner l’impossibilité d’accéder à certains services.
                       L’utilisateur peut toutefois configurer son ordinateur de la manière suivante, pour refuser
                       l’installation des cookies : Sous Internet Explorer : onglet outil / options internet. Cliquez
                       sur Confidentialité et choisissez Bloquer tous les cookies. Validez sur Ok. Sous Netscape :
                       onglet édition / préférences. Cliquez sur Avancées et choisissez Désactiver les cookies. Validez sur Ok.
                    </h4>
                    <h2 className="Poppins or mt-4 mb-4">Protection des données personnelles</h2>
                    <h4>
                        Veuillez consulter notre politique de confidentialité
                    </h4>
                </div>
                <Footer/>
            </div>
        </div>

    )
};

export default LegalNotice;