import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import {useNavigate} from 'react-router-dom';

import { checkValidity } from '../../../shared/utility';
import { saveEnergyKeyWord, load_user_details } from '../../../store/actions/auth';
import routes from '../../../config/routes';
import Input from '../../../components/UI/Input/Input';
import Footer from '../../Footer/Footer';


function EnergyKeyWord({user, saveEnergyKeyWord, load_user_details, isAuthenticated}) {

    //SELECT FIELDS
    const number = [
        {value: '', displayValue: 'Premier choix'},
        {value: 'P1', displayValue: 'A'},
        {value: 'P2', displayValue: 'B'},
        {value: 'P3', displayValue: 'C'},
        {value: 'P4', displayValue: 'D'},
        {value: 'P5', displayValue: 'E'},
        {value: 'P6', displayValue: 'F'},
        {value: 'P7', displayValue: 'G'},
        {value: 'P8', displayValue: 'H'},
        {value: 'P9', displayValue: 'I'},
    ]
    const number_2 = [
        {value: '', displayValue: 'Second choix'},
        {value: 'P1', displayValue: 'A'},
        {value: 'P2', displayValue: 'B'},
        {value: 'P3', displayValue: 'C'},
        {value: 'P4', displayValue: 'D'},
        {value: 'P5', displayValue: 'E'},
        {value: 'P6', displayValue: 'F'},
        {value: 'P7', displayValue: 'G'},
        {value: 'P8', displayValue: 'H'},
        {value: 'P9', displayValue: 'I'},
    ]
    const number_3 = [
        {value: '', displayValue: 'Troisième choix'},
        {value: 'P1', displayValue: 'A'},
        {value: 'P2', displayValue: 'B'},
        {value: 'P3', displayValue: 'C'},
        {value: 'P4', displayValue: 'D'},
        {value: 'P5', displayValue: 'E'},
        {value: 'P6', displayValue: 'F'},
        {value: 'P7', displayValue: 'G'},
        {value: 'P8', displayValue: 'H'},
        {value: 'P9', displayValue: 'I'},
    ]
    const number_4 = [
        {value: '', displayValue: 'Quatrième choix'},
        {value: 'P1', displayValue: 'A'},
        {value: 'P2', displayValue: 'B'},
        {value: 'P3', displayValue: 'C'},
        {value: 'P4', displayValue: 'D'},
        {value: 'P5', displayValue: 'E'},
        {value: 'P6', displayValue: 'F'},
        {value: 'P7', displayValue: 'G'},
        {value: 'P8', displayValue: 'H'},
        {value: 'P9', displayValue: 'I'},
    ]
    const number_5 = [
        {value: '', displayValue: 'Cinquième choix'},
        {value: 'P1', displayValue: 'A'},
        {value: 'P2', displayValue: 'B'},
        {value: 'P3', displayValue: 'C'},
        {value: 'P4', displayValue: 'D'},
        {value: 'P5', displayValue: 'E'},
        {value: 'P6', displayValue: 'F'},
        {value: 'P7', displayValue: 'G'},
        {value: 'P8', displayValue: 'H'},
        {value: 'P9', displayValue: 'I'},
    ]
    const number_6 = [
        {value: '', displayValue: 'Sixième choix'},
        {value: 'P1', displayValue: 'A'},
        {value: 'P2', displayValue: 'B'},
        {value: 'P3', displayValue: 'C'},
        {value: 'P4', displayValue: 'D'},
        {value: 'P5', displayValue: 'E'},
        {value: 'P6', displayValue: 'F'},
        {value: 'P7', displayValue: 'G'},
        {value: 'P8', displayValue: 'H'},
        {value: 'P9', displayValue: 'I'},
    ]
    const number_7 = [
        {value: '', displayValue: 'Septième choix'},
        {value: 'P1', displayValue: 'A'},
        {value: 'P2', displayValue: 'B'},
        {value: 'P3', displayValue: 'C'},
        {value: 'P4', displayValue: 'D'},
        {value: 'P5', displayValue: 'E'},
        {value: 'P6', displayValue: 'F'},
        {value: 'P7', displayValue: 'G'},
        {value: 'P8', displayValue: 'H'},
        {value: 'P9', displayValue: 'I'},
    ]
    const number_8 = [
        {value: '', displayValue: 'Huitième choix'},
        {value: 'P1', displayValue: 'A'},
        {value: 'P2', displayValue: 'B'},
        {value: 'P3', displayValue: 'C'},
        {value: 'P4', displayValue: 'D'},
        {value: 'P5', displayValue: 'E'},
        {value: 'P6', displayValue: 'F'},
        {value: 'P7', displayValue: 'G'},
        {value: 'P8', displayValue: 'H'},
        {value: 'P9', displayValue: 'I'},
    ]
    const number_9 = [
        {value: '', displayValue: 'Neuvième choix'},
        {value: 'P1', displayValue: 'A'},
        {value: 'P2', displayValue: 'B'},
        {value: 'P3', displayValue: 'C'},
        {value: 'P4', displayValue: 'D'},
        {value: 'P5', displayValue: 'E'},
        {value: 'P6', displayValue: 'F'},
        {value: 'P7', displayValue: 'G'},
        {value: 'P8', displayValue: 'H'},
        {value: 'P9', displayValue: 'I'},
    ]

    //STATE
    const [loader, setLoader] = useState(true);

    const [inputs, setInputs] = useState({
        EKW1: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "",
            valid: false,
            validation: {
                required: true,
                different: "",
            },
            touched: false,
            errorMessage: "La réponse doit différer des autres. Champ requis",
        },
        EKW2: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number_2
            },
            value: '',
            label: "",
            valid: false,
            validation: {
                required: true,
                different: "",
            },
            touched: false,
            errorMessage: "La réponse doit différer des autres. Champ requis",
        },
        EKW3: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number_3
            },
            value: '',
            label: "",
            valid: false,
            validation: {
                required: true,
                different: "",
            },
            touched: false,
            errorMessage: "La réponse doit différer des autres. Champ requis",
        },
        EKW4: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number_4
            },
            value: '',
            label: "",
            valid: false,
            validation: {
                required: true,
                different: "",
            },
            touched: false,
            errorMessage: "La réponse doit différer des autres. Champ requis",
        },
        EKW5: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number_5
            },
            value: '',
            label: "",
            valid: false,
            validation: {
                required: true,
                different: "",
            },
            touched: false,
            errorMessage: "La réponse doit différer des autres. Champ requis",
        },
        EKW6: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number_6
            },
            value: '',
            label: "",
            valid: false,
            validation: {
                required: true,
                different: "",
            },
            touched: false,
            errorMessage: "La réponse doit différer des autres. Champ requis",
        },
        EKW7: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number_7
            },
            value: '',
            label: "",
            valid: false,
            validation: {
                required: true,
                different: "",
            },
            touched: false,
            errorMessage: "La réponse doit différer des autres. Champ requis",
        },
        EKW8: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number_8
            },
            value: '',
            label: "",
            valid: false,
            validation: {
                required: true,
                different: "",
            },
            touched: false,
            errorMessage: "La réponse doit différer des autres. Champ requis",
        },
        EKW9: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number_9
            },
            value: '',
            label: "",
            valid: false,
            validation: {
                required: true,
                different: "",
            },
            touched: false,
            errorMessage: "La réponse doit différer des autres. Champ requis",
        },
    });

    const [valid, setValid] = useState(false);
    const [quizCompleted, setQuizCompleted] = useState(false);

    //Redirect
    const navigate = useNavigate();

    //ComponentDidMount
    useEffect(() => {
        load_user_details();
        if (!user){
            setTimeout(() => {
            setLoader(false);
            }, 1000);
        }
        else {
            if(user.EKW){
                navigate(routes.HOME)
            }
            else{
                setLoader(false)
            }
        }
    }, [])

    //ComponentDidUpdate
    useEffect(() => {
        if (!user){
            setTimeout(() => {
            setLoader(false);
            }, 1000);
        }
        else {
            if(user.EKW){
                navigate(routes.HOME)
            }
            else{
                setLoader(false)
            }
        }
        const auth = localStorage.getItem('authenticated')
        if (! auth){
            alert('vous devez vous connecter pour répondre au questionnaire.')
            navigate(routes.HOME)
        }
    });

    //Functions

    const InputChangedHandler = (event, id) => {

        //Change Value
        const newInputs = {...inputs};
        newInputs[id].value = event.target.value;
        newInputs[id].touched = true;

        //Check value
        inputs.EKW1.validation.different = [];
        inputs.EKW1.validation.different.push(inputs.EKW2.value, inputs.EKW3.value, inputs.EKW4.value, inputs.EKW5.value,
                                                inputs.EKW6.value, inputs.EKW7.value, inputs.EKW8.value, inputs.EKW9.value
                                                );
        inputs.EKW2.validation.different = [];
        inputs.EKW2.validation.different.push(inputs.EKW1.value, inputs.EKW3.value, inputs.EKW4.value, inputs.EKW5.value,
                                                inputs.EKW6.value, inputs.EKW7.value, inputs.EKW8.value, inputs.EKW9.value
                                                );
        inputs.EKW3.validation.different = [];
        inputs.EKW3.validation.different.push(inputs.EKW1.value, inputs.EKW2.value, inputs.EKW4.value, inputs.EKW5.value,
                                                inputs.EKW6.value, inputs.EKW7.value, inputs.EKW8.value, inputs.EKW9.value
                                                );
        inputs.EKW4.validation.different = [];
        inputs.EKW4.validation.different.push(inputs.EKW2.value, inputs.EKW3.value, inputs.EKW1.value, inputs.EKW5.value,
                                                inputs.EKW6.value, inputs.EKW7.value, inputs.EKW8.value, inputs.EKW9.value
                                                );
        inputs.EKW5.validation.different = [];
        inputs.EKW5.validation.different.push(inputs.EKW2.value, inputs.EKW3.value, inputs.EKW4.value, inputs.EKW1.value,
                                                inputs.EKW6.value, inputs.EKW7.value, inputs.EKW8.value, inputs.EKW9.value
                                                );
        inputs.EKW6.validation.different = [];
        inputs.EKW6.validation.different.push(inputs.EKW2.value, inputs.EKW3.value, inputs.EKW4.value, inputs.EKW5.value,
                                                inputs.EKW1.value, inputs.EKW7.value, inputs.EKW8.value, inputs.EKW9.value
                                                );
        inputs.EKW7.validation.different = [];
        inputs.EKW7.validation.different.push(inputs.EKW2.value, inputs.EKW3.value, inputs.EKW4.value, inputs.EKW5.value,
                                                inputs.EKW6.value, inputs.EKW1.value, inputs.EKW8.value, inputs.EKW9.value
                                                );
        inputs.EKW8.validation.different = [];
        inputs.EKW8.validation.different.push(inputs.EKW2.value, inputs.EKW3.value, inputs.EKW4.value, inputs.EKW5.value,
                                                inputs.EKW6.value, inputs.EKW7.value, inputs.EKW1.value, inputs.EKW9.value
                                                );
        inputs.EKW9.validation.different = [];
        inputs.EKW9.validation.different.push(inputs.EKW2.value, inputs.EKW3.value, inputs.EKW4.value, inputs.EKW5.value,
                                                inputs.EKW6.value, inputs.EKW7.value, inputs.EKW8.value, inputs.EKW1.value
                                                );
        newInputs[id].valid = checkValidity(event.target.value, newInputs[id].validation)

        for (let input in newInputs){
            newInputs[input].valid = checkValidity(newInputs[input].value, newInputs[input].validation)
        }

        setInputs(newInputs);

        //Form checking
        let formIsValid = true;
        for (let input in newInputs) {
            formIsValid = newInputs[input].valid && formIsValid;
        }
        setValid(formIsValid);
    };

    const formHandler = event => {
        event.preventDefault();
    }


    //Save Confirmation
    const saveClickedHandler = () => {
        console.log(localStorage);
        const EKW = {
            user: user.email, user_email: user.email,
            EKW1: inputs.EKW1.value, EKW2: inputs.EKW2.value, EKW3: inputs.EKW3.value, EKW4: inputs.EKW4.value,
            EKW5: inputs.EKW5.value, EKW6: inputs.EKW6.value, EKW7: inputs.EKW7.value, EKW8: inputs.EKW8.value,
            EKW9: inputs.EKW9.value
        };
        try{
            saveEnergyKeyWord(
                EKW.user, EKW.user_email,
                EKW.EKW1, EKW.EKW2, EKW.EKW3, EKW.EKW4, EKW.EKW5, EKW.EKW6, EKW.EKW7, EKW.EKW8, EKW.EKW9
            );
            setQuizCompleted(true);
            navigate(routes.QUIZ)
        }
        catch(err){
            alert("Vérifiez que l'ensemble des champs soient renseignés")
        }
    }

    //Variables

    const formElementArray = [];
    for(let key in inputs) {
        formElementArray.push({
            id: key,
            config: inputs[key]
        });
    };

    let form = (
        <form onSubmit={(e) => formHandler(e)}>
            {formElementArray.map(formElement => (
            <div className="row d-flex justify-content-center mt-2" key={formElement.id}>
                <Input
                    id={formElement.id}
                    value={formElement.config.value}
                    label={formElement.config.label}
                    type={formElement.config.elementType}
                    config={formElement.config.elementConfig}
                    valid={formElement.config.valid}
                    touched={formElement.config.touched}
                    errorMessage={formElement.config.errorMessage}
                    changed={(e) => InputChangedHandler(e, formElement.id)}
                />
            </div>
            ))}
        </form>
    );


    return loader ? (
        <>
            <div className="container-fluid">
                <div className='row d-flex justify-content-center mt-5'>

                </div>
            </div>
        </>
    ) : (
        <div className='container-fluid p-3'>
            <div className='row d-flex justify-content-center mt-5'>
                <h1 className='PoppinsLight or'>Enneagramme : Mots Clés</h1>
            </div>
            <div className='row d-flex justify-content-center mt-5'>
                <div className='col-10 col-md-8 p-4 cardPurple mt-5'>
                    <h4 className='RobotoThin text-white'>
                        Ces mots clés peuvent vous aider à vous reconnaître s'ils font sens pour vous. Classez les du n°1 à 9, de ceux qui ont le plus de sens vers ceux qui en ont le moins:
                    </h4>
                </div>
            </div>
            <div className='row d-flex justify-content-center mt-5'>
                <div className='col-12 col-md-5 mt-5 text-start RobotoLight text-white'>
                    <h4 className="mb-5"><b><span className="orange fs-2">A </span></b> Je dois, Il faut, Correct, Incorrect, Agacé</h4>
                    <h4 className="mb-5"><b><span className="orange fs-2">B </span></b> Aider, Partager, Amour, Coeur, Donner</h4>
                    <h4 className="mb-5"><b><span className="orange fs-2">C </span></b> Réussir, Objectifs, Résultats, Agir, Projets, Challenge</h4>
                    <h4 className="mb-5"><b><span className="orange fs-2">D </span></b> Authentique, Sensibilité, Originalité, Beauté, Bizarre, Différent</h4>
                    <h4 className="mb-5"><b><span className="orange fs-2">E </span></b> Comprendre, Information, Chercher, Savoir, Ressources.</h4>
                    <h4 className="mb-5"><b><span className="orange fs-2">F </span></b> Attention, 'On ne sait jamais', Se méfier, Confiance, Doute, Problème</h4>
                    <h4 className="mb-5"><b><span className="orange fs-2">G </span></b> Plaisir, Liberté, Nouveauté, Profiter, Bonheur</h4>
                    <h4 className="mb-5"><b><span className="orange fs-2">H </span></b> Contrôle, Fort, Protéger, Injustice, Faiblesse, Force, Colère</h4>
                    <h4 className="mb-5"><b><span className="orange fs-2">I </span></b> Sérénité, Paix, Harmonie, Calme, Tranquilité</h4>
                </div>

                <div className='col-12 col-md-5 mb-3 mt-2'>
                    {form}
                </div>
            </div>
            <div className='row d-flex justify-content-center gy-3 mt-5 mb-5'>
                <button type='submit' className='signupBtn' onClick={saveClickedHandler} disabled={!valid} >
                    <span className='signupBtnSpan roboto'>Enregistrer</span>
                </button>
            </div>
            <Footer/>
        </div>
    )
};

const mapStateToProps = state => ({
    user: state.auth.user,
    isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps, {saveEnergyKeyWord, load_user_details}) (EnergyKeyWord);