import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import {useNavigate} from 'react-router-dom';

import { checkValidity } from '../../../shared/utility';
import { saveEnergyModel, load_user_details } from '../../../store/actions/auth';
import routes from '../../../config/routes';
import Input from '../../../components/UI/Input/Input';
import Footer from '../../Footer/Footer';


function EnergyModel({user, saveEnergyModel, load_user_details, isAuthenticated}) {

    //SELECT FIELDS
    const number = [
        {value: '', displayValue: 'Premier choix'},
        {value: 'BOIS', displayValue: 'A'},
        {value: 'FEU', displayValue: 'B'},
        {value: 'TERRE', displayValue: 'C'},
        {value: 'METAL', displayValue: 'D'},
        {value: 'EAU', displayValue: 'E'},
    ]
    const number_2 = [
        {value: '', displayValue: 'Second choix'},
        {value: 'BOIS', displayValue: 'A'},
        {value: 'FEU', displayValue: 'B'},
        {value: 'TERRE', displayValue: 'C'},
        {value: 'METAL', displayValue: 'D'},
        {value: 'EAU', displayValue: 'E'},
    ]
    const number_3 = [
        {value: '', displayValue: 'Troisième choix'},
        {value: 'BOIS', displayValue: 'A'},
        {value: 'FEU', displayValue: 'B'},
        {value: 'TERRE', displayValue: 'C'},
        {value: 'METAL', displayValue: 'D'},
        {value: 'EAU', displayValue: 'E'},
    ]
    const number_4 = [
        {value: '', displayValue: 'Quatrième choix'},
        {value: 'BOIS', displayValue: 'A'},
        {value: 'FEU', displayValue: 'B'},
        {value: 'TERRE', displayValue: 'C'},
        {value: 'METAL', displayValue: 'D'},
        {value: 'EAU', displayValue: 'E'},
    ]
    const number_5 = [
        {value: '', displayValue: 'Cinquième choix'},
        {value: 'BOIS', displayValue: 'A'},
        {value: 'FEU', displayValue: 'B'},
        {value: 'TERRE', displayValue: 'C'},
        {value: 'METAL', displayValue: 'D'},
        {value: 'EAU', displayValue: 'E'},
    ]

    //STATE
    const [loader, setLoader] = useState(true);

    const [inputs, setInputs] = useState({
        EM1: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "Choix N°1",
            valid: false,
            validation: {
                required: true,
                different: "",
            },
            touched: false,
            errorMessage: "La réponse doit différer des autres. Champ requis",
        },
        EM2: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number_2
            },
            value: '',
            label: "Choix N°2",
            valid: false,
            validation: {
                required: true,
                different: "",
            },
            touched: false,
            errorMessage: "La réponse doit différer des autres. Champ requis",
        },
        EM3: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number_3
            },
            value: '',
            label: "Choix N°3",
            valid: false,
            validation: {
                required: true,
                different: "",
            },
            touched: false,
            errorMessage: "La réponse doit différer des autres. Champ requis",
        },
        EM4: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number_4
            },
            value: '',
            label: "Choix N°4",
            valid: false,
            validation: {
                required: true,
                different: "",
            },
            touched: false,
            errorMessage: "La réponse doit différer des autres. Champ requis",
        },
        EM5: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number_5
            },
            value: '',
            label: "Choix N°5",
            valid: false,
            validation: {
                required: true,
                different: "",
            },
            touched: false,
            errorMessage: "La réponse doit différer des autres. Champ requis",
        },

    });

    const [valid, setValid] = useState(false);
    const [quizCompleted, setQuizCompleted] = useState(false);

    //Redirect
    const navigate = useNavigate();

    //ComponentDidMount
    useEffect(() => {
        load_user_details();
        if (!user){
            setTimeout(() => {
            setLoader(false);
            }, 1000);
        }
        else {
            if(user.EM){
                navigate(routes.HOME)
            }
            else{
                setLoader(false)
            }
        }
    }, [])

    //ComponentDidUpdate
    useEffect(() => {
        if (!user){
            setTimeout(() => {
            setLoader(false);
            }, 1000);
        }
        else {
            if(user.EM){
                navigate(routes.HOME)
            }
            else{
                setLoader(false)
            }
        }
        const auth = localStorage.getItem('authenticated')
        if (! auth){
            alert('vous devez vous connecter pour répondre au questionnaire.')
            navigate(routes.HOME)
        }
    });

    //Functions

    const InputChangedHandler = (event, id) => {

        //Change Value
        const newInputs = {...inputs};
        newInputs[id].value = event.target.value;
        newInputs[id].touched = true;

        //Check value
        inputs.EM1.validation.different = [];
        inputs.EM1.validation.different.push(inputs.EM2.value, inputs.EM3.value, inputs.EM4.value, inputs.EM5.value);
        inputs.EM2.validation.different = [];
        inputs.EM2.validation.different.push(inputs.EM1.value, inputs.EM3.value, inputs.EM4.value, inputs.EM5.value);
        inputs.EM3.validation.different = [];
        inputs.EM3.validation.different.push(inputs.EM1.value, inputs.EM2.value, inputs.EM4.value, inputs.EM5.value);
        inputs.EM4.validation.different = [];
        inputs.EM4.validation.different.push(inputs.EM2.value, inputs.EM3.value, inputs.EM1.value, inputs.EM5.value);
        inputs.EM5.validation.different = [];
        inputs.EM5.validation.different.push(inputs.EM2.value, inputs.EM3.value, inputs.EM4.value, inputs.EM1.value);

        newInputs[id].valid = checkValidity(event.target.value, newInputs[id].validation)

        for (let input in newInputs){
            newInputs[input].valid = checkValidity(newInputs[input].value, newInputs[input].validation)
        }

        setInputs(newInputs);

        //Form checking
        let formIsValid = true;
        for (let input in newInputs) {
            formIsValid = newInputs[input].valid && formIsValid;
        }
        setValid(formIsValid);
    };

    const formHandler = event => {
        event.preventDefault();
    }


    //Save Confirmation
    const saveClickedHandler = () => {
        console.log(localStorage);
        const EM = {
            user: user.email, user_email: user.email,
            EM1: inputs.EM1.value, EM2: inputs.EM2.value, EM3: inputs.EM3.value, EM4: inputs.EM4.value, EM5: inputs.EM5.value
        };
        try{
            saveEnergyModel(
                EM.user, EM.user_email,
                EM.EM1, EM.EM2, EM.EM3, EM.EM4, EM.EM5, EM.EM6, EM.EM7, EM.EM8, EM.EM9
            );
            setQuizCompleted(true);
            navigate(routes.QUIZ)
        }
        catch(err){
            alert("Vérifiez que l'ensemble des champs soient renseignés")
        }
    }

    //Variables

    const formElementArray = [];
    for(let key in inputs) {
        formElementArray.push({
            id: key,
            config: inputs[key]
        });
    };

    let form = (
        <form onSubmit={(e) => formHandler(e)}>
            {formElementArray.map(formElement => (
            <div className="row d-flex justify-content-center mt-5" key={formElement.id}>
                <Input
                    id={formElement.id}
                    value={formElement.config.value}

                    type={formElement.config.elementType}
                    config={formElement.config.elementConfig}
                    valid={formElement.config.valid}
                    touched={formElement.config.touched}
                    errorMessage={formElement.config.errorMessage}
                    changed={(e) => InputChangedHandler(e, formElement.id)}
                />
            </div>
            ))}
        </form>
    );


    return loader ? (
        <>
            <div className="container-fluid">
                <div className='row d-flex justify-content-center mt-5'>

                </div>
            </div>
        </>
    ) : (
        <div className='container-fluid p-3'>
            <div className='row d-flex justify-content-center mt-5'>
                <h1 className='PoppinsLight or'>Profil Energétique: Préférences</h1>
            </div>
            <div className='row d-flex justify-content-center mt-3'>
                <div className='col-12 col-md-8 cardPurple p-4 mt-5 mb-5'>
                    <h4 className='RobotoThin text-white'>
                        Au plus profond de vous, quelles sont les personnes que vous admirez, qui vous touchent le plus?
                        (Classez les dans l'ordre de vos préférences)
                    </h4>
                </div>
            </div>
            <div className='row d-flex justify-content-center mt-3'>
                <div className='col-12 col-md-8 cardGold text-start text-white p-3 mt-3'>
                    <h4 className='RobotoLight'><span className="orange fs-2"><b>A.</b></span> Les entrepreneurs, les sportifs, ceux qui sont motivés, actifs, volontaires, courageux et audacieux, visionnaires et stratégiques.</h4>
                    <h4 className='RobotoLight'><span className="orange fs-2"><b>B.</b></span> Ceux qui sont plus intelligents, plus beaux, plus visibles, qui ont réussis, qui rayonnent, qui s'expriment bien, qui chantent bien, qui gouvernent.</h4>
                    <h4 className='RobotoLight'><span className="orange fs-2"><b>C.</b></span> Les enfants, la famille, le vivre ensemble, la nature, les animaux, être entouré, l'équilibre, la paix.</h4>
                    <h4 className='RobotoLight'><span className="orange fs-2"><b>D.</b></span> Les gens forts, qui savent se faire respecter, les rocks, ceux qui font preuve de cohérence extrême, qui imposent le respect, ceux sur qui on peut compter</h4>
                    <h4 className='RobotoLight'><span className="orange fs-2"><b>E.</b></span> Les talents, les missions, les gens qui ont tenu bon, l'endurance, la conviction extrême, la spiritualité, ceux qui se sentent guidés et connectés.</h4>
                </div>
            </div>
            <div className='row d-flex justify-content-center'>
                <div className='col-12 col-md-6 mb-5 mt-4'>
                    {form}
                </div>
            </div>
            <div className='row d-flex justify-content-center gy-3 mt-5 mb-5'>
                <button type='submit' className='signupBtn' onClick={saveClickedHandler} disabled={!valid} >
                    <span className='signupBtnSpan roboto'>Enregistrer</span>
                </button>
            </div>
            <Footer/>
        </div>
    )
};

const mapStateToProps = state => ({
    user: state.auth.user,
    isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps, {saveEnergyModel, load_user_details}) (EnergyModel);