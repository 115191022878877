import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { S3Client, PutObjectCommand } from "@aws-sdk/client-s3";
import axiosTrainings from '../../config/axios_trainings';


import {
    TRAINING_CREATED,
    TRAINING_UPDATED,
} from './train_types';

//S3 CONFIG
const s3Client = new S3Client({
  region: "ap-southeast-2",
  credentials: {
    accessKeyId: "AKIATIBXZSKX2L5Y7PXP",
    secretAccessKey: "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",
  },
});

//S3 VARIABLES
const bucketName = "myd3s-static";
const bucketRegion = "ap-southeast-2"
const folderName = 'photo'

// S3 UPLOAD
const uploadFileToS3 = async (file, name) => {
  try {
    const objectKey = `${folderName}/${name}_Thumbnail`;
    const fileData = await file.arrayBuffer();
    const fileContentType = file.type;

    const uploadParams = {
      Bucket: bucketName,
      Key: objectKey,
      Body: fileData,
      ContentType: fileContentType,
    };

    const uploadCommand = new PutObjectCommand(uploadParams);
    const response = await s3Client.send(uploadCommand);

    const uploadedFileUrl = `https://${bucketName}.s3.${bucketRegion}.amazonaws.com/${objectKey}`;
    return null;
    //return uploadedFileUrl;
  }
  catch (error) {
    console.error("Erreur lors de l'upload du fichier vers S3 : ", error);
    return null;
  }
};

//SLUGIFY (https://mhagemann.medium.com/the-ultimate-way-to-slugify-a-url-string-in-javascript-b8e4a0d849e1)
function slugify(string) {
  const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìıİłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;'
  const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------'
  const p = new RegExp(a.split('').join('|'), 'g')

  return string.toString().toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
    .replace(/&/g, '-and-') // Replace & with 'and'
    .replace(/[^\w\-]+/g, '') // Remove all non-word characters
    .replace(/\-\-+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, '') // Trim - from end of text
}

const showToastCreateTraining = () => {
        toast('Nouvelle formation crée', {
            position: toast.POSITION.TOP_CENTER
        });
    };

const showToastCreateTrainingError = () => {
        toast.error('Echec de la création, cette formation existe peut-être déjà', {
            position: toast.POSITION.TOP_CENTER
        });
    };
const showToastUpdateTraining = () => {
        toast('Formation modifiée', {
            position: toast.POSITION.TOP_CENTER
        });
    };

const showToastUpdateTrainingError = () => {
        toast.error('Echec de la modification', {
            position: toast.POSITION.TOP_CENTER
        });
    };

//ADMIN CREATE TRAINING
export const create_training = (name, description, start, end, is_active, thumbnail) => async dispatch => {

    let accessToken = localStorage.getItem('access');
    const slugName = slugify(name)


    if ( accessToken ) {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `JWT ${accessToken}`,
                'Accept': 'application/json'
            }
        };
        const thumbnailURL = `https://${bucketName}.s3.${bucketRegion}.amazonaws.com/${folderName}/${slugName}_Thumbnail`;
        const body = JSON.stringify({name, description, start, end, is_active, thumbnailURL});
        try{
            await axiosTrainings.post('list', body, config);
            await uploadFileToS3(thumbnail, slugName);
            showToastCreateTraining()
        }
        catch (err) {
            showToastCreateTrainingError()
        }
    }
    else {
        showToastCreateTrainingError()
    }
};

export const update_training = (previous, name, description, start, end, is_active, thumbnail) => async dispatch => {

    let accessToken = localStorage.getItem('access');
    const slugName = slugify(previous)

    if ( accessToken ) {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `JWT ${accessToken}`,
                'Accept': 'application/json'
            }
        };
        const thumbnailURL = `https://${bucketName}.s3.${bucketRegion}.amazonaws.com/${folderName}/${slugName}_Thumbnail`;
        const body = JSON.stringify({name, description, start, end, is_active, thumbnailURL});
        try{
            await axiosTrainings.patch(`${previous}`, body, config);
            await uploadFileToS3(thumbnail, slugName);
            showToastUpdateTraining()
        }
        catch (err) {
            showToastUpdateTrainingError()
        }
    }
    else {
        showToastUpdateTrainingError()
    }
};