import axios from 'axios';

//const axiosTrainings = axios.create({
//   baseURL: 'http://127.0.0.1:8000/trainings/'
//});

const axiosTrainings = axios.create({
    baseURL: 'https://kkqf31ivb3.execute-api.ap-southeast-2.amazonaws.com/dev/trainings/'
});

export default axiosTrainings;