//Library
import React from 'react';
import { Link } from 'react-router-dom';
import routes from '../../../config/routes';


function Choice() {

    return(
        <div className='container-fluid screenHeight100'>
            <div className='row d-flex justify-content-center my-5'>
            <h1 className='text-center mt-5 PoppinsLight or'>Êtes-vous un Particulier ou une Entreprises?</h1>
                <div className='row d-flex justify-content-center mt-5'>
                    <Link to={routes.COMPANY} className='signupBtn mx-5'>
                        <span className='signupBtnSpan'>Entreprise</span>
                    </Link>
                    <Link to={routes.HOME} className='signupBtn'>
                        <span className='signupBtnSpan'>Particulier</span>
                    </Link>
                </div>
            </div>
        </div>
    );
};



export default Choice;