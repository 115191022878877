//Library
import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { checkValidity } from '../../../shared/utility';
import { reset_password } from '../../../store/actions/auth';
import routes from '../../../config/routes';

//Components
import Input from '../../../components/UI/Input/Input';

function ResetPassword({reset_password}) {

    //State
    const [inputs, setInputs] = useState({
        email: {
            elementType: 'input',
            elementConfig: {
                type: 'email',
                placeholder: 'Email'
            },
            value: '',
            label: '',
            valid: false,
            validation: {
                required: true,
                email: true
            },
            touched: false,
            errorMessage: "Invalid email address"
        },

    });

    const [valid, setValid] = useState (false);

    const [requestSent, setRequestSent] = useState(false);

    //Functions

    const InputChangedHandler = (event, id) => {

        //Change Value
        const newInputs = {...inputs};
        newInputs[id].value = event.target.value;
        newInputs[id].touched = true;

        //Check value
        newInputs[id].valid = checkValidity(event.target.value, newInputs[id].validation)

        setInputs(newInputs);

        //Form checking
        let formIsValid = true;
        for (let input in newInputs) {
            formIsValid = newInputs[input].valid && formIsValid;
        }
        setValid(formIsValid);
    };

    const formHandler = event => {
        event.preventDefault();
    }

    //Redirect
    const navigate = useNavigate();

    //Confirm Login
    const ResetClickedHandler = () => {
        const user = {
            email: inputs.email.value,
        };
        reset_password(user.email);
        navigate(routes.HOME);
    }

    //Variables

    const formElementArray = [];
    for(let key in inputs) {
        formElementArray.push({
            id: key,
            config: inputs[key]
        });
    };

    let form = (
        <form onSubmit={(e) => formHandler(e)}>
            {formElementArray.map(formElement => (
                <Input
                    key={formElement.id}
                    id={formElement.id}
                    value={formElement.config.value}
                    label={formElement.config.label}
                    type={formElement.config.elementType}
                    config={formElement.config.elementConfig}
                    valid={formElement.config.valid}
                    touched={formElement.config.touched}
                    errorMessage={formElement.config.errorMessage}
                    changed={(e) => InputChangedHandler(e, formElement.id)}
                />
            ))}
            <div className='row d-flex justify-content-center gy-3'>
                <button type='button' className='signupBtn' onClick={ResetClickedHandler} disabled={!valid}>
                    <span className="signupBtnSpan">Valider</span>
                </button>
            </div>
        </form>
    );


    return(
        <div className='container-fluid'>
            <div className='row d-flex justify-content-center my-5'>
            <h1 className='text-center mt-5 PoppinsLight or'>Nouveau mot de passe</h1>
                <div className='col-10 col-md-6'>
                    {form}
                </div>
                <div className='row d-flex justify-content-center mt-5'>
                    <div className='col-10 col-md-3 text-center'>
                        <h4 className='mt-5 PoppinsLight or'>Pas encore inscrit ?</h4>
                        <div className='row d-flex justify-content-center'>
                            <Link className='signupBtn' to={routes.SIGNUP} >
                                <span className="signupBtnSpan">S'inscrire</span>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};


export default connect(null, { reset_password }) (ResetPassword);