import React from 'react';
import {Link} from 'react-router-dom';

import Logo from './picts/logo-d3s.png'
import emailIcon from './picts/emailIcon.png'
import webIcon from './picts/webIcon.png'
import phoneIcon from './picts/phoneIcon.png'
import MBTI from './picts/MBTI.png'

function Footer() {
    return (

            <div className="row p-4 d-flex justify-content-center ">
                <div className="col-12 col-xl-6 cardGold">
                    <div className="row">
                        <div className="col-12 col-md-8 col-lg-6 text-start or">
                            <h3 className="PoppinsLight mt-3">Sandra Parent</h3>
                            <h4 className="RobotoBold">SARL D3S</h4>
                            <h5 className="RobotoMediumItalic">Formatrice et consultante en Entreprise</h5>
                            <h5 className="RobotoMediumItalic">Maitre Praticien en Ennéagramme - Praticien MBTI certifié</h5>
                            <h5 className="RobotoMediumItalic">Coach de la performance et préparateur mental</h5>
                            <h5 className="RobotoMediumItalic">N° DFPC : 988/0570/18R</h5>
                            <h5 className="RobotoMediumItalic">N° Formateur : 2020-0534</h5>
                            <h5 className="RobotoBold mt-4">Tel: 78.45.35</h5>
                            <p><a href="www.d3s.nc" className="RobotoBold h5">www.myd3s.com</a></p>
                            <p><a href="http://www.facebook.com/d3s.nc" className="RobotoBold h5">facebook/d3s.nc</a></p>
                        </div>
                        <div className="col-12 col-md-4 col-lg-6 text-center">
                            <img src={MBTI} className="img img-fluid"/>
                        </div>
                    </div>
                </div>
            </div>

    );
};

export default Footer;