import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import {useNavigate} from 'react-router-dom';

import { checkValidity } from '../../../shared/utility';
import { saveTemperament, load_user_details } from '../../../store/actions/auth';
import routes from '../../../config/routes';
import Input from '../../../components/UI/Input/Input';
import Footer from '../../Footer/Footer';


function Temperament({user, saveTemperament, load_user_details, isAuthenticated}) {

    //SELECT FIELDS
    const number = [
        {value: '', displayValue: 'Sélectionner votre réponse'},
        {value: 'SJ', displayValue: 'SJ'},
        {value: 'SP', displayValue: 'SP'},
        {value: 'NF', displayValue: 'NF'},
        {value: 'NT', displayValue: 'NT'},
    ]

    //STATE
    const [loader, setLoader] = useState(true);

    const [inputs, setInputs] = useState({
        TP1: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "Choisissez votre tempérament",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis"
        },
        TP2: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "Si différent, votre tempérament à 20 ans",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis"
        }
    });

    const [valid, setValid] = useState(false);
    const [quizCompleted, setQuizCompleted] = useState(false);

    //Redirect
    const navigate = useNavigate();

    //ComponentDidMount
    useEffect(() => {
        load_user_details();
        if (!user){
            setTimeout(() => {
            setLoader(false);
            }, 1000);
        }
        else {
            if(user.TP){
                navigate(routes.HOME)
            }
            else{
                setLoader(false)
            }
        }
    }, [])

    //ComponentDidUpdate
    useEffect(() => {
        if (!user){
            setTimeout(() => {
            setLoader(false);
            }, 1000);
        }
        else {
            if(user.TP){
                navigate(routes.HOME)
            }
            else{
                setLoader(false)
            }
        }
        const auth = localStorage.getItem('authenticated')
        if (! auth){
            alert('vous devez vous connecter pour répondre au questionnaire.')
            navigate(routes.HOME)
        }
    });

    //Functions

    const InputChangedHandler = (event, id) => {

        //Change Value
        const newInputs = {...inputs};
        newInputs[id].value = event.target.value;
        newInputs[id].touched = true;

        //Check value
        newInputs[id].valid = checkValidity(event.target.value, newInputs[id].validation)

        setInputs(newInputs);

        //Form checking
        let formIsValid = true;
        for (let input in newInputs) {
            formIsValid = newInputs[input].valid && formIsValid;
        }
        setValid(formIsValid);
    };

    const formHandler = event => {
        event.preventDefault();
    }


    //Save Confirmation
    const saveClickedHandler = () => {
        console.log(inputs.TP1.value);
        const TP = {
            user: user.email,
            user_email: user.email,
            TP1: inputs.TP1.value,
            TP2: inputs.TP2.value
        };

        try{
            saveTemperament(TP.user, TP.user_email, TP.TP1, TP.TP2);
            setQuizCompleted(true);
            navigate(routes.QUIZ)
        }
        catch(err){
            alert("Vérifiez que l'ensemble des champs soient renseignés")
        }
    };

    //Variables

    const formElementArray = [];
    for(let key in inputs) {
        formElementArray.push({
            id: key,
            config: inputs[key]
        });
    };

    let form = (
        <form onSubmit={(e) => formHandler(e)}>
            {formElementArray.map(formElement => (
            <div className="row mt-5" key={formElement.id}>
                <h4 className="RobotoLight orange">{formElement.config.label}</h4>
                <Input
                    key={formElement.id}
                    id={formElement.id}
                    value={formElement.config.value}

                    type={formElement.config.elementType}
                    config={formElement.config.elementConfig}
                    valid={formElement.config.valid}
                    touched={formElement.config.touched}
                    errorMessage={formElement.config.errorMessage}
                    changed={(e) => InputChangedHandler(e, formElement.id)}
                />
            </div>
            ))}
        </form>
    );


    return loader ? (
        <>
            <div className="container-fluid">
                <div className='row d-flex justify-content-center mt-5'>

                </div>
            </div>
        </>
    ) : (
        <div className='container-fluid p-3'>
            <div className='row d-flex justify-content-center mt-5'>
                <h1 className='PoppinsLight or'>Les 4 Familles de Tempéraments</h1>
            </div>
            <div className='row d-flex justify-content-center mt-3'>
                <div className='col-12 col-md-8 cardPurple p-4 mt-5 mb-5'>
                    <h4 className='RobotoThin text-white'>
                        Parmi ces 4 familles de tempéraments, LAQUELLE vous parle le plus spontanément depuis toujours ?
                    </h4>
                </div>
            </div>
            <div className='row d-flex justify-content-center mt-5'>
                <div className='col-12 col-md-8 align-items-center'>
                <h4 className='RobotoLight orange mb-3 text-start'>Les Gardiens : l'habilité Logistique <span className="text-white "><b>SJ</b></span></h4>
                    <div className='RobotoLight text-start cardGold p-4'>
                        <h4 className='card-text'>
                            Les besoins de base du Gardien sont <b>l'adhésion</b> au groupe et la responsabilité
                        </h4>
                        <h4 className='card-text'>
                            Ils ont besoin de savoir qu'ils font leur <b>devoir</b>. Ils valorisent la <b>stabilité</b>, la sécurité et le sens de la communauté.
                        </h4>
                        <h4 className='card-text'>
                            Ils se fient à la <b>hiérarchie</b>, à l'autorité, et peuvent être surpris que d'autres aillent à l'encontre de des structures sociales.
                            Les Gardiens savent comment les choses ont toujours été faites, et donc ils <b>anticipent</b> les problèmes.
                        </h4>
                        <h4 className='card-text'>
                            Ils ont le chic pour <b>suivre</b> les règles, les procédures et le protocole.
                        </h4>
                    </div>
                </div>
            </div>
            <div className='row d-flex justify-content-center mt-5'>
                <div className='col-12 col-md-8 align-items-center'>
                    <h4 className='RobotoLight orange mb-3 text-start'>Les Artisans: l'habilité Tactique <span className="text-white "><b>SP</b></span></h4>
                    <div className='RobotoLight text-start cardGold p-4'>
                        <h4 className='card-text'>
                            Les besoins de base de l'Artisan sont d'avoir la <b>liberté d'agir</b> sans obstacles et de voir un résultat marqué à leurs actions.
                        </h4>
                        <h4 className='card-text'>
                            Les Artisans valorisent hautement <b>l'esthétique</b>, que ce soit dans la nature ou dans l'art.
                        </h4>
                        <h4 className='card-text'>
                            Leurs énergies sont concentrées sur la <b>performance</b> adroite, la variété et la stimulation. Les Artisans ont tendance à
                            être doués à <b>employer</b> les moyens disponibles pour accomplir un but.
                        </h4>
                        <h4 className='card-text'>
                            Leur <b>créativité</b> est révélée par la <b>variété</b> de solutions qu'ils trouvent. Ils sont doués pour l'utilisation des <b>instruments</b>,
                            que l'instrument soit la langue, les théories, un pinceau ou un ordinateur.
                        </h4>
                    </div>
                </div>
            </div>
            <div className='row d-flex justify-content-center mt-5'>
                <div className='col-12 col-md-8 align-items-center'>
                    <h4 className='RobotoLight text-start orange mb-3'>Les Idéalistes: l'habilité Diplomatique <span className="text-white "><b>NF</b></span></h4>
                    <div className='RobotoLight text-start cardGold p-4'>
                        <h4 className='card-text'>
                            Les besoins de base de l'Idéaliste sont d'avoir un <b>but</b> à atteindre et de travailler à améliorer les choses.
                        </h4>
                        <h4 className='card-text'>
                            Les Idéaliste ont besoin d'avoir un <b>sens</b> unique de l'identité. Ils valorisent <b>l'union</b> et <b>l'authenticité</b>.
                        </h4>
                        <h4 className='card-text'>
                            Les Idéalistes préfèrent les intéractions coopératives centrées sur l'éthique et la moralité.
                        </h4>
                        <h4 className='card-text'>
                            Ils ont tendance à être doués pour <b>unifier</b> des personnes différentes et à <b>aider</b> les individus à réaliser leur potentiel.
                        </h4>
                        <h4 className='card-text'>
                            Ils construisent des ponts entre les gens par <b>l'empathie</b> et la <b>clarification</b> de questions plus profondes.
                        </h4>
                    </div>
                </div>
            </div>
            <div className='row d-flex justify-content-center mt-5'>
                <div className='col-12 col-md-8 align-items-center'>
                    <h4 className='RobotoLight text-start orange mb-3'>Les Rationnels: l'habilité Stratégique <span className="text-white "><b>NT</b></span></h4>
                    <div className='RobotoLight text-start cardGold p-4'>
                        <h4 className='card-text'>
                            Les besoins de base du Rationnel sont la <b>maîtrise</b> de concepts, de connaissances, et de compétences.
                        </h4>
                        <h4 className='card-text'>
                            Il veut <b>comprendre</b> les principes de l'univers et apprendre ou même développer des théories pour tout.
                        </h4>
                        <h4 className='card-text'>
                            Ils valorisent <b>l'expertise</b>, la consistance logique, les concepts et les idées, et cherchent le progrès.
                            la recherche, <b>l'analyse</b>, la quête de modèles et le développement d'hypothèses seront probablement leur façon d'opérer naturelle.
                        </h4>
                    </div>
                </div>
            </div>
            <div className='row d-flex justify-content-center mt-5'>
                <div className='col-12 col-md-6 mb-5 mt-4'>
                    {form}
                </div>
            </div>
            <div className='row d-flex justify-content-center gy-3 mt-5 mb-5'>
                <button type='button' className='signupBtn' onClick={saveClickedHandler} disabled={!valid}>
                    <span className='signupBtnSpan roboto'>Enregistrer</span>
                </button>
            </div>
            <Footer/>
        </div>
    )
};

const mapStateToProps = state => ({
    user: state.auth.user,
    isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps, {saveTemperament, load_user_details}) (Temperament);