import React, { useState, useEffect, useRef, forwardRef } from 'react';
import {Link} from 'react-router-dom';
import { connect } from 'react-redux';
import axiosTrainings from'../../config/axios_trainings';
import Calendar from 'react-calendar';
/*import 'react-calendar/dist/Calendar.css';*/
import anime from "animejs/lib/anime.es.js"

import routes from '../../config/routes';
import { logout } from '../../store/actions/auth';

import Footer from '../Footer/Footer';
import Logout from './home_picts/onOff.png';
import Logo from './home_picts/logo-d3s.png';
import Peoples from './home_picts/D3sWeb.jpeg';
import Mongolie from './home_picts/deviens_qui_tu_es.jpg';


function Home({logout, user}) {

    // STATES

    // Retrieved Objects Arrays
    const [trainings, setTrainings] = useState ([]);
    const [tools, setTools] = useState ([]);
    const [partners, setPartners] = useState ([]);

    // Cards Sliders Widths
    const [sliders, setSliders] = useState ([]);
    const [toolsSliders, setToolsSliders] = useState ([]);
    const [partnersSliders, setPartnersSliders] = useState ([]);

    // Cards Sliders X Positions
    const [translateXTrainings, setTranslateXTrainings] = useState (0);
    const [translateXTools, setTranslateXTools] = useState (0);

    // Screen Width Used To Modify Sliders Behavior During Development
    // Set To window.innerWidth In Production
    const [screenWidth, setScreenWidth] = useState (window.innerWidth);


    //COMPONENT DID MOUNT
    useEffect(() =>{

        // Trainings Objects Recovery
        const axiosFetch = async () => {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
            };
            try {
                const res = await axiosTrainings.get('public');
                const newTrainings = [];
                let active_training = 0;
                for(let key in res.data){
                    if (res.data[key].is_active) {active_training += 1};
                    newTrainings.push({
                        ...res.data[key],
                        id: key
                    });
                };
                setTrainings(newTrainings);

                // Setting Trainings Cards Slider Width
                if (screenWidth > 799) {
                    setSliders(active_training * (screenWidth/2))
                }else {
                    setSliders(screenWidth)
                }

            }
            catch(err){
                console.log('Axios error trainings.objects.all')
            }
        };

        // Tools Objects Recovery
        const axiosFetchTools = async () => {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
            };
            try {
                const res = await axiosTrainings.get('tools');
                const newTools = [];
                for(let key in res.data){
                    newTools.push({
                        ...res.data[key],
                        id: key
                    });
                };
                setTools(newTools);

                // Setting Tools Cards Slider Width
                if (screenWidth > 799) {
                    setToolsSliders(newTools.length * (screenWidth / 2.2))
                }else {
                    setToolsSliders(screenWidth)
                }
            }
            catch(err){
                console.log('Axios error tools.objects.all')
            }
        };

        // Partners Objects Recovery
        const axiosFetchPartners = async () => {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
            };
            try {
                const res = await axiosTrainings.get('partners');
                const newPartners = [];
                for(let key in res.data){
                    newPartners.push({
                        ...res.data[key],
                        id: key
                    });
                };
                setPartners(newPartners);

                // Setting Partners Cards Slider Width
                setPartnersSliders(newPartners.length * (screenWidth / 3))
            }
            catch(err){
                console.log('Axios error partners.objects.all')
            }
        };
        axiosFetch();
        axiosFetchTools();
        axiosFetchPartners();
    }, []);

    // Refs For Trainings Objects
    let modal = useRef(null);
    function getMap() {
        if (!modal.current) {
            // First Use
            modal.current = new Map();
        }
        return modal.current;
    }

    // Trainings Modals Functions
    const openTrainingModal = (index) => {
        document.body.style.overflow = 'hidden';
        const map = getMap()
        const node = map.get(index)
        node.style.display = 'block';
        node.style.opacity = 0.95;
    };
    const closeTrainingModal = (index) => {
        document.body.style.overflowX = 'hidden';
        document.body.style.overflowY = 'auto';
        const map = getMap()
        const node = map.get(index)
        node.style.display = 'none';
    };

    // Map Trainings Objects To Generate Cards
    const getTrainings = trainings.map((training, index) => {
        if (training.is_active) {
            return(
                <div key={index}>
                    <button type="button" className="trainingCard mt-2 mb-2 col-10 col-lg-3 col-xl-4 me-3 me-md-5" onClick={() => openTrainingModal(index)} data-aos="flip-left" data-aos-delay="300" data-aos-duration="1000">
                        <img src={training.thumbnail} className="trainingCard-img img-fluid" alt='formation image'/>
                        <h1 className="card-title MontserratBoldItalic or mt-2 mb-2">{training.name}</h1>
                    </button>
                </div>
            )
        }
    });

    // Map Trainings Objects To Generate Modals With Corresponding Card's Index
    const getModalTrainings = trainings.map((training, index) => {

        // Calendar Parameters
        const dateRange = [training.start_date, training.end_date];
        const selectedRange = true;

        return (
            <div key={index}>
                <section className="modal fade" id={training.name} ref={(node) => {const map = getMap(); if (node) {map.set(index, node);} else {map.delete(index)}}} tabIndex="-1" role="dialog" aria-labelledby="TrainingModal" aria-hidden="true">
                    <div className="modal-dialog  modal-dialog-centered modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header d-flex justify-content-center">
                                <h5 className="modal-title fs-1 MontserratBlack text-white text-center mt-5" id="ModalTitle">{training.name}</h5>
                            </div>
                            <div className="modal-body">
                                <img src={training.thumbnail} className="trainingCard-img img-fluid" alt='formation image'/>
                                <div className="row d-flex justify-content-center mt-2">
                                    <button type="button" className="smartBtn MontserratBlack purple mt-2" data-dismiss="modal" aria-label="Close" onClick={() => closeTrainingModal(index)}>
                                        <span aria-hidden="true">Fermer</span>
                                    </button>
                                </div>
                                <p className="Roboto text-white mt-5 fs-2">{training.description}</p>
                                <div className="d-flex justify-content-center">
                                    <Calendar selectRange={selectedRange} value={dateRange} view="month" className="calendar mt-5"/>
                                </div>
                            </div>
                            <div className="modal-footer">
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    });

    // Refs For Tools Objects
    let toolModal = useRef(null);
    function getToolMap() {
        if (!toolModal.current) {
            // First Use
            toolModal.current = new Map();
        }
        return toolModal.current;
    };
    const animatedModal = useRef(null)

    // Tools Modals Functions
    const openModal = (index) => {
        document.body.style.overflow = 'hidden';
        const map = getToolMap()
        const node = map.get(index)
        node.style.display = 'block';
        node.style.opacity = 1;
        };
    const closeModal = (index) => {
        document.body.style.overflowX = 'hidden';
        document.body.style.overflowY = 'auto';
        const map = getToolMap()
        const node = map.get(index)
        node.style.display = 'none';
    };

    // Map Tools Objects To Generate Cards
    const getTools = tools.map((tool, index) => {

        if (tool.name !== "A propos" ){
            return(
                <div key={index}>
                    <button type="button" className="toolCard mt-5 col-10 col-lg-3 col-xl-4 me-3 me-md-5" onClick={() => openModal(index)} data-aos="flip-left" data-aos-delay="300" data-aos-duration="1000">
                        <img src={tool.thumbnail} className="trainingCard-img" alt='formation image'/>
                        <h1 className="card-title MontserratBoldItalic or mt-2 mb-2">{tool.name}</h1>
                    </button>
                </div>
           )
        }
    });

    // Map Tools Objects To Generate Modals With Corresponding Card's Index
    const getModalTools = tools.map((tool, index) => {

        // Used For Formatting Text. Html Tags Directly Included In The Text Field.
        const htmlContent = { __html: tool.description };

        return (
            <div key={index}>
                <div className="modal" id={tool.name} ref={(node) => {const map = getToolMap(); if (node) {map.set(index, node);} else {map.delete(index)}}} tabIndex="-1" role="dialog" aria-labelledby="TrainingModal" aria-hidden="true">
                    <div className="modal-dialog modal-lg" role="document" id='child'>
                        <div className="modal-content">
                            <div className="modal-header d-flex justify-content-center">
                                <h5 className="modal-title fs-1 MontserratBlack text-white text-center" id="ModalTitle">{tool.name}</h5>
                            </div>
                            <div className="modal-body px-5">
                                <img src={tool.thumbnail} className="trainingCard-img img-fluid" alt='formation image'/>
                                <div className="row d-flex justify-content-center mt-2">
                                    <button type="button" className="smartBtn MontserratBlack purple mt-2" data-dismiss="modal" aria-label="Close" onClick={() => closeModal(index)}>
                                        <span aria-hidden="true">Fermer</span>
                                    </button>
                                </div>
                                <p className="Roboto text-white mt-5 fs-2 text-start innerHTML" dangerouslySetInnerHTML={htmlContent} />
                            </div>
                            <div className="modal-footer">

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    })

    // Map Partners Objects To Generate Cards
    const getPartners = partners.map((tool, index) => {
        return(
            <div key={index}>
                <div className="partnersCard mt-2 mb-2 col-10 col-lg-3 col-xl-4 me-3 me-md-5" data-aos="flip-left" data-aos-delay="300" data-aos-duration="1000">
                    <img src={tool.thumbnail} className="partners-img img-fluid" alt='Partners image'/>
                </div>
            </div>
           )
    });

    // Map Tools Objects To Retrieve tools.name = "A Propos"
    const getAbout = tools.map((tool, index) => {

        // Modals Functions
        const openModal = (index) => {
            document.body.style.overflow = 'hidden';
            const map = getToolMap()
            const node = map.get(index)
            node.style.display = 'block';
            node.style.opacity = 0.95;
        };
        const closeModal = (index) => {
            document.body.style.overflowX = 'hidden';
            document.body.style.overflowY = 'auto';
            const map = getToolMap()
            const node = map.get(index)
            node.style.display = 'none';
        };

        // Used For Formatting Text. Html Tags Directly Included In The Text Field.
        const htmlContent = { __html: tool.description };

        if (tool.name === "A propos"){
            return(
            <div key={index}>
                <button type="button" className="portraitCard mt-5 col-10 col-lg-3 col-xl-4 me-0 me-md-5 p-3" onClick={() => openModal(index)} data-aos="zoom-in-up" data-aos-duration="1000">
                    <img src={tool.thumbnail} className="trainingCard-img img-fluid" alt='formation image'/>
                </button>
                <div className="modal" id={tool.name} ref={(node) => {const map = getToolMap(); if (node) {map.set(index, node);} else {map.delete(index)}}} tabIndex="-1" role="dialog" aria-labelledby="TrainingModal" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header d-flex justify-content-center">
                                <h5 className="modal-title fs-1 MontserratBlack text-white text-center" id="ModalTitle">{tool.name}</h5>
                            </div>
                            <div className="modal-body px-5">
                                <img src={tool.thumbnail} className="trainingCard-img img-fluid" alt='formation image'/>
                                <div className="row d-flex justify-content-center mt-2">
                                    <button type="button" className="smartBtn MontserratBlack purple mt-2" data-dismiss="modal" aria-label="Close" onClick={() => closeModal(index)}>
                                        <span aria-hidden="true">Fermer</span>
                                    </button>
                                </div>
                                <p className="Roboto text-white mt-5 fs-2 text-start innerHTML" dangerouslySetInnerHTML={htmlContent} />
                            </div>
                            <div className="modal-footer">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
           )
        }
    });


    // SWIP TRAINING CONTAINER
    const trainingSwip = () => {
        let swipTrainings = document.getElementById('trainingItems');
        let initialX = null;
        let currentX = null;
        let isSwiping = false;

        swipTrainings.addEventListener('touchstart', function(e) {
            initialX = e.touches[0].clientX;
            isSwiping = true;
        });

        swipTrainings.addEventListener('touchmove', function(e) {
            if (!isSwiping) return;

            currentX = e.touches[0].clientX;
            let deltaX = currentX - initialX;

            // Position Upgrade
            swipTrainings.style.left = deltaX + 'px';
        });

        swipTrainings.addEventListener('touchend', function() {
            if (!isSwiping) return;

            // Values Reset
            initialX = null;
            currentX = null;
            isSwiping = false;
        });
    }

    // Displaying Of Logout Icon For Authenticated Users
    /*
    const authenticated = () => (
        <div className="d-flex justify-content-center mt-5">
            <div className="col-10 col-md-6 text-end mt-5">
                <img src={Logout} type='button' onClick={logout} className='logout' alt="logout" />
            </div>
        </div>
    );
    */

    // Displaying Of Connection Button For Anonym Users
    const anonym = () => (
        <div className="d-flex justify-content-center mt-5">
            <div className="col-10 mt-5" data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1500">
                <Link to={routes.LOGIN}
                    type="button"
                    className="text-decoration-none col-12 smartBtn connectButton">
                    <div className="col-12">
                        <h1 className='text-white MontserratBlack connectLink'>Connectez-vous !!!</h1>
                    </div>
                </Link>
            </div>
        </div>
    );

    // MONGOLIA DOWNLOADABLE DOCUMENT
    const MONGOLIA_URL = "https://myd3s-static.s3.ap-southeast-2.amazonaws.com/photo/deviens-qui-tu-es-2024.pdf"

    // ANIMATIONS WITH ANIM JS

    // Refs Used For Animations
    const animatedTools = useRef(null)
    const animatedTrainingCards = useRef(null)
    const animation = useRef(null);

    // Title Animation
    useEffect(() => {
        animation.current = anime.timeline({loop: false})
        .add({
            targets: '.ml1 .letter',
            scale: [0.3,1],
            opacity: [0,1],
            translateZ: 0,
            easing: "easeOutExpo",
            duration: 600,
            delay: (el, i) => 700 * (i+1)
        }).add({
            targets: '.ml1 .line',
            scaleX: [0,1],
            opacity: [0.5,1],
            easing: "easeOutExpo",
            duration: 700,
            offset: '-=875',
            delay: (el, i, l) => 80 * (l - i)
        }).add({
            targets: '.ml1',
            opacity: 0,
            duration: 1000,
            easing: "easeOutExpo",
            delay: 2000
        }).add({
            targets: '.d3s_header',
            opacity: [0, 0.8],
            scale: [0.3,1],
            duration: 2500,
            easing: "easeOutExpo",
            delay: 0
        });
    },[] );

    // Trainings Cards Translation To The Right
    const XNegTranslation = () => {
        let translate;
        if (translateXTrainings != 0) {
            if ((translateXTrainings + screenWidth) < 0) {
                translate = (translateXTrainings + screenWidth)
                setTranslateXTrainings(translateXTrainings + screenWidth)
            } else {
                translate = 0
                setTranslateXTrainings(0)
            }
        }
        animatedTools.current = anime({
                    targets: '.training-items',
                    translateX: translate,
                    loop: false,
                    easing: 'easeInOutSine'
        });
    };

    // Trainings Cards Translation To The Left
    const XPosTranslation = () => {
        let translate;
        if ((translateXTrainings - screenWidth) > -(sliders)) {
            if (translateXTrainings == 0) {
                translate = -(screenWidth)
            } else {
                translate = translateXTrainings - screenWidth
            };
            setTranslateXTrainings(prev => prev - screenWidth)
        } else {
            translate = -(sliders - screenWidth)
            setTranslateXTrainings(-(sliders - screenWidth))
        }
        animatedTools.current = anime({
                    targets: '.training-items',
                    translateX: translate,
                    loop: false,
                    easing: 'easeInOutSine'
        });
    }

    // Tools Cards Translation To The Right
    const ToolXNegTranslation = () => {
        let translate;
        if (translateXTools != 0) {
            if ((translateXTools + screenWidth) < 0) {
                translate = (translateXTools + screenWidth)
                setTranslateXTools(translateXTools + screenWidth)
            } else {
                translate = 0
                setTranslateXTools(0)
            }
        }
        animatedTools.current = anime({
                    targets: '.tools-items',
                    translateX: translate,
                    loop: false,
                    easing: 'easeInOutSine'
        });
    };

    // Tools Cards Translation To The Left
    const ToolXPosTranslation = () => {
        let translate;
        if ((translateXTools - screenWidth) > -(toolsSliders)) {
            if (translateXTools == 0) {
                translate = -(screenWidth)
            } else {
                translate = translateXTools - screenWidth
            };
            setTranslateXTools(prev => prev - screenWidth)
        } else {
            translate = -(toolsSliders - screenWidth)
            setTranslateXTools(-(toolsSliders - screenWidth))
        }
        animatedTools.current = anime({
                    targets: '.tools-items',
                    translateX: translate,
                    loop: false,
                    easing: 'easeInOutSine'
        });
    }

    // MAIN RETURN
    return (
        <>
        <section className='container-fluid p-0'>
            <img src="https://myd3s-static.s3.ap-southeast-2.amazonaws.com/photo/D3S_HEADER_16_9.jpeg" className="d3s_header"/>
            <div className='row d-flex justify-content-end screenHeight10'>
            </div>
            <h1 className="ml1">
                <span className="text-wrapper">
                <span className="line line1"></span>
                <span className="letters">
                    <span className ='letter'>M</span>
                    <span className ='letter'>y</span>
                    <span className ='letter'>D</span>
                    <span className ='letter'>3</span>
                    <span className ='letter'>S</span>
                </span>
                <span className="line line2"></span>
                </span>
            </h1>
        </section>
        <div className="container-fluid header_container">
            <h1 className="MontserratBlack text-white section-title" data-aos="zoom-in-up">PARTICIPEZ A NOS FORMATIONS...</h1>
            <h1 className="MontserratBoldItalic mb-5 purple">Agenda</h1>
            <div className='row'>
                {getModalTrainings}
            </div>
            <div className="training-container">
                <div className="training-items" id="trainingItems" style={{width: `${sliders}px`}}>
                    {getTrainings}
                </div>
                <button type="button" className="training-container__prev" onClick={XNegTranslation}/>
                <button type="button" className="training-container__next" onClick={XPosTranslation}/>
            </div>
            <div className="row d-flex justify-content-center presentation-row">
                <a href="https://myd3s-static.s3.ap-southeast-2.amazonaws.com/photo/deviens-qui-tu-es-2024.pdf" className="mt-5 text-decoration-none mongolia" type="button" data-aos="flip-left" data-aos-duration="1000" data-aos-delay="300">
                    <h3 className="MontserratItalic purple mt-5">Cliquez pour obtenir le programme</h3>
                    <img src={Mongolie} className="img img-fluid p-0 mt-5 classroom screenHeight80" alt="image of land"/>
                </a>
            </div>
            <div className='row d-flex justify-content-center screenMinHeight20 mt-5 mb-5'>
                {user ? "" : anonym()}
            </div>
            <div className="row d-flex justify-content-center gx-5 align-items-center px-3 mb-5">
                <div className="col-10 col-xl-5" data-aos="fade-right" data-aos-duration="1000" data-aos-delay="300">
                    <img src="https://myd3s-static.s3.ap-southeast-2.amazonaws.com/photo/SALLE_ATTENTE.jpeg" className="img img-fluid w-100 p-0 mt-5 waiting-room" alt="image of peoples"/>
                    <h3 className="MontserratItalic purple mt-5">"Venez partager de bons moments tout en apprenant."</h3>
                </div>
                <div className="col-10 col-xl-5" data-aos="fade-left" data-aos-duration="1000" data-aos-delay="300">
                    <img src="https://myd3s-static.s3.ap-southeast-2.amazonaws.com/photo/D3S_TAPIS_2.jpg" className="img img-fluid w-100 p-0 mt-5 classroom" alt="image of peoples"/>
                </div>
            </div>
            <div className="row d-sm-flex justify-content-center align-items-center px-3 mb-5 mt-5">
                <h1 className="MontserratBlack text-white section-title baseSection" data-aos="zoom-in-up">Connaissez-vous votre profil de personnalité ?</h1>
                <img src={Peoples} className="img img-fluid p-0 mt-5 classroom screenHeight80" alt="image of peoples" data-aos="zoom-in" data-aos-duration="1000" data-aos-delay="300"/>
            </div>
            <div className="row d-flex justify-content-center mt-5">
                <h1 className="MontserratBlack text-white section-title baseSection" data-aos="zoom-in-up">DECOUVREZ NOS OUTILS...</h1>
                <h1 className="MontserratBoldItalic mt-5 mb-5 purple">Nos Outils & Ateliers...</h1>
            </div>
            <div className='row'>
                {getModalTools}
            </div>
            <div className="training-container">
                <div className="tools-items mb-5" style={{width: `${toolsSliders}px`}}>
                    {getTools}
                </div>
                <button type="button" className="training-container__prev" onClick={ToolXNegTranslation}/>
                <button type="button" className="training-container__next" onClick={ToolXPosTranslation}/>
            </div>
            <div className="row d-flex justify-content-center partnersTitle">
                <h1 className="MontserratBlack text-white section-title baseSection" data-aos="zoom-in-up">ILS NOUS FONT CONFIANCE!</h1>
            </div>
            <div className="partners-container">
                <div className="partners-items" style={{width: `${partnersSliders}px`}}>
                    {getPartners}
                </div>
            </div>
            <div className="row d-flex justify-content-center portraitContainer">
                <h1 className="MontserratBlack text-white section-title baseSection" data-aos="zoom-in-up">SANDRA PARENT</h1>
                <div className="col-10 col-xl-4 mb-5 p-0">
                    {getAbout}
                </div>
                <h1 className="MontserratBlack text-white section-title baseSection" data-aos="zoom-in-up">REJOIGNEZ-NOUS!</h1>
                <h1 className="MontserratBoldItalic mt-5 mb-5 purple">Où nous trouver...</h1>
                <div className="col-10">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1268.206634812349!2d166.4536201422115!3d-22.28398165012233!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6c27e379a2c526db%3A0xa88ea1f263bbbbb3!2sD3S%20SARL!5e0!3m2!1sfr!2sfr!4v1698364142647!5m2!1sfr!2sfr"
                    className="googleIframe"
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"/>
                </div>
            </div>
            <div className="row d-flex justify-content-center mt-5 pt-5">
                <div className="col-10 cardPurple Montserrat text-white p-4">
                    <h4 className="">Pour toutes demandes, n’hésitez pas à me contacter en direct, par email de préférence à </h4>
                    <h3 className="MontserratBold orange spEmail">d3savoirs@gmail.com </h3>
                    <h4 className="">Afin de m’expliquer vos besoins et attentes. </h4>
                    <h4 className="">Je ne fais pas de formation type « catalogue » mais que des Formations Professionnelles Continues, des cohésions, ou du coaching individuel ou collectif « sur mesure » adaptées à votre public et votre environnement. </h4>
                    <h4 className="">Je vous fixerai alors un rdv sur place, ou téléphonique, voir en visio, afin de vous faire une proposition adaptée à votre objectif, et votre budget.</h4>
                    <h4 className="">Je peux me déplacer, sur la grande terre, les Iles, le Vanuatu ou Tahiti facilement.</h4>
                </div>
            </div>
        </div>
        <Footer/>
        <div className="row d-flex justify-content-center PoppinsLight purple">
            <div className="col-6 col-sm-4 text-center text-sm-end">
                <Link to={routes.RGPD} type="button" className="text-decoration-none purple">
                    <p className='PoppinsLight mt-4'>Politique de confidentialité</p>
                </Link>
            </div>
            <div className="col-6 col-sm-4 text-center text-sm-start">
                <Link to={routes.LEGALNOTICE} type="button" className="text-decoration-none purple">
                    <p className='PoppinsLight mt-4'>Mentions Légales</p>
                </Link>
            </div>
        </div>
        <div className="row d-flex justify-content-center">
            <a href="https://www.intuitive-conception.com">
                <img src="https://myd3s-static.s3.ap-southeast-2.amazonaws.com/ic-logo.png" className="img img-fluid iclogo"/>
            </a>
            <p className="purple"> Icônes conçues par <a href="https://www.flaticon.com/fr/auteurs/roundicons" title="Roundicons" className="text-decoration-none"> Roundicons </a> from <a href="https://www.flaticon.com/fr/" title="Flaticon" className="text-decoration-none">www.flaticon.com</a></p>
        </div>
        </>
    )
}

const mapStateToProps = state => ({
    user: state.auth.user
});

export default connect(mapStateToProps, { logout }) (Home);