//Library
import React, { useState } from 'react';
import { useNavigate, Link, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { checkValidity } from '../../../shared/utility';
import { reset_password_confirm } from '../../../store/actions/auth';
import routes from '../../../config/routes';

//Components
import Input from '../../../components/UI/Input/Input';

function ResetPasswordConfirm({reset_password_confirm}) {

    //State
    const [inputs, setInputs] = useState({
        new_password: {
            elementType: 'input',
            elementConfig: {
                type: 'password',
                placeholder: 'Nouveau mot de passe'
            },
            value: '',
            label: '',
            valid: false,
            validation: {
                required: true,
                minLength: 9
            },
            touched: false,
            errorMessage: "Le mot de passe doit contenir 9 caractères au minimum"
        },
        re_new_password: {
            elementType: 'input',
            elementConfig: {
                type: 'password',
                placeholder: 'Confirmez votre mot de passe'
            },
            value: '',
            label: '',
            valid: false,
            validation: {
                required: true,
                minLength: 9,
                password:''
            },
            touched: false,
            errorMessage: "Les mots de passe doivent être semblables"
        }

    });

    const [valid, setValid] = useState (false);

    const [requestSent, setRequestSent] = useState(false);

    //Functions

    const InputChangedHandler = (event, id) => {

        //Change Value
        const newInputs = {...inputs};
        newInputs[id].value = event.target.value;
        newInputs[id].touched = true;

        //Check value
        inputs.re_new_password.validation.password = inputs.new_password.value;
        newInputs[id].valid = checkValidity(event.target.value, newInputs[id].validation)

        setInputs(newInputs);

        //Form checking
        let formIsValid = true;
        for (let input in newInputs) {
            formIsValid = newInputs[input].valid && formIsValid;
        }
        setValid(formIsValid);
    };

    const formHandler = event => {
        event.preventDefault();
    }

    //Redirect
    const navigate = useNavigate();

    //URL match data
    const match = useParams() ;

    //Confirm new password
    const ConfirmClickedHandler = () => {

        const reset = {
            new_password: inputs.new_password.value,
            re_new_password: inputs.re_new_password.value,
        };

        reset_password_confirm(match.uid, match.token, reset.new_password, reset.re_new_password);
        setRequestSent(true);
        navigate(routes.HOME);
    }

    //Variables

    const formElementArray = [];
    for(let key in inputs) {
        formElementArray.push({
            id: key,
            config: inputs[key]
        });
    };

    let form = (
        <form onSubmit={(e) => formHandler(e)}>
            {formElementArray.map(formElement => (
                <Input
                    key={formElement.id}
                    id={formElement.id}
                    value={formElement.config.value}
                    label={formElement.config.label}
                    type={formElement.config.elementType}
                    config={formElement.config.elementConfig}
                    valid={formElement.config.valid}
                    touched={formElement.config.touched}
                    errorMessage={formElement.config.errorMessage}
                    changed={(e) => InputChangedHandler(e, formElement.id)}
                />
            ))}
            <div className='row d-flex justify-content-center gy-3'>
                <button type='button' className='signupBtn' onClick={ConfirmClickedHandler} disabled={!valid}>
                    <span className="signupBtnSpan">Valider</span>
                </button>
            </div>
        </form>
    );


    return(
        <div className='container-fluid screenHeight100'>
            <div className='row d-flex justify-content-center my-5'>
            <h1 className='text-center mt-5 PoppinsLight or'>Confirmation de votre nouveau mot de passe</h1>
                <div className='col-10 col-md-6 mt-5'>
                    {form}
                </div>
                <div className='row d-flex justify-content-center mt-5'>
                    <div className='col-10 col-md-6'>
                        <h3 className='text-center PoppinsLight or'>Pas encore inscrit ?</h3>
                        <div className="row d-flex justify-content-center">
                            <Link className='signupBtn' to={routes.SIGNUP} >
                                <span className="signupBtnSpan">S'inscrire</span>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};


export default connect(null, { reset_password_confirm }) (ResetPasswordConfirm);