import {
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    USER_LOADED_SUCCESS,
    USER_LOADED_FAIL,
    USER_DETAILS_LOADED_SUCCESS,
    USER_DETAILS_LOADED_FAIL,
    AUTHENTICATED_SUCCESS,
    AUTHENTICATED_FAIL,
    PASSWORD_RESET_SUCCESS,
    PASSWORD_RESET_FAIL,
    PASSWORD_RESET_CONFIRM_SUCCESS,
    PASSWORD_RESET_CONFIRM_FAIL,
    SIGNUP_SUCCESS,
    SIGNUP_FAIL,
    ACTIVATION_SUCCESS,
    ACTIVATION_FAIL,
    COMPANY_REGISTER_SUCCESS,
    COMPANY_REGISTER_FAIL,
    PREFERENCES_SAVE_SUCCESS,
    PREFERENCES_SAVE_FAIL,
    AVOIDANCES_SAVE_SUCCESS,
    AVOIDANCES_SAVE_FAIL,
    DESCRIPTIONS_SAVE_SUCCESS,
    DESCRIPTIONS_SAVE_FAIL,
    TRANSACTIONAL_ANALYSIS_SAVE_SUCCESS,
    TRANSACTIONAL_ANALYSIS_SAVE_FAIL,
    LIFE_POSITION_SAVE_SUCCESS,
    LIFE_POSITION_SAVE_FAIL,
    ASSERTIVENESS_SAVE_SUCCESS,
    ASSERTIVENESS_SAVE_FAIL,
    SUBTYPE_SAVE_SUCCESS,
    SUBTYPE_SAVE_FAIL,
    EMOTIONAL_REPERTOIRE_SAVE_SUCCESS,
    EMOTIONAL_REPERTOIRE_SAVE_FAIL,
    MYERS_BRIGGS_SAVE_SUCCESS,
    MYERS_BRIGGS_SAVE_FAIL,
    TEMPERAMENT_SAVE_SUCCESS,
    TEMPERAMENT_SAVE_FAIL,
    VALUES_SAVE_SUCCESS,
    VALUES_SAVE_FAIL,
    ENERGY_MODEL_SAVE_SUCCESS,
    ENERGY_MODEL_SAVE_FAIL,
    ENERGY_KEYWORD_SAVE_SUCCESS,
    ENERGY_KEYWORD_SAVE_FAIL,
    ENERGY_LAW_SAVE_SUCCESS,
    ENERGY_LAW_SAVE_FAIL,
    LEADERSHIP_SAVE_SUCCESS,
    LEADERSHIP_SAVE_FAIL,
    LOGOUT,
    SENT,
} from '../actions/types';

const initialState = {
    access: localStorage.getItem('access'),
    refresh: localStorage.getItem('refresh'),
    isAuthenticated: null,
    user: null,
    sent: null
};

export default function (state=initialState, action) {
    const { type, payload } = action;

    switch(type) {
        case AUTHENTICATED_SUCCESS:
            return {
                ...state,
                isAuthenticated: true
            }
        case LOGIN_SUCCESS:
            localStorage.setItem('access', payload.access);
            localStorage.setItem('authenticated', true);
            return {
                ...state,
                isAuthenticated: true,
                access: payload.access,
                refresh: payload.refresh
            }
        case SIGNUP_SUCCESS:
            return {
                ...state,
                isAuthenticated: false
            }
        case USER_LOADED_SUCCESS:
            return {
                ...state,
                user: payload,
            }
        case USER_DETAILS_LOADED_SUCCESS:
            localStorage.setItem('commitment', payload.commitment)
            localStorage.setItem('userLoaded', true)
            return {
                ...state,
                user: payload,
            }
        case AUTHENTICATED_FAIL:
            localStorage.removeItem('authenticated');
            return {
                ...state,
                isAuthenticated: false
            }
        case USER_LOADED_FAIL:
            localStorage.clear();
            return {
                ...state,
                user: null
            }
        case LOGIN_FAIL:
        case SIGNUP_FAIL:
        case LOGOUT:
            localStorage.clear();
            return {
                ...state,
                access: null,
                refresh: null,
                isAuthenticated: false,
                user: null
            }
        case SENT:
            return {
                ...state,
                sent: true
            }
        case PASSWORD_RESET_SUCCESS:
        case PASSWORD_RESET_FAIL:
        case PASSWORD_RESET_CONFIRM_SUCCESS:
        case PASSWORD_RESET_CONFIRM_FAIL:
        case ACTIVATION_SUCCESS:
        case ACTIVATION_FAIL:
        case USER_DETAILS_LOADED_FAIL:
        case COMPANY_REGISTER_SUCCESS:
        case COMPANY_REGISTER_FAIL:
        case PREFERENCES_SAVE_SUCCESS:
        case PREFERENCES_SAVE_FAIL:
        case AVOIDANCES_SAVE_SUCCESS:
        case AVOIDANCES_SAVE_FAIL:
        case DESCRIPTIONS_SAVE_SUCCESS:
        case DESCRIPTIONS_SAVE_FAIL:
        case TRANSACTIONAL_ANALYSIS_SAVE_SUCCESS:
        case TRANSACTIONAL_ANALYSIS_SAVE_FAIL:
        case LIFE_POSITION_SAVE_SUCCESS:
        case LIFE_POSITION_SAVE_FAIL:
        case ASSERTIVENESS_SAVE_SUCCESS:
        case ASSERTIVENESS_SAVE_FAIL:
        case SUBTYPE_SAVE_SUCCESS:
        case SUBTYPE_SAVE_FAIL:
        case EMOTIONAL_REPERTOIRE_SAVE_SUCCESS:
        case EMOTIONAL_REPERTOIRE_SAVE_FAIL:
        case MYERS_BRIGGS_SAVE_SUCCESS:
        case MYERS_BRIGGS_SAVE_FAIL:
        case TEMPERAMENT_SAVE_SUCCESS:
        case TEMPERAMENT_SAVE_FAIL:
        case VALUES_SAVE_SUCCESS:
        case VALUES_SAVE_FAIL:
        case ENERGY_MODEL_SAVE_SUCCESS:
        case ENERGY_MODEL_SAVE_FAIL:
        case ENERGY_KEYWORD_SAVE_SUCCESS:
        case ENERGY_KEYWORD_SAVE_FAIL:
        case ENERGY_LAW_SAVE_SUCCESS:
        case ENERGY_LAW_SAVE_FAIL:
        case LEADERSHIP_SAVE_SUCCESS:
        case LEADERSHIP_SAVE_FAIL:
            return {
                ...state
            }
        default:
            return state
    }
};