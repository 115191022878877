import React from 'react';
import Navigation from './Navigation/Navigation';


function Header() {
    return (
            <header>
                <div className='row screenHeight15 nav-container'>
                    <Navigation/>
                </div>
            </header>
    );
};

export default Header;