import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { S3Client, PutObjectCommand } from "@aws-sdk/client-s3";
import axios from '../../config/axios_instances';
import axiosAccounts from '../../config/axios_accounts';
import axiosQuiz from '../../config/axios_quiz';
import axiosTrainings from '../../config/axios_trainings';


import {
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    USER_LOADED_SUCCESS,
    USER_LOADED_FAIL,
    USER_DETAILS_LOADED_SUCCESS,
    USER_DETAILS_LOADED_FAIL,
    AUTHENTICATED_SUCCESS,
    AUTHENTICATED_FAIL,
    PASSWORD_RESET_SUCCESS,
    PASSWORD_RESET_FAIL,
    PASSWORD_RESET_CONFIRM_SUCCESS,
    PASSWORD_RESET_CONFIRM_FAIL,
    SIGNUP_SUCCESS,
    SIGNUP_FAIL,
    ACTIVATION_SUCCESS,
    ACTIVATION_FAIL,
    COMPANY_REGISTER_SUCCESS,
    COMPANY_REGISTER_FAIL,
    PREFERENCES_SAVE_SUCCESS,
    PREFERENCES_SAVE_FAIL,
    AVOIDANCES_SAVE_SUCCESS,
    AVOIDANCES_SAVE_FAIL,
    DESCRIPTIONS_SAVE_SUCCESS,
    DESCRIPTIONS_SAVE_FAIL,
    TRANSACTIONAL_ANALYSIS_SAVE_SUCCESS,
    TRANSACTIONAL_ANALYSIS_SAVE_FAIL,
    LIFE_POSITION_SAVE_SUCCESS,
    LIFE_POSITION_SAVE_FAIL,
    ASSERTIVENESS_SAVE_SUCCESS,
    ASSERTIVENESS_SAVE_FAIL,
    SUBTYPE_SAVE_SUCCESS,
    SUBTYPE_SAVE_FAIL,
    EMOTIONAL_REPERTOIRE_SAVE_SUCCESS,
    EMOTIONAL_REPERTOIRE_SAVE_FAIL,
    MYERS_BRIGGS_SAVE_SUCCESS,
    MYERS_BRIGGS_SAVE_FAIL,
    TEMPERAMENT_SAVE_SUCCESS,
    TEMPERAMENT_SAVE_FAIL,
    VALUES_SAVE_SUCCESS,
    VALUES_SAVE_FAIL,
    ENERGY_MODEL_SAVE_SUCCESS,
    ENERGY_MODEL_SAVE_FAIL,
    ENERGY_KEYWORD_SAVE_SUCCESS,
    ENERGY_KEYWORD_SAVE_FAIL,
    ENERGY_LAW_SAVE_SUCCESS,
    ENERGY_LAW_SAVE_FAIL,
    LEADERSHIP_SAVE_SUCCESS,
    LEADERSHIP_SAVE_FAIL,
    LOGOUT,
    SENT
} from './types';

//S3 CONFIG
const s3Client = new S3Client({
  region: "ap-southeast-2",
  credentials: {
    accessKeyId: "AKIATIBXZSKX2L5Y7PXP",
    secretAccessKey: "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",
  },
});

//S3 VARIABLES
const bucketName = "myd3s-deployment";
const bucketRegion = "ap-southeast-2"
const folderName = 'curriculum'

// S3 UPLOAD
const uploadFileToS3 = async (file, firstName, lastName) => {
  try {
    const objectKey = `${folderName}/${firstName}_${lastName}_CV`;
    const fileData = await file.arrayBuffer();
    const fileContentType = file.type;

    const uploadParams = {
      Bucket: bucketName,
      Key: objectKey,
      Body: fileData,
      ContentType: fileContentType,
    };

    const uploadCommand = new PutObjectCommand(uploadParams);
    const response = await s3Client.send(uploadCommand);

    const uploadedFileUrl = `https://${bucketName}.s3.${bucketRegion}.amazonaws.com/${objectKey}`;
    return null;
    //return uploadedFileUrl;
  }
  catch (error) {
    console.error("Erreur lors de l'upload du fichier vers S3 : ", error);
    return null;
  }
};

//SLUGIFY (https://mhagemann.medium.com/the-ultimate-way-to-slugify-a-url-string-in-javascript-b8e4a0d849e1)
function slugify(string) {
  const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìıİłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;'
  const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------'
  const p = new RegExp(a.split('').join('|'), 'g')

  return string.toString().toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
    .replace(/&/g, '-and-') // Replace & with 'and'
    .replace(/[^\w\-]+/g, '') // Remove all non-word characters
    .replace(/\-\-+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, '') // Trim - from end of text
}

const showToastError = () => {
        toast.error('Pour des raisons de sécurité, vous devez vous reconnecter !', {
            position: toast.POSITION.TOP_RIGHT
        });
    };

const showToastLogError = () => {
        toast.error('Mot de passe ou utilisateur incorrect !', {
            position: toast.POSITION.TOP_RIGHT
        });
    };

const showToastAccessError = () => {
    toast.error('Votre accès temporaire est révolu. Veuillez refaire une demande de nouveau mot de passe.', {
        position: toast.POSITION.TOP_RIGHT
    });
};

const showToastQuizError = () => {
    toast.error('Votre questionnaire est invalide', {
        position: toast.POSITION.TOP_RIGHT
    });
};

const showToastQuizSuccess = () => {
    toast.success('BRAVO !!!', {
        position: toast.POSITION.TOP_CENTER
    });
};

const showToastLoginInfo = () => {
        toast.info("Vérifiez que vous êtes toujours connecté.", {
            position: toast.POSITION.TOP_RIGHT
        });
    };

const showToastLogout = () => {
        toast.info("Vous êtes déconnecté.", {
            position: toast.POSITION.TOP_RIGHT
        });
    };

const showToastEmail = () => {
        toast('Vos résultats sont transmis !', {
            position: toast.POSITION.TOP_CENTER
        });
    };

const showToastDownLoad = () => {
        toast('Résultats Envoyés !', {
            position: toast.POSITION.TOP_CENTER
        });
    };

const showToastSignup = () => {
        toast('Bienvenue ! (Consultez vos emails pour activer votre compte)', {
            position: toast.POSITION.TOP_CENTER
        });
    };

const showToastSignupError = () => {
        toast.error("Le nom d'utilisateur ou l'adresse email sont déjà enregistrés", {
            position: toast.POSITION.TOP_CENTER
        });
    };

const showToastPasswordReset = () => {
        toast('Un email vous a été envoyé', {
            position: toast.POSITION.TOP_CENTER
        });
    };

const showToastPasswordResetError = () => {
        toast.error("L'adresse email n'est pas enregistrée, avez-vous suivi l'email d'activation reçu lors de votre inscription ?", {
            position: toast.POSITION.TOP_CENTER
        });
    };

const showToastPasswordResetConfirm = () => {
        toast('Votre nouveau mot de passe est enregistré.', {
            position: toast.POSITION.TOP_CENTER
        });
    };

const showToastPasswordResetConfirmError = () => {
        toast.error("Une erreur s'est produite, veuillez réessayer en cliquant de nouveau sur 'Mot de passe oublié'", {
            position: toast.POSITION.TOP_CENTER
        });
    };

// TOKEN AUTHENTICATION
export const checkAuthenticated = () => async dispatch => {
    let accessToken = localStorage.getItem('access');

    if( accessToken ) {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        const body = JSON.stringify({ token: accessToken });
        try {
            const res = await axios.post('jwt/verify/', body, config)
            if (res.data.code !== 'token_not_valid') {
                dispatch({
                    type: AUTHENTICATED_SUCCESS
                })
            }
            else {
                dispatch ({
                    type: AUTHENTICATED_FAIL
                });
            }
        }
        catch (err) {
            dispatch ({
                type: AUTHENTICATED_FAIL
            });
        }
    }
    else {
        dispatch ({
            type: AUTHENTICATED_FAIL
        });
    }
};

//USER INFORMATIONS
export const load_user_details = () => async dispatch => {

    let accessToken = localStorage.getItem('access');


    if ( accessToken ) {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `JWT ${accessToken}`,
                'Accept': 'application/json'
            }
        };

        try{
            const res = await axiosAccounts.get('details/', config);
            localStorage.setItem('userLoaded', false)
            dispatch({
                type: USER_DETAILS_LOADED_SUCCESS,
                payload: res.data
            });
        }
        catch (err) {
            showToastError();
            dispatch({
                type: USER_DETAILS_LOADED_FAIL
            });
        }
    }
    else {
        showToastError()
    }
};

//USER INFORMATION EDIT HIMSELF
export const edit_user_details = (first_name, last_name, birth_date, diploma, occupation, company, recruiter, curriculum, BR, BP, BE, BS, commitment) => async dispatch => {

    let accessToken = localStorage.getItem('access');
    const slugFirstName = slugify(first_name)
    const slugLastName = slugify(last_name)

    if ( accessToken ) {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `JWT ${accessToken}`,
                'Accept': 'application/json'
            }
        };
        const CVUrl = `https://${bucketName}.s3.${bucketRegion}.amazonaws.com/${folderName}/${slugFirstName}_${slugLastName}_CV`;
        const body = JSON.stringify({first_name, last_name, birth_date, diploma, occupation, company, recruiter, CVUrl, BR, BP, BE, BS, commitment});
        try{
            await axiosAccounts.patch('details/', body, config);
            await uploadFileToS3(curriculum, slugFirstName, slugLastName)
            dispatch(load_user_details());
        }
        catch (err) {
            showToastLoginInfo()
        }
    }
    else {
        showToastLoginInfo()
    }
};

//USER PROFILE EDIT ADMIN
export const edit_profile = (email, enneagramme, mbti) => async dispatch => {

    let accessToken = localStorage.getItem('access');
    let user = email

    if ( accessToken ) {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `JWT ${accessToken}`,
                'Accept': 'application/json'
            }
        };
        const body = JSON.stringify({enneagramme, mbti});
        try{
            await axiosAccounts.patch(`details/${user}`, body, config);
        }
        catch (err) {
            showToastLoginInfo();
        }
    }
    else {
        showToastLoginInfo();
    }
};

//USER COMMENT EDIT ADMIN
export const edit_comment = (email, comment) => async dispatch => {

    let accessToken = localStorage.getItem('access');
    let user = email

    if ( accessToken ) {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `JWT ${accessToken}`,
                'Accept': 'application/json'
            }
        };
        const body = JSON.stringify({comment});
        try{
            await axiosAccounts.patch(`details/${user}`, body, config);
        }
        catch (err) {
            showToastLoginInfo();
        }
    }
    else {
        showToastLoginInfo();
    }
};

//USER ACTIVATION ADMIN
export const user_activate = (email, is_active) => async dispatch => {

    let accessToken = localStorage.getItem('access');
    let user = email

    if ( accessToken ) {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `JWT ${accessToken}`,
                'Accept': 'application/json'
            }
        };
        const body = JSON.stringify({is_active});
        try{
            await axiosAccounts.patch(`details/${user}`, body, config);
        }
        catch (err) {
            showToastLoginInfo();
        }
    }
    else {
        showToastLoginInfo();
    }
};

//USER LOAD TO STORE PAYLOAD
export const load_user = () => async dispatch => {

    let accessToken = localStorage.getItem('access');

    if ( accessToken ) {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `JWT ${accessToken}`,
                'Accept': 'application/json'
            }
        };

        try{
            const res = await axios.get('users/me/', config);
            dispatch({
                type: USER_LOADED_SUCCESS,
                payload: res.data
            });
            dispatch(load_user_details());
        }
        catch (err) {
            dispatch({
                type: USER_LOADED_FAIL
            });
        }
    }
    else {
        dispatch({
            type: USER_LOADED_FAIL
        });
    }
};

//LOGIN
export const login = (email, password) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    };

    const body = JSON.stringify({email, password});
    localStorage.setItem('user', email);
    try{
        const res = await axios.post('jwt/create/', body, config);
        dispatch({
            type: LOGIN_SUCCESS,
            payload: res.data
        });
        const loginDate = new Date();
        console.log(loginDate)
        localStorage.setItem('loginDate', loginDate);
        dispatch(load_user());
    }
    catch (err) {
        showToastLogError();
        dispatch({
            type: LOGIN_FAIL
        });
    }
};

// SENDING QUIZ RESULTS
export const sentResults = () => async dispatch => {
    let accessToken = localStorage.getItem('access');

    if ( accessToken ) {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `JWT ${accessToken}`,
                'Accept': 'application/json'
            }
        };
        try{
            await axiosQuiz.get('envoi/', config);
            showToastEmail()
        }
        catch (err) {
            showToastLoginInfo();
        }
    }
};

//FRONT ADMIN DOWNLOAD USER RESULTS
export const downLoadResults = () => async dispatch => {
    let accessToken = localStorage.getItem('access');
    let user = localStorage.getItem('candidate');

    if ( accessToken ) {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `JWT ${accessToken}`,
                'Accept': 'application/json'
            }
        };
        try{
            await axiosQuiz.get(`download/${user}`, config);
            dispatch({
                    type: SENT
                })
            showToastDownLoad();
        }
        catch (err) {
            dispatch({
                    type: SENT
                })
            showToastLoginInfo();
        }
    }
};

//USER CREATE RESULTS MODEL INSTANCE
export const results = (user) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    };

    const body = JSON.stringify({user});
    try{
        await axiosQuiz.post('results/', body, config);
    }
    catch (err) {
        showToastLoginInfo();
    }
};

//USER SIGNUP
export const signup = (email, username, password, re_password, first_name, last_name) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    };
    const body = JSON.stringify({email, username, password, re_password});
    try{
        const res = await axios.post('users/', body, config);
        dispatch({
            type: SIGNUP_SUCCESS,
            payload: res.data
        });
        dispatch(results(email));
        showToastSignup();
    }
    catch (err) {
        dispatch({
            type: SIGNUP_FAIL
        });
        showToastSignupError();
    }
};

//UID & TOKEN VERIFICATION
export const verify = (uid, token) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    };

    const body = JSON.stringify({uid, token});

    try{
        await axios.post('users/activation/', body, config);

        dispatch({
            type: ACTIVATION_SUCCESS,
        });
    }
    catch (err) {
        dispatch({
            type: ACTIVATION_FAIL
        });
    }
};

//EMAIL SENDING FOR PASSWORD RESET (DJOSER SIGNAL)
export const reset_password = (email) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    };

    const body = JSON.stringify({email});

    try{
        await axios.post('users/reset_password/', body, config);
        dispatch({
            type: PASSWORD_RESET_SUCCESS
        });
        showToastPasswordReset();
    }
    catch (err){
        dispatch({
            type: PASSWORD_RESET_FAIL
        });
        showToastPasswordResetError();
    }
};

//NEW PASSWORD
export const reset_password_confirm = (uid, token, new_password, re_new_password) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    };

    const body = JSON.stringify({uid, token, new_password, re_new_password});

    try{
        await axios.post('users/reset_password_confirm/', body, config);
        dispatch({
            type: PASSWORD_RESET_CONFIRM_SUCCESS
        });
        showToastPasswordResetConfirm();
    }
    catch (err){
        showToastAccessError();
        dispatch({
            type: PASSWORD_RESET_CONFIRM_FAIL
        });
        showToastPasswordResetConfirmError();
    }
};

//LOGOUT
export const logout = () => dispatch => {
    dispatch({
        type: LOGOUT
    });
    showToastLogout();
};

//COMPANY SIGNUP
export const company_register = (owner, entreprise, code_client, contact, telephone, user) => async dispatch => {
    let accessToken = localStorage.getItem('access');

    if( accessToken ) {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `JWT ${accessToken}`,
                'Accept': 'application/json'
            }
        };

        const body = JSON.stringify({owner, entreprise, code_client, contact, telephone, user});

        try{
            await axiosAccounts.post('company/', body, config);

            dispatch({
                type: COMPANY_REGISTER_SUCCESS
            });
        }
        catch (err){
            alert('Cette entreprise ou ce code client sont déjà utilisés')
            dispatch({
                type: COMPANY_REGISTER_FAIL
            });
        }
    };
};


//SAVE RESULTS QUIZ/PREFERENCES
export const savePreferences = (user, user_email, preferences_1, preferences_2, preferences_3, preferences_4) => async dispatch => {
    let accessToken = localStorage.getItem('access');
    if( accessToken ) {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `JWT ${accessToken}`,
                'Accept': 'application/json'
            }
        };

        const body = JSON.stringify({user, user_email, preferences_1, preferences_2, preferences_3, preferences_4});

        try{
            await axiosQuiz.post('preferences/', body, config);

            dispatch({
                type: PREFERENCES_SAVE_SUCCESS
            });
            dispatch(load_user_details());
            showToastQuizSuccess();
        }
        catch (err){
            dispatch({
                type: PREFERENCES_SAVE_FAIL
            });
            showToastQuizError();
        }
    };
};

//SAVE RESULTS QUIZ/AVOIDANCES
export const saveAvoidances = (user, user_email, avoidances_1, avoidances_2, avoidances_3, avoidances_4) => async dispatch => {
    let accessToken = localStorage.getItem('access');
    if( accessToken ) {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `JWT ${accessToken}`,
                'Accept': 'application/json'
            }
        };

        const body = JSON.stringify({user, user_email, avoidances_1, avoidances_2, avoidances_3, avoidances_4});

        try{
            await axiosQuiz.post('avoidances/', body, config);

            dispatch({
                type: AVOIDANCES_SAVE_SUCCESS
            });
            dispatch(load_user_details());
            showToastQuizSuccess();
        }
        catch (err){
            dispatch({
                type: AVOIDANCES_SAVE_FAIL
            });
            showToastQuizError();
        }
    };
};

//SAVE RESULTS QUIZ/DESCRIPTIONS
export const saveDescriptions = (user, user_email, descriptions_1, descriptions_2, descriptions_3, descriptions_4) => async dispatch => {
    let accessToken = localStorage.getItem('access');

    if( accessToken ) {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `JWT ${accessToken}`,
                'Accept': 'application/json'
            }
        };

        const body = JSON.stringify({user, user_email, descriptions_1, descriptions_2, descriptions_3, descriptions_4});

        try{
            await axiosQuiz.post('descriptions/', body, config);

            dispatch({
                type: DESCRIPTIONS_SAVE_SUCCESS
            });
            dispatch(load_user_details());
            showToastQuizSuccess();
        }
        catch (err){
            dispatch({
                type: DESCRIPTIONS_SAVE_FAIL
            });
            showToastQuizError();
        }
    };
};

//SAVE RESULTS QUIZ/TRANSACTIONAL ANALYSIS
export const saveTransactionalAnalysis = (
    user, user_email, AT1, AT2, AT3, AT4, AT5, AT6, AT7, AT8, AT9, AT10, AT11, AT12, AT13, AT14, AT15, AT16, AT17, AT18, AT19, AT20, AT21, AT22, AT23, AT24, AT25, AT26, AT27, AT28, AT29, AT30,
    AT31, AT32, AT33, AT34, AT35, AT36, AT37, AT38, AT39, AT40, AT41, AT42, AT43, AT44, AT45, AT46, AT47, AT48, AT49, AT50, AT51, AT52, AT53, AT54, AT55, AT56, AT57, AT58, AT59, AT60
) => async dispatch => {
    let accessToken = localStorage.getItem('access');

    if( accessToken ) {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `JWT ${accessToken}`,
                'Accept': 'application/json'
            }
        };

        const body = JSON.stringify({
            user, user_email, AT1, AT2, AT3, AT4, AT5, AT6, AT7, AT8, AT9, AT10, AT11, AT12, AT13, AT14, AT15, AT16, AT17, AT18, AT19, AT20, AT21, AT22, AT23, AT24, AT25, AT26, AT27, AT28, AT29, AT30,
            AT31, AT32, AT33, AT34, AT35, AT36, AT37, AT38, AT39, AT40, AT41, AT42, AT43, AT44, AT45, AT46, AT47, AT48, AT49, AT50, AT51, AT52, AT53, AT54, AT55, AT56, AT57, AT58, AT59, AT60
        });

        try{
            await axiosQuiz.post('analyse-transactionnelle/', body, config);

            dispatch({
                type: TRANSACTIONAL_ANALYSIS_SAVE_SUCCESS
            });
            dispatch(load_user_details());
            showToastQuizSuccess();
        }
        catch (err){
            dispatch({
                type: TRANSACTIONAL_ANALYSIS_SAVE_FAIL
            });
            showToastQuizError();
        }
    };
};

//SAVE RESULTS QUIZ/LIFE POSITIONS
export const saveLifePosition = (
    user, user_email, PV1, PV2, PV3, PV4, PV5, PV6, PV7, PV8, PV9, PV10, PV11, PV12, PV13, PV14, PV15, PV16, PV17, PV18, PV19, PV20, PV21, PV22, PV23, PV24, PV25, PV26, PV27, PV28, PV29, PV30,
    PV31, PV32
) => async dispatch => {
    let accessToken = localStorage.getItem('access');

    if( accessToken ) {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `JWT ${accessToken}`,
                'Accept': 'application/json'
            }
        };

        const body = JSON.stringify({
            user, user_email, PV1, PV2, PV3, PV4, PV5, PV6, PV7, PV8, PV9, PV10, PV11, PV12, PV13, PV14, PV15, PV16, PV17, PV18, PV19, PV20, PV21, PV22, PV23, PV24, PV25, PV26, PV27, PV28, PV29, PV30,
            PV31, PV32
        });

        try{
            await axiosQuiz.post('position-de-vie/', body, config);

            dispatch({
                type: LIFE_POSITION_SAVE_SUCCESS
            });
            dispatch(load_user_details());
            showToastQuizSuccess();
        }
        catch (err){
            dispatch({
                type: LIFE_POSITION_SAVE_FAIL
            });
            showToastQuizError();
        }
    };
};

//SAVE RESULTS QUIZ/ASSERTIVENESS
export const saveAssertiveness = (
    user, user_email, AS1, AS2, AS3, AS4, AS5, AS6, AS7, AS8, AS9, AS10, AS11, AS12, AS13, AS14, AS15, AS16, AS17, AS18,
     AS19, AS20, AS21, AS22, AS23, AS24, AS25, AS26, AS27, AS28, AS29, AS30,
    AS31, AS32, AS33, AS34, AS35, AS36, AS37, AS38, AS39, AS40
) => async dispatch => {
    let accessToken = localStorage.getItem('access');

    if( accessToken ) {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `JWT ${accessToken}`,
                'Accept': 'application/json'
            }
        };

        const body = JSON.stringify({
            user, user_email, AS1, AS2, AS3, AS4, AS5, AS6, AS7, AS8, AS9, AS10, AS11, AS12, AS13, AS14, AS15, AS16,
            AS17, AS18, AS19, AS20, AS21, AS22, AS23, AS24, AS25, AS26, AS27, AS28, AS29, AS30,
            AS31, AS32, AS33, AS34, AS35, AS36, AS37, AS38, AS39, AS40
        });

        try{
            await axiosQuiz.post('assertivite/', body, config);

            dispatch({
                type: ASSERTIVENESS_SAVE_SUCCESS
            });
            dispatch(load_user_details());
            showToastQuizSuccess();
        }
        catch (err){
            dispatch({
                type: ASSERTIVENESS_SAVE_FAIL
            });
            showToastQuizError();
        }
    };
};

//SAVE RESULTS QUIZ/SUB TYPES
export const saveSubType = (
    user, user_email, ST1, ST2, ST3, ST4, ST5, ST6, ST7, ST8, ST9
) => async dispatch => {
    let accessToken = localStorage.getItem('access');

    if( accessToken ) {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `JWT ${accessToken}`,
                'Accept': 'application/json'
            }
        };

        const body = JSON.stringify({
            user, user_email, ST1, ST2, ST3, ST4, ST5, ST6, ST7, ST8, ST9
        });

        try{
            await axiosQuiz.post('sous-types/', body, config);

            dispatch({
                type: SUBTYPE_SAVE_SUCCESS
            });
            dispatch(load_user_details());
            showToastQuizSuccess();
        }
        catch (err){
            dispatch({
                type: SUBTYPE_SAVE_FAIL
            });
            showToastQuizError();
        }
    };
};

//SAVE RESULTS QUIZ/EMOTIONAL REPERTOIRE
export const saveEmotionalRepertoire = (
    user, user_email, REJFOR, REJMOY, REJFAI, RECFOR, RECMOY, RECFAI, RETFOR, RETMOY, RETFAI, REPFOR, REPMOY, REPFAI,
) => async dispatch => {
    let accessToken = localStorage.getItem('access');

    if( accessToken ) {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `JWT ${accessToken}`,
                'Accept': 'application/json'
            }
        };

        const body = JSON.stringify({
            user, user_email, REJFOR, REJMOY, REJFAI, RECFOR, RECMOY, RECFAI, RETFOR, RETMOY, RETFAI, REPFOR, REPMOY, REPFAI,
        });

        try{
            await axiosQuiz.post('repertoire-emotionnel/', body, config);

            dispatch({
                type: EMOTIONAL_REPERTOIRE_SAVE_SUCCESS
            });
            dispatch(load_user_details());
            showToastQuizSuccess();
        }
        catch (err){
            dispatch({
                type: EMOTIONAL_REPERTOIRE_SAVE_FAIL
            });
            showToastQuizError();
        }
    };
};

//SAVE RESULTS QUIZ/MYERS BRIGGS
export const saveMyersBriggs = (
    user, user_email,
    MB1, MB2, MB3, MB4, MB5, MB6, MB7, MB8, MB9, MB10,
    MB11, MB12, MB13, MB14, MB15, MB16, MB17, MB18, MB19, MB20,
    MB21, MB22, MB23, MB24, MB25, MB26, MB27, MB28, MB29, MB30,
    MB31, MB32, MB33, MB34, MB35, MB36, MB37, MB38, MB39, MB40,
    MB41, MB42, MB43, MB44, MB45, MB46, MB47, MB48, MB49, MB50,
    MB51, MB52, MB53, MB54, MB55, MB56, MB57, MB58, MB59, MB60,
    MB61, MB62, MB63, MB64, MB65, MB66, MB67, MB68, MB69, MB70,
    MB71, MB72, MB73, MB74, MB75, MB76, MB77, MB78, MB79, MB80
) => async dispatch => {
    let accessToken = localStorage.getItem('access');

    if( accessToken ) {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `JWT ${accessToken}`,
                'Accept': 'application/json'
            }
        };

        const body = JSON.stringify({
            user, user_email,
            MB1, MB2, MB3, MB4, MB5, MB6, MB7, MB8, MB9, MB10,
            MB11, MB12, MB13, MB14, MB15, MB16, MB17, MB18, MB19, MB20,
            MB21, MB22, MB23, MB24, MB25, MB26, MB27, MB28, MB29, MB30,
            MB31, MB32, MB33, MB34, MB35, MB36, MB37, MB38, MB39, MB40,
            MB41, MB42, MB43, MB44, MB45, MB46, MB47, MB48, MB49, MB50,
            MB51, MB52, MB53, MB54, MB55, MB56, MB57, MB58, MB59, MB60,
            MB61, MB62, MB63, MB64, MB65, MB66, MB67, MB68, MB69, MB70,
            MB71, MB72, MB73, MB74, MB75, MB76, MB77, MB78, MB79, MB80
        });

        try{
            await axiosQuiz.post('myers-briggs/', body, config);

            dispatch({
                type: MYERS_BRIGGS_SAVE_SUCCESS
            });
            dispatch(load_user_details());
            showToastQuizSuccess();
        }
        catch (err){
            dispatch({
                type: MYERS_BRIGGS_SAVE_FAIL
            });
            showToastQuizError();
        }
    };
};

//SAVE RESULTS QUIZ/TEMPERAMENT
export const saveTemperament = (
    user, user_email,
    TP1, TP2
) => async dispatch => {
    let accessToken = localStorage.getItem('access');

    if( accessToken ) {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `JWT ${accessToken}`,
                'Accept': 'application/json'
            }
        };

        const body = JSON.stringify({
            user, user_email,
            TP1, TP2
        });

        try{
            await axiosQuiz.post('temperament/', body, config);

            dispatch({
                type: TEMPERAMENT_SAVE_SUCCESS
            });
            dispatch(load_user_details());
            showToastQuizSuccess();
        }
        catch (err){
            dispatch({
                type: TEMPERAMENT_SAVE_FAIL
            });
            showToastQuizError();
        }
    };
};

//SAVE RESULTS QUIZ/VALUES
export const saveValues = (
    user, user_email,
    GRP1, GRP2, GRP3, GRP4, GRP5
) => async dispatch => {
    let accessToken = localStorage.getItem('access');

    if( accessToken ) {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `JWT ${accessToken}`,
                'Accept': 'application/json'
            }
        };

        const body = JSON.stringify({
            user, user_email,
            GRP1, GRP2, GRP3, GRP4, GRP5
        });

        try{
            await axiosQuiz.post('valeurs/', body, config);

            dispatch({
                type: VALUES_SAVE_SUCCESS
            });
            dispatch(load_user_details());
            showToastQuizSuccess();
        }
        catch (err){
            dispatch({
                type: VALUES_SAVE_FAIL
            });
            showToastQuizError();
        }
    };
};

//SAVE RESULTS QUIZ/ENERGY MODEL
export const saveEnergyModel = (
    user, user_email,
    EM1, EM2, EM3, EM4, EM5
) => async dispatch => {
    let accessToken = localStorage.getItem('access');

    if( accessToken ) {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `JWT ${accessToken}`,
                'Accept': 'application/json'
            }
        };

        const body = JSON.stringify({
            user, user_email,
            EM1, EM2, EM3, EM4, EM5
        });

        try{
            await axiosQuiz.post('nrj-preferences/', body, config);

            dispatch({
                type: ENERGY_MODEL_SAVE_SUCCESS
            });
            dispatch(load_user_details());
            showToastQuizSuccess();
        }
        catch (err){
            dispatch({
                type: ENERGY_MODEL_SAVE_FAIL
            });
            showToastQuizError();
        }
    };
};

//SAVE RESULTS QUIZ/ENERGY KEY WORDS
export const saveEnergyKeyWord = (
    user, user_email,
    EKW1, EKW2, EKW3, EKW4, EKW5, EKW6, EKW7, EKW8, EKW9,
) => async dispatch => {
    let accessToken = localStorage.getItem('access');

    if( accessToken ) {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `JWT ${accessToken}`,
                'Accept': 'application/json'
            }
        };

        const body = JSON.stringify({
            user, user_email,
            EKW1, EKW2, EKW3, EKW4, EKW5, EKW6, EKW7, EKW8, EKW9,
        });

        try{
            await axiosQuiz.post('nrj-mots-cles/', body, config);

            dispatch({
                type: ENERGY_KEYWORD_SAVE_SUCCESS
            });
            dispatch(load_user_details());
            showToastQuizSuccess();
        }
        catch (err){
            dispatch({
                type: ENERGY_KEYWORD_SAVE_FAIL
            });
            showToastQuizError();
        }
    };
};

//SAVE RESULTS QUIZ/ENERGY LAW (NOT USED AT NOW)
export const saveEnergyLaw = (
    user, user_email,
    EL1, EL2, EL3, EL4, EL5, EL6, EL7, EL8, EL9, EL10
) => async dispatch => {
    let accessToken = localStorage.getItem('access');

    if( accessToken ) {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `JWT ${accessToken}`,
                'Accept': 'application/json'
            }
        };

        const body = JSON.stringify({
            user, user_email,
            EL1, EL2, EL3, EL4, EL5, EL6, EL7, EL8, EL9, EL10
        });


        try{
            await axiosQuiz.post('nrj-lois/', body, config);

            dispatch({
                type: ENERGY_LAW_SAVE_SUCCESS
            });
            dispatch(load_user_details());
            showToastQuizSuccess();
        }
        catch (err){
            dispatch({
                type: ENERGY_LAW_SAVE_FAIL
            });
            showToastQuizError();
        }
    };
};

//SAVE RESULTS QUIZ/LEADERSHIP
export const saveLeadership = (
    user, user_email,
    LEAD1, LEAD2, LEAD3, LEAD4, LEAD5, LEAD6, LEAD7, LEAD8, LEAD9,
) => async dispatch => {
    let accessToken = localStorage.getItem('access');

    if( accessToken ) {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `JWT ${accessToken}`,
                'Accept': 'application/json'
            }
        };

        const body = JSON.stringify({
            user, user_email,
            LEAD1, LEAD2, LEAD3, LEAD4, LEAD5, LEAD6, LEAD7, LEAD8, LEAD9,
        });

        try{
            await axiosQuiz.post('leadership/', body, config);

            dispatch({
                type: LEADERSHIP_SAVE_SUCCESS
            });
            dispatch(load_user_details());
            showToastQuizSuccess();
        }
        catch (err){
            dispatch({
                type: LEADERSHIP_SAVE_FAIL
            });
            showToastQuizError();
        }
    };
};

