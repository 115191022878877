import React, { useEffect } from 'react';
import Header from '../../components/Header/Header';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { connect } from 'react-redux';
import { checkAuthenticated, load_user } from '../../store/actions/auth';
import { GoHome } from '../../config/routes';

import './Layout.css';


function Layout(props, {checkAuthenticated, load_user}) {

//    useMemo(() => {
//        try {
//            props.checkAuthenticated();
//            props.load_user();
//            console.log("useCallBack")
//            console.log(props)
//        }
//        catch(err){
//            GoHome()
//        }
//    }, [])

    useEffect(() => {

        try {
            props.checkAuthenticated();
            props.load_user();
        }
        catch(err){
            GoHome()
        }

    }, []);

    return(
        <>
            <Header/>
            <ToastContainer/>
            {props.children}
        </>
    );
}

export default connect(null, {checkAuthenticated, load_user}) (Layout);