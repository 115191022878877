//Library
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import routes from '../../../config/routes';
import {Link} from 'react-router-dom';
import axiosTrainings from'../../../config/axios_trainings';

function Trainings({user, isAuthenticated}){

    //STATE
    const [trainings, setTrainings] = useState ([]);

    //REDIRECT
    const navigate = useNavigate();

    //COMPONENT DID MOUNT
    useEffect(() =>{

        let accessToken = localStorage.getItem('access');

        if( accessToken ) {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `JWT ${accessToken}`,
                    'Accept': 'application/json'
                },
            };

            const axiosFetch = async () => {

                try {
                    const res = await axiosTrainings.get('list', config);
                    const newTrainings = [];
                    for(let key in res.data){
                        newTrainings.push({
                            ...res.data[key],
                            id: key
                        });
                    };
                    setTrainings(newTrainings);
                }
                catch(err){
                    console.log('Axios error')
                }
            };
            axiosFetch();
        }
    }, []);

    //ComponentDidUpdate
    useEffect(() => {
        const auth = localStorage.getItem('authenticated')
        const loading = localStorage.getItem('userLoaded')
        if (!auth){
            navigate(routes.HOME)
        }
        if (!user){
            navigate(routes.LOGIN)
        }
        else {
            if (user.is_front_admin === false){
                navigate(routes.HOME)
            }
        }
    }, [trainings, user]);

    const getTrainings = trainings.map((training, index) => {
        const updateClickHandler = () => {
            sessionStorage.setItem('training_name', training.name)
            sessionStorage.setItem('training_description', training.description)
            sessionStorage.setItem('training_start_date', training.start_date)
            sessionStorage.setItem('training_end_date', training.end_date)
            sessionStorage.setItem('training_is_active', training.is_active)
            sessionStorage.setItem('training_thumbnail', training.thumbnail)
            navigate(routes.UPDATE_TRAINING)
        }
       return(
            <div className="cardOr card mt-5 col-10 col-md-3 me-0 me-md-5 p-2 p-md-5" key={index}>
                <h1 className="card-title Roboto purple mt-2 mb-2">{training.name}</h1>
                {training.is_active ? <h2 className="RobotoBoldItalic text-white">Active</h2> : <h2 className="RobotoItalic text-danger">Inactive</h2>}
                <div className="row d-flex justify-content-center">
                    <button onClick={updateClickHandler} className="signupBtn mt-3 mb-4 text-decoration-none RobotoBold purple">Modifier</button>
                </div>
                <img src={training.thumbnail} className="card-img mt-2" alt='formation image'/>
                <p className="card-text roboto text-white fs-3 mt-3"><span className="purple RobotoBold">Début : </span>{training.start_date}</p>
                <p className="card-text roboto text-white fs-3 mt-3"><span className="purple RobotoBold">Fin : </span>{training.end_date}</p>
                <p className="card-text roboto text-white fs-3 mt-3 mb-5 text-overflow-clamp">{training.description}</p>
            </div>
       )

    });

    return(
        <div className="container-fluid pt-5 pb-5">
            <div className="row d-flex justify-content-center">
                <h1 className="Poppins or">Formations</h1>
                <Link to={routes.CREATE_TRAINING} className="cardPurple col-8 col-md-3 mb-4 mt-5 text-decoration-none RobotoBold text-white fs-3">Nouvelle Formation</Link>
                <div className="row p-1 px-md-5 mb-5 d-flex justify-content-center">
                    {getTrainings}
                </div>
            </div>
        </div>
    )
};

const mapStateToProps = state => ({
    user: state.auth.user,
    isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps, null) (Trainings);