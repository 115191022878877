//Library
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { verify } from '../../../store/actions/auth';
import routes from '../../../config/routes';


function Activate({verify}) {

    //State


    //Redirect
    const navigate = useNavigate();

    //URL match data
    const match = useParams() ;

    //Confirm Register
    const activateClickedHandler = () => {

        verify(match.uid, match.token);

        navigate(routes.LOGIN);
    }

    return(
        <div className='container-fluid screenHeight100'>
            <div className='row d-flex justify-content-center my-5'>
            <h1 className='text-center mt-5 PoppinsLight or'>Validez votre compte</h1>
                <div className='row d-flex justify-content-center mt-5'>
                    <div className='col-4'>
                        <button type="button" className='signupBtn' onClick={activateClickedHandler} >
                            <span className='signupBtnSpan'>Activation</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};



export default connect(null, { verify }) (Activate);