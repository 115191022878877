import axios from 'axios';

//const axiosQuiz = axios.create({
//    baseURL: 'http://127.0.0.1:8000/quiz/'
//});

const axiosQuiz = axios.create({
    baseURL: 'https://kkqf31ivb3.execute-api.ap-southeast-2.amazonaws.com/dev/quiz/'
});

export default axiosQuiz;