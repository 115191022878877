export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_FAIL = 'SIGNUP_FAIL';
export const ACTIVATION_SUCCESS = 'SIGNUP_SUCCESS';
export const ACTIVATION_FAIL = 'SIGNUP_FAIL';
export const USER_LOADED_SUCCESS = 'USER_LOADED_SUCCESS';
export const USER_LOADED_FAIL = 'USER_LOADED_FAIL';
export const USER_DETAILS_LOADED_SUCCESS = 'USER_DETAILS_LOADED_SUCCESS';
export const USER_DETAILS_LOADED_FAIL = 'USER_DETAILS_LOADED_FAIL';
export const AUTHENTICATED_SUCCESS = 'AUTHENTICATED_SUCCESS';
export const AUTHENTICATED_FAIL = 'AUTHENTICATED_FAIL';
export const PASSWORD_RESET_SUCCESS = 'PASSWORD_RESET_SUCCESS';
export const PASSWORD_RESET_FAIL = 'PASSWORD_RESET_FAIL';
export const PASSWORD_RESET_CONFIRM_SUCCESS = 'PASSWORD_RESET_CONFIRM_SUCCESS';
export const PASSWORD_RESET_CONFIRM_FAIL = 'PASSWORD_RESET_CONFIRM_FAIL';
export const COMPANY_REGISTER_SUCCESS = 'COMPANY_REGISTER_SUCCESS';
export const COMPANY_REGISTER_FAIL = 'COMPANY_REGISTER_FAIL';
export const PREFERENCES_SAVE_SUCCESS = 'PREFERENCES_SAVE_SUCCESS';
export const PREFERENCES_SAVE_FAIL = 'PREFERENCES_SAVE_FAIL';
export const AVOIDANCES_SAVE_SUCCESS = 'AVOIDANCES_SAVE_SUCCESS';
export const AVOIDANCES_SAVE_FAIL = 'AVOIDANCES_SAVE_FAIL';
export const DESCRIPTIONS_SAVE_SUCCESS = 'DESCRIPTIONS_SAVE_SUCCESS';
export const DESCRIPTIONS_SAVE_FAIL = 'DESCRIPTIONS_SAVE_FAIL';
export const TRANSACTIONAL_ANALYSIS_SAVE_SUCCESS = 'TRANSACTIONAL_ANALYSIS_SAVE_SUCCESS';
export const TRANSACTIONAL_ANALYSIS_SAVE_FAIL = 'TRANSACTIONAL_ANALYSIS_SAVE_FAIL';
export const LIFE_POSITION_SAVE_SUCCESS = 'LIFE_POSITION_SAVE_SUCCESS';
export const LIFE_POSITION_SAVE_FAIL = 'LIFE_POSITION_SAVE_FAIL';
export const ASSERTIVENESS_SAVE_SUCCESS = 'ASSERTIVENESS_SAVE_SUCCESS';
export const ASSERTIVENESS_SAVE_FAIL = 'ASSERTIVENESS_SAVE_FAIL';
export const SUBTYPE_SAVE_SUCCESS = 'SUBTYPE_SAVE_SUCCESS';
export const SUBTYPE_SAVE_FAIL = 'SUBTYPE_SAVE_FAIL';
export const EMOTIONAL_REPERTOIRE_SAVE_SUCCESS = 'EMOTIONAL_REPERTOIRE_SAVE_SUCCESS';
export const EMOTIONAL_REPERTOIRE_SAVE_FAIL = 'EMOTIONAL_REPERTOIRE_SAVE_FAIL';
export const MYERS_BRIGGS_SAVE_SUCCESS = 'MYERS_BRIGGS_SAVE_SUCCESS';
export const MYERS_BRIGGS_SAVE_FAIL = 'MYERS_BRIGGS_SAVE_FAIL';
export const TEMPERAMENT_SAVE_SUCCESS = 'TEMPERAMENT_SAVE_SUCCESS';
export const TEMPERAMENT_SAVE_FAIL = 'TEMPERAMENT_SAVE_FAIL';
export const VALUES_SAVE_SUCCESS = 'VALUES_SAVE_SUCCESS';
export const VALUES_SAVE_FAIL = 'VALUES_SAVE_FAIL';
export const ENERGY_MODEL_SAVE_SUCCESS = 'ENERGY_MODEL_SAVE_SUCCESS';
export const ENERGY_MODEL_SAVE_FAIL = 'ENERGY_MODEL_SAVE_FAIL';
export const ENERGY_KEYWORD_SAVE_SUCCESS = 'ENERGY_KEYWORD_SAVE_SUCCESS';
export const ENERGY_KEYWORD_SAVE_FAIL = 'ENERGY_KEYWORD_SAVE_FAIL';
export const ENERGY_LAW_SAVE_SUCCESS = 'ENERGY_LAW_SAVE_SUCCESS';
export const ENERGY_LAW_SAVE_FAIL = 'ENERGY_LAW_SAVE_FAIL';
export const LEADERSHIP_SAVE_SUCCESS = 'LEADERSHIP_SAVE_SUCCESS';
export const LEADERSHIP_SAVE_FAIL = 'LEADERSHIP_SAVE_FAIL';
export const LOGOUT = 'LOGOUT';
export const SENT = 'SENT';