import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import {Link, useNavigate} from 'react-router-dom';
import axiosAccounts from '../../config/axios_accounts';
import {USER_DETAILS_LOADED_SUCCESS, USER_DETAILS_LOADED_FAIL} from '../../store/actions/types';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { AnimatePresence, motion } from "framer-motion";

import { logout, sentResults } from '../../store/actions/auth';
import routes from '../../config/routes';

import Footer from '../Footer/Footer';
import Logout from './picts/onOff.png';
import Good from './picts/Pouce.png';


function Profile ({logout, user, load_user_details, sentResults, isAuthenticated}){

    //STATE
    const [loader, setLoader] = useState(true);

    //REDIRECT
    const navigate = useNavigate();

    //VARIABLES


    //FUNCTIONS

    const logoutHandler = () => {
        navigate(routes.HOME);
        logout();
    }

    const showToastError = () => {
        toast('vous devez vous connecter pour accéder à votre profil !', {
            position: toast.POSITION.TOP_CENTER
        });
    };

    const onClickHandler = () => {
        user = localStorage.getItem('user')
        sentResults(user)
        navigate(routes.HOME)
    }

    const completedQuiz = () => {
        if (user.BP && user.AS && user.AT && user.AVOID && user.DESCRIPT && user.EKW && user.ER && user.LP && user.MB && user.PREF && user.ST && user.TP){
            if (user.sent === false){
                return (
                <>
                    <div className='row d-flex justify-content-center p-4'>
                        <div className='col-12 col-md-6 card cardPurple p-4 align-items-center'>
                            <h3 className='PoppinsLight text-white'>Bravo vous avez Terminé</h3>
                            <h4 className="PoppinsLight text-white">Cliquez sur le bouton ci-dessous pour transmettre vos résultats</h4>
                            <button type='button' className='signupBtn' onClick={onClickHandler}>
                                <span className='signupBtnSpan'>Envoyer</span>
                            </button>
                        </div>
                    </div>
                </>
                )
            }

        }
        else if (user.BR && user.AS && user.AT && user.AVOID && user.DESCRIPT && user.EKW && user.ER && user.LP && user.MB && user.PREF && user.ST && user.TP && user.EM && user.LEAD && user.VAL){
            if (user.sent === false){
                return (
                <>
                    <div className='row d-flex justify-content-center p-4'>
                        <div className='col-12 col-md-6 card cardPurple p-4 align-items-center'>
                            <h3 className='PoppinsLight text-white'>Bravo vous avez Terminé</h3>
                            <h4 className="PoppinsLight text-white">Cliquez sur le bouton ci-dessous pour transmettre vos résultats</h4>
                            <button type='button' className='signupBtn' onClick={onClickHandler}>
                                <span className='signupBtnSpan'>Envoyer</span>
                            </button>
                        </div>
                    </div>
                </>
                )
            }
        }
        else if (user.BE && user.AS && user.AT && user.AVOID && user.DESCRIPT && user.EKW && user.ER && user.LP && user.MB && user.PREF && user.ST && user.TP && user.EM && user.LEAD && user.VAL){
            if (user.sent === false){
                return (
                <>
                    <div className='row d-flex justify-content-center p-4'>
                        <div className='col-12 col-md-6 card cardPurple p-4 align-items-center'>
                            <h3 className='PoppinsLight text-white'>Bravo vous avez Terminé</h3>
                            <h4 className="PoppinsLight text-white">Cliquez sur le bouton ci-dessous pour transmettre vos résultats</h4>
                            <button type='button' className='signupBtn' onClick={onClickHandler}>
                                <span className='signupBtnSpan'>Envoyer</span>
                            </button>
                        </div>
                    </div>
                </>
                )
            }
        }
        else if (user.BS === true && user.AT && user.MB){
            if (user.sent === false){
                return (
                <>
                    <div className='row d-flex justify-content-center p-4'>
                        <div className='col-12 col-md-6 card cardPurple p-4 align-items-center'>
                            <h3 className='PoppinsLight text-white'>Bravo vous avez Terminé</h3>
                            <h4 className="PoppinsLight text-white">Cliquez sur le bouton ci-dessous pour transmettre vos résultats</h4>
                            <button type='button' className='signupBtn' onClick={onClickHandler}>
                                <span className='signupBtnSpan'>Envoyer</span>
                            </button>
                        </div>
                    </div>
                </>
                )
            }
        }
        else{
            return (
                <>
                   <div className='row d-flex justify-content-center p-4'>
                        <div className='col-12 col-md-6 card cardOr p-4 align-items-center'>
                            <h3 className='PoppinsLight text-white'>QUESTIONNAIRES</h3>
                            <Link to={routes.QUIZ} className='signupBtn'>
                                <span className='signupBtnSpan'>Commencer</span>
                            </Link>
                        </div>
                   </div>
                </>
            )
        }

    }

    //ComponentDidMount
    useEffect(() => {
        //USER INFORMATIONS
        const load_user_details = () => async dispatch => {

            let accessToken = localStorage.getItem('access');

            if ( accessToken ) {
                const config = {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `JWT ${accessToken}`,
                        'Accept': 'application/json'
                    }
                };

                try{
                    const res = await axiosAccounts.get('details/', config);
                    localStorage.setItem('userLoaded', false)
                    dispatch({
                        type: USER_DETAILS_LOADED_SUCCESS,
                        payload: res.data
                    });
                    if (!user){
                       navigate(routes.LOGIN)
                    }
                    else {
                        if (user.is_front_admin === true){
                            navigate(routes.ADMIN)
                        }
                        setLoader(false)
                    }
                }
                catch (err) {
                    showToastError();
                    dispatch({
                        type: USER_DETAILS_LOADED_FAIL
                    });
                }
            }
            else {
                showToastError()
            }
        };
        load_user_details()
    }, [])

    //ComponentDidUpdate
    useEffect(() => {
        const auth = localStorage.getItem('authenticated')
        const loading = localStorage.getItem('userLoaded')
        if (! auth){
            navigate(routes.HOME)
            showToastError()
        }
        if (!user) {
            navigate(routes.LOGIN)
        }
        else {
            if (user.is_front_admin === true){
                navigate(routes.ADMIN)
            }
            if (loading) {
                setLoader(false)
            }
        }

    });

    return loader ? (
        <AnimatePresence exitBeforeEnter>
            <motion.div className="container-fluid" initial={{ x:-200, opacity: 1}} animate={{ x:0, opacity: 1}} transition={{ duration: 1.5 }}>
                <div className='row d-flex justify-content-center mt-5'>

                </div>
            </motion.div>
        </AnimatePresence>
    ) : (
        <motion.div initial={{ x:300, opacity: 0}} animate={{ x:0, opacity: 1}} transition={{ duration: 1.5 }} className='container-fluid'>
                <div className='row d-flex justify-content-end screenHeight20'>
                    <div className="col-6">
                        <img src={Logout} type='button' onClick={logoutHandler} className='logout' alt="" />
                    </div>
                </div>
            <div className='row d-flex justify-content-center mt-5'>
                {user ? <h1 className='PoppinsLight or screenHeight10'>Bonjour <span className='PoppinsLight purple'>{user.first_name}</span></h1> : ''}
            </div>
            {user.commitment === true ? (
                <>
                    <div className='row d-flex justify-content-center p-4'>
                        <div className='col-12 col-md-6 card cardOr p-4 align-items-center'>
                            <h3 className='PoppinsLight text-white'>Mes Informations Personnelles</h3>
                            <p className='RobotoThin text-white'>Prénom: <span className='RobotoLight purple'>{user.first_name}</span></p>
                            <p className='RobotoThin text-white'>Nom: <span className='RobotoLight purple'>{user.last_name}</span></p>
                            <p className='RobotoThin text-white'>Date de naissance: <span className='RobotoLight purple'>{user.birth_date}</span></p>
                            <p className='RobotoThin text-white'>Profession: <span className='RobotoLight purple'>{user.occupation}</span></p>
                            <p className='RobotoThin text-white'>Entreprise: <span className='RobotoLight purple'>{user.company}</span></p>
                            {user.BR ? <h3 className='RobotoThin text-white'>Bilan <span className='RobotoLight purple'>en Recrutement</span></h3> : ''}
                            {user.BP ? <h3 className='RobotoThin text-white'>Bilan <span className='RobotoLight purple'>Personnel</span></h3> : ''}
                            {user.BE ? <h3 className='RobotoThin text-white'>Bilan <span className='RobotoLight purple'>en Entreprise</span></h3> : ''}
                            {user.BS ? <h3 className='RobotoThin text-white'>Bilan <span className='RobotoLight purple'>Simplifié</span></h3> : ''}
                            <Link to={routes.USER_DETAILS} className='signupBtn'>
                                <span className='signupBtnSpan'>Modifier</span>
                            </Link>
                        </div>
                    </div>
                    {completedQuiz()}
                </>
            ) : (
                <>
                   <div className='row d-flex justify-content-center p-4'>
                        <div className='col-12 col-md-6 cardOr p-4 align-items-center'>
                            <h3 className="PoppinsLight text-white">Commencez par remplir vos informations personnelles</h3>
                            <div className="row d-flex justify-content-center">
                                <Link to={routes.USER_DETAILS} className='signupBtn mt-3'>
                                    <span className='signupBtnSpan'>Commencer</span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </>
            )}
            {user.sent === true ? (<h1 className="PoppinsLight or">Résultats Transmis !</h1>) : " " }
            <Footer/>
        </motion.div>
    )
};

const mapStateToProps = state => ({
    user: state.auth.user,
    isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps, { logout, sentResults }) (Profile);