import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import {useNavigate} from 'react-router-dom';

import { checkValidity } from '../../../shared/utility';
import { saveEnergyLaw, load_user_details } from '../../../store/actions/auth';
import routes from '../../../config/routes';
import Input from '../../../components/UI/Input/Input';


function EnergyLaw({user, saveEnergyLaw, load_user_details, isAuthenticated}) {

    //SELECT FIELDS
    const number = [
        {value: '', displayValue: ''},
        {value: 'A', displayValue: 'A'},
        {value: 'B', displayValue: 'B'},
        {value: 'C', displayValue: 'C'},
        {value: 'D', displayValue: 'D'},
        {value: 'E', displayValue: 'E'},
        {value: 'F', displayValue: 'F'},
        {value: 'G', displayValue: 'G'},
        {value: 'H', displayValue: 'H'},
        {value: 'I', displayValue: 'I'},
        {value: 'J', displayValue: 'J'},
    ]

    //STATE
    const [loader, setLoader] = useState(true);

    const [inputs, setInputs] = useState({
        EL1: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "Choix N°1",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis",
        },
        EL2: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "Choix N°2",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis",
        },
        EL3: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "Choix N°3",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis",
        },
        EL4: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "Choix N°4",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis",
        },
        EL5: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "Choix N°5",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis",
        },
        EL6: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "Choix N°6",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis",
        },
        EL7: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "Choix N°7",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis",
        },
        EL8: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "Choix N°8",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis",
        },
        EL9: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "Choix N°9",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis",
        },
        EL10: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "Choix N°10",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis",
        },
    });

    const [valid, setValid] = useState(false);
    const [quizCompleted, setQuizCompleted] = useState(false);

    //Redirect
    const navigate = useNavigate();

    //ComponentDidMount
    useEffect(() => {
        load_user_details();
        if (!user){
            setTimeout(() => {
            setLoader(false);
            }, 1000);
        }
        else {
            if(user.EL){
                navigate(routes.HOME)
            }
            else{
                setLoader(false)
            }
        }
    }, [])

    //ComponentDidUpdate
    useEffect(() => {
        if (!user){
            setTimeout(() => {
            setLoader(false);
            }, 1000);
        }
        else {
            if(user.EL){
                navigate(routes.HOME)
            }
            else{
                setLoader(false)
            }
        }
        const auth = localStorage.getItem('authenticated')
        if (! auth){
            alert('vous devez vous connecter pour répondre au questionnaire.')
            navigate(routes.HOME)
        }
    });

    //Functions

    const InputChangedHandler = (event, id) => {

        //Change Value
        const newInputs = {...inputs};
        newInputs[id].value = event.target.value;
        newInputs[id].touched = true;

        //Check value
        newInputs[id].valid = checkValidity(event.target.value, newInputs[id].validation)

        setInputs(newInputs);

        //Form checking
        let formIsValid = true;
        for (let input in newInputs) {
            formIsValid = newInputs[input].valid && formIsValid;
        }
        setValid(formIsValid);
    };

    const formHandler = event => {
        event.preventDefault();
    }


    //Save Confirmation
    const saveClickedHandler = () => {
        console.log(localStorage);
        const EL = {
            user: user.email, user_email: user.email,
            EL1: inputs.EL1.value, EL2: inputs.EL2.value, EL3: inputs.EL3.value, EL4: inputs.EL4.value,
            EL5: inputs.EL5.value, EL6: inputs.EL6.value, EL7: inputs.EL7.value, EL8: inputs.EL8.value,
            EL9: inputs.EL9.value, EL10: inputs.EL10.value
        };
        try{
            saveEnergyLaw(
                EL.user, EL.user_email,
                EL.EL1, EL.EL2, EL.EL3, EL.EL4, EL.EL5, EL.EL6, EL.EL7, EL.EL8, EL.EL9, EL.EL10
            );
            setQuizCompleted(true);
            navigate(routes.QUIZ)
        }
        catch(err){
            alert("Vérifiez que l'ensemble des champs soient renseignés")
        }
    }

    //Variables

    const formElementArray = [];
    for(let key in inputs) {
        formElementArray.push({
            id: key,
            config: inputs[key]
        });
    };

    let form = (
        <form onSubmit={(e) => formHandler(e)}>
            {formElementArray.map(formElement => (
            <div className="row d-flex justify-content-center mt-5" key={formElement.id}>
                <Input
                    id={formElement.id}
                    value={formElement.config.value}
                    label={formElement.config.label}
                    type={formElement.config.elementType}
                    config={formElement.config.elementConfig}
                    valid={formElement.config.valid}
                    touched={formElement.config.touched}
                    errorMessage={formElement.config.errorMessage}
                    changed={(e) => InputChangedHandler(e, formElement.id)}
                />
            </div>
            ))}
        </form>
    );


    return loader ? (
        <>
            <div className="container-fluid">
                <div className='row d-flex justify-content-center mt-5'>

                </div>
            </div>
        </>
    ) : (
        <div className='container-fluid p-3'>
            <div className='row d-flex justify-content-center mt-5'>
                <h1 className='RobotoBold blue'>Mots Clés</h1>
            </div>
            <div className='row d-flex justify-content-center mt-3'>
                <div className='col-8'>
                    <h5 className='RobotoItalic'>
                        Classez ces 10 lois dans l'ordre de vos préférences. (Du plus vers le moins)
                    </h5>
                </div>
                <div className='col-8 mt-3'>
                    <p className='RobotoItalic'><b>A. Loi de la liberté:</b> "Bloquer c'est mourir, libérer c'est vivre"</p>
                    <p className='RobotoItalic'><b>B. Loi de la semence:</b> "On récolte ce que l'on sème"</p>
                    <p className='RobotoItalic'><b>C. Loi de la lumière:</b> "La lumière chasse l'obscurité"</p>
                    <p className='RobotoItalic'><b>D. Loi de la chaleur:</b> "La chaleur réchauffe sans condition"</p>
                    <p className='RobotoItalic'><b>E. Loi de l'équilibre:</b> "L'équilibre me stabilise"</p>
                    <p className='RobotoItalic'><b>F. Loi de la nourriture:</b> "Ce qui me nourrit me crée"</p>
                    <p className='RobotoItalic'><b>G. Loi de la forge:</b> "C'est en forgeant qu'on devient forgeron"</p>
                    <p className='RobotoItalic'><b>H. Loi de la pureté:</b> "La pureté révèle"</p>
                    <p className='RobotoItalic'><b>I. Loi de ressourcement:</b> "Le repos ressource et restaure les stocks"</p>
                    <p className='RobotoItalic'><b>J. Loi de l'adaptation:</b> "L'adaptation évite les tensions"</p>
                </div>
            </div>
            <div className='row d-flex justify-content-center'>
                <div className='col-4 mb-5 mt-4'>
                    {form}
                </div>
            </div>
            <div className='row d-flex justify-content-center gy-3 mt-5'>
                <button type='submit' className='signupBtn' onClick={saveClickedHandler} disabled={!valid} >
                    <span className='signupBtnSpan roboto'>Enregistrer</span>
                </button>
            </div>
        </div>
    )
};

const mapStateToProps = state => ({
    user: state.auth.user,
    isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps, {saveEnergyLaw, load_user_details}) (EnergyLaw);