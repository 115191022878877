import axios from 'axios';

//const axiosAccount = axios.create({
//    baseURL: 'http://127.0.0.1:8000/accounts/'
//});

const axiosAccount = axios.create({
    baseURL: 'https://kkqf31ivb3.execute-api.ap-southeast-2.amazonaws.com/dev/accounts/'
});

export default axiosAccount;