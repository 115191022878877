import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import {useNavigate} from 'react-router-dom';

import { checkValidity } from '../../../shared/utility';
import { savePreferences, load_user_details } from '../../../store/actions/auth';
import routes from '../../../config/routes';
import Input from '../../../components/UI/Input/Input';
import Footer from '../../Footer/Footer';

import Graph from './pref_picts/prefGraph.jpg'

function Preferences({user, savePreferences, load_user_details, isAuthenticated}) {

    //SELECT FIELDS
    const number = [
        {value: '', displayValue: 'Sélectionner votre première préférence'},
        {value: '1', displayValue: '1'},
        {value: '2', displayValue: '2'},
        {value: '3', displayValue: '3'},
        {value: '4', displayValue: '4'},
        {value: '5', displayValue: '5'},
        {value: '6', displayValue: '6'},
        {value: '7', displayValue: '7'},
        {value: '8', displayValue: '8'},
        {value: '9', displayValue: '9'},
    ]

    const number_2 = [
        {value: '', displayValue: 'Sélectionner votre seconde préférence'},
        {value: '1', displayValue: '1'},
        {value: '2', displayValue: '2'},
        {value: '3', displayValue: '3'},
        {value: '4', displayValue: '4'},
        {value: '5', displayValue: '5'},
        {value: '6', displayValue: '6'},
        {value: '7', displayValue: '7'},
        {value: '8', displayValue: '8'},
        {value: '9', displayValue: '9'},
    ]

    //STATE
    const [loader, setLoader] = useState(true);

    const [inputs, setInputs] = useState({
        preference_1: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "Préférence N°1",
                options: number
            },
            value: '',
            label: "",
            valid: false,
            validation: {
                required: true,
                different: "",
            },
            touched: false,
            errorMessage: "La réponse doit différer de la suivante. Champ requis"
        },
        preference_2: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "Préférence N°2",
                options: number_2
            },
            value: '',
            label: "",
            valid: false,
            validation: {
                required: true,
                different: "",
            },
            touched: false,
            errorMessage: "La réponse doit différer de la précédente. Champ requis"
        }
    });

    const [choices, setChoices] = useState({
        preference_3: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "Choix N°1",
                options: number
            },
            value: '',
            label: "",
            valid: false,
            validation: {
                required: true,
                different: "",
            },
            touched: false,
            errorMessage: "La réponse doit différer de la suivante. Champ requis"
        },
        preference_4: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "Choix N°2",
                options: number_2
            },
            value: '',
            label: "",
            valid: false,
            validation: {
                required: true,
                different: "",
            },
            touched: false,
            errorMessage: "La réponse doit différer de la précédente. Champ requis"
        },
    });

    const [valid, setValid] = useState(false);
    const [quizCompleted, setQuizCompleted] = useState(false);

    //Redirect
    const navigate = useNavigate();

    //ComponentDidMount
    useEffect(() => {
        load_user_details();
        if (!user){
            setTimeout(() => {
            setLoader(false);
            }, 1000);
        }
        else {
            if(user.PREF){
                navigate(routes.HOME)
            }
            else{
                setLoader(false)
            }
        }
    }, [])

    //ComponentDidUpdate
    useEffect(() => {
        if (!user){
            setTimeout(() => {
            setLoader(false);
            }, 1000);
        }
        else {
            if(user.PREF){
                navigate(routes.HOME)
            }
            else{
                setLoader(false)
            }
        }
        const auth = localStorage.getItem('authenticated')
        if (! auth){
            alert('vous devez vous connecter pour répondre au questionnaire.')
            navigate(routes.HOME)
        }
    });

    //Functions

    const InputChangedHandler = (event, id) => {

        //Change Value
        const newInputs = {...inputs};
        newInputs[id].value = event.target.value;
        newInputs[id].touched = true;

        //Check value
        inputs.preference_1.validation.different = [];
        inputs.preference_1.validation.different.push(inputs.preference_2.value);
        inputs.preference_2.validation.different = [];
        inputs.preference_2.validation.different.push(inputs.preference_1.value);

        newInputs[id].valid = checkValidity(event.target.value, newInputs[id].validation)

        for (let input in newInputs){
            newInputs[input].valid = checkValidity(newInputs[input].value, newInputs[input].validation)
        }

        setInputs(newInputs);

        //Form checking
        let formIsValid = true;
        for (let input in newInputs) {
            formIsValid = newInputs[input].valid && formIsValid;
        }
        setValid(formIsValid);
    };

    const choicesChangedHandler = (event, id) => {

        //Change Value
        const newChoices = {...choices};
        newChoices[id].value = event.target.value;
        newChoices[id].touched = true;

        //Check value
        choices.preference_3.validation.different = [];
        choices.preference_3.validation.different.push(choices.preference_4.value);
        choices.preference_4.validation.different = [];
        choices.preference_4.validation.different.push(choices.preference_3.value);

        newChoices[id].valid = checkValidity(event.target.value, newChoices[id].validation)

        for (let choice in newChoices){
            newChoices[choice].valid = checkValidity(newChoices[choice].value, newChoices[choice].validation)
        }

        setChoices(newChoices);

        //Form checking
        let formIsValid = true;
        for (let choice in newChoices) {
            formIsValid = newChoices[choice].valid && formIsValid;
        }
        setValid(formIsValid);
    };

    const formHandler = event => {
        event.preventDefault();
    }


    //Save Confirmation
    const saveClickedHandler = () => {
        console.log(localStorage);
        const preferences = {
            user: user.email,
            user_email: user.email,
            question_1: inputs.preference_1.value,
            question_2: inputs.preference_2.value,
            question_3: choices.preference_3.value,
            question_4: choices.preference_4.value
        };
        try{
            savePreferences(preferences.user, preferences.user_email, preferences.question_1, preferences.question_2, preferences.question_3, preferences.question_4);
            setQuizCompleted(true);
            navigate(routes.QUIZ);
        }
        catch(err){
            alert("Vérifiez que l'ensemble des champs soient renseignés")
        }
    }

    //Variables

    const formElementArray = [];
    for(let key in inputs) {
        formElementArray.push({
            id: key,
            config: inputs[key]
        });
    };

    const formElementArray2 = [];
    for(let key in choices) {
        formElementArray2.push({
            id: key,
            config: choices[key]
        });
    };

    let form = (
        <form onSubmit={(e) => formHandler(e)}>
            {formElementArray.map(formElement => (
                <Input
                    key={formElement.id}
                    id={formElement.id}
                    value={formElement.config.value}
                    label={formElement.config.label}
                    type={formElement.config.elementType}
                    config={formElement.config.elementConfig}
                    valid={formElement.config.valid}
                    touched={formElement.config.touched}
                    errorMessage={formElement.config.errorMessage}
                    changed={(e) => InputChangedHandler(e, formElement.id)}
                />
            ))}
        </form>
    );

    let form2 = (
        <form onSubmit={(e) => formHandler(e)}>
            {formElementArray2.map(formElement => (
                <Input
                    key={formElement.id}
                    id={formElement.id}
                    value={formElement.config.value}
                    label={formElement.config.label}
                    type={formElement.config.elementType}
                    config={formElement.config.elementConfig}
                    valid={formElement.config.valid}
                    touched={formElement.config.touched}
                    errorMessage={formElement.config.errorMessage}
                    changed={(e) => choicesChangedHandler(e, formElement.id)}
                />
            ))}
        </form>
    );


    return loader ? (
        <>
            <div className="container-fluid">
                <div className='row d-flex justify-content-center mt-5'>

                </div>
            </div>
        </>
    ) : (
        <div className='container-fluid p-3'>
            <div className='row d-flex justify-content-center mt-5'>
                <h1 className='PoppinsLight or'>Enneagramme : Préférences</h1>
            </div>
            <div className='row d-flex justify-content-center mt-5'>
                <div className='col-12 col-md-8 cardPurple p-5 mb-5'>
                    <h4 className='RobotoThin text-white'>
                        Le moyen le plus simple d’avoir une première idée de votre type est de chercher quels moments de votre vie vous semblent les plus importants,
                        et de quels traits de votre personnalité vous êtes le plus satisfait:
                    </h4>
                </div>
            </div>
            <div className='row d-flex justify-content-center mt-5'>
                <h4 className='PoppinsLight orange'><b>1/ Choisissez un n° de phrase spontanément, puis un deuxième s'il vous semble aussi proche de vous</b></h4>
            </div>
            <div className='row d-flex justify-content-center mt-5'>
                <div className='col-12 col-md-6 RobotoLight text-start text-white mx-4'>
                    <h4 className="mb-4"><b><span className="orange fs-2">1 </span></b>  Est fier de sa capacité de travail, de son honnêteté et de sa rigueur morale (capacité de perfection et idéalisme). Il revendique également son sens de l'ordre, de la rigueur et du travail bien fait. </h4>
                    <h4 className="mb-4"><b><span className="orange fs-2">2 </span></b>  Est fier de l'amour et de l'aide chaleureuse qu'il apporte aux autres (capacité d'amour). Il s'attribue le sens de l'empathie, de l'aide et du soutien des autres. </h4>
                    <h4 className="mb-4"><b><span className="orange fs-2">3 </span></b>  Est fier de son efficacité et de ses réussites surtout sur les plans professionnel et social (capacité d'action, volonté d'atteindre ses buts et combativité)</h4>
                    <h4 className="mb-4"><b><span className="orange fs-2">4 </span></b>  Est fier de sa profonde sensibilité et de son originalité (créativité, sens artistique, émotions).</h4>
                    <h4 className="mb-4"><b><span className="orange fs-2">5 </span></b>  Est fier de ses multiples connaissances et de sa capacité à comprendre (besoin de connaissances). Il apprécie son recul, son raisonnement, sa solitude et le sens qu'il attribue à la réflexion.</h4>
                    <h4 className="mb-4"><b><span className="orange fs-2">6 </span></b>  Est fier de sa loyauté vis-à-vis des autres, et du fait de remplir son devoir (fiabilité et sens du devoir).</h4>
                    <h4 className="mb-4"><b><span className="orange fs-2">7 </span></b>  Est fier de vivre et manifester optimisme et capacité à être gai et heureux</h4>
                    <h4 className="mb-4"><b><span className="orange fs-2">8 </span></b>  Est fier de sa force et de savoir faire respecter sa justice (courage, leadership, confiance en soi)</h4>
                    <h4 className="mb-4"><b><span className="orange fs-2">9 </span></b>  Est fier d'être facile à vivre, calme, et de prendre le temps de vivre en paix (soutien et acceptation).</h4>
                </div>
            </div>
            <div className='row d-flex justify-content-center'>
                <div className='col-12 col-md-6 mb-5 mt-4 '>
                    {form}
                </div>
            </div>
            <div className='row d-flex justify-content-center mt-5'>
                <h4 className='PoppinsLight orange'><b>2/ Dans le graphique ci-dessous, choisissez un n° spontanément, puis un deuxième s’il vous semble aussi proche de vous</b></h4>
            </div>
            <div className='row d-flex justify-content-center mt-5'>
                <div className='col-12 col-md-10'>
                    <img src={Graph} className='img img-fluid'/>
                </div>
            </div>
            <div className='row d-flex justify-content-center'>
                <div className='col-12 col-md-6 mt-4'>
                    {form2}
                </div>
            </div>
            <div className='row d-flex justify-content-center gy-3 mt-5 mb-5'>
                <button type='button' className='signupBtn' onClick={saveClickedHandler} disabled={!valid}>
                    <span className='signupBtnSpan roboto'>Enregistrer</span>
                </button>
            </div>
            <Footer/>
        </div>
    )
};

const mapStateToProps = state => ({
    user: state.auth.user,
    isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps, {savePreferences, load_user_details}) (Preferences);