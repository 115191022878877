import { useNavigate } from 'react-router-dom';

const routes = {
    HOME: '/',
    PROFILE: '/profile',
    ADMIN: '/admin',
    USER_RESULTS: '/admin/user_results',
    USER_VALIDATED: '/admin/user_results/validated',
    USER_COMMENT: '/admin/user_results/comment',
    USER_ACTIVATE: '/admin/user_results/user_activate',
    TRAININGS: '/admin/trainings',
    CREATE_TRAINING: '/admin/trainings/create_training',
    UPDATE_TRAINING: '/admin/trainings/update_training',
    USER_DETAILS: '/user_details',
    QUIZ: '/quiz',
    LOGIN: '/login',
    SIGNUP: '/signup',
    ACTIVATE: '/activate/:uid/:token',
    CHOICE: '/choice',
    COMPANY: '/company',
    RESET_PASSWORD: '/reset-password',
    RESET_PASSWORD_CONFIRM: 'password/reset/confirm/:uid/:token',
    PREFERENCES: '/quiz/preferences',
    AVOIDANCES: '/quiz/avoidances',
    DESCRIPTIONS: '/quiz/descriptions',
    TRANSACTIONALANALYSIS: '/quiz/analyse-transactionnelle',
    LIFEPOSITION: '/quiz/position-de-vie',
    ASSERTIVENESS: '/quiz/assertivite',
    SUBTYPE: '/quiz/sous-types',
    EMOTIONALREPERTOIRE: '/quiz/repertoire-emotionnel',
    MYERSBRIGGS: '/quiz/myers-briggs',
    TEMPERAMENT: '/quiz/temperament',
    VALUES: '/quiz/valeurs',
    ENERGYMODEL: '/quiz/nrj-preferences',
    ENERGYKEYWORD: '/quiz/nrj-mots-cles',
    ENERGYLAW: '/quiz/nrj-lois',
    LEADERSHIP: '/quiz/leadership',
    RGPD: '/rgpd',
    LEGALNOTICE: '/legal-notice',
}

export default routes;

export function GoHome () {
    const navigate = useNavigate();
    navigate(routes.HOME)
};