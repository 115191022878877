//Library
import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { checkValidity } from '../../../shared/utility';
import { login } from '../../../store/actions/auth';
import routes from '../../../config/routes';
import { AnimatePresence, motion } from "framer-motion";

import Footer from '../../../components/Footer/Footer';

//Components
import Input from '../../../components/UI/Input/Input';

function Login({login, isAuthenticated}) {

    //State
    const [inputs, setInputs] = useState({
        email: {
            elementType: 'input',
            elementConfig: {
                type: 'email',
                placeholder: 'Email'
            },
            value: '',
            label: '',
            valid: false,
            validation: {
                required: true,
                email: true
            },
            touched: false,
            errorMessage: "Email invalide"
        },
        password: {
            elementType: 'input',
            elementConfig: {
                type: 'password',
                placeholder: 'Mot de passe'
            },
            value: '',
            label: '',
            valid: false,
            validation: {
                required: true
            },
            touched: false,
            errorMessage: "Mot de passe requis"
        }
    });

    const [valid, setValid] = useState (false);

    //Redirect
    const navigate = useNavigate();


    //ComponentDidUpdate
    useEffect(() => {
        if(isAuthenticated){
            navigate(routes.PROFILE)
        }
    });

    //Functions

    const InputChangedHandler = (event, id) => {

        //Change Value
        const newInputs = {...inputs};
        newInputs[id].value = event.target.value;
        newInputs[id].touched = true;

        //Check value
        newInputs[id].valid = checkValidity(event.target.value, newInputs[id].validation)

        setInputs(newInputs);

        //Form checking
        let formIsValid = true;
        for (let input in newInputs) {
            formIsValid = newInputs[input].valid && formIsValid;
        }
        setValid(formIsValid);
    };

    const formHandler = event => {
        event.preventDefault();
    }

    //Confirm Login
    const loginClickedHandler = () => {
        const user = {
            email: inputs.email.value,
            password: inputs.password.value
        };
        login(user.email, user.password);
    }


    //Variables

    const formElementArray = [];
    for(let key in inputs) {
        formElementArray.push({
            id: key,
            config: inputs[key]
        });
    };

    let form = (
        <form onSubmit={(e) => formHandler(e)}>
            {formElementArray.map(formElement => (
                <Input
                    key={formElement.id}
                    id={formElement.id}
                    value={formElement.config.value}
                    label={formElement.config.label}
                    type={formElement.config.elementType}
                    config={formElement.config.elementConfig}
                    valid={formElement.config.valid}
                    touched={formElement.config.touched}
                    errorMessage={formElement.config.errorMessage}
                    changed={(e) => InputChangedHandler(e, formElement.id)}
                />
            ))}
            <div className='row d-flex justify-content-center gy-3'>
                <button type='submit' className='signupBtn' onClick={loginClickedHandler} disabled={!valid}>
                    <span className='signupBtnSpan RobotoBoldItalic'>Connexion</span>
                </button>
            </div>
        </form>
    );


    return(
        <AnimatePresence exitBeforeEnter>
            <motion.div className='container-fluid' initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ duration: 1.5 }}>
                <div className='row d-flex justify-content-center my-5'>
                <h1 className='text-center mt-5 PoppinsLight or'>Connexion</h1>
                <h5 className='text-center mt-5 PoppinsLight purple'>Afin de sécuriser vos informations, la durée de connexion est limitée à 240 minutes</h5>
                <h5 className='text-center mt-2 PoppinsLight orange'>Passé ce délai vous devrez vous reconnecter</h5>
                    <div className='col-12 col-md-4 mt-5'>
                        {form}
                    </div>
                    <div className='row d-flex justify-content-center mt-5'>
                        <h4 className='text-center PoppinsLight or'>Mot de passe oublié?</h4>
                    </div>
                    <div className='row d-flex justify-content-center'>
                        <Link className='signupBtn' to={routes.RESET_PASSWORD} >
                            <span className='signupBtnSpan RobotoBoldItalic'>Nouveau mot de passe</span>
                        </Link>
                    </div>
                    <div className='row d-flex justify-content-center mt-5'>
                        <h1 className='text-center PoppinsLight or'>Pas encore inscrit?</h1>
                    </div>
                    <div className='row d-flex justify-content-center'>
                        <Link className='signupBtn' to={routes.SIGNUP} >
                            <span className='signupBtnSpan RobotoBoldItalic'>Inscription</span>
                        </Link>
                    </div>
                </div>
                <Footer/>
            </motion.div>
        </AnimatePresence>
    );
};

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated
});


export default connect(mapStateToProps, { login }) (Login);