import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import {useNavigate} from 'react-router-dom';

import { checkValidity } from '../../../shared/utility';
import { saveDescriptions, load_user_details } from '../../../store/actions/auth';
import routes from '../../../config/routes';
import Input from '../../../components/UI/Input/Input';
import Footer from '../../Footer/Footer';


function Descriptions({user, saveDescriptions, load_user_details, isAuthenticated}) {

    //SELECT FIELDS
    const number = [
        {value: '', displayValue: 'Sélectionner votre réponse'},
        {value: '1', displayValue: '1'},
        {value: '2', displayValue: '2'},
        {value: '3', displayValue: '3'},
        {value: '4', displayValue: '4'},
        {value: '5', displayValue: '5'},
        {value: '6', displayValue: '6'},
        {value: '7', displayValue: '7'},
        {value: '8', displayValue: '8'},
        {value: '9', displayValue: '9'},
    ]

    //STATE
    const [loader, setLoader] = useState(true);

    const [inputs, setInputs] = useState({
        description_1: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "Description N°1",
                options: number
            },
            value: '',
            label: "Quelle est votre premier choix?",
            valid: false,
            validation: {
                required: true,
                different: "",
            },
            touched: false,
            errorMessage: "La réponse doit différer des suivantes. Champ requis"
        },
        description_2: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "Description N°2",
                options: number
            },
            value: '',
            label: "Quelle est votre second choix?",
            valid: false,
            validation: {
                required: true,
                different: "",
            },
            touched: false,
            errorMessage: "La réponse doit différer de la précédente et de la suivante. Champ requis"
        },
        description_3: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "Choix N°1",
                options: number
            },
            value: '',
            label: "Quel est votre troisième choix?",
            valid: false,
            validation: {
                required: true,
                different: "",
            },
            touched: false,
            errorMessage: "La réponse doit différer des précédentes. Champ requis"
        },
        description_4: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "Choix N°2",
                options: number
            },
            value: '',
            label: "Quel est celui qui vous correspond à 20 ans?",
            valid: false,
            validation: {
                required: true,

            },
            touched: false,
            errorMessage: "La réponse doit différer de la précédente. Champ requis"
        }
    });

    const [valid, setValid] = useState(false);
    const [quizCompleted, setQuizCompleted] = useState(false);

    //Redirect
    const navigate = useNavigate();

    //ComponentDidMount
    useEffect(() => {
        load_user_details();
        if (!user){
            setTimeout(() => {
            setLoader(false);
            }, 1000);
        }
        else {
            if(user.DESCRIPT){
                navigate(routes.HOME)
            }
            else{
                setLoader(false)
            }
        }
    }, [])

    //ComponentDidUpdate
    useEffect(() => {
        if (!user){
            setTimeout(() => {
            setLoader(false);
            }, 1000);
        }
        else {
            if(user.DESCRIPT){
                navigate(routes.HOME)
            }
            else{
                setLoader(false)
            }
        }
        const auth = localStorage.getItem('authenticated')
        if (! auth){
            alert('vous devez vous connecter pour répondre au questionnaire.')
            navigate(routes.HOME)
        }
    });

    //Functions

    const InputChangedHandler = (event, id) => {

        //Change Value
        const newInputs = {...inputs};
        newInputs[id].value = event.target.value;
        newInputs[id].touched = true;

        //Check value
        inputs.description_1.validation.different = [];
        inputs.description_1.validation.different.push(inputs.description_2.value, inputs.description_3.value);
        inputs.description_2.validation.different = [];
        inputs.description_2.validation.different.push(inputs.description_1.value, inputs.description_3.value);
        inputs.description_3.validation.different = [];
        inputs.description_3.validation.different.push(inputs.description_1.value, inputs.description_2.value)

        newInputs[id].valid = checkValidity(event.target.value, newInputs[id].validation)


        for (let input in newInputs){
            newInputs[input].valid = checkValidity(newInputs[input].value, newInputs[input].validation)
        }

        setInputs(newInputs);

        //Form checking
        let formIsValid = true;
        for (let input in newInputs) {
            formIsValid = newInputs[input].valid && formIsValid;
        }
        setValid(formIsValid);
    };

    const formHandler = event => {
        event.preventDefault();
    }


    //Save Confirmation
    const saveClickedHandler = () => {
        console.log(localStorage);
        const descriptions = {
            user: user.email,
            user_email: user.email,
            question_1: inputs.description_1.value,
            question_2: inputs.description_2.value,
            question_3: inputs.description_3.value,
            question_4: inputs.description_4.value
        };
        try{
            saveDescriptions(descriptions.user, descriptions.user_email, descriptions.question_1, descriptions.question_2, descriptions.question_3, descriptions.question_4);
            setQuizCompleted(true);
            navigate(routes.QUIZ)
        }
        catch(err){
            alert("Vérifiez que l'ensemble des champs soient renseignés")
        }
    }

    //Variables

    const formElementArray = [];
    for(let key in inputs) {
        formElementArray.push({
            id: key,
            config: inputs[key]
        });
    };

    let form = (
        <form onSubmit={(e) => formHandler(e)}>
            {formElementArray.map(formElement => (
            <>
                <h4 className="RobotoLight orange mt-5">{formElement.config.label}</h4>
                <Input
                    key={formElement.id}
                    id={formElement.id}
                    value={formElement.config.value}

                    type={formElement.config.elementType}
                    config={formElement.config.elementConfig}
                    valid={formElement.config.valid}
                    touched={formElement.config.touched}
                    errorMessage={formElement.config.errorMessage}
                    changed={(e) => InputChangedHandler(e, formElement.id)}
                />
            </>
            ))}
        </form>
    );


    return loader ? (
        <>
            <div className="container-fluid">
                <div className='row d-flex justify-content-center mt-5'>

                </div>
            </div>
        </>
    ) : (
        <div className='container-fluid p-3'>
            <div className='row d-flex justify-content-center mt-5'>
                <h1 className='PoppinsLight or'>Enneagramme : Descriptions</h1>
            </div>
            <div className='row d-flex justify-content-center mt-5'>
                <div className='col-10 col-md-8 cardPurple mb-5'>
                    <h4 className='RobotoThin text-white p-5'>
                        Lisez les 9 paragraphes (résumés caricaturales de profil ci-dessous), et même si vous ne vous y retrouvez pas complètement,
                        merci de noter les 3 paragraphes où vous vous retrouvez le plus (dans votre vie en général, pas que professionnelle)
                    </h4>
                </div>
            </div>
            <div className='row d-flex justify-content-center mt-5 mb-5'>
                <div className='col-12 col-md-8 align-items-center'>
                    <h4 className='PoppinsLight orange mb-3 mt-5 text-start'><b>Paragraphe 1</b></h4>
                    <div className='RobotoLight text-start p-4'>
                        <h4 className='card-text'>
                            Je suis très exigeant envers moi-même sur la façon de faire les choses et je peux me montrer très critique vis-à-vis de moi et des autres pour que le travail se fasse correctement.
                        </h4>
                        <h4 className='card-text'>
                            Je trouve important de s’astreindre à une grande intégrité et à une grande honnêteté et je peux me montrer mécontent ou tendu quand les autres ne respectent pas les règles ou ne prennent pas le temps de bien faire le travail.
                        </h4>
                        <h4 className='card-text'>
                            J’ai la correction en haute estime et j’essaie de vivre selon mes principes
                        </h4>
                        <h4 className='card-text'>
                            Je vois facilement ce qui ne va pas et comment les choses pourraient être améliorées
                        </h4>
                        <h4 className='card-text'>
                            Les autres me considèrent probablement comme trop critique ou en quête de perfection, mais il m’est difficile de rester indifférent ou d’accepter que les choses ne soient pas comme elles devraient être.
                        </h4>
                        <h4 className='card-text'>
                            Quand je suis responsable de quelque chose on peut être sur que je le ferai convenablement et j’en suis fier. (si je m’engage à faire quelque chose je le fais bien)
                        </h4>
                        <h4 className='card-text'>
                            J’éprouve parfois du ressentiment quand les gens n’essaient pas de faire leur travail correctement ou se comportent de manière irresponsable, même si je m’efforce de ne pas le leur montrer ouvertement.
                        </h4>
                        <h4 className='card-text'>
                            Pour moi le travail passe généralement avant le plaisir et je peux faire taire mes désirs en conséquence, afin que le travail soit fait et bien fait. (je peux avoir du mal à me détendre ou à prendre du repos)
                        </h4>
                        <h4 className='card-text'>
                            J’ai un grand sens de l’ordre et j’aime travailler de façon très organisée.
                        </h4>
                    </div>
                </div>
            </div>
            <div className='row d-flex justify-content-center mt-3'>
                <div className='col-12 col-md-8 p-4 align-items-center'>
                    <h4 className='PoppinsLight orange mb-5 text-start'><b>Paragraphe 2</b></h4>
                    <div className='RobotoLight text-start cardGold p-4'>
                        <h4 className='card-text'>
                            Je suis très sensible aux sentiments des autres.
                        </h4>
                        <h4 className='card-text'>
                            Je peux voir ce dont ils ont besoin même quand je ne les connais pas.
                        </h4>
                        <h4 className='card-text'>
                            C’est parfois frustrant d’être autant conscient des besoins des autres surtouts de leur douleur ou de leur malheur parce que je ne peux pas faire pour eux autant que je le voudrais.
                        </h4>
                        <h4 className='card-text'>
                            Il m’est facile de donner de ma personne
                        </h4>
                        <h4 className='card-text'>
                            J’aurais parfois envie d’être capable de dire non plus facilement, parce que je finis par mettre plus d’énergie à m’occuper des autres que je n’en mets à m’occuper de moi-même.
                        </h4>
                        <h4 className='card-text'>
                            J’ai de la peine quand les gens s’imaginent que j’essaie de les manipuler ou de les contrôler alors que tout ce que j’essaie de faire c’est de les comprendre et de les aider.
                        </h4>
                        <h4 className='card-text'>
                            J’aime être considéré comme quelqu’un de bon et de chaleureux mais quand je ne suis pas reconnu ou apprécié, je peux être très affecté émotionnellement.
                        </h4>
                        <h4 className='card-text'>
                            De bons rapports humains sont importants pour moi et je suis prêt à me donner du mal pour qu’ils existent.
                        </h4>
                        <h4 className='card-text'>
                            Je n’aime pas la solitude, et même dans ce cas, je vais probablement penser aux autres.
                        </h4>
                    </div>
                </div>
            </div>
            <div className='row d-flex justify-content-center mt-3'>
                <div className='col-12 col-md-8 p-4 align-items-center'>
                <h4 className='PoppinsLight orange mb-3 mt-5 text-start'><b>Paragraphe 3</b></h4>
                    <div className='RobotoLight text-start p-4'>
                        <h4 className='card-text'>
                            Je suis fortement motivé par le fait d’être le meilleur dans ce que je fais et au fil des ans, mes réalisations m’ont valu une certaine estime.
                        </h4>
                        <h4 className='card-text'>
                            Je suis très actif et je réussis pratiquement tout ce que j’entreprends
                        </h4>
                        <h4 className='card-text'>
                            Je m’identifie à ce que je fais parce que je pense que la valeur de chacun dépend de ce qu’il accomplit et de l’approbation qui en résulte.
                        </h4>
                        <h4 className='card-text'>
                            J’ai toujours plus de choses à faire que mon emploi du temps ne peut en contenir donc je laisse de coté les sentiments pour me concentrer sur les objectifs
                        </h4>
                        <h4 className='card-text'>
                            Parce qu’il y a toujours quelque chose à faire, je trouve difficile de prendre le temps de m’asseoir et de me détendre.
                        </h4>
                        <h4 className='card-text'>
                            Je suis contrarié quand les gens ne savent pas profiter du temps que je leur accorde
                        </h4>
                        <h4 className='card-text'>
                            Il m’arrive d’avoir envie de prendre en main le projet que quelqu’un assume trop lentement à mon gout.
                        </h4>
                        <h4 className='card-text'>
                            J’aime la sensation (et donner l’impression), de pouvoir gérer n’importe quelle situation
                        </h4>
                        <h4 className='card-text'>
                            J’aime bien la compétition individuelle mais je suis également un bon joueur d’équipe.
                        </h4>
                    </div>
                </div>
            </div>
            <div className='row d-flex justify-content-center mt-3'>
                <div className='col-12 col-md-8 p-4 align-items-center'>
                    <h4 className='PoppinsLight orange mb-5 text-start'><b>Paragraphe 4</b></h4>
                    <div className='RobotoLight text-start cardGold p-4'>
                        <h4 className='card-text'>
                            Je suis quelqu’un de sensible et j’ai une grande intensité de sentiments
                        </h4>
                        <h4 className='card-text'>
                            Je me sens souvent incompris et seul parce que je me sens différent des autres
                        </h4>
                        <h4 className='card-text'>
                            Mon comportement peut apparaître comme dramatique pour les autres et il arrive que l’on me critique pour mes excès de sensibilité et ma façon d’amplifier mes émotions.
                        </h4>
                        <h4 className='card-text'>
                            En fait, à l’intérieur de moi, il y a une éternelle recherche d’une relation affective à la fois profonde et émotionnellement forte.
                        </h4>
                        <h4 className='card-text'>
                            J’ai du mal à apprécier les relations au présent à cause de ma tendance à toujours vouloir ce que je n’ai pas et à dédaigner ce que j’ai.
                        </h4>
                        <h4 className='card-text'>
                            La recherche de grandes émotions a toujours existé chez moi et l’absence d’un lien émotionnel fort m’a régulièrement conduit à la mélancolie, voire à la dépression.
                        </h4>
                        <h4 className='card-text'>
                            Je me demande parfois comment font les autres, pour avoir plus que moi, une vie privée facile et joyeuse.
                        </h4>
                        <h4 className='card-text'>
                            J’ai un sens aigu de l’esthétique et je vis dans un univers riche d’émotions et de sens.
                        </h4>
                    </div>
                </div>
            </div>
            <div className='row d-flex justify-content-center mt-3'>
                <div className='col-12 col-md-8 p-4 align-items-center'>
                    <h4 className='PoppinsLight orange mb-3 mt-5 text-start'><b>Paragraphe 5</b></h4>
                    <div className='RobotoLight text-start p-4'>
                        <h4 className='card-text'>
                            Je me définirais comme quelqu’un de calme et d’analytique qui a besoin de plus de solitude que la plupart des gens
                        </h4>
                        <h4 className='card-text'>
                            Je préfère généralement observer ce qui se passe plutôt que d’y participer
                        </h4>
                        <h4 className='card-text'>
                            Je n’aime pas que l’on attende beaucoup de moi ou que l’on espère que je connaisse et que je parle beaucoup de mes sentiments.
                        </h4>
                        <h4 className='card-text'>
                            Je parviens mieux à contacter mes émotions quand je suis seul et je profite souvent mieux de certaines expériences vécues en me les remémorant que sur l’instant.
                        </h4>
                        <h4 className='card-text'>
                            Je ne m’ennuie jamais quand je suis seul parce que mon mental est toujours très actif.
                        </h4>
                        <h4 className='card-text'>
                            Il m’est important de protéger mon temps et mon énergie et donc de mener une vie simple, sans complication, et aussi autonome que possible.
                        </h4>
                    </div>
                </div>
            </div>
            <div className='row d-flex justify-content-center mt-3'>
                <div className='col-12 col-md-8 p-4 align-items-center'>
                    <h4 className='PoppinsLight orange mb-5 text-start'><b>Paragraphe 6</b></h4>
                    <div className='RobotoLight text-start cardGold p-4'>
                        <h4 className='card-text'>
                            J’ai une imagination fertile surtout pour ce qui touche à ce qui pourrait menacer la sécurité. En général je peux pressentir ce qui pourrait être dangereux et avoir aussi peur que si ça arrivait réellement.
                        </h4>
                        <h4 className='card-text'>
                            Face au danger, j’ai l’une ou l’autre de ces 2 attitudes: je l’évite ou je passe mon temps à le provoquer.
                        </h4>
                        <h4 className='card-text'>
                            Mon imagination est vive et me donne un sens de l’humour assez original, souvent caustique.
                        </h4>
                        <h4 className='card-text'>
                            J’aimerai être + confiant dans la vie mais j’ai plutôt tendance à me méfier des gens et des choses
                        </h4>
                        <h4 className='card-text'>
                            Je décèle facilement les failles ou les non-dits dans les arguments avancés
                        </h4>
                        <h4 className='card-text'>
                            A cause de cette faculté à déceler les obstacles éventuels je pense que l’on me considère comme quelqu’un d’avisé
                        </h4>
                        <h4 className='card-text'>
                            J’ai tendance à me méfier de l’autorité et je ne me sens pas très à l’aise quand je dois endosser ce rôle.
                        </h4>
                        <h4 className='card-text'>
                            Une fois que je me suis engagé envers une personne ou une cause je suis d’une loyauté à toute épreuve.
                        </h4>
                    </div>
                </div>
            </div>
            <div className='row d-flex justify-content-center mt-3'>
                <div className='col-12 col-md-8 p-4 align-items-center'>
                    <h4 className='PoppinsLight orange mb-3 mt-5 text-start'><b>Paragraphe 7</b></h4>
                    <div className='RobotoLight text-start p-4'>
                        <h4 className='card-text'>
                            Je suis quelqu’un d’optimisme qui aime entreprendre de nouveaux projets.
                        </h4>
                        <h4 className='card-text'>
                            J’ai un esprit vif qui passe très vite d’une idée à l’autre. J’aime avoir une vue d’ensemble sur la façon dont s’enchainent les idées
                        </h4>
                        <h4 className='card-text'>
                            Et je suis particulièrement enthousiaste à l’idée de relier des concepts qui n’avaient à priori pas de point commun.
                        </h4>
                        <h4 className='card-text'>
                            J’aime travailler sur des choses qui m’intéressent et j’y mets beaucoup d’énergie.
                        </h4>
                        <h4 className='card-text'>
                            J’ai du mal à rester présent sur des tâches laborieuses et répétitives.
                        </h4>
                        <h4 className='card-text'>
                            J’aime être au démarrage d’un projet, lors de la planification, quand il y a encore différentes options à considérer.
                        </h4>
                        <h4 className='card-text'>
                            Quand j’ai épuisé mon intérêt pour quelque chose, il m’est difficile d’y demeurer parce que j’ai envie de passer à une autre qui a su capter mon attention.
                        </h4>
                        <h4 className='card-text'>
                            Quand quelque chose me déprime je préfère tourner mes pensées vers des idées plus gaies
                        </h4>
                        <h4 className='card-text'>
                            Je crois que les gens sont en droit de mener une vie joyeuse.
                        </h4>
                    </div>
                </div>
            </div>
            <div className='row d-flex justify-content-center mt-3'>
                <div className='col-12 col-md-8 p-4 align-items-center'>
                    <h4 className='PoppinsLight orange mb-5 text-start'><b>Paragraphe 8</b></h4>
                    <div className='RobotoLight text-start cardGold p-4'>
                        <h4 className='card-text'>
                            J’approche les situations surtout celles qui me tiennent à cœur de façon entière: tout ou rien
                        </h4>
                        <h4 className='card-text'>
                            J’attache de la valeur à être quelqu’un de fort, de juste, et sur qui on peut compter.
                        </h4>
                        <h4 className='card-text'>
                            Quand on me rencontre, pas d’embrouille, je suis ce dont j’ai l’air
                        </h4>
                        <h4 className='card-text'>
                            Je ne fais pas confiance aux autres, avant qu’ils n’aient montré qu’ils étaient fiables.
                        </h4>
                        <h4 className='card-text'>
                            J’aime que les gens soient directs avec moi et je sais quand quelqu’un cache son jeu, ment ou essaye de m’avoir
                        </h4>
                        <h4 className='card-text'>
                            J’ai du mal à tolérer la faiblesse chez les autres, sauf si j’en comprends la raison, ou si je constate qu’ils essayent de s’améliorer sur ce plan.
                        </h4>
                        <h4 className='card-text'>
                            J’ai aussi du mal à recevoir des ordres surtout si je ne respecte pas l’autorité en place, ou si je ne suis pas d’accord avec elle.
                        </h4>
                        <h4 className='card-text'>
                            Je préfère nettement prendre les choses en main.
                        </h4>
                        <h4 className='card-text'>
                            Je trouve difficile de ne pas montrer mes sentiments quand je suis en colère
                        </h4>
                        <h4 className='card-text'>
                            Je suis toujours prêt à défendre mes amis et mes proches, surtout si je considère qu’ils sont traités injustement
                        </h4>
                        <h4 className='card-text'>
                            Je ne gagne pas toujours les batailles que j’entreprends mais en général les autres se souviennent longtemps de leur rencontre avec moi.
                        </h4>
                    </div>
                </div>
            </div>
            <div className='row d-flex justify-content-center mt-3'>
                <div className='col-12 col-md-8 p-4 align-items-center'>
                    <h4 className='PoppinsLight orange mb-3 mt-5 text-start'><b>Paragraphe 9</b></h4>
                    <div className='RobotoLight text-start p-4'>
                        <h4 className='card-text'>
                            J’ai l’impression d’être capable de distinguer l’ensemble des points de vue assez facilement.
                        </h4>
                        <h4 className='card-text'>
                            Je peux même parfois donner l’impression d’être indécis parce que je peux discerner les avantages et les inconvénients de tous les points de vue.
                        </h4>
                        <h4 className='card-text'>
                            Ma facilité à appréhender toutes les opinions me rend bon à aider les autres à résoudre leurs différends.
                        </h4>
                        <h4 className='card-text'>
                            Cette capacité peut m’amener à être davantage conscient des positions des autres et de leurs priorités que des miennes.
                        </h4>
                        <h4 className='card-text'>
                            Il m’arrive d’être distrait et de dévier de la tâche que j’accomplissais
                        </h4>
                        <h4 className='card-text'>
                            Quand ça m’arrive mon attention s’échappe généralement vers des tâches sans importance.
                        </h4>
                        <h4 className='card-text'>
                            J’ai souvent du mal à savoir ce qui est réellement important pour moi et j’évite le conflit en agissant selon le désir des autres.
                        </h4>
                        <h4 className='card-text'>
                            On me considère en général comme facile à vivre, agréable et conciliant.
                        </h4>
                        <h4 className='card-text'>
                            Il en faut beaucoup pour m’amener au point de me mettre ouvertement en colère.
                        </h4>
                        <h4 className='card-text'>
                            J’aime que la vie soit confortable, harmonieuse, et que les autres m’accueillent.
                        </h4>
                    </div>
                </div>
            </div>
            <div className='row d-flex justify-content-center'>
                <div className='col-12 col-md-6 mb-5 mt-4'>
                    {form}
                </div>
            </div>
            <div className='row d-flex justify-content-center gy-3 mt-5 mb-5'>
                <button type='button' className='signupBtn' onClick={saveClickedHandler} disabled={!valid}>
                    <span className='signupBtnSpan'>Enregistrer</span>
                </button>
            </div>
            <Footer/>
        </div>
    )
};

const mapStateToProps = state => ({
    user: state.auth.user,
    isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps, {saveDescriptions, load_user_details}) (Descriptions);