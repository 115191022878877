import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import {useNavigate} from 'react-router-dom';

import { checkValidity } from '../../../shared/utility';
import { saveLeadership, load_user_details } from '../../../store/actions/auth';
import routes from '../../../config/routes';
import Input from '../../../components/UI/Input/Input';
import Footer from '../../Footer/Footer';


function Leadership({user, saveLeadership, load_user_details, isAuthenticated}) {

    //SELECT FIELDS
    const number = [
        {value: '', displayValue: 'Sélectionner votre réponse'},
        {value: 'A', displayValue: 'A'},
        {value: 'B', displayValue: 'B'},
        {value: 'C', displayValue: 'C'},
        {value: 'D', displayValue: 'D'},
        {value: 'E', displayValue: 'E'},
    ]

    //STATE
    const [loader, setLoader] = useState(true);

    const [inputs, setInputs] = useState({
        LEAD1: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "Contrôle de l'éxécution des tâches:",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis",
            proposal_A: "Je me tiens informé de l’avancement des travaux de chacun et je fais régulièrement le point avec tous mes employés.",
            proposal_B: "Il est rare que j’intervienne. Il vaut mieux laisser les employés résoudre seuls leurs problèmes.",
            proposal_C: "Je m'assure que tout va bien et que rien ne manque à personne.",
            proposal_D: "Je me tiens au courant de l’essentiel. S’il le faut je révise les objectifs et les plans de réalisation avec les intéressés.",
            proposal_E: "Je contrôle de prés mes collaborateurs."
        },
        LEAD2: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "Manière dont je prends mes décisions:",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis",
            proposal_A: "Je prends ma décision. C’est le rôle du chef de diriger et d’en assumer la responsabilité.",
            proposal_B: "Je m’efforce que mes décisions soient conformes aux idées de mes supérieurs.",
            proposal_C: "Je réunis ceux de mes collaborateurs qui seront affectés par le choix qui sera fait et nous étudions ensemble ce qu’il y a lieu de décider.",
            proposal_D: "Je rencontre les intéressés un à un afin de connaître leur opinion. Après quoi je prends ma décision et la leur explique.",
            proposal_E: "Je m’efforce de « vendre » mes décisions à mes collaborateurs afin qu’ils les acceptent."
        },
        LEAD3: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "En cas de violation d'une règle ou d'une procédure:",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis",
            proposal_A: "Des règles uniformes sont indispensables à la bonne marche d’une entreprise. Dès qu’une entorse est faite il faut insister pour les faire respecter.",
            proposal_B: "Quand une règle est violée mieux vaut fermer les yeux : de nombreuses règles sont faites pour être violées.",
            proposal_C: "Il ne faut rappeler la règle que dans les cas importants : les règles sont des guide-ânes ; trop d’insistance provoquerait des éclats.",
            proposal_D: "Il est nécessaire que les règles soient respectées mais il faut que le personnel comprenne qu’elles sont édictées dans l’intérêt de tous.",
            proposal_E: "Quand il y a une entorse à une règle, j’en discute avec les intéressés pour en découvrir les raisons et déterminer s’il y a lieu ou non de modifier la règle."
        },
        LEAD4: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "Décision contestée par un subordonnée:",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis",
            proposal_A: "Je réexplique ma décision pour être sur qu’elle a été comprise. Si mon collaborateur est encore réticent, je lui explique qu’il faut l’exécuter même si elle ne lui parait pas appropriée.",
            proposal_B: "Je lui dis : « c’est un ordre » je suis mieux placé que vous pour juger, faites ce qui vous a été demandé.",
            proposal_C: "Je répète ma décision puis, si elle est toujours discutée « bon eh, bien, faites comme vous voulez ».",
            proposal_D: "Je repporte la décision afin d’éviter les histoires.",
            proposal_E: "Je refais la genèse de la décision en expliquant des points qui n’étaient peut être pas connus. Ensuite j’écoute l’exposé des faits nouveaux qui seraient susceptibles de me faire modifier ma décision."
        },
        LEAD5: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "Je dois revenir sur une décision prise sur instructions de la direction:",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis",
            proposal_A: "« Je ne pense pas que ce changement soit bon, je l’ai combattu mais on n’a pas tenu compte de mes objections ».",
            proposal_B: "« La discussion a été rapportée parce que :… » efforçons nous de profiter de l’expérience pour les décisions à prendre ultérieurement..",
            proposal_C: "« C’est ainsi que nous allons dorénavant procéder. Il a fallu changer parce que … ».",
            proposal_D: "« Voici les nouvelles instructions. Maintenant nous allons procéder ainsi ».",
            proposal_E: "« Une fois de plus, ils ont changé d’idée, voici leurs nouvelles instructions »."
        },
        LEAD6: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "Comment je vois ma collaboration avec mon propre supérieur:",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis",
            proposal_A: "J’estime partager la responsabilité de mon chef. Nous formons une équipe.",
            proposal_B: "Je tiens à obtenir des résultats avec le minimum de heurts. J’attends de mon chef qu’il soit réaliste. Je lui rends compte des faits exceptionnels",
            proposal_C: "Je m’efforce de lui souligner la bonne entente de mon équipe. Mes rapports avec lui doivent être cordiaux ; il doit comprendre qu’on obtiendrait plus de rendement sans heurts.",
            proposal_D: "Moins je le vois, mieux je me porte. Je fais ponctuellement tous les rapports et comptes rendus.",
            proposal_E: "Je cherche à m’affirmer par mes résultats. Je désire que mon chef me laisse les coudées franches tant que tout va bien."
        },
        LEAD7: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "Arbitrage de différents entre subordonnés:",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis",
            proposal_A: "Je les convoque ensemble et nous nous efforçons de trouver une solution par la discussion ; il faut faire face aux conflits.",
            proposal_B: "Les conflits sont inadmissibles ; il faut les étouffer avec fermeté",
            proposal_C: "Le mieux est de les laisser régler eux même leurs désaccords.",
            proposal_D: "Je les vois l’un et l’autre et calme les esprits en les persuadant que ce n’est pas si grave.",
            proposal_E: "Je les sépare, leur parle pour départager leurs torts, recherche le meilleur compromis et leur fais comprendre qu’une querelle peut être préjudiciable à tous."
        },
        LEAD8: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "Pour motiver les employés:",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis",
            proposal_A: "Le mieux pour stimuler les efforts et l’enthousiasme est de dire au personnel qu’il doit se mettre au service de l’entreprise et de le laisser faire son travail, sans l’importuner.",
            proposal_B: "Les employés préfèrent les fonctions qui n’exigent que peu de réflexions ou de responsabilités.",
            proposal_C: "La plupart des employés apprécient qu’il y ait un juste équilibre entre les exigences du rendement et la possibilité de ne pas se faire trop de souci.",
            proposal_D: "Pour que les collaborateurs s’intéressent à leur travail, il faut leur donner l’occasion de prendre des décisions dont ils se sentent responsables.",
            proposal_E: "Pour la plupart de mes subordonnés, ce qu’ils gagnent est plus important que ce qu’ils font."
        },
        LEAD9: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: number
            },
            value: '',
            label: "Conception d'ensemble du commandement:",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis",
            proposal_A: "Etre juste mais ferme. Fixer les objectifs au niveau des capacités des employés. Les convaincre qu’ils ont intérêt à faire le maximum.",
            proposal_B: "Maintenir un haut degré de satisfaction parmi les membres de son équipe. Veiller au bon climat de camaraderie. Ne pas exiger un rythme de travail trop élevé.",
            proposal_C: "Prévoir des objectifs élevés, récompenser les individus qui les atteignent et faire aux autres les observations nécessaires. Organiser, diriger et contrôler le travail de façon à ce que les éléments humains ne compromettent pas la productivité.",
            proposal_D: "Intéresser les gens aux résultats en les faisant participer aux décisions. Utiliser leurs idées.",
            proposal_E: "Transmettre scrupuleusement les instructions. Distribuer les tâches aux employés et les laisser faire. Faire un rapport ponctuellement sur les réalisations."
        },

    });

    const [valid, setValid] = useState(false);
    const [quizCompleted, setQuizCompleted] = useState(false);

    //Redirect
    const navigate = useNavigate();

    //ComponentDidMount
    useEffect(() => {
        load_user_details();
        if (!user){
            setTimeout(() => {
            setLoader(false);
            }, 1000);
        }
        else {
            if(user.LEAD){
                navigate(routes.HOME)
            }
            else{
                setLoader(false)
            }
        }
    }, [])

    //ComponentDidUpdate
    useEffect(() => {
        if (!user){
            setTimeout(() => {
            setLoader(false);
            }, 1000);
        }
        else {
            if(user.LEAD){
                navigate(routes.HOME)
            }
            else{
                setLoader(false)
            }
        }
        const auth = localStorage.getItem('authenticated')
        if (! auth){
            alert('vous devez vous connecter pour répondre au questionnaire.')
            navigate(routes.HOME)
        }
    });

    //Functions

    const InputChangedHandler = (event, id) => {

        //Change Value
        const newInputs = {...inputs};
        newInputs[id].value = event.target.value;
        newInputs[id].touched = true;

        //Check value
        newInputs[id].valid = checkValidity(event.target.value, newInputs[id].validation)

        setInputs(newInputs);

        //Form checking
        let formIsValid = true;
        for (let input in newInputs) {
            formIsValid = newInputs[input].valid && formIsValid;
        }
        setValid(formIsValid);
    };

    const formHandler = event => {
        event.preventDefault();
    }


    //Save Confirmation
    const saveClickedHandler = () => {
        console.log(localStorage);
        const LEAD = {
            user: user.email, user_email: user.email,
            LEAD1: inputs.LEAD1.value, LEAD2: inputs.LEAD2.value, LEAD3: inputs.LEAD3.value, LEAD4: inputs.LEAD4.value,
            LEAD5: inputs.LEAD5.value, LEAD6: inputs.LEAD6.value, LEAD7: inputs.LEAD7.value, LEAD8: inputs.LEAD8.value,
            LEAD9: inputs.LEAD9.value
        };
        try{
            saveLeadership(
                LEAD.user, LEAD.user_email,
                LEAD.LEAD1, LEAD.LEAD2, LEAD.LEAD3, LEAD.LEAD4, LEAD.LEAD5, LEAD.LEAD6, LEAD.LEAD7, LEAD.LEAD8, LEAD.LEAD9
            );
            setQuizCompleted(true);
            navigate(routes.QUIZ)
        }
        catch(err){
            alert("Vérifiez que l'ensemble des champs soient renseignés")
        }
    }

    //Variables

    const formElementArray = [];
    for(let key in inputs) {
        formElementArray.push({
            id: key,
            config: inputs[key]
        });
    };

    let form = (
        <form onSubmit={(e) => formHandler(e)}>
            {formElementArray.map(formElement => (
            <div className="row d-flex justify-content-center mt-5" key={formElement.id}>
                <h3 className="RobotoLight orange mt-5">{formElement.config.label}</h3>
                <h4 className="RobotoLight text-white text-start mt-3"><span className="orange fs-2">A </span>{formElement.config.proposal_A}</h4>
                <h4 className="RobotoLight text-white text-start mt-3"><span className="orange fs-2">B </span>{formElement.config.proposal_B}</h4>
                <h4 className="RobotoLight text-white text-start mt-3"><span className="orange fs-2">C </span>{formElement.config.proposal_C}</h4>
                <h4 className="RobotoLight text-white text-start mt-3"><span className="orange fs-2">D </span>{formElement.config.proposal_D}</h4>
                <h4 className="RobotoLight text-white text-start mt-3"><span className="orange fs-2">E </span>{formElement.config.proposal_E}</h4>
                <Input
                    id={formElement.id}
                    value={formElement.config.value}

                    type={formElement.config.elementType}
                    config={formElement.config.elementConfig}
                    valid={formElement.config.valid}
                    touched={formElement.config.touched}
                    errorMessage={formElement.config.errorMessage}
                    changed={(e) => InputChangedHandler(e, formElement.id)}

                />
            </div>
            ))}
        </form>
    );


    return loader ? (
        <>
            <div className="container-fluid">
                <div className='row d-flex justify-content-center mt-5'>

                </div>
            </div>
        </>
    ) : (
        <div className='container-fluid p-3'>
            <div className='row d-flex justify-content-center mt-5'>
                <h1 className='PoppinsLight or'>Leadership</h1>
            </div>
            <div className='row d-flex justify-content-center mt-3'>
                <div className='col-12 col-md-8 cardPurple p-4 mt-5 mb-5'>
                    <h4 className='RobotoThin text-white'>
                        Les questions suivantes évoquent des situations concrètes qui se posent aux chefs de service ou
                        expriment des attitudes à l’égard de certains problèmes.
                    </h4>
                </div>
            </div>
            <div className='row d-flex justify-content-center mt-3'>
                <div className='col-12 col-md-8'>
                    <h4 className='PoppinsLight orange'>
                        Parmi les 5 réponses proposées à chaque thème, choisissez celle qui correspond le mieux à votre
                        manière la plus habituelle de faire ou de penser en pareille circonstance.
                    </h4>
                </div>
            </div>
            <div className='row d-flex justify-content-center'>
                <div className='col-12 col-md-6 mb-5 mt-4'>
                    {form}
                </div>
            </div>
            <div className='row d-flex justify-content-center gy-3 mt-5 mb-5'>
                <button type='submit' className='signupBtn' onClick={saveClickedHandler} disabled={!valid} >
                    <span className='signupBtnSpan roboto'>Enregistrer</span>
                </button>
            </div>
            <Footer/>
        </div>
    )
};

const mapStateToProps = state => ({
    user: state.auth.user,
    isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps, {saveLeadership, load_user_details}) (Leadership);