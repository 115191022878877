//Library
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { checkValidity } from '../../../shared/utility';
import { company_register } from '../../../store/actions/auth';
import routes from '../../../config/routes';

//Components
import Input from '../../../components/UI/Input/Input';

function CompanyRegister({company_register, isAuthenticated}) {

    //State
    const [inputs, setInputs] = useState({
        entreprise: {
            elementType: 'input',
            elementConfig: {
                type: 'name',
                placeholder: "Nom de l'entreprise"
            },
            value: '',
            label: "Nom de l'Entreprise",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis"
        },
        code: {
            elementType: 'input',
            elementConfig: {
                type: 'code',
                placeholder: 'Votre code client'
            },
            value: '',
            label: 'Code Client',
            valid: false,
            validation: {
                required: true,
                minLength: 4,
                maxLength: 4,
            },
            touched: false,
            errorMessage: "Code à 4 chiffre requis"
        },
        referent: {
            elementType: 'input',
            elementConfig: {
                type: 'referent',
                placeholder: 'Nom du référent'
            },
            value: '',
            label: 'Nom du référent machines',
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis"
        },
        telephone: {
            elementType: 'input',
            elementConfig: {
                type: 'telephone',
                placeholder: 'N° de téléphone'
            },
            value: '',
            label: 'Téléphone du référent',
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis"
        }
    });

    const [valid, setValid] = useState(false);


    //Redirect
    const navigate = useNavigate();

    //ComponentDidUpdate
    useEffect(() => {
        const auth = localStorage.getItem('authenticated')
        if (! auth){
            alert('vous devez vous connecter pour enregistrer votre entreprise')
            navigate(routes.HOME)
        }
    });

    //Functions

    const InputChangedHandler = (event, id) => {

        //Change Value
        const newInputs = {...inputs};
        newInputs[id].value = event.target.value;
        newInputs[id].touched = true;

        //Check value
        newInputs[id].valid = checkValidity(event.target.value, newInputs[id].validation)

        setInputs(newInputs);

        //Form checking
        let formIsValid = true;
        for (let input in newInputs) {
            formIsValid = newInputs[input].valid && formIsValid;
        }
        setValid(formIsValid);
    };

    const formHandler = event => {
        event.preventDefault();
    }


    //Confirm Register
    const registerClickedHandler = () => {
        console.log(localStorage);
        const company = {
            user: localStorage.getItem('user'),
            entreprise: inputs.entreprise.value,
            code_client: inputs.code.value,
            contact: inputs.referent.value,
            telephone: inputs.telephone.value
        };
        try{
            company_register(company.user, company.entreprise, company.code_client, company.contact, company.telephone, company.user);
        }
        catch(err){
            alert("Vérifiez que l'ensemble des champs soient renseignés")
        }
    }

    //Variables

    const formElementArray = [];
    for(let key in inputs) {
        formElementArray.push({
            id: key,
            config: inputs[key]
        });
    };

    let form = (
        <form onSubmit={(e) => formHandler(e)}>
            {formElementArray.map(formElement => (
                <Input
                    key={formElement.id}
                    id={formElement.id}
                    value={formElement.config.value}
                    label={formElement.config.label}
                    type={formElement.config.elementType}
                    config={formElement.config.elementConfig}
                    valid={formElement.config.valid}
                    touched={formElement.config.touched}
                    errorMessage={formElement.config.errorMessage}
                    changed={(e) => InputChangedHandler(e, formElement.id)}
                />
            ))}
            <div className='row d-flex justify-content-center gy-3'>
                <button type='button' className='signupBtn' onClick={registerClickedHandler} disabled={!valid}>
                    <span className='signupBtnSpan'>Enregistrer</span>
                </button>
            </div>
        </form>
    );


    return(
        <div className='container-fluid screenHeight100'>
            <div className='row d-flex justify-content-center my-5'>
            <h1 className='text-center mt-5 PoppinsLight or'>Enregistrez votre Entreprise</h1>
                <div className='col-10 col-md-6'>
                    {form}
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated
});


export default connect(mapStateToProps, { company_register }) (CompanyRegister);