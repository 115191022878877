import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import {useNavigate} from 'react-router-dom';
import axiosAccounts from'../../config/axios_accounts';
import {USER_DETAILS_LOADED_SUCCESS, USER_DETAILS_LOADED_FAIL} from '../../store/actions/types';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Input from '../../components/UI/Input/Input';

import { logout, downLoadResults } from '../../store/actions/auth';
import routes from '../../config/routes';

import Logout from './picts/onOff.png';
import Checked from './picts/checked.png';
import unChecked from './picts/unChecked.png';


function Admin ({logout, user, downLoadResults, isAuthenticated, sent}){

    //SELECT FIELDS
    const bs_type = [
        {value: '', displayValue: 'Type de Bilan'},
        {value: 'BR', displayValue: 'Recrutement'},
        {value: 'BE', displayValue: 'Employé'},
        {value: 'BP', displayValue: 'Personnel'},
        {value: 'BS', displayValue: 'Simplifié'},
    ];

    //STATE
    const [loader, setLoader] = useState(true);
    const [candidates, setCandidates] = useState ([]);
    const [query, setQuery] = useState ();
    const [bsheet, setBsheet] = useState ();
    const [loadButton, setLoadButton] = useState (false);

    const [inputs, setInputs] = useState({
        candidate: {
            elementType: 'input',
            elementConfig: {
                type: 'name',
                placeholder: 'Rechercher un candidat'
            },
            value: '',
            label: '',
            valid: false,
            validation: {
                required: false,
            },
            touched: false,
            errorMessage: ""
        },
        balance_sheet: {
            elementType: 'select',
            elementConfig: {
                type: 'name',
                placeholder: "",
                options: bs_type
            },
            value: '',
            label: "",
            valid: false,
            validation: {
                required: false,
            },
            touched: false,
            errorMessage: "",
        }
    });

    //REDIRECT
    const navigate = useNavigate();

    //VARIABLES

    const formElementArray = [];
    for(let key in inputs) {
        formElementArray.push({
            id: key,
            config: inputs[key]
        });
    };

    let form = (
        <form onSubmit={(e) => formHandler(e)}>
            {formElementArray.map(formElement => (
                <div className="row d-flex justify-content-center mt-5" key={formElement.id}>
                    <Input
                        key={formElement.id}
                        id={formElement.id}
                        value={formElement.config.value}
                        label={formElement.config.label}
                        type={formElement.config.elementType}
                        config={formElement.config.elementConfig}
                        valid={formElement.config.valid}
                        touched={formElement.config.touched}
                        errorMessage={formElement.config.errorMessage}
                        changed={(e) => InputChangedHandler(e, formElement.id)}
                        proposal_A={formElement.config.proposal_A}
                        proposal_B={formElement.config.proposal_B}
                    />
                </div>
            ))}
        </form>
    );


    //COMPONENT DID MOUNT

    useEffect(() =>{

        let accessToken = localStorage.getItem('access');

        if( accessToken ) {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `JWT ${accessToken}`,
                    'Accept': 'application/json'
                },
            };

            const axiosFetch = async () => {

                try {
                    const res = await axiosAccounts.get('candidats/', config);
                    const newCandidates = [];
                    for(let key in res.data){
                        if( res.data[key].pk > 1){
                            newCandidates.push({
                            ...res.data[key],
                            id: key
                            });
                        }

                    };
                    newCandidates.sort((a, b) => b.pk - a.pk)
                    setCandidates(newCandidates);
                }
                catch(err){
                    console.log('Axios error')
                }
            };
            axiosFetch();
        }
    }, []);

    useEffect(() => {
        setQuery("");
        setBsheet("");

        //USER INFORMATIONS
        const load_user_details = () => async dispatch => {

            let accessToken = localStorage.getItem('access');

            if ( accessToken ) {
                const config = {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `JWT ${accessToken}`,
                        'Accept': 'application/json'
                    }
                };

                try{
                    const res = await axiosAccounts.get('details/', config);
                    localStorage.setItem('userLoaded', false)
                    dispatch({
                        type: USER_DETAILS_LOADED_SUCCESS,
                        payload: res.data
                    });
                    if (!user){
                       navigate(routes.LOGIN)
                    }
                    else {
                        if (user.is_front_admin === true){
                            navigate(routes.ADMIN)
                        }
                        setLoader(false)
                    }
                }
                catch (err) {
                    showToastError();
                    dispatch({
                        type: USER_DETAILS_LOADED_FAIL
                    });
                }
            }
            else {
                showToastError()
            }
        };
        load_user_details()
    }, []);

    //ComponentDidUpdate
    useEffect(() => {
        const auth = localStorage.getItem('authenticated')
        const loading = localStorage.getItem('userLoaded')
        if (!auth){
            navigate(routes.HOME)
            showToastError()
        }
        if (!user){
            navigate(routes.LOGIN)
        }
        else {
            if (user.is_front_admin === false){
                navigate(routes.HOME)
            }
            if ( loading ){
                setLoader(false)
            }
            if (sent) {
            setLoadButton(false)
            }
        }
    }, [loadButton, sent]);

    //FUNCTIONS

    const InputChangedHandler = (event, id) => {

        //Change Value
        const newInputs = {...inputs};
        newInputs[id].value = event.target.value;
        newInputs[id].touched = true;

        //Check value
//        newInputs[id].valid = checkValidity(event.target.value, newInputs[id].validation)
        setQuery(newInputs.candidate.value)
        setBsheet(newInputs.balance_sheet.value)
        setInputs(newInputs);

//        //Form checking
//        let formIsValid = true;
//        for (let input in newInputs) {
//            formIsValid = newInputs[input].valid && formIsValid;
//        }
//        setValid(formIsValid);
    };

    const formHandler = event => {
        event.preventDefault();
    }
    const goToTrainings = () => {
        navigate(routes.TRAININGS)
    }

    const getCandidates = candidates.map((candidate, index) => {

        const balanceSheet = () => {
            if (candidate.BR === true){
                return <h4 className="RobotoLight or">Recrutement</h4>
            }
            if (candidate.BP === true){
                return <h4 className="RobotoLight or">Bilan Personnel</h4>
            }
            if (candidate.BE === true){
                return <h4 className="RobotoLight or">Bilan Employé</h4>
            }
            if (candidate.BS === true){
                return <h4 className="RobotoLight or">Bilan Simplifié</h4>
            }
        }
        const sent = () => {
            if (candidate.sent === true){
                return <h4 className="RobotoLight or">Transmis</h4>
            }
            if (candidate.sent === false){
                return <h4 className="RobotoLight orange">Non Transmis</h4>
            }
        }
        const checked = () => {
            if (candidate.sent === true){
                return <img src={Checked} alt="Checked" className="img img-fluid"/>
            }
            if (candidate.sent === false){
                return <img src={unChecked} alt="unchecked" className="img img-fluid"/>
            }
        }
        const verified = () => {
            if (candidate.first_name){
                return <h4 className="purple">{candidate.first_name} {candidate.last_name}</h4>
            }
            else{
                return <h4 className="orange RobotoItalic">{candidate.username}</h4>
            }
        }
        const onClickHandler = () => {
            localStorage.setItem('candidate', candidate.email)
            navigate(routes.USER_RESULTS)
        }
        const loadingButton = () => {
            if (loadButton === true){
                return(
                    <div type="button" className="col-3 col-sm-2 col-md-3 col-lg-2 col-xl-1 d-flex align-items-center justify-content-center cardGold ms-1 mt-3 p-2 p-md-2">
                        <h4 className="RobotoMediumItalic purple xs-fs-6 fs-6">Envoi...</h4>
                    </div>
                )
            }
            else{
                return(
                    <div type="button" onClick={downLoad} className="col-3 col-sm-2 col-md-3 col-lg-2 col-xl-1 d-flex align-items-center justify-content-center cardPurple ms-1 mt-3 p-2 p-md-2">
                        <h4 className="RobotoLight text-white xs-fs-6 fs-6">Résultats</h4>
                    </div>
                )
            }
        }
        const downLoad = () => {
            setLoadButton(true);
            localStorage.setItem('candidate', candidate.email);
            downLoadResults();
        }

        if (query === "") {
            if (bsheet === ""){
                return(
                <div className="row d-flex justify-content-center" key={candidate.id}>
                    <div type="button" onClick={onClickHandler} className="col-8 col-lg-9 col-xl-10 d-flex align-items-center cardGold RobotoLight ms-3 mt-3 p-1">
                        <div className="col-6 col-md-4 col-lg-3 col-xl-2 mr-1 ps-md-5">
                            {verified()}
                        </div>
                        <div className="d-none d-xl-block col-xl-4">
                            <h4 className="purple">{candidate.company}</h4>
                        </div>
                        <div className="d-none d-lg-block col-lg-2 col-xl-2 ms-3">
                            {balanceSheet()}
                        </div>
                        <div className="d-none d-md-block col-md-4 col-lg-2 col-xl-1 ms-5 ms-xl-5">
                            {sent()}
                        </div>
                        <div className="col-2 col-sm-1 ms-5 d-md-none">
                            {checked()}
                        </div>
                        <div className="d-none d-md-block col-md-3 col-lg-3 col-xl-2 text-center">
                            <h4 className="purple"><b>{candidate.enneagramme} {candidate.MBTI}</b></h4>
                        </div>
                    </div>
                    {loadingButton()}
                </div>
                )
            }
            else if (candidate.BR === true && bsheet === "BR" ) {
                return(
                <div className="row d-flex justify-content-center" key={candidate.id}>
                    <div type="button" onClick={onClickHandler} className="col-8 col-lg-9 col-xl-10 d-flex align-items-center cardGold RobotoLight ms-3 mt-3 p-1">
                        <div className="col-6 col-md-4 col-lg-3 col-xl-2 mr-1 ps-md-5">
                            {verified()}
                        </div>
                        <div className="d-none d-xl-block col-xl-4">
                            <h4 className="purple">{candidate.company}</h4>
                        </div>
                        <div className="d-none d-lg-block col-lg-2 col-xl-2 ms-3">
                            {balanceSheet()}
                        </div>
                        <div className="d-none d-md-block col-md-4 col-lg-2 col-xl-1 ms-5 ms-xl-5">
                            {sent()}
                        </div>
                        <div className="col-2 col-sm-1 ms-5 d-md-none">
                            {checked()}
                        </div>
                        <div className="d-none d-md-block col-md-3 col-lg-3 col-xl-2 text-center">
                            <h4 className="purple"><b>{candidate.enneagramme} {candidate.MBTI}</b></h4>
                        </div>
                    </div>
                    {loadingButton()}
                </div>
                )
            }
            else if (candidate.BP === true && bsheet === "BP" ) {
                return(
                <div className="row d-flex justify-content-center" key={candidate.id}>
                    <div type="button" onClick={onClickHandler} className="col-8 col-lg-9 col-xl-10 d-flex align-items-center cardGold RobotoLight ms-3 mt-3 p-1">
                        <div className="col-6 col-md-4 col-lg-3 col-xl-2 mr-1 ps-md-5">
                            {verified()}
                        </div>
                        <div className="d-none d-xl-block col-xl-4">
                            <h4 className="purple">{candidate.company}</h4>
                        </div>
                        <div className="d-none d-lg-block col-lg-2 col-xl-2 ms-3">
                            {balanceSheet()}
                        </div>
                        <div className="d-none d-md-block col-md-4 col-lg-2 col-xl-1 ms-5 ms-xl-5">
                            {sent()}
                        </div>
                        <div className="col-2 col-sm-1 ms-5 d-md-none">
                            {checked()}
                        </div>
                        <div className="d-none d-md-block col-md-3 col-lg-3 col-xl-2 text-center">
                            <h4 className="purple"><b>{candidate.enneagramme} {candidate.MBTI}</b></h4>
                        </div>
                    </div>
                    {loadingButton()}
                </div>
                )
            }
            else if (candidate.BE === true && bsheet === "BE" ) {
                return(
                <div className="row d-flex justify-content-center" key={candidate.id}>
                    <div type="button" onClick={onClickHandler} className="col-8 col-lg-9 col-xl-10 d-flex align-items-center cardGold RobotoLight ms-3 mt-3 p-1">
                        <div className="col-6 col-md-4 col-lg-3 col-xl-2 mr-1 ps-md-5">
                            {verified()}
                        </div>
                        <div className="d-none d-xl-block col-xl-4">
                            <h4 className="purple">{candidate.company}</h4>
                        </div>
                        <div className="d-none d-lg-block col-lg-2 col-xl-2 ms-3">
                            {balanceSheet()}
                        </div>
                        <div className="d-none d-md-block col-md-4 col-lg-2 col-xl-1 ms-5 ms-xl-5">
                            {sent()}
                        </div>
                        <div className="col-2 col-sm-1 ms-5 d-md-none">
                            {checked()}
                        </div>
                        <div className="d-none d-md-block col-md-3 col-lg-3 col-xl-2 text-center">
                            <h4 className="purple"><b>{candidate.enneagramme} {candidate.MBTI}</b></h4>
                        </div>
                    </div>
                    {loadingButton()}>
                </div>
                )
            }
            else if (candidate.BS === true && bsheet === "BS" ) {
                return(
                <div className="row d-flex justify-content-center" key={candidate.id}>
                    <div type="button" onClick={onClickHandler} className="col-8 col-lg-9 col-xl-10 d-flex align-items-center cardGold RobotoLight ms-3 mt-3 p-1">
                        <div className="col-6 col-md-4 col-lg-3 col-xl-2 mr-1 ps-md-5">
                            {verified()}
                        </div>
                        <div className="d-none d-xl-block col-xl-4">
                            <h4 className="purple">{candidate.company}</h4>
                        </div>
                        <div className="d-none d-lg-block col-lg-2 col-xl-2 ms-3">
                            {balanceSheet()}
                        </div>
                        <div className="d-none d-md-block col-md-4 col-lg-2 col-xl-1 ms-5 ms-xl-5">
                            {sent()}
                        </div>
                        <div className="col-2 col-sm-1 ms-5 d-md-none">
                            {checked()}
                        </div>
                        <div className="d-none d-md-block col-md-3 col-lg-3 col-xl-2 text-center">
                            <h4 className="purple"><b>{candidate.enneagramme} {candidate.MBTI}</b></h4>
                        </div>
                    </div>
                    {loadingButton()}>
                </div>
                )
            }
        }

        else if (candidate.first_name.toLowerCase().includes(query.toLowerCase()) || candidate.last_name.toLowerCase().includes(query.toLowerCase()) ) {
            return (
                <div className="row d-flex justify-content-center" key={candidate.id}>
                    <div type="button" onClick={onClickHandler} className="col-8 col-lg-9 col-xl-10 d-flex align-items-center cardGold RobotoLight ms-3 mt-3 p-1">
                        <div className="col-6 col-md-4 col-lg-3 col-xl-2 mr-1 ps-md-5">
                            {verified()}
                        </div>
                        <div className="d-none d-xl-block col-xl-4">
                            <h4 className="purple">{candidate.company}</h4>
                        </div>
                        <div className="d-none d-lg-block col-lg-2 col-xl-2 ms-3">
                            {balanceSheet()}
                        </div>
                        <div className="d-none d-md-block col-md-4 col-lg-2 col-xl-1 ms-5 ms-xl-5">
                            {sent()}
                        </div>
                        <div className="col-2 col-sm-1 ms-5 d-md-none">
                            {checked()}
                        </div>
                        <div className="d-none d-md-block col-md-3 col-lg-3 col-xl-2 text-center">
                            <h4 className="purple"><b>{candidate.enneagramme} {candidate.MBTI}</b></h4>
                        </div>
                    </div>
                    {loadingButton()}
                </div>
            )
        }

    });

    const logoutHandler = () => {
        logout();
        navigate(routes.HOME);
    }

    const showToastError = () => {
        toast('vous devez vous connecter pour accéder à votre profil !', {
            position: toast.POSITION.TOP_CENTER
        });
    };

    return loader ? (
        <>
            <div className="container-fluid">
                <div className='row d-flex justify-content-center mt-5 screenHeight100'>

                </div>
            </div>
        </>
    ) : (
        <div className='container-fluid p-1 p-md-4'>
                <div className='row d-flex justify-content-end screenHeight20'>
                    <div className="col-6">
                        <img src={Logout} type='button' onClick={logoutHandler} className='logout' />
                    </div>
                </div>
            <div className='row d-flex justify-content-center mt-5'>
                <h1 className="PoppinsLight purple">Administrateur</h1>
                {user ? <h1 className='PoppinsLight or screenHeight10 mt-5'>Bonjour <span className='PoppinsLight purple'>{user.first_name}</span></h1> : ''}
                <div className="row d-flex justify-content-center">
                    <div type="button" onClick={goToTrainings} className="col-3 col-sm-2 col-md-3 col-lg-2 col-xl-1 d-flex align-items-center justify-content-center cardPurple ms-1 mt-3 p-2 p-md-2">
                        <h4 className="Roboto text-white xs-fs-6 fs-6">Formations</h4>
                    </div>
                </div>
                <div className='col-10 col-md-8 mt-5 mb-5'>
                  {form}
                </div>
            </div>

            <div className="row mt-5">
                <h1 className="PoppinsLight orange mb-5">Liste des candidats</h1>
                {getCandidates}
            </div>
        </div>
    )
};

const mapStateToProps = state => ({
    user: state.auth.user,
    isAuthenticated: state.auth.isAuthenticated,
    sent: state.auth.sent
});

export default connect(mapStateToProps, { logout, downLoadResults }) (Admin);