import React from 'react';
import {NavLink} from 'react-router-dom';




function NavigationItem(props) {
    return (
        <li>
            <NavLink to={props.to} className="nav-link text-dark">{props.children}</NavLink>
        </li>

    );
}

export default NavigationItem;