//Library
import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { checkValidity } from '../../../shared/utility';
import { signup } from '../../../store/actions/auth';
import routes from '../../../config/routes';

//Components
import Input from '../../../components/UI/Input/Input';

function Signup({signup, isAuthenticated}) {

    //State
    const [inputs, setInputs] = useState({
        first_name: {
            elementType: 'input',
            elementConfig: {
                type: 'name',
                placeholder: 'Votre prénom'
            },
            value: '',
            label: 'Prénom',
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis"
        },
        last_name: {
            elementType: 'input',
            elementConfig: {
                type: 'name',
                placeholder: 'Votre nom'
            },
            value: '',
            label: 'Nom',
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis"
        },
        email: {
            elementType: 'input',
            elementConfig: {
                type: 'email',
                placeholder: 'Email'
            },
            value: '',
            label: 'Votre adresse email',
            valid: false,
            validation: {
                required: true,
                email: true
            },
            touched: false,
            errorMessage: "Adresse Email invalide"
        },
        password: {
            elementType: 'input',
            elementConfig: {
                type: 'password',
                placeholder: 'Mot de passe'
            },
            value: '',
            label: 'Votre mot de passe (9 caractères)',
            valid: false,
            validation: {
                required: true,
                minLength: 9,
            },
            touched: false,
            errorMessage: "Votre mot de passe doit contenir au moins 9 caractères et ne pas être trop commun"
        },
        re_password: {
            elementType: 'input',
            elementConfig: {
                type: 'password',
                placeholder: 'Confirmation du mot de passe'
            },
            value: '',
            label: 'Confirmez votre mot de passe',
            valid: false,
            validation: {
                required: true,
                minLength: 9,
                password: '',
            },
            touched: false,
            errorMessage: "Les mots de passe doivent être identiques"
        }
    });

    const [valid, setValid] = useState(false);

    //Functions

    const InputChangedHandler = (event, id) => {

        //Change Value
        const newInputs = {...inputs};
        newInputs[id].value = event.target.value;
        newInputs[id].touched = true;

        //Check value
        inputs.re_password.validation.password = inputs.password.value;
        newInputs[id].valid = checkValidity(event.target.value, newInputs[id].validation)

        setInputs(newInputs);

        //Form checking
        let formIsValid = true;
        for (let input in newInputs) {
            formIsValid = newInputs[input].valid && formIsValid;
        }
        setValid(formIsValid);
    };

    const formHandler = event => {
        event.preventDefault();
    }

    //Redirect
    const navigate = useNavigate();

    //Confirm Register
    const registerClickedHandler = () => {
        let first_name = inputs.first_name.value;
        let last_name = inputs.last_name.value;
        const user = {
            username: first_name + '_' + last_name,
            email: inputs.email.value,
            password: inputs.password.value,
            re_password: inputs.re_password.value,
        };

        if (user.password === user.re_password) {
            signup(user.email, user.username, user.password, user.re_password);
            navigate(routes.HOME);
        }
        else{
            alert("Vérifiez vos mots de passe");
        }

    }

    //Variables

    const formElementArray = [];
    for(let key in inputs) {
        formElementArray.push({
            id: key,
            config: inputs[key]
        });
    };

    let form = (
        <form onSubmit={(e) => formHandler(e)}>
            {formElementArray.map(formElement => (
                <Input
                    key={formElement.id}
                    id={formElement.id}
                    value={formElement.config.value}
                    label={formElement.config.label}
                    type={formElement.config.elementType}
                    config={formElement.config.elementConfig}
                    valid={formElement.config.valid}
                    touched={formElement.config.touched}
                    errorMessage={formElement.config.errorMessage}
                    changed={(e) => InputChangedHandler(e, formElement.id)}
                />
            ))}
            <div className='row d-flex justify-content-center mt-4 gy-3'>
                <button type='button' className='signupBtn' onClick={registerClickedHandler} disabled={!valid}><span className='signupBtnSpan'>Inscription</span></button>
            </div>
        </form>
    );


    return(
        <div className='container-fluid screenHeight100'>
            <div className='row d-flex justify-content-center my-5'>
            <h1 className='text-center mt-5 PoppinsLight or'>Inscription</h1>
                <div className='col-10 col-md-6 mt-5'>
                    {form}
                </div>
                <div className='row d-flex justify-content-center mt-5'>
                    <h3 className='text-center PoppinsLight or'>Déjà inscrit?</h3>
                </div>
                <div className='row d-flex justify-content-center'>
                    <Link className='signupBtn' to={routes.LOGIN} >
                        <span className='signupBtnSpan RobotoBoldItalic'>Connexion</span>
                    </Link>
                </div>
                <div className='row d-flex justify-content-center mt-5'>
                    <h3 className='text-center PoppinsLight or'>Mot de passe oublié?</h3>
                </div>
                <div className='row d-flex justify-content-center'>
                    <Link className='signupBtn' to={routes.RESET_PASSWORD} >
                        <span className='signupBtnSpan'>Nouveau mot de passe</span>
                    </Link>
                </div>
            </div>
        </div>
    );
};



export default connect(null, { signup }) (Signup);