//Library
import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { checkValidity } from '../../../shared/utility';
import { edit_comment, load_user_details } from '../../../store/actions/auth';
import routes from '../../../config/routes';
import axiosAccounts from'../../../config/axios_accounts';

//Components
import Input from '../../../components/UI/Input/Input';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function Comment({edit_comment, load_user_details, user, isAuthenticated}) {

    //STATE
    const [loader, setLoader] = useState(true);
    const [info, setInfo] = useState ({});


    //State
    const [inputs, setInputs] = useState({
        comment: {
            elementType: 'textarea',
            elementConfig: {
                type: 'name',
                placeholder: ""
            },
            value: '',
            label: "",
            valid: false,
            validation: {
                required: true,
            },
            touched: false,
            errorMessage: "Champ requis",
        }
    });

    const [valid, setValid] = useState(false);

    //Functions

    const showToastError = () => {
        toast('vous devez vous connecter pour accéder à votre profil !', {
            position: toast.POSITION.TOP_CENTER
        });
    };

    const InputChangedHandler = (event, id) => {

        //Change Value
        const newInputs = {...inputs};
        newInputs[id].value = event.target.value;
        newInputs[id].touched = true;

        //Check value
        newInputs[id].valid = checkValidity(event.target.value, newInputs[id].validation)

        setInputs(newInputs);

        //Form checking
        let formIsValid = true;
        for (let input in newInputs) {
            formIsValid = newInputs[input].valid && formIsValid;
        }
        setValid(formIsValid);
    };

    const formHandler = event => {
        event.preventDefault();
    }

    //ComponentDidMount

    useEffect(() =>{

        let accessToken = localStorage.getItem('access');
        let user = localStorage.getItem('candidate');

        if( accessToken ) {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `JWT ${accessToken}`,
                    'Accept': 'application/json'
                }
            };

            const axiosFetch = async () => {
                try {
                    const res = await axiosAccounts.get(`details/${user}`, config);
                    console.log('Infos')
                    setInfo(res.data)
                    console.log(info)

                }
                catch(err){
                    console.log('Axios error')
                }
            };
            axiosFetch();
        }
    }, []);

    useEffect(() => {
        load_user_details();
        if (!user){
            setTimeout(() => {
            setLoader(false);
            }, 1000);
        }
        else {
            if (user.is_front_admin === false){
                navigate(routes.HOME)
            }
            setLoader(false)
        }
    }, [])


    //ComponentDidUpdate
    useEffect(() => {
        const auth = localStorage.getItem('authenticated')
        if (! auth){
            navigate(routes.HOME)
            showToastError()
        }
        if (!user){
            setTimeout(() => {
            setLoader(false);
            }, 1000);
        }
        else {
            if (user.is_front_admin === false){
                navigate(routes.HOME)
            }
            setLoader(false)
        }
    });

    //Redirect
    const navigate = useNavigate();

    //Confirm Register
    const registerClickedHandler = () => {

        const user = {
            email: localStorage.getItem('candidate'),
            comment: inputs.comment.value
        };

        try{
            edit_comment(user.email, user.comment);
            navigate(routes.USER_RESULTS);
        }
        catch(err){
            alert("Veuillez renseigner un commentaire")
        }

    }

    //Variables

    const formElementArray = [];
    for(let key in inputs) {
        formElementArray.push({
            id: key,
            config: inputs[key]
        });
    };

    let form = (
        <form onSubmit={(e) => formHandler(e)}>
            {formElementArray.map(formElement => (
                <div className="row d-flex justify-content-center mt-5" key={formElement.id}>
                    <Input
                        key={formElement.id}
                        id={formElement.id}
                        value={formElement.config.value}
                        label={formElement.config.label}
                        type={formElement.config.elementType}
                        config={formElement.config.elementConfig}
                        valid={formElement.config.valid}
                        touched={formElement.config.touched}
                        errorMessage={formElement.config.errorMessage}
                        changed={(e) => InputChangedHandler(e, formElement.id)}
                        proposal_A={formElement.config.proposal_A}
                        proposal_B={formElement.config.proposal_B}
                    />
                </div>
            ))}
            <div className='row d-flex justify-content-center gy-3 mt-5 mb-5'>
                <button type='button' className='signupBtn' onClick={registerClickedHandler} disabled={!valid}><span className='signupBtnSpan RobotoBold'>Enregistrer</span></button>
            </div>
        </form>
    );


    return loader ? (
        <>
            <div className="container-fluid">
                <div className='row d-flex justify-content-center mt-5'>

                </div>
            </div>
        </>
    ) : (
        <div className='container-fluid'>
            <div className='row d-flex justify-content-center my-5'>
            <h1 className='text-center mt-5 PoppinsLight or'>Commentaire</h1>
            <h1 className='text-center mt-5 PoppinsLight purple'>{info.first_name} {info.last_name}</h1>
                <div className='col-12 col-md-6'>
                    {form}
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = state => ({
    user: state.auth.user,
    isAuthenticated: state.auth.isAuthenticated
});

export default connect(null, { edit_comment, load_user_details }) (Comment);