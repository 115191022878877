import axios from 'axios';

//const axiosDjoser = axios.create({
//    baseURL: 'http://127.0.0.1:8000/auth/'
//});

const axiosDjoser = axios.create({
    baseURL: 'https://kkqf31ivb3.execute-api.ap-southeast-2.amazonaws.com/dev/auth/'
});

export default axiosDjoser;